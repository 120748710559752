import * as d3 from "d3";

const dateFormatYSEOP = d3.utcFormat("%d/%m/%Y");

const countryMap = {
  UK: "United Kingdom",
  USA: "United States",
  UAE: "United Arab Emirates",
};

export default function (country, market, data, metric) {
    const correct = (s) => s.replace("&", "&amp;");
    const firstLetter = (s) =>
      s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    const prepareCountry = (c) => {
      return (countryMap[c] || c)
        .split(" ")
        .map((s) => firstLetter(s))
        .join(" ");
    };
  
    return `<?xml version="1.0" encoding="UTF-8"?>
      <y:input xmlns:y="http://www.yseop.com/engine/3">
        <y:data>
          <y:instance yid="thePerformanceAnalysis" yclass="GeneralData">
            <countryLabel>${prepareCountry(country)}</countryLabel>
            ${
              ["EU", "Euro", "EUR", "EU (MNF)", "EU (PUB)"].includes(metric)
                ? ""
                : `<volumeMetric>${metric}</volumeMetric>`
            }
            <analysisDates yclass="List">
              ${data[0].values.reduce(
                (s, v) => s + `<values>${dateFormatYSEOP(v.date)}</values>`,
                ""
              )}
            </analysisDates>
            <analysisResults yid="ANALYSIS_COUNTRY">
              <performanceIndicators yclass="PerformanceIndicatorTree">
                <indicatorName>${correct(
                  market.split(" in ").pop()
                )}</indicatorName>
                ${data.reduce((s, d) => {
                  const child = `<child yclass="PerformanceIndicatorTree">
                    <indicatorName>${correct(d.productGroup1)}</indicatorName>
                    <indicatorCompany>${correct(
                      d.corporationGroup1
                    )}</indicatorCompany>
                    <productGroup2>${correct(d.productGroup2)}</productGroup2>
                    <isNewLaunch>${d.isNewLaunch}</isNewLaunch>
                    <numericValues yclass="List">
                      ${d.values.reduce(
                        (s, v) => s + `<values>${Math.round(v.value)}</values>`,
                        ""
                      )}
                    </numericValues>
                  </child>
                  `;
                  return s + child;
                }, "")}
              </performanceIndicators>
            </analysisResults>
          </y:instance>
        </y:data>
      </y:input>
    `;
  };