import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import { actionRemoveNotification } from './../../store/actions';

const MyAlert = withStyles((theme) => ({
  root: {
    paddingRight: 20,
  },
}))(Alert);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 10,
    //left: 10,
    zIndex: 9999999,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    pointerEvents: "none",
  },
  notification: {
    position: 'relative',
    margin: 6,
    animation: '$myanimation 7s',
    pointerEvents: "auto",

    '&:hover svg': {
      display: 'block',
    },
  },
  remove: {
    display: 'none',
    position: 'absolute',
    top: 3,
    right: 3,
    width: 15,
    height: 15,
    cursor: 'pointer',
  },
  /* Стандартный синтаксис */
  '@keyframes myanimation': {
    '0%': { opacity: 0 },
    '1%': { opacity: 1 },
    '60%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
}));

function Notifications({ notifications, actionRemoveNotification: removeNotification }) {
  const classes = useStyles();

  const remove = useCallback(
    (id) => {
      removeNotification({ id });
    },
    [notifications]
  );

  useEffect(() => {
    if (notifications.length) {
      const id = notifications[notifications.length - 1].id;

      async function delayedDeletion(id) {
        setTimeout(() => removeNotification({ id }), 7000);
      }

      delayedDeletion(id);
    }
  }, [notifications]);

  return (
    <div className={classes.root}>
      {notifications.map((el) => (
        <div key={el.id} className={classes.notification} id={el.id}>
          <MyAlert severity={el.type}>{el.text}</MyAlert>
          <CloseIcon className={classes.remove} fontSize="small" onClick={() => remove(el.id)} />
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = ({ data: state }, props) => {
  return {
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionRemoveNotification: (n) => dispatch(actionRemoveNotification(n))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
