import React from "react";
import * as d3 from "d3";
import ReactDOM from "react-dom";

import "./Chart.scss";

const defaultProps = {
  margin: { top: 20, right: 80, bottom: 100, left: 80 },
  margin2: { top: 200, right: 80, bottom: 30, left: 80 },
  width: 540,
  height: 250,
  height2: 250
};

const parseDate = d3.utcParse("%d %b %Y");
const formatDate = d3.utcFormat("%b %Y");
const formatDateBrushAxis = (date) => {
  const _date = parseDate(date);
  return _date ? formatDate(_date) : "";
}

const defined = (d, col) => !isNaN(d[col.name]) && d[col.name] !== null;

class _BarLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legendState: []
    }

    this.type = "barline";

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
  }

  updateDimensions = () => {
    const {
      data,
      id,
      cols,
      full_screen
    } = this.props;

    const axisLabels = data.map(d => d.date);

    this.cols = cols;

    this.type = "barline-brush";//type;
    this.secondYAxis = true;
    this.id = id;

    this.axisFontSize = full_screen ? '11px' : '10px';
    this.labelFontSize = full_screen ? '12px' : '10px';

    const width = this.refs[`chart_${id}`].parentNode.clientWidth;

    this.width = width || defaultProps.width;
    this.height = defaultProps.height;
    this.height2 = defaultProps.height2;
    this.margin = defaultProps.margin;
    this.margin2 = defaultProps.margin2;

    this.innerWidth = this.width - this.margin.left - this.margin.right;
    this.innerHeight = this.height - this.margin.top - this.margin.bottom + (full_screen ? -10 : 0);
    this.innerHeight2 = this.height2 - this.margin2.top - this.margin2.bottom + (full_screen ? -10 : 0);

    if (this.secondYAxis) {
      this.y1 = d3.scaleLinear().range([this.innerHeight, 0]); // for bar chart
      this.y3 = d3.scaleLinear().range([this.innerHeight2, 0]); // for bar chart brush
    }

    this.y = d3.scaleLinear().range([this.innerHeight, 0]);
    this.y2 = d3.scaleLinear().range([this.innerHeight2, 0]);
    this.x = d3.scaleLinear().range([0, this.innerWidth]);
    this.x2 = d3.scaleLinear().range([0, this.innerWidth]);
    this.xBand = d3.scaleBand().domain(d3.range(-1, axisLabels.length)).range([0, this.innerWidth]);
    this.xBand2 = d3.scaleBand().domain(d3.range(-1, axisLabels.length)).range([0, this.innerWidth]);

    this.initial(data, axisLabels);
  }

  initial = (data, axisLabels) => {
    const { id, type, chartType, innerWidth, innerHeight, innerHeight2, margin, margin2, selectedDate } = this;

    // clear old svg
    d3
      .select(ReactDOM.findDOMNode(this))
      .select('.' + type + "-svg")
      .remove();

    // clear old tooltip
    d3
      .select("body")
      .select("#tooltip_" + id)
      .remove()

    // create new tooltip  
    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("id", "tooltip_" + id)
      .attr("class", "tooltip")
      .style("opacity", 0);

    this.line = col => d3.line()
      .x((d, i) => this.x(i))
      .y((d) => this.y(d[col.name]))
      .curve(d3.curveMonotoneX);

    this.line2 = col => d3.line()
      .x((d, i) => this.x2(i))
      .y((d) => this.y2(d[col.name]))
      .curve(d3.curveMonotoneX);

    // add svg
    this.svg = d3
      .select(ReactDOM.findDOMNode(this))
      .append("svg")
      .attr("class", type + "-svg")
      .attr("width", innerWidth + margin.left + margin.right)
      .attr("height", innerHeight + margin.top + margin.bottom + (this.props.full_screen ? 10 : 0));

    this.focus = this.svg
      .append("g")
      .attr("class", "focus")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    this.context = this.svg.append("g")
      .attr("class", "context")
      .attr("transform", "translate(" + margin2.left + "," + margin2.top + ")");

    // add legend
    this.svg
      .append("g")
      .attr("class", "legend");

    // // add axes
    this.focus.append("g").attr("class", "x-axis");
    this.context.append("g").attr("class", "x2-axis");
    this.focus.append("g").attr("class", "y-axis");

    if (this.secondYAxis) this.focus.append("g").attr("class", "y-axis y1-axis").attr("transform", "translate( " + innerWidth + ", 0 )");
    this.focus.append("g").attr("class", "zero-line");

    // add multiple line charts
    this.focus
      .append("g")
      .attr("class", "lines")
      .attr("fill", "none");

    this.prepareData(data, axisLabels);
    this.redrawLineChart(data, axisLabels);

    // update axes
    this.updateAxes(axisLabels);

    this.updateLegend();
  };

  prepareData = (data, axisLabels) => {
    const bar_col = this.cols.filter(d => d.type === "bar")[0];
    const line_col = this.cols.filter(d => d.type === "line")[0];

    this.x.domain([-1, axisLabels.length]);
    this.x2.domain(this.x.domain());
    this.y.domain([0, 1.1 * d3.max(data, d => d[line_col.name])]);
    this.y2.domain(this.y.domain());

    if (this.secondYAxis) {
      this.y1.domain([0, 1.1 * d3.max(data, d => d[bar_col.name])]);
      this.y3.domain(this.y1.domain());
    }
  };

  updateAxes = (axisLabels) => {
    const { axisFontSize } = this;
    this.svg
      .select(".x-axis")
      .attr("transform", "translate(0," + this.innerHeight + ")")
      .call(d3.axisBottom(this.x).tickFormat((d, e) => axisLabels[d]))//.ticks(10))
      .selectAll("text")
      .style("text-anchor", "end")
      .style("font-size", "8px")
      .attr("dx", "-.55em")
      .attr("dy", ".45em")
      .attr("transform", "rotate(-35)");

    this.svg
      .select(".x2-axis")
      .attr("transform", "translate(0," + this.innerHeight2 + ")")
      .call(d3.axisBottom(this.x2).tickFormat((d, e) => formatDateBrushAxis(axisLabels[d])).ticks(3))
      .selectAll("text")
      .style("text-anchor", "end")
      .style("font-size", "8px")
      .attr("dx", "1em")
      .attr("dy", ".45em");

    this.svg
      .select(".y-axis")
      .call(
        d3
          .axisLeft(this.y)
      )
      .selectAll("text")
      .style("font-size", axisFontSize);

    if (this.secondYAxis) {
      this.svg.select(".y1-axis").call(
        d3
          .axisRight(this.y1)
      ).selectAll("text")
        .style("font-size", axisFontSize);
    }
  };

  updateLegend() {
    const that = this;
    const cols = this.cols;
    const { axisFontSize, margin, innerWidth, height } = this;
    const { legendState } = this.state;
    this.svg
      .select(".legend")
      .selectAll(".legend-item")
      .remove();

    const _legend = this.svg.select(".legend");

    const legend = _legend
      .selectAll(".legend-item")
      .data(cols)
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .style("cursor", "pointer")
      .style("opacity", d => legendState.includes(d.name) ? .4 : 1)
      .on('click', handleClickLegend)

    let xOffset = 0, yOffset = 0, xPadding = 25, yPadding = 12;

    legend.each((f, i) => {
      const node = legend.nodes()[i];
      const legendItem = d3.select(node);

      legendItem.attr("transform", `translate(${xOffset},${yOffset})`);
      if (f.type === "bar") {
        legendItem
          .append("rect")
          .attr("x", 0)
          .attr("y", 0)
          .attr("width", 20)
          .attr("height", 7)
          .style("fill", f.color)
      } else if (f.type === "line") {
        legendItem
          .append("line")
          .attr("x1", 0)
          .attr("y1", 3.5)
          .attr("x2", 20)
          .attr("y2", 3.5)
          .attr("fill", "none")
          .attr("stroke", f.color)
          .attr("stroke-width", 3)
      }

      legendItem
        .append("text")
        .attr("x", 26)
        .attr("y", 7)
        .text(f.title)
        .style("font-size", axisFontSize);

      const rect = node.getBBox();
      if (xOffset + rect.width + xPadding > this.innerWidth - 5) {
        xOffset = 0;
        yOffset += yPadding;
      } else {
        xOffset += rect.width + xPadding;
      }
    });

    const size = _legend.node().getBBox();
    _legend.attr("transform", `translate(${margin.left + (innerWidth - size.width) / 2},${height - 10})`);

    function handleClickLegend(obj) {
      that.setState(state => {
        let legendState = [...state.legendState];
        const idx = state.legendState.findIndex(fi => fi === obj.name);
        if (idx !== -1) {
          legendState = state.legendState.filter((item) => item !== obj.name);
        } else {
          legendState = [...state.legendState, obj.name]
        }
        return {
          legendState,
        };
      })
    }
  }

  redrawLineChart = (data, axisLabels) => {
    const that = this;
    const tooltip = this.tooltip;

    const bar_col = this.cols.filter(d => d.type === "bar")[0];
    const line_col = this.cols.filter(d => d.type === "line")[0];

    const lines = this.svg.select(".lines");

    const _focus = lines.append("g")
      .attr("clip-path", "url(#clip_barline)");

    this.brush = d3.brushX()
      .extent([[0, 0], [this.innerWidth, this.innerHeight2]])
      .on("brush end", brushed);

    const bar_focus = _focus
      .append("g")
      .attr("class", "bar-focus")

    const line_focus = _focus
      .append("g")
      .attr("class", "line-focus")

    bar_focus
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d, i) => this.x(i) - this.xBand.bandwidth() * 0.8 / 2)
      .attr('y', d => this.secondYAxis ? this.y1(d[bar_col.name]) : this.y(d[bar_col.name]))
      .attr('width', this.xBand.bandwidth() * 0.8)
      .attr('height', d => this.innerHeight - (this.secondYAxis ? this.y1(d[bar_col.name]) : this.y(d[bar_col.name])))
      .attr("fill", bar_col.color)

    line_focus
      .append("path")
      .attr("class", "line")
      .attr("d", this.line(line_col)(data))
      .style("stroke", line_col.color)
      .style("stroke-width", "2.5px");

    line_focus
      .append("circle")
      .attr("class", "dot_hover")
      .attr("r", 3)
      .style("stroke", "none")
      .attr("fill", "none")


    const tooltipLine = this.focus.append("line").attr("class", "tooltip_line");

    const tipBox = this.focus
      .append('rect')
      .attr('width', this.innerWidth)
      .attr('height', this.innerHeight)
      .attr('opacity', 0)
      .on('mousemove', drawTooltip)
      .on('mouseout', removeTooltip);

    const _context = this.context.append("g").attr("class", "context-charts");

    const bar_context = _context.append("g").attr("class", "bar-context");

    const line_context = _context.append("g").attr("class", "line-context");

    bar_context
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d, i) => this.x2(i) - this.xBand2.bandwidth() * 0.8 / 2)
      .attr('y', d => this.secondYAxis ? this.y3(d[bar_col.name]) : this.y2(d[bar_col.name]))
      .attr('width', this.xBand2.bandwidth() * 0.8)
      .attr('height', d => this.innerHeight2 - (this.secondYAxis ? this.y3(d[bar_col.name]) : this.y2(d[bar_col.name])))
      .attr("fill", bar_col.color)

    line_context
      .append("path")
      .attr("class", "line")
      .attr("d", this.line2(line_col)(data))
      .style("stroke", line_col.color)
      .style("stroke-width", "1.5px");

    const br_bgr = this.context
      .append("g")
      .attr("class", "brush-background");

    br_bgr
      .append("rect")
      .attr("class", "left")
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 0)
      .attr('height', this.innerHeight2)

    br_bgr
      .append("rect")
      .attr("class", "right")
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 0)
      .attr('height', this.innerHeight2)

    this.context
      .append("g")
      .attr("class", "brush")
      .call(this.brush)
    // .call(this.brush.move, this.x.range());

    const defs = lines.append('defs')

    // use clipPath
    defs.append('clipPath')
      .attr('id', 'clip_barline')
      .append('rect')
      .attr('width', this.innerWidth)
      .attr('height', this.innerHeight)

    function brushed() {
      let newXBand = [];

      let s = d3.event.selection || that.x2.range()

      that.xBand2.domain().forEach((d) => {
        var pos = that.xBand2(d) + that.xBand2.bandwidth() * 0.8 / 2;
        if (pos >= s[0] && pos <= s[1]) {
          newXBand.push(d);
        }
      });

      that.x.domain(s.map(that.x2.invert, that.x2))

      that.xBand.domain(newXBand);

      that.updateAxes(axisLabels);
      lines.select(".line")
        .attr("d", that.line(line_col)(data));
      lines.selectAll('.bar')
        .attr('x', (d, i) => that.x(i) - that.xBand.bandwidth() * 0.8 / 2)
        .attr('width', that.xBand.bandwidth() * 0.8);

      br_bgr.select(".left").attr("width", s[0])
      br_bgr.select(".right").attr("x", s[1]).attr("width", (that.innerWidth - s[1]))
    }

    function drawTooltip() {
      const date = Math.floor((that.x.invert(d3.mouse(tipBox.node())[0]) + 0.5));

      if (date >= 0 && date <= data.length - 1) {
        line_focus.select(".dot_hover")
          .attr("cx", that.x(date))
          .attr("cy", that.y(data[date][line_col.name]))
          .attr("fill", "#fff")
          .style("stroke", line_col.color)

        tooltipLine
          .attr('stroke', 'hsla(0, 0%, 47%, 0.6)')
          .attr('x1', that.x(date))
          .attr('x2', that.x(date))
          .attr('y1', 0)
          .attr('y2', that.innerHeight);

        const tpl =
          `<ul>
            <li >${data[date].date}</li>
            <li><div class="square" style="background-color: ${line_col.color}"></div>${line_col.title}: ${data[date][line_col.name]}</li>
            <li><div class="square" style="background-color: ${bar_col.color}"></div>${bar_col.title}: ${data[date][bar_col.name]}</li>
          </ul>`

        tooltip
          // .transition()
          // .duration(200)
          .style("display", "block") // TODO
          .style("opacity", 0.9);

        tooltip
          .html(tpl)
          .style("left", `${d3.event.pageX + 8}px`)
          .style("top", `${d3.event.pageY - 48}px`);
      }
    }

    function removeTooltip() {
      if (tooltip)
        tooltip
          .style("display", "none") // TODO
          .style('opacity', 0);
      if (tooltipLine) tooltipLine.attr('stroke', 'none');
      if (line_focus.select(".dot_hover"))
        line_focus.select(".dot_hover")
          .attr("fill", "none")
          .style("stroke", "none")
    }

  }

  showHideCharts = (legendState) => {
    const { data } = this.props;
    this.cols.forEach(d => {
      this.svg.select(`.${d.type}-focus`).style("opacity", legendState.includes(d.name) ? 0 : 1)
      this.svg.select(`.${d.type}-context`).style("opacity", legendState.includes(d.name) ? 0 : 1)
    })
    const axisLabels = data.map(d => d.date);
    this.updateAxes(axisLabels)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { legendState } = nextState;
    if (legendState.length !== this.state.legendState.length || !legendState.every((v, i) => v === this.state.legendState[i])) return true;
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateLegend()
    this.showHideCharts(this.state.legendState)
  }

  componentWillReceiveProps(next) {
  }

  render() {
    return (
      <div ref={`chart_${this.props.id}`} className="barline-brush">
        {this.props.children}
      </div>
    );
  }
}

export default _BarLine