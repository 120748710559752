import { useMediaQuery } from "@material-ui/core";

function useIsIpad() {
  const isSm = useMediaQuery("(max-width: 1024px)");
  return isSm;
}

function useIsSmall() {
  const isSm = useMediaQuery("(max-width: 600px)");
  return isSm;
}

export { useIsIpad, useIsSmall };
