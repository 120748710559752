import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    margin: '3.3rem 1rem 0 1rem',
  },
  horizontalContainer: {
    display: 'flex',
  },
  box: { padding: '.5rem' },
});

export default function LoadingComponent() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton variant="rect" width={'100%'} height={55} />
      <Box className={classes.box}></Box>
      <div className={classes.horizontalContainer}>
        <Skeleton variant="rect" width={'100%'} height={'70vh'} />
        <Box className={classes.box}></Box>
        <Skeleton variant="rect" width={'280px'} height={'70vh'} />
      </div>
    </div>
  );
}

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import LinearProgress from '@material-ui/core/LinearProgress';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     marginTop:"4rem",
//     marginLeft: "20%",
//     width: '60%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

// export default function LoadingComponent() {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <LinearProgress />
//       <LinearProgress color="secondary" />
//     </div>
//   );
// }
