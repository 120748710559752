import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '../text-field';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 120,
    width: '100%',
  },
  inputRoot: {
    width: '100%',
  },
  paper: {
    width: '210px',
  },
  list: {
    maxHeight: '20rem',
    '& li': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  },
}));

const Component = ({
  label,
  disabled = false,
  value = '',
  values = [],
  style = {},
  handleChange,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={style}>
      <TextField
        select
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        label={label}
        variant="outlined"
        className={classes.inputRoot}
        disabled={disabled}
        SelectProps={{
          MenuProps: {
            classes: {
              paper: classes.paper,
              list: classes.list,
            },
          },
        }}>
        {values.map((d) => (
          <MenuItem key={d.value} value={d.value}>
            {d.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export const Select = (props) => <Component {...props} />;
