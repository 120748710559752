import { sum } from 'd3';

const calcs = {
  MG: ({ columns: [col] }, { value, valuePrev }) =>
    valuePrev[col] !== 0 && value[col] !== 0
      ? value[col] / valuePrev[col] - 1
      : 0,
  GtPY: ({ columns: [col] }, { value, valuePrev }) =>
    valuePrev[col] !== 0 && value[col] !== 0
      ? value[col] / valuePrev[col] - 1
      : 0,
  GtPY_total: ({ columns: [col] }, { total, totalPrev }) =>
    totalPrev[col] !== 0 && total[col] !== 0
      ? total[col] / totalPrev[col] - 1
      : 0,

  GtMkt: ({ columns: [col] }, { value, total, valuePrev, totalPrev }) =>
    valuePrev[col] !== 0 && totalPrev[col] !== 0
      ? value[col] / valuePrev[col] - 1 - (total[col] / totalPrev[col] - 1)
      : 0,
  EI: ({ columns: [col] }, { value, total, valuePrev, totalPrev }) =>
    valuePrev[col] !== 0 &&
    totalPrev[col] !== 0 &&
    total[col] !== totalPrev[col]
      ? (100 * (value[col] / valuePrev[col] - 1 + 1)) /
        (total[col] / totalPrev[col] - 1 + 1)
      : 0,
  AG: ({ columns: [col] }, { value, valuePrev }) => value[col] - valuePrev[col],

  MSE: ({ columns: [col] }, { value, total }) =>
    total[col] !== 0 ? value[col] / total[col] : 0,
  AS: ({ columns: [col] }, { value }) => value[col],
  DMS: ({ columns: [col] }, { value, total, valuePrev, totalPrev }) =>
    total[col] !== 0 && totalPrev[col] !== 0
      ? value[col] / total[col] - valuePrev[col] / totalPrev[col]
      : 0,

  PLP: ({ columns: [col], marketColumns: [m_col] }, { value, valuePrev }) =>
    valuePrev[col] !== 0 ? value[m_col] / valuePrev[col] : 0,

  SoV_PR: ({ columns: [col] }, { value, total }) =>
    total[col] !== 0 ? value[col] / total[col] : 0,

  // Patients Percents
  PP: ({ patientType: [PT], dynamicCleaned: [N, S] }, { value, total }) => {
    return S
      ? total[PT + ':' + N] + total[PT + ':' + S] !== 0
        ? (value[PT + ':' + N] + value[PT + ':' + S]) /
          (total[PT + ':' + N] + total[PT + ':' + S])
        : 0
      : total[PT + ':' + N] !== 0
      ? value[PT + ':' + N] / total[PT + ':' + N]
      : 0;
  },
  // Patients Absolute
  PA: ({ patientType: [PT], dynamicCleaned: [N, S] }, { value }) => {
    return S ? value[PT + ':' + N] + value[PT + ':' + S] : value[PT + ':' + N];
  },
  // Patients_weekly Percents
  PPNS: (
    { patientType: [PT], dynamicCleaned: [N, S, NS] },
    { value, total }
  ) => {
    return NS
      ? total[PT + ':' + N] + total[PT + ':' + S] + total[PT + ':' + NS] !== 0
        ? (value[PT + ':' + N] + value[PT + ':' + S] + value[PT + ':' + NS]) /
          (total[PT + ':' + N] + total[PT + ':' + S] + +total[PT + ':' + NS])
        : 0
      : S
      ? total[PT + ':' + N] + total[PT + ':' + S] !== 0
        ? (value[PT + ':' + N] + value[PT + ':' + S]) /
          (total[PT + ':' + N] + total[PT + ':' + S])
        : 0
      : total[PT + ':' + N] !== 0
      ? value[PT + ':' + N] / total[PT + ':' + N]
      : 0;
  },
  // Patients_weekly Absolute
  PANS: ({ patientType: [PT], dynamicCleaned: [N, S, NS] }, { value }) => {
    return NS
      ? value[PT + ':' + N] + value[PT + ':' + S] + value[PT + ':' + NS]
      : S
      ? value[PT + ':' + N] + value[PT + ':' + S]
      : value[PT + ':' + N];
  },
  // Patients Total Percents
  PTP: ({ patientType: [PT] }, { value, total }) =>
    total[PT] !== 0 ? value[PT] / total[PT] : 0,
  // Patients Total Absolute
  PTA: ({ patientType: [PT] }, { value }) => value[PT],
  PTNSA: (
    { patientType: [PT], dynamicCleaned: [N, S, A] },
    { value, total }
  ) => {
    return value[PT + ':' + N];
  },

  // SOB
  // Patients Percents
  SOB_TAD_P: ({ col }, { value, total }) =>
    total[col] !== 0 ? value[col] / total[col] : 0,
  // Patients Absolute
  SOB_TAD_A: ({ col }, { value }) => value[col],

  // EOG
  EOG_E: ({ columns: [col0, col1] }, { value, valuePrev }) =>
    valuePrev[col1] !== 0 ? value[col0] / valuePrev[col1] : 0,
  EOG_LE: ({ columns: [col0, col1, col2, col3] }, { value, valuePrev }) =>
    valuePrev[col0] !== 0 ? value[col3] / valuePrev[col0] : 0,
  EOG_PE: ({ columns: [col0, col1, col2, col3] }, { value, valuePrev }) =>
    valuePrev[col0] !== 0 ? value[col2] / valuePrev[col0] : 0,
  EOG_VE: ({ columns: [col0, col1, col2, col3] }, { value, valuePrev }) =>
    calcs['MG']({ columns: [col1] }, { value, valuePrev }),
  EOG_ME: ({ columns: [col0, col1, col2, col3] }, { value, valuePrev }) => {
    const LE = valuePrev[col0] !== 0 ? value[col3] / valuePrev[col0] : 0;
    const PE = valuePrev[col0] !== 0 ? value[col2] / valuePrev[col0] : 0;
    const VE = calcs['MG']({ columns: [col1] }, { value, valuePrev });
    const TE = calcs['MG']({ columns: [col0] }, { value, valuePrev });
    const ME = TE - (LE + PE + VE);
    return ME;
  },

  // Finance
  F_Act: ({ columns: [A] }, { value }) => value[A],
  F_Bud: ({ columns: [, B] }, { value }) => value[B],
  F_F1: ({ columns: [, , F1] }, { value }) => value[F1],
  F_F2: ({ columns: [, , , F2] }, { value }) => value[F2],
  F_Tgt: ({ columns: [, B, F1, F2] }, { value }) =>
    value[F2] || value[F1] || value[B],
  F_AB: ({ columns: [A, B] }, { value }) => value[A] - value[B],
  F_PAB: ({ columns: [A, B] }, { value }) =>
    value[B] !== 0 && value[A] !== 0 ? value[A] / value[B] - 1 : 0,
  F_AF1: ({ columns: [A, B, F1] }, { value }) => value[A] - value[F1],
  F_PAF1: ({ columns: [A, B, F1] }, { value }) =>
    value[F1] !== 0 && value[A] !== 0 ? value[A] / value[F1] - 1 : 0,
  F_AF2: ({ columns: [A, B, F1, F2] }, { value }) => value[A] - value[F2],
  F_PAF2: ({ columns: [A, B, F1, F2] }, { value }) =>
    value[F2] !== 0 && value[A] !== 0 ? value[A] / value[F2] - 1 : 0,
  F_AT: ({ columns: [A, B, F1, F2] }, { value }) =>
    value[F2]
      ? value[A] - value[F2]
      : value[F1]
      ? value[A] - value[F1]
      : value[A] - value[B],
  F_PAT: ({ columns: [A, B, F1, F2] }, { value }) =>
    value[A] === 0
      ? 0
      : value[F2]
      ? value[A] / value[F2] - 1
      : value[F1]
      ? value[A] / value[F1] - 1
      : value[B]
      ? value[A] / value[B] - 1
      : 0,
  F_YTG: ({ columns: [A, B, F1, F2] }, { value, valueTotalYear }) =>
    valueTotalYear[F2]
      ? valueTotalYear[F2] - value[A]
      : valueTotalYear[F1]
      ? valueTotalYear[F1] - value[A]
      : valueTotalYear[B] - value[A],
  F_GtPY: ({ columns: [A] }, { value, valuePrev }) =>
    valuePrev[A] !== 0 && value[A] !== 0 ? value[A] / valuePrev[A] - 1 : 0,
  F_GtP_1: ({ columns: [A] }, { value, valuePrev_1 }) =>
    valuePrev_1[A] !== 0 && value[A] !== 0 ? value[A] / valuePrev_1[A] - 1 : 0,
  F_AvgYTG: ({ columns: [A, B, F1, F2] }, { value, valueTotalYear, month }) =>
    month === 12
      ? 0
      : (valueTotalYear[F2]
          ? valueTotalYear[F2] - value[A]
          : valueTotalYear[F1]
          ? valueTotalYear[F1] - value[A]
          : valueTotalYear[B] - value[A]) /
        (12 - month),
  F_AvgAct: ({ columns: [A] }, { value, month }) => value[A] / month,

  // Forecast
  FT_Bud: ({ columns: [B] }, { value }) => value[B],
  FT_BudMS: ({ columns: [B] }, { value, total }) =>
    total[B] !== 0 ? value[B] / total[B] : 0,
  FT_AB: ({ columns: [B], marketColumns: [A] }, { value }) =>
    value[A] - value[B],
  FT_PAB: ({ columns: [B], marketColumns: [A] }, { value }) =>
    value[B] !== 0 && value[A] !== 0 ? value[A] / value[B] - 1 : 0,
  FT_F1: ({ columns: [, F] }, { value }) => value[F],
  FT_F1MS: ({ columns: [, F] }, { value, total }) =>
    total[F] !== 0 ? value[F] / total[F] : 0,
  FT_ActF1: ({ columns: [, F], marketColumns: [A] }, { value }) =>
    value[A] - value[F],
  FT_PActF1: ({ columns: [, F], marketColumns: [A] }, { value }) =>
    value[F] !== 0 && value[A] !== 0 ? value[A] / value[F] - 1 : 0,
  FT_F2: ({ columns: [, , F] }, { value }) => value[F],
  FT_F2MS: ({ columns: [, , F] }, { value, total }) =>
    total[F] !== 0 ? value[F] / total[F] : 0,
  FT_ActF2: ({ columns: [, , F], marketColumns: [A] }, { value }) =>
    value[A] - value[F],
  FT_PActF2: ({ columns: [, , F], marketColumns: [A] }, { value }) =>
    value[F] !== 0 && value[A] !== 0 ? value[A] / value[F] - 1 : 0,
  FT_YTG: ({ columns: [col2, col1] }, { value, valueTotalYear }) =>
    valueTotalYear[col2] - value[col1],

  // SFE
  SFE_CPA: (
    { columns: [CU_M, CU_Q, CU_Y, AC_M, AC_Q, AC_Y], aggr: { name } },
    { value }
  ) =>
    name === 'MTH'
      ? value[CU_M] > 0
        ? value[AC_M] / value[CU_M]
        : 0
      : name === 'RQTR'
      ? value[CU_Q] > 0
        ? value[AC_Q] / value[CU_Q]
        : 0
      : name === 'YTD'
      ? value[CU_Y] > 0
        ? value[AC_Y] / value[CU_Y]
        : 0
      : 0,
  SFE_CPA_A: (
    { columns: [CU_M, CU_Q, CU_Y, AC_M, AC_Q, AC_Y], aggr: { name } },
    { value },
    i
  ) =>
    name === 'MTH'
      ? value[CU_M][i] > 0
        ? value[AC_M][i] / value[CU_M][i]
        : 0
      : name === 'RQTR'
      ? value[CU_Q][i] > 0
        ? value[AC_Q][i] / value[CU_Q][i]
        : 0
      : name === 'YTD'
      ? value[CU_Y][i] > 0
        ? value[AC_Y][i] / value[CU_Y][i]
        : 0
      : 0,
  SFE_PC: (
    { columns: [PC_M, C_M, PC_Q, C_Q, PC_Y, C_Y], aggr: { name } },
    { value }
  ) =>
    name === 'MTH'
      ? value[C_M] > 0
        ? value[PC_M] / value[C_M]
        : 0
      : name === 'RQTR'
      ? value[C_Q] > 0
        ? value[PC_Q] / value[C_Q]
        : 0
      : name === 'YTD'
      ? value[C_Y] > 0
        ? value[PC_Y] / value[C_Y]
        : 0
      : 0,
  SFE_PC_A: (
    { columns: [PC_M, C_M, PC_Q, C_Q, PC_Y, C_Y], aggr: { name } },
    { value },
    i
  ) =>
    name === 'MTH'
      ? value[C_M][i] > 0
        ? value[PC_M][i] / value[C_M][i]
        : 0
      : name === 'RQTR'
      ? value[C_Q][i] > 0
        ? value[PC_Q][i] / value[C_Q][i]
        : 0
      : name === 'YTD'
      ? value[C_Y][i] > 0
        ? value[PC_Y][i] / value[C_Y][i]
        : 0
      : 0,
  SFE_C: ({ columns: [C_M, C_Q, C_Y], aggr: { name } }, { value }) =>
    name === 'MTH'
      ? value[C_M]
      : name === 'RQTR'
      ? value[C_Q]
      : name === 'YTD'
      ? value[C_Y]
      : 0,
  SFE_C_A: ({ columns: [C_M, C_Q, C_Y], aggr: { name } }, { value }, i) =>
    name === 'MTH'
      ? value[C_M][i]
      : name === 'RQTR'
      ? value[C_Q][i]
      : name === 'YTD'
      ? value[C_Y][i]
      : 0,
  SFE_CvT: (
    { columns: [C_M, TC_M, C_Q, TC_Q, C_Y, TC_Y], aggr: { name } },
    { value }
  ) =>
    name === 'MTH'
      ? value[TC_M] > 0
        ? value[C_M] / value[TC_M]
        : 0
      : name === 'RQTR'
      ? value[TC_Q] > 0
        ? value[C_Q] / value[TC_Q]
        : 0
      : name === 'YTD'
      ? value[TC_Y] > 0
        ? value[C_Y] / value[TC_Y]
        : 0
      : 0,
  SFE_CvT_A: (
    { columns: [C_M, TC_M, C_Q, TC_Q, C_Y, TC_Y], aggr: { name } },
    { value },
    i
  ) =>
    name === 'MTH'
      ? value[TC_M][i] > 0
        ? value[C_M][i] / value[TC_M][i]
        : 0
      : name === 'RQTR'
      ? value[TC_Q][i] > 0
        ? value[C_Q][i] / value[TC_Q][i]
        : 0
      : name === 'YTD'
      ? value[TC_Y][i] > 0
        ? value[C_Y][i] / value[TC_Y][i]
        : 0
      : 0,
  SFE_VC: ({ columns: [VC_M, VC_Q, VC_Y], aggr: { name } }, { value }) =>
    name === 'MTH'
      ? value[VC_M]
      : name === 'RQTR'
      ? value[VC_Q]
      : name === 'YTD'
      ? value[VC_Y]
      : 0,
  SFE_VC_A: ({ columns: [VC_M, VC_Q, VC_Y], aggr: { name } }, { value }, i) =>
    name === 'MTH'
      ? value[VC_M][i]
      : name === 'RQTR'
      ? value[VC_Q][i]
      : name === 'YTD'
      ? value[VC_Y][i]
      : 0,
  SFE_Cov: (
    { columns: [CU_M, CU_Q, CU_Y, VC_M, VC_Q, VC_Y], aggr: { name } },
    { value }
  ) =>
    name === 'MTH'
      ? value[CU_M] > 0
        ? value[VC_M] / value[CU_M]
        : 0
      : name === 'RQTR'
      ? value[CU_Q] > 0
        ? value[VC_Q] / value[CU_Q]
        : 0
      : name === 'YTD'
      ? value[CU_Y] > 0
        ? value[VC_Y] / value[CU_Y]
        : 0
      : 0,
  SFE_Cov_A: (
    { columns: [CU_M, CU_Q, CU_Y, VC_M, VC_Q, VC_Y], aggr: { name } },
    { value },
    i
  ) =>
    name === 'MTH'
      ? value[CU_M][i] > 0
        ? value[VC_M][i] / value[CU_M][i]
        : 0
      : name === 'RQTR'
      ? value[CU_Q][i] > 0
        ? value[VC_Q][i] / value[CU_Q][i]
        : 0
      : name === 'YTD'
      ? value[CU_Y][i] > 0
        ? value[VC_Y][i] / value[CU_Y][i]
        : 0
      : 0,
  SFE_F: (
    { columns: [C_M, VC_M, C_Q, VC_Q, C_Y, VC_Y], aggr: { name } },
    { value }
  ) =>
    name === 'MTH'
      ? value[VC_M] > 0
        ? value[C_M] / value[VC_M]
        : 0
      : name === 'RQTR'
      ? value[VC_Q] > 0
        ? value[C_Q] / value[VC_Q]
        : 0
      : name === 'YTD'
      ? value[VC_Y] > 0
        ? value[C_Y] / value[VC_Y]
        : 0
      : 0,
  SFE_F_A: (
    { columns: [C_M, VC_M, C_Q, VC_Q, C_Y, VC_Y], aggr: { name } },
    { value },
    i
  ) =>
    name === 'MTH'
      ? value[VC_M][i] > 0
        ? value[C_M][i] / value[VC_M][i]
        : 0
      : name === 'RQTR'
      ? value[VC_Q][i] > 0
        ? value[C_Q][i] / value[VC_Q][i]
        : 0
      : name === 'YTD'
      ? value[VC_Y][i] > 0
        ? value[C_Y][i] / value[VC_Y][i]
        : 0
      : 0,

  // SIT
  SIT_SC: ({ columns: [stocks, so] }, { value }) =>
    sum(value[so])
      ? value[stocks] / (sum(value[so]) / (30 * value[so].length))
      : 0,
  SIT_SO_Vol: (value, col, datesFirst, datesLast, period, isKP) =>
    value[col + (isKP ? ':KP' : '')].slice(
      Math.max(0, datesLast - 3),
      datesLast
    ),
  SIT_SI: ({ columns: [SI] }, { value }) => value[SI],
  SIT_SO: ({ columns: [, SO] }, { value }) => value[SO],
  SIT_Stock: ({ columns: [, , Stock] }, { value }) => value[Stock],
  SIT_CvgVol: ({ columns: [stocks, so] }, { value }) =>
    sum(value[so])
      ? value[stocks] / (sum(value[so]) / (30 * value[so].length))
      : 0,
  SIT_CvgVal: ({ columns: [, , stocks, so] }, { value }) =>
    sum(value[so])
      ? value[stocks] / (sum(value[so]) / (30 * value[so].length))
      : 0,
  SIT_SO_Vol: (value, col, datesFirst, datesLast, period, isKP) =>
    value[col + (isKP ? ':KP' : '')].slice(
      Math.max(0, datesLast - 3),
      datesLast
    ),
  SIT_SO_Val: (value, col, datesFirst, datesLast, period, isKP) =>
    value[col + (isKP ? ':KP' : '')].slice(
      Math.max(0, datesLast - 3),
      datesLast
    ),
};

export default calcs;
