import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';

import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

import 'uikit/dist/css/uikit.min.css';
import * as UIkit from 'uikit';
import UIIcons from 'uikit/dist/js/uikit-icons.min.js';

// add bootstrap
// import './../node_modules/bootstrap/scss/bootstrap.scss';

// General Styles
import './index.scss';

// loads the Icon plugin
UIkit.use(UIIcons);

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
