import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import UIkit from "uikit";
import MultiSelect from "react-multi-select-component";

import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
} from "@material-ui/core";

const MyFormControlLabel = withStyles({
  label: {
    fontSize: "0.8rem",
  },
})(FormControlLabel);

const MyCheckbox = withStyles({
  root: {
    width: 22,
    height: 22,
    padding: 2,
  },
})(Checkbox);

const MySelect = withStyles({
  root: {
    fontSize: "0.8rem",
    minWidth: 50,
    padding: "3px 25px 3px 7px !important",
  },
  iconOutlined: {
    right: 0,
  },
})(Select);

const MyMenuItem = withStyles({
  root: {
    fontSize: "0.8rem",
    padding: "3px 7px 3px 7px !important",
  },
})(MenuItem);

const MyRadio = withStyles({
  root: {
    padding: 2,
  },
})(Radio);

export default function ChartControl(props) {
  const {
    controlsData,
    chartControls,
    actionChangeChartControl,
    actives,
    channels,
    data,
    controlName,
    aggrData: { uniqueChannelGroups },
  } = props;
  const chartControl = chartControls[controlName];

  if (["patient", "patient_weekly"].includes(controlName)) {
    return (
      <Box display="flex" alignItems="center">
        <RadioGroup
          row
          aria-label={"chart-control-0-" + controlName}
          name={"chart-control-0-" + controlName}
          value={chartControl.displayType}
        >
          {[
            {
              name: "percents",
              label: "100%",
            },
            {
              name: "absolute",
              label: "absolute",
            },
          ].map((el, i) => (
            <MyFormControlLabel
              key={`radio-${el.name}-${i}`}
              value={el.name}
              control={<MyRadio size="small" color="primary" />}
              name={`radio-${el.name}-${i}`}
              label={el.label}
              labelPlacement="end"
              onChange={() =>
                actionChangeChartControl(controlName, "displayType", el.name)
              }
            />
          ))}
        </RadioGroup>
        <Box mr={3} ml={.7} mb={.2}>
        <Typography variant="body1" component="span">{"|"}</Typography>   
        </Box>     
        <RadioGroup
          row
          aria-label={"chart-control-1-" + controlName}
          name={"chart-control-1-" + controlName}
          value={chartControl.dynamicCleaned}
        >
          {[
            {
              name: "new",
              label: "New",
            },
            {
              name: "switch",
              label: "Swith",
            },
            {
              name: "new+switch",
              label: "New + Switch",
            },
          ].map((el, i) => (
            <MyFormControlLabel
              key={`radio-${el.name}-${i}`}
              value={el.name}
              control={<MyRadio size="small" color="primary" />}
              name={`radio-${el.name}-${i}`}
              label={el.label}
              labelPlacement="end"
              onChange={() =>
                actionChangeChartControl(controlName, "dynamicCleaned", el.name)
              }
            />
          ))}
        </RadioGroup>
      </Box>
    );
  } else if (["finance"].includes(controlName)) {
    return (
      <Box>
        <FormGroup row>
          {Object.keys(chartControl).map((key) => (
            <MyFormControlLabel
              key={controlName + "_" + key}
              control={
                <MyCheckbox
                  size="small"
                  color="primary"
                  checked={chartControl[key].value}
                  onChange={(e) =>
                    actionChangeChartControl(controlName, key, e.target.checked)
                  }
                  name={`checkbox_${controlName}_${key}`}
                />
              }
              label={chartControl[key].name}
            />
          ))}
        </FormGroup>
      </Box>
    );
  } else if (["forecast"].includes(controlName)) {
    const forecastChannel = channels.filter(
      (d) =>
        d["DataSource"] === "Forecast" &&
        (data.currentFilter["Country"].length
          ? data.currentFilter["Country"].includes(d["Country"])
          : true) &&
        (data.currentFilter["Category0"].length
          ? data.currentFilter["Category0"].includes(d["Category"])
          : true)
    );

    const channel = {
      channels: d3
        .set(forecastChannel, (d) => d["Channel"])
        .values()
        .join(", "),
      metrics: d3
        .set(forecastChannel, (d) => d["Metric"])
        .values()
        .join(", "),
    };

    const tpl = `<ul class="help-message">
          <li><b>Countries:</b> ${actives.headerCountries.join(", ")}</li>
          <li><b>Market:</b> ${actives.headerCategories.join(", ")}</li>
          </br>
          <li><b>Market Metric:</b> ${
            controlsData[3].data[1].controls.find((d) => d.active).name
          }</li>
          <li><b>Market Channel:</b> ${uniqueChannelGroups}</li>
          </br>
          <li><b>Forecast Metric:</b> ${channel ? channel.metrics : ""}</li>
          <li><b>Forecast Channel:</b> ${channel ? channel.channels : ""}</li>
        </ul>
      `;
    return (
      <div className="chart-controls">
        <div className="uk-grid-small uk-child-width-auto checkbox-control">
          {Object.keys(chartControl).map((key) => (
            <label key={controlName + "_" + key}>
              <input
                className="uk-checkbox"
                type="checkbox"
                defaultChecked={chartControl[key].value}
                onChange={(e) =>
                  actionChangeChartControl(controlName, key, e.target.checked)
                }
              />{" "}
              {chartControl[key].name}
            </label>
          ))}
          <label>
            <div
              className="help-button"
              onClick={() => {
                UIkit.notification.closeAll();
                UIkit.notification({ message: tpl, timeout: 5000 });
              }}
            >
              ?
            </div>
          </label>
        </div>
      </div>
    );
  } else if (["promo"].includes(controlName)) {
    return (
      <Box display="flex" alignItems="center" mb={0.5}>
        <Box mr={1}>
          <Typography variant="body2" component="span">
            Category Group:
          </Typography>
        </Box>
        <FormControl variant="outlined" size="small">
          <MySelect
            value={chartControl.selectedCategoryGroup}
            onChange={(e) =>
              actionChangeChartControl(
                controlName,
                "selectedCategoryGroup",
                e.target.value
              )
            }
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {chartControl.categoryGroups.map((d) => (
              <MyMenuItem key={d} value={d}>
                {d}
              </MyMenuItem>
            ))}
          </MySelect>
        </FormControl>
      </Box>
    );
  } else if (["sob"].includes(controlName)) {
    return (
      <div className="chart-controls">
        <div className="uk-grid-small uk-child-width-auto radio-control">
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio1"
              value="percents"
              checked={chartControl.displayType === "percents"}
              onChange={() =>
                actionChangeChartControl(controlName, "displayType", "percents")
              }
            />{" "}
            100%
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio1"
              value="absolute"
              checked={chartControl.displayType === "absolute"}
              onChange={() =>
                actionChangeChartControl(controlName, "displayType", "absolute")
              }
            />{" "}
            Absolute
          </label>
        </div>

        <span className="vertical-divider"> | </span>

        <div className="chart-controls">
          <label className="uk-form-label" data-for="form-horizontal-select">
            Type:{" "}
          </label>
          <div className="uk-form-controls">
            <select
              defaultValue={chartControl.selectedPType}
              className="uk-select"
              onChange={(e) =>
                actionChangeChartControl(
                  controlName,
                  "selectedPType",
                  e.target.value
                )
              }
            >
              {chartControl.ptype.map((d) => (
                <option key={d}>{d}</option>
              ))}
            </select>
          </div>

          <label className="uk-form-label" data-for="form-horizontal-select">
            Age:{" "}
          </label>
          <div className="uk-form-controls">
            <select
              defaultValue={chartControl.selectedAge}
              className="uk-select"
              onChange={(e) =>
                actionChangeChartControl(
                  controlName,
                  "selectedAge",
                  e.target.value
                )
              }
            >
              {chartControl.age.map((d) => (
                <option key={d}>{d}</option>
              ))}
            </select>
          </div>

          <label className="uk-form-label" data-for="form-horizontal-select">
            Dynamic:{" "}
          </label>
          <div className="uk-form-controls">
            <select
              defaultValue={chartControl.selectedDynamic}
              className="uk-select"
              onChange={(e) =>
                actionChangeChartControl(
                  controlName,
                  "selectedDynamic",
                  e.target.value
                )
              }
            >
              {chartControl.dynamic.map((d) => (
                <option key={d}>{d}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  } else if (["crm"].includes(controlName)) {
    return (
      <div className="chart-controls">
        <label className="uk-form-label" data-for="form-horizontal-select">
          Charts by:{" "}
        </label>
        <div className="uk-form-controls">
          <select
            defaultValue={chartControl.selectedChartsBy}
            className="uk-select"
            onChange={(e) =>
              actionChangeChartControl(
                controlName,
                "selectedChartsBy",
                e.target.value
              )
            }
          >
            {chartControl.chartsBy.map((d) => (
              <option key={d}>{d}</option>
            ))}
          </select>
        </div>

        <span className="vertical-divider"> | </span>

        <label className="uk-form-label" data-for="form-horizontal-select">
          Type:{" "}
        </label>
        <div className="uk-grid-small uk-child-width-auto radio-control">
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio1"
              value="hcp"
              checked={chartControl.displayType === "HCP"}
              onChange={() =>
                actionChangeChartControl(controlName, "displayType", "HCP")
              }
            />{" "}
            HCP
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio1"
              value="ho"
              checked={chartControl.displayType === "HO"}
              onChange={() =>
                actionChangeChartControl(controlName, "displayType", "HO")
              }
            />{" "}
            HO
          </label>
        </div>

        <span className="vertical-divider"> | </span>

        <div className="chart-controls">
          <label className="uk-form-label" data-for="form-horizontal-select">
            Segment:{" "}
          </label>

          <MultiSelect
            options={chartControl.segments.map((d) => ({
              label: d,
              value: d,
            }))}
            value={chartControl.selectedSegment.map((d) => ({
              label: d,
              value: d,
            }))}
            onChange={(e) =>
              actionChangeChartControl(
                controlName,
                "selectedSegment",
                e.map((d) => d.label)
              )
            }
            labelledBy={"Select"}
            disableSearch={true}
          />
          {/* <div className="uk-form-controls">
              <select defaultValue={chartControl.selectedSegment} className="uk-select" onChange={(e) => actionChangeChartControl(controlName, 'selectedSegment', e.target.value)}>
                {
                  chartControl.segments.map(d => <option key={d}>{d}</option>)
                }
              </select>
            </div> */}

          <label className="uk-form-label" data-for="form-horizontal-select">
            Channel:{" "}
          </label>
          <MultiSelect
            options={chartControl.channels.map((d) => ({
              label: d,
              value: d,
            }))}
            value={chartControl.selectedChannel.map((d) => ({
              label: d,
              value: d,
            }))}
            onChange={(e) =>
              actionChangeChartControl(
                controlName,
                "selectedChannel",
                e.map((d) => d.label)
              )
            }
            labelledBy={"Select"}
            disableSearch={true}
          />
          {/* <div className="uk-form-controls">
              <select defaultValue={chartControl.selectedChannel} className="uk-select" onChange={(e) => actionChangeChartControl(controlName, 'selectedChannel', e.target.value)}>
                {
                  chartControl.channels.map(d => <option key={d}>{d}</option>)
                }
              </select>
            </div> */}
        </div>
      </div>
    );
  } else return null;
}
