import React from 'react';
import { connect } from 'react-redux';

import { actionRemoveNotification } from './../../store/actions';

import Tabs from '../Other/Tabs';

import InitLoader from '../Other/InitLoader';

import { makeStyles } from '@material-ui/core/styles';

import Notifications from '../Other/Notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

const Workspace = ({
  init_loading,
  notifications,
  actionRemoveNotification,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {init_loading ? <InitLoader /> : <Tabs />}
      <Notifications
        notifications={notifications}
        removeNotification={actionRemoveNotification}
      />
    </div>
  );
};

const mapStateToProps = ({ data: state }, props) => {
  return {
    ...state,
    init_loading: state.init_loading || false,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionRemoveNotification: (n) => dispatch(actionRemoveNotification(n)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
