import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { actionHideModal } from '../actions';
import { actionCloseConfig, actionUpdateConfig } from '../../../store/actions';

import FormikSample from '../../Other/FormikSample';
import ButtonSample from '../../Other/ButtonSample';

const useStyles = makeStyles((theme) => ({
  blockRight: {
    paddingLeft: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& h6': {
      marginBottom: '.5rem',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function UpdateModal(props) {
  const { config, config_id } = props.config;
  const {
    datasources,
    actionHideModal,
    actionCloseConfig,
    actionUpdateConfig,
  } = props;
  const classes = useStyles();
  const formikRef = createRef();

  const handleUpdateConfig = (values) => {
    const { name, datasource_id } = values;
    const newConfig = { name, datasource_id };
    actionUpdateConfig({ config_id, data: newConfig });
  };

  return (
    <div className={classes.blockRight} style={{ paddingLeft: 0 }}>
      <div>
        <FormikSample
          ref={formikRef}
          index={1}
          data={{ name: config.name, datasource_id: config.datasource_id }}
          datasources={datasources}
          submit={handleUpdateConfig}
        />
      </div>
      <div className={classes.buttons}>
        <ButtonSample name='Cancel' color='default' method={actionHideModal} />
        <Box style={{ margin: '0 1rem' }}>
          <ButtonSample
            name='Delete'
            color='secondary'
            method={() => actionCloseConfig('delete', config_id)}
          />
        </Box>
        <ButtonSample
          name='Save'
          method={() => formikRef.current.submitForm()}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (rootState, props) => {
  const state = rootState.modal;
  const dataState = rootState.data;
  return {
    ...state,
    config: state.config,
    datasources: dataState.datasources || [],
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionCloseConfig: (action, config_id) =>
      dispatch(actionCloseConfig({ action, config_id })),
    actionUpdateConfig: (data) => dispatch(actionUpdateConfig(data)),
    actionHideModal: () => dispatch(actionHideModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal);
