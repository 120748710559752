import React from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import numeral from "numeral";

const defaultProps = {
  margin: { top: 0, right: 20, bottom: 17, left: 40 },
  width: 120,
  height: 150
};

const selectFormat = (f, d) => {
  const format = Math.abs(d) < 1000 ? numeral(d).format("0.[0]") : numeral(d).format("0.0a");
  switch (f) {
    case "pts":
      return numeral(100 * d).format("0.0") + "pts";
    case "percent":
      return numeral(d).format("0.0%");
    case "euro_number":
      return "€" + format.toUpperCase();
    default:
      return format.toUpperCase();
  }
};

class BarChart extends React.Component {
  constructor(props) {
    super(props);

    this.initialize(props);    
  }

  initialize = (props) => {
    this.width = props.width || defaultProps.width;
    this.height = props.height || defaultProps.height;
    this.margin = defaultProps.margin;

    this.innerWidth = this.width - this.margin.left - this.margin.right;
    this.innerHeight = this.height - this.margin.top - this.margin.bottom;
    this.y = d3
      .scaleBand()
      .padding(0.1)
      .rangeRound([0, this.innerHeight]);
    this.x = d3.scaleLinear();
    this.format = props.format;
    this.baseColor = props.baseColor || "#00b900" 
  }

  componentDidMount() {
    this.initGraph(this.props.data, this.props.type);
  }

  prepareData = (data, type) => {
    const minValue = d3.min(data, d => d.value);
    const maxValue = d3.max(data, d => d.value);

    this.x
      .domain([Math.min(0, minValue), maxValue])
      .rangeRound([0, this.innerWidth + (type === "Sales" ? -80 : 0)]);
    this.y.domain(
      data.map(function(d) {
        return d.name;
      })
    );
  };

  initGraph(data, type) {
    d3.select(ReactDOM.findDOMNode(this)).select("svg").remove();

    // add svg
    this.svg = d3
      .select(ReactDOM.findDOMNode(this))
      .append("svg")
      .attr("width", this.innerWidth + this.margin.left + this.margin.right)
      .attr("height", this.innerHeight + this.margin.top + this.margin.bottom);

    // add g
    this.g = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    // add axes
    this.g.append("g").attr("class", "x-axis");
    this.g.append("g").attr("class", "y-axis");

    // add bar chart
    this.g.append("g").attr("class", "barChart");

    this.prepareData(data, type);
    this.redrawBarChart(data, type);

    // update axes
    this.updateAxes();
  }

  updateAxes = () => {
    // Add the Y Axis
    this.yAxis = this.svg.select(".y-axis").call(d3.axisLeft(this.y));
    this.yAxis.select("path").style("stroke", "none");
    this.yAxis.selectAll("line").style("stroke", "none");
  };

  redrawBarChart(data, type) {
    this.g
      .select(".barChart")
      .selectAll("g")
      .remove();

    const barChart = this.g
      .select(".barChart")
      .selectAll("rect")
      .data(data)
      .enter()
      .append("g");

    // bar chart
    barChart
      .append("rect")
      .attr("class", "bar")
      .style("fill", d => d.value < 0 ? "#ff6666" : this.baseColor)
      .style("fill-opacity", (d, i) => 0.2 + i * 0.8/4)
      .attr("x", d => this.x(Math.min(0, d.value)))
      .attr("y", d => this.y(d.name))
      .attr("width", d => Math.abs(this.x(d.value) - this.x(0)))
      .attr("height", this.y.bandwidth());

    barChart
      .append("text")
      .text(d => selectFormat(this.format, type === "Sales" ? d.value * d.period : d.value))
      .attr("transform", d => "translate(" + ((type === "Sales" 
        ? this.innerWidth : this.x(d.value)) || 0) + "," + this.y(d.name) + ")")
      .style("fill", d => type === "Sales" ? "#5c5cff" : (d.value < 0 ? "red" : "black"))
      .style("font-size", type === "Sales" ? "16px" : "11px")
      .style("font-weight", type === "Sales" ? 700 : 400)
      .style("text-anchor", d => {
        const middle = (this.x.domain()[1] + this.x.domain()[0])/2;
        return (d.value < 0 && d.value <= middle) || (d.value > 0 && d.value <= middle) ? "start" : "end"
      })
      .attr("dx", d => {
        const middle = (this.x.domain()[1] + this.x.domain()[0])/2;
        return (d.value < 0 && d.value <= middle) || (d.value > 0 && d.value <= middle) ? "5px" : "-5px"
      })
      .attr("dy", (this.y.bandwidth() / 2 + 3.5) + "px");   
    
    if (type === "Sales") {
      barChart
        .append("text")
        .text(d => d.period > 1 ? '~' + selectFormat(this.format, d.value) + "/mth" : "")
        .attr("transform", d => {
          return "translate(" + (this.innerWidth || 0) + "," + (this.y(d.name) || 0) + ")";
        })
        .style("fill", "#5c5cffcc")
        .style("font-size", "10px")
        .style("font-weight", 500)
        .style("font-style", "italic")
        .style("text-anchor", "end")
        .attr("dx", "-5px")
        .attr("dy", this.y.bandwidth() / 2 + 16 + "px");
    }

    barChart.exit().remove();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillReceiveProps(next, prev) {
    if (next !== prev) {
      this.initialize(next);
      this.initGraph(next.data, next.type)
    }
  }

  render() {
    return (
      <div className="barChart" id={0}>
        {this.props.children}
      </div>
    );
  }
}

export default BarChart;
