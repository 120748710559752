import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { actionLogout } from './../../store/actions';

import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
  };
});

function Layout({ query_loading, actionLogout, version, children, backgroundColor, footerBackgroundColor }) {
  const classes = useStyles;

  return (
    <div className={classes.root} style={{ backgroundColor }}>
      <Header query_loading={query_loading} actionLogout={actionLogout} />
      {children}
      <Footer version={version} backgroundColor={footerBackgroundColor || "inherit"} />
    </div>
  );
}

const mapStateToProps = ({ data: state }, props) => {
  return {
    init_loading: state.init_loading || false,
    query_loading: state.query_loading || 0,
    version: state.version,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionLogout: () => dispatch(actionLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
