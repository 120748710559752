import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';

import * as actions from './../actions';
import * as api from './../../services/api';
import { login, logout } from './../../services/auth';
import history from './../../services/history'

function* sagaLogin() {
  yield takeLatest(actions.LOGIN, function* ({
    payload: { data, from },
  }) {
    console.log('SAGA: login');

    const user = yield call(api.userLogin, data);

    if (!user.error) {
      yield call(login, user);
      yield put(actions.actionLoadData());      
      yield call(history.push, from);
    } else {
      console.log('???', user);

      yield put(
        actions.actionAddNotification({
          type: 'error',
          text: "Invalid email or password",
          //id,
        })
      )
    }
  });
}

function* sagaLogout() {
  yield takeEvery(actions.LOGOUT, function* () {
    console.log('SAGA: logout');
    yield call(api.userLogout);
    yield call(logout);
    yield call(history.push, '/');
  });
}

export default function* watchAuthSaga() {
  yield all([sagaLogin(), sagaLogout()]);
}
