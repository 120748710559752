import _ from 'lodash';
import * as d3 from 'd3';
import colors from '../../../store/reducers/config/colors';
import { selectFormat } from './selectFormat';

export const line = ({ secondYAxis, defined, x, y, y1 }) =>
  d3
    .line()
    .defined(defined)
    .x((d) => x(d.date) + x.bandwidth() / 2)
    .y((d) => (secondYAxis ? y1(d.value) || 0 : y(d.value) || 0))
    .curve(d3.curveMonotoneX);

const renderLineChart = ({
  data,
  g,
  defined,
  color_codes,
  x,
  y,
  y1,
  secondYAxisFormat,
  format,
  tooltip,
  chartType,
  labelFontSize,
  showLabels,
}) => {
  g.select('.lines').selectAll('.line-g').remove();

  const lines = g
    .select('.lines')
    .selectAll('.line-g')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'line-g');

  lines.each((d, i) => {
    const lineG = d3.select(lines.nodes()[i]);

    const lineData = d.data.filter(defined); //.filter(d => !isNaN(d.value) && d.value !== null)
    if (!lineData.length) return;

    // lines
    lineG
      .append('path')
      .attr('class', 'line')
      .attr(
        'd',
        line({ secondYAxis: d.secondYAxis, defined, x, y, y1 })(lineData)
      )
      .style(
        'stroke',
        color_codes[d.name.split(' - ')[0]] ||
          (d.styles && d.styles.color) ||
          colors[i]
      )
      .style(
        'stroke-width',
        (d.styles && d.styles.strokeWidth) || (i === 0 ? '3.5px' : '2.5px')
      )
      .style(
        'stroke-dasharray',
        (d.styles && d.styles.strokeDasharray) || null
      );

    //text
    lineG
      .append('text')
      .attr('transform', (d) => {
        return (
          'translate(' +
          (x(lineData[lineData.length - 1].date) + x.bandwidth() / 2) +
          ',' +
          (d.secondYAxis
            ? y1(lineData[lineData.length - 1].value) || 0
            : y(lineData[lineData.length - 1].value) || 0) +
          ')'
        );
      })
      .attr('dx', '.9em')
      .attr('dy', '.35em')
      .attr('text-anchor', 'start')
      .style(
        'fill',
        color_codes[d.name.split(' - ')[0]] ||
          (d.styles && d.styles.color) ||
          colors[i]
      )
      .style('font-size', labelFontSize)
      .text((_) =>
        selectFormat(
          d.secondYAxis ? secondYAxisFormat : format,
          lineData[lineData.length - 1].value
        )
      );

    // dots
    if (d.dots) {
      lineG
        .selectAll('.dot')
        .data(lineData.filter(defined))
        .enter()
        .append('circle')
        .attr('class', 'dot')
        .attr('cx', (dd, i) => x(dd.date) + x.bandwidth() / 2)
        .attr('cy', (dd) =>
          d.secondYAxis ? y1(dd.value) || 0 : y(dd.value) || 0
        )
        .attr('r', i === 0 ? 4 : 3)
        .style(
          'stroke',
          color_codes[d.name.split(' - ')[0]] ||
            (d.styles && d.styles.color) ||
            colors[i]
        )
        .attr('fill', '#fff')
        .on('mouseover', function (dd) {
          d3.select(this)
            .style(
              'fill',
              color_codes[d.name.split(' - ')[0]] ||
                (d.styles && d.styles.color) ||
                colors[i]
            )
            .attr('r', i === 0 ? 5 : 4);

          const tpl =
            `<ul><li><div class="square" style="background-color: ${
              color_codes[d.name.split(' - ')[0]] ||
              (d.styles && d.styles.color) ||
              colors[i]
            }"></div>${d.name}: ${selectFormat(
              d.secondYAxis ? secondYAxisFormat : format,
              dd.value
            )}</li>` +
            `<li>${
              chartType === 'launch'
                ? dd.date
                : 'Date: ' +
                  (typeof dd.date === 'string'
                    ? dd.date
                    : d3.utcFormat('%b %Y')(dd.date))
            }</li><ul>`;

          tooltip.transition().duration(200).style('opacity', 0.9);
          tooltip
            .html(tpl)
            .style('left', `${d3.event.pageX + 8}px`)
            .style('top', `${d3.event.pageY - 48}px`);
        })
        .on('mouseout', function (dd) {
          d3.select(this)
            .style('fill', '#fff')
            .attr('r', i === 0 ? 4 : 3);

          tooltip.transition().duration(500).style('opacity', 0);
        });
    }

    // labels
    if (showLabels) {
      lineG
        .selectAll('.dot-label')
        .data(lineData.slice(0, -1).filter(defined))
        .enter()
        .append('text')
        .attr('class', 'dot-label')
        .attr('x', (dd, i) => x(dd.date) + x.bandwidth() / 2)
        .attr('y', (dd) => (d.secondYAxis ? y1(dd.value) : y(dd.value)))
        .attr('dx', '.1rem')
        .attr('dy', '-.5rem')
        .attr(
          'fill',
          color_codes[d.name.split(' - ')[0]] ||
            (d.styles && d.styles.color) ||
            colors[i]
        )
        .style('text-anchor', 'middle')
        .style('font-size', labelFontSize)
        .text((d) =>
          selectFormat(d.secondYAxis ? secondYAxisFormat : format, d.value)
        );
    }
  });
  d3.select(lines.nodes()[0]).raise();
};

const redrawLineChart = ({
  data,
  g,
  defined,
  color_codes,
  x,
  y,
  y1,
  secondYAxisFormat,
  format,
  tooltip,
  chartType,
  labelFontSize,
  showLabels,
}) => {
  if (!showLabels) g.select('.lines').selectAll('.dot-label').remove();
  const lines = g
    .select('.lines')
    .selectAll('.line-g')
    .data(data, (d) => d.styles?.color || d.name) // TODO make better
    .join(
      (enter) => {
        const lines = enter.append('g').attr('class', 'line-g');
        return lines
          .each((d, i) => {
            const nodeIdx = lines
              .nodes()
              .findIndex((dd) => dd.__data__.name === d.name);
            const lineG = d3.select(lines.nodes()[nodeIdx]);

            const lineData = d.data.filter(defined); //.filter(d => !isNaN(d.value) && d.value !== null)
            if (!lineData.length) return;

            // lines
            lineG
              .append('path')
              .transition()
              .duration(1200)
              .attr('class', 'line')
              .attr(
                'd',
                line({ secondYAxis: d.secondYAxis, defined, x, y, y1 })(
                  lineData
                )
              )
              .style(
                'stroke',
                color_codes[d.name.split(' - ')[0]] ||
                  (d.styles && d.styles.color) ||
                  colors[i]
              )
              .style(
                'stroke-width',
                (d.styles && d.styles.strokeWidth) ||
                  (i === 0 ? '3.5px' : '2.5px')
              )
              .style(
                'stroke-dasharray',
                (d.styles && d.styles.strokeDasharray) || null
              );

            //text
            lineG
              .append('text')
              .attr('transform', (d) => {
                return (
                  'translate(' +
                  (x(lineData[lineData.length - 1].date) + x.bandwidth() / 2) +
                  ',' +
                  (d.secondYAxis
                    ? y1(lineData[lineData.length - 1].value) || 0
                    : y(lineData[lineData.length - 1].value) || 0) +
                  ')'
                );
              })
              .attr('dx', '.9em')
              .attr('dy', '.35em')
              .attr('text-anchor', 'start')
              .style(
                'fill',
                color_codes[d.name.split(' - ')[0]] ||
                  (d.styles && d.styles.color) ||
                  colors[i]
              )
              .style('font-size', labelFontSize)
              .text((_) =>
                selectFormat(
                  d.secondYAxis ? secondYAxisFormat : format,
                  lineData[lineData.length - 1].value
                )
              );

            // dots
            if (d.dots) {
              lineG
                .selectAll('.dot')
                .data(lineData.filter(defined))
                .enter()
                .append('circle')
                .attr('class', 'dot')
                .attr('cx', (dd, i) => x(dd.date) + x.bandwidth() / 2)
                .attr('cy', (dd) =>
                  d.secondYAxis ? y1(dd.value) || 0 : y(dd.value) || 0
                )
                .attr('r', i === 0 ? 4 : 3)
                .style(
                  'stroke',
                  color_codes[d.name.split(' - ')[0]] ||
                    (d.styles && d.styles.color) ||
                    colors[i]
                )
                .attr('fill', '#fff')
                .on('mouseover', function (dd) {
                  d3.select(this)
                    .style(
                      'fill',
                      color_codes[d.name.split(' - ')[0]] ||
                        (d.styles && d.styles.color) ||
                        colors[i]
                    )
                    .attr('r', i === 0 ? 5 : 4);

                  const tpl =
                    `<ul><li><div class="square" style="background-color: ${
                      color_codes[d.name.split(' - ')[0]] ||
                      (d.styles && d.styles.color) ||
                      colors[i]
                    }"></div>${d.name}: ${selectFormat(
                      d.secondYAxis ? secondYAxisFormat : format,
                      dd.value
                    )}</li>` +
                    `<li>${
                      chartType === 'launch'
                        ? dd.date
                        : 'Date: ' +
                          (typeof dd.date === 'string'
                            ? dd.date
                            : d3.utcFormat('%b %Y')(dd.date))
                    }</li><ul>`;

                  tooltip.transition().duration(200).style('opacity', 0.9);
                  tooltip
                    .html(tpl)
                    .style('left', `${d3.event.pageX + 8}px`)
                    .style('top', `${d3.event.pageY - 48}px`);
                })
                .on('mouseout', function (dd) {
                  d3.select(this)
                    .style('fill', '#fff')
                    .attr('r', i === 0 ? 4 : 3);

                  tooltip.transition().duration(500).style('opacity', 0);
                });
            }

            // labels
            if (showLabels) {
              lineG
                .selectAll('.dot-label')
                .data(lineData.slice(0, -1).filter(defined))
                .enter()
                .append('text')
                .attr('class', 'dot-label')
                .attr('x', (dd, i) => x(dd.date) + x.bandwidth() / 2)
                .attr('y', (dd) => (d.secondYAxis ? y1(dd.value) : y(dd.value)))
                .attr('dx', '.1rem')
                .attr('dy', '-.5rem')
                .attr(
                  'fill',
                  color_codes[d.name.split(' - ')[0]] ||
                    (d.styles && d.styles.color) ||
                    colors[i]
                )
                .style('text-anchor', 'middle')
                .style('font-size', labelFontSize)
                .text((d) =>
                  selectFormat(
                    d.secondYAxis ? secondYAxisFormat : format,
                    d.value
                  )
                );
            }
          })
          .call((enter) => enter.transition().duration(1200));
      },
      (update) => {
        return update.each((d, i) => {
          const nodeIdx = update
            .nodes()
            .findIndex((dd) => dd.__data__.name === d.name);
          const lineG = d3.select(update.nodes()[nodeIdx]);

          const lineData = d.data.filter(defined); //.filter(d => !isNaN(d.value) && d.value !== null)
          if (!lineData.length) return;

          const lineGPath = lineG.select('path');
          const lineGText = lineG.select('text');

          // lines
          lineGPath
            .transition()
            .duration(1200)
            .style(
              'stroke',
              color_codes[d.name.split(' - ')[0]] ||
                (d.styles && d.styles.color) ||
                colors[i]
            )
            .style(
              'stroke-width',
              (d.styles && d.styles.strokeWidth) ||
                (i === 0 ? '3.5px' : '2.5px')
            )
            .style(
              'stroke-dasharray',
              (d.styles && d.styles.strokeDasharray) || null
            )
            .attr(
              'd',
              line({ secondYAxis: d.secondYAxis, defined, x, y, y1 })(lineData)
            );

          //text
          lineGText
            .transition()
            .duration(1200)
            .attr('transform', (d) => {
              return (
                'translate(' +
                (x(lineData[lineData.length - 1].date) + x.bandwidth() / 2) +
                ',' +
                (d.secondYAxis
                  ? y1(lineData[lineData.length - 1].value) || 0
                  : y(lineData[lineData.length - 1].value) || 0) +
                ')'
              );
            })
            .style(
              'fill',
              color_codes[d.name.split(' - ')[0]] ||
                (d.styles && d.styles.color) ||
                colors[i]
            )
            .style('font-size', labelFontSize)
            .text((_) =>
              selectFormat(
                d.secondYAxis ? secondYAxisFormat : format,
                lineData[lineData.length - 1].value
              )
            );

          // dots
          if (d.dots) {
            lineG
              .selectAll('.dot')
              .data(lineData.filter(defined), (d) => d.date)
              .join(
                (enter) =>
                  enter
                    .append('circle')
                    .attr('class', 'dot')
                    .attr('cx', (dd, i) => x(dd.date) + x.bandwidth() / 2)
                    .attr('cy', (dd) =>
                      d.secondYAxis ? y1(dd.value) || 0 : y(dd.value) || 0
                    )
                    .attr('r', i === 0 ? 4 : 3)
                    .style(
                      'stroke',
                      color_codes[d.name.split(' - ')[0]] ||
                        (d.styles && d.styles.color) ||
                        colors[i]
                    )
                    .attr('fill', '#fff')
                    .call((enter) => enter.transition().duration(1200)),
                (update) =>
                  update
                    .transition()
                    .duration(1200)
                    .attr('cx', (dd, i) => x(dd.date) + x.bandwidth() / 2)
                    .attr('cy', (dd) =>
                      d.secondYAxis ? y1(dd.value) || 0 : y(dd.value) || 0
                    )
                    .attr('r', i === 0 ? 4 : 3)
                    .style(
                      'stroke',
                      color_codes[d.name.split(' - ')[0]] ||
                        (d.styles && d.styles.color) ||
                        colors[i]
                    )
                    .call((update) => update.transition().duration(1200)),
                (exit) => exit.call((exit) => exit.remove())
              )
              .on('mouseover', function (dd) {
                d3.select(this)
                  .style(
                    'fill',
                    color_codes[d.name.split(' - ')[0]] ||
                      (d.styles && d.styles.color) ||
                      colors[i]
                  )
                  .attr('r', i === 0 ? 5 : 4);

                const tpl =
                  `<ul><li><div class="square" style="background-color: ${
                    color_codes[d.name.split(' - ')[0]] ||
                    (d.styles && d.styles.color) ||
                    colors[i]
                  }"></div>${d.name}: ${selectFormat(
                    d.secondYAxis ? secondYAxisFormat : format,
                    dd.value
                  )}</li>` +
                  `<li>${
                    chartType === 'launch'
                      ? dd.date
                      : 'Date: ' +
                        (typeof dd.date === 'string'
                          ? dd.date
                          : d3.utcFormat('%b %Y')(dd.date))
                  }</li><ul>`;

                tooltip.transition().duration(200).style('opacity', 0.9);
                tooltip
                  .html(tpl)
                  .style('left', `${d3.event.pageX + 8}px`)
                  .style('top', `${d3.event.pageY - 48}px`);
              })
              .on('mouseout', function (dd) {
                d3.select(this)
                  .style('fill', '#fff')
                  .attr('r', i === 0 ? 4 : 3);

                tooltip.transition().duration(500).style('opacity', 0);
              });
          }

          // labels
          if (showLabels) {
            lineG
              .selectAll('.dot-label')
              .data(lineData.slice(0, -1).filter(defined), (d) => d.date)
              .join(
                (enter) =>
                  enter
                    .append('text')
                    .attr('class', 'dot-label')
                    .attr('x', (dd, i) => x(dd.date) + x.bandwidth() / 2)
                    .attr('y', (dd) =>
                      d.secondYAxis ? y1(dd.value) : y(dd.value)
                    )
                    .attr('dx', '.1rem')
                    .attr('dy', '-.5rem')
                    .attr(
                      'fill',
                      color_codes[d.name.split(' - ')[0]] ||
                        (d.styles && d.styles.color) ||
                        colors[i]
                    )
                    .style('text-anchor', 'middle')
                    .style('font-size', labelFontSize)
                    .text((d) =>
                      selectFormat(
                        d.secondYAxis ? secondYAxisFormat : format,
                        d.value
                      )
                    )
                    .call((enter) => enter.transition().duration(1200)),
                (update) =>
                  update
                    .transition()
                    .duration(1200)
                    .attr('x', (dd, i) => x(dd.date) + x.bandwidth() / 2)
                    .attr('y', (dd) =>
                      d.secondYAxis ? y1(dd.value) : y(dd.value)
                    )
                    .attr(
                      'fill',
                      color_codes[d.name.split(' - ')[0]] ||
                        (d.styles && d.styles.color) ||
                        colors[i]
                    )
                    .text((d) =>
                      selectFormat(
                        d.secondYAxis ? secondYAxisFormat : format,
                        d.value
                      )
                    )
                    .call((update) => update.transition().duration(1200)),
                (exit) => exit.call((exit) => exit.remove())
              );
          }
        });
      },
      (exit) => exit.call((exit) => exit.remove())
    );

  d3.select(lines.nodes()[0]).raise();
};

export { renderLineChart, redrawLineChart };
