import numeral from 'numeral';

export const selectFormat = (f, d) => {
  const format = Math.abs(d) < 1000 ? numeral(d).format("0.[0]") : numeral(d).format("0.0a");
  switch (f) {
    case "pts":
      return numeral(d).format("0.0") + "pts";
    case "percent":
      return numeral(d / 100).format("0.0%");
    default:
      return format.toUpperCase();
  }
};