import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ScaleLoader } from 'react-spinners';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: '50vw',
    top: '.85rem',
    marginLeft: '-15px',
    animation: 'fadein 1s ease-out alternate'
    // z-index: 100;
    // height: '30px',
    // padding: 0px 4px;
  },
  bottom: {
    color: '#181f28',
  },
  top: {
    color: '#f5f5f5',
    animationDuration: '950ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

export function ClipLoader(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={30}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={30}
        thickness={5}
        {...props}
      />
    </div>
  );
}

export function Loader({ color = '#fff', className = { root: {} } }) {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className.root)}>
      <ScaleLoader
        height={24}
        width={4}
        radius={2}
        margin={'3px'}
        color={color}
        loading={true}
      />
    </div>
  );
}
