import { combineReducers } from 'redux';
import dataReducer from './data';
import modalReducer from '../../components/Modals/reducer';

const reducers = combineReducers({
  data: dataReducer,
  modal: modalReducer,
});

export default reducers;
