import React from 'react';
import { TextField, Box, Typography } from '@material-ui/core';
import InputSearch from './../../../components/Other/InputSearch';

// map chart_id -> chart control
const controlTemplates = {
  MSE_Comp: (props, filter, setFilter) => {
    const data = props.tableData.data;
    const { selectedItem1, selectedItem2 } = props.chartControls[
      'MSE_Comp'
    ];

    return (
      <Box>
        <Box mb={1}>
          <Typography variant="body1" component="p">
            Select Items
          </Typography>
        </Box>
        <InputSearch
          id="input-search-MSE"
          setFilter={setFilter}
          filter={filter}
          size="small"
        />
        <ul className="list">
          {data
            ? data
                .filter((q) =>
                  filter
                    ? q.key.toLowerCase().indexOf(filter.toLowerCase()) >
                      -1
                    : true
                )
                .map((d) => (
                  <li
                    className={`list-item ${
                      [selectedItem1, selectedItem2].includes(d.id)
                        ? 'selected'
                        : ''
                    }`}
                    key={'1-' + d.id}
                    onClick={() => {
                      props.actionChangeChartControl(
                        'MSE_Comp',
                        selectedItem2 !== null
                          ? 'selectedItem1'
                          : 'selectedItem2',
                        d.id
                      );
                      setFilter('');
                    }}>
                    {d.key}
                  </li>
                ))
            : null}
        </ul>
      </Box>
    );
  }
};

export default controlTemplates;
