import React, { useState, useCallback } from 'react';
import {
  makeStyles,
  withStyles,
  Paper,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import _ from 'lodash';

// import Chart from './Chart';
import Chart from './_Chart';
import MarketAccessTable from './MarketAccessTable';
import ChartHead from './ChartHead';

import { isDoubleClick, getInsights, downloadXLS, downloadPPT } from './utils';

import { ClipLoader } from 'react-spinners';

import logo_YSEOP from './../../icons/pngs/powered_by_YSEOP_LOGO.png';

import _BarLine from './_BarLine';
import _Multiline from './_Multiline';

import SimpleDropdown from './../Other/SimpleDropdown';

const daily_charts = [
  {
    id: 'DS',
    name: 'Daily Sales',
    type: 'multiline',
    size: '1-1',
    cols: [
      {
        name: 'DARW',
        title: 'Daily Avg Rolling Week',
        color: '#a5a5a5', // gray
      },
      {
        name: 'DAR4W',
        title: 'Daily Avg Rolling 4 Week',
        color: '#4472c4', // blue
      },
      {
        name: 'DRR',
        title: 'Daily Run Rate',
        color: '#ffc001', // orange
      },
    ],
    data: 'sales',
    component: (props, data) => (
      <_Multiline id={props.id} data={data} cols={props.cols} />
    ),
  },
  {
    id: 'DS_QTD',
    name: 'Daily Sales',
    type: 'bar-line',
    size: '1-1',
    cols: [
      {
        name: 'QTD',
        title: 'QTD',
        color: '#ecab42', // orange
        type: 'line',
      },
      {
        name: 'Day',
        title: 'Day',
        color: '#c4e5de', // green
        type: 'bar',
      },
    ],
    data: 'sales',
    component: (props, data) => (
      <_BarLine id={props.id} data={data} cols={props.cols} />
    ),
  },
  {
    id: 'DS_Stocks',
    name: 'Sanofi Stocks',
    type: 'multiline',
    size: '1-1',
    cols: [
      {
        name: 'value',
        title: 'Daily Stocks',
        color: '#4472c4',
      },
    ],
    data: 'stocks',
    component: (props, data) => (
      <_Multiline id={props.id} data={data} cols={props.cols} />
    ),
  },
  {
    id: 'DS_Social',
    name: 'Mention in Derma',
    type: 'multiline',
    size: '1-1',
    cols: [
      {
        name: 'value',
        title: 'Daily Socials',
        color: '#4472c4',
      },
    ],
    data: 'socials',
    component: (props, data) => (
      <_Multiline id={props.id} data={data} cols={props.cols} />
    ),
  },
];

const ChartPaper = withStyles((thCme) => ({
  root: {
    cursor: 'pointer',
    height: '300px',
    //padding: "1rem",
    position: 'relative',
  },
}))(Paper);

const ChartTemplate = ({
  chart,
  chartVisible,
  setChartVisible,
  setState,
  fs_index,
  childrenPaper,
  childrenGrid,
}) => (
  <Grid
    item
    xs={12}
    className={chart.id}
    sm={chart.size ? 12 / chart.size.split('-')[1] : 6}
  >
    <ChartPaper
      elevation={chartVisible && chartVisible['chart_' + chart.index] ? 5 : 3}
      className={
        fs_index !== null && fs_index === chart.index
          ? 'full-screen-styles'
          : ''
      }
      onMouseEnter={() =>
        setChartVisible
          ? setChartVisible({ ['chart_' + chart.index]: true })
          : ''
      }
      onMouseLeave={() =>
        setChartVisible
          ? setChartVisible({ ['chart_' + chart.index]: false })
          : ''
      }
      onClick={(e) => {
        if (isDoubleClick(e)) {
          setState({
            fs_index: fs_index === null ? chart.index : null,
          });
        }
      }}
    >
      {childrenPaper}
    </ChartPaper>
    {childrenGrid}
  </Grid>
);

const useStyles = makeStyles((theme) => {
  return {
    chartIconsContainer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      top: 0,
      right: 0,
      marginTop: '4px',
      marginRight: '4px',
      color: theme.palette.grey[600],
    },
    plusMinus: {
      display: 'flex',
      alignItems: 'center',

      '& div svg': {
        width: '1rem',
      },
    },
    dropdown: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '100px',
      padding: '0.8rem 0rem',
      '& a:first-child': {
        marginBottom: '0.8rem',
        paddingBottom: '0.8rem',
        borderBottom: `1px solid ${theme.palette.grey[600]}`,
      },
      '& a': {
        paddingLeft: '1rem',
        textDecoration: 'none',
        color: theme.palette.grey[500],
      },
      '& a:hover': {
        color: theme.palette.text.primary,
      },
    },
  };
});

const Charts = React.memo(
  ({ params, state, setState, leftButtonsCharts }) => {
    const classes = useStyles();

    const [chartControlFilter, setChartControlFilter] = useState('');
    const handleChartControlFilter = useCallback((value) => setChartControlFilter(value), [])

    const [chartVisible, setChartVisible] = useState({});

    const { isInsightsLoading, insightsData } = state;

    const {
      charts,
      selectedId,
      controlsData,
      isShowRP,
      activeChartView,
      color_codes,
      tab_footnote,
      dateParse,
      daily_data,
      market_access,
      aggrData: { uniqueCountries },
      fs_index,
      actionSetFsIndex,
    } = params;

    return (
      <Grid container spacing={2}>
        {charts
          .filter((d) => d.tab === activeChartView && !d.disabled)
          .map((chart) => (
            <ChartTemplate
              key={'chart_' + chart.id}
              chart={chart}
              chartVisible={chartVisible}
              setChartVisible={setChartVisible}
              setState={setState}
              fs_index={fs_index}
              childrenGrid={
                chart.control ? (
                  <div className='chart-control-custom'>
                    {chart.control(
                      params,
                      chartControlFilter,
                      handleChartControlFilter
                    )}
                  </div>
                ) : null
              }
              childrenPaper={
                <>
                  <Box pl={1.2} pt={0.8}>
                    <ChartHead
                      chart={chart}
                      actionPlus={params.actionPlus}
                      actionMinus={params.actionMinus}
                      controlsData={controlsData}
                      names={
                        selectedId
                          ? selectedId.split(':').reverse().slice(0)
                          : ''
                      }
                      dateParse={dateParse}
                    />
                  </Box>
                  <Chart
                    id={chart.id}
                    type={chart.type}
                    format={chart.format}
                    data={chart.data || []}
                    chartType={chart.chartType}
                    margin={chart.margin}
                    secondYAxis={chart.secondYAxis}
                    secondYAxisFormat={chart.secondYAxisFormat}
                    showLabels={chart.showLabels}
                    yDomain={chart.yDomain}
                    selectedDate={
                      chart.selectedDate ? dateParse(chart.selectedDate) : null
                    }
                    changeSelectedDate={params.actionChangeSelectedDate}
                    full_screen={
                      fs_index !== null && fs_index === chart.index
                        ? true
                        : false
                    }
                    control={chart.control}
                    color_codes={color_codes}
                    isShowRP={isShowRP}
                  />
                  {chartVisible['chart_' + chart.index] ? (
                    <div className={classes.chartIconsContainer}>
                      {chart.number ? (
                        <Box mr={0.5} className={classes.plusMinus}>
                          <Box
                            mr={0.5}
                            onClick={() => params.actionPlus(chart.id)}
                          >
                            <AddCircleOutlineIcon className='w-icon-small' />
                          </Box>
                          <Box onClick={() => params.actionMinus(chart.id)}>
                            <RemoveCircleOutlineIcon className='w-icon-small' />
                          </Box>
                        </Box>
                      ) : null}
                      <Box
                        mr={0.1}
                        onClick={() =>
                          actionSetFsIndex(
                            fs_index === null ? chart.index : null
                          )
                        }
                      >
                        {fs_index === null ? (
                          <FullscreenIcon />
                        ) : (
                          <FullscreenExitIcon />
                        )}
                      </Box>
                      <SimpleDropdown
                        className={classes.root}
                        header={<GetAppIcon className='w-icon' />}
                        dropdownPositioning={{
                          top: 25,
                          right: 0,
                        }}
                        dropdown={
                          <Paper className={classes.dropdown}>
                            <a
                              href='#downloadXLS'
                              onClick={() => downloadXLS(chart)}
                            >
                              <Typography variant='body2' component='span'>
                                .xls
                              </Typography>
                            </a>
                            <a
                              href='#downloadPPT'
                              onClick={() =>
                                downloadPPT(chart.data, chart, params)
                              }
                            >
                              <Typography variant='body2' component='span'>
                                .pptx
                              </Typography>
                            </a>
                          </Paper>
                        }
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </>
              }
            />
          ))}
        {activeChartView === 14
          ? daily_charts &&
            daily_charts.map((chart) => (
              <ChartTemplate
                key={'chart_' + chart.id}
                chart={chart}
                chartVisible={chartVisible}
                setChartVisible={setChartVisible}
                setState={setState}
                fs_index={fs_index}
                childrenPaper={
                  <>
                    <ChartHead chart={chart} />
                    {chart.component(chart, daily_data[chart.data])}
                  </>
                }
              />
            ))
          : null}
        {activeChartView === 15 ? (
          <ChartTemplate
            chart={{}}
            chartVisible={chartVisible}
            setChartVisible={setChartVisible}
            setState={setState}
            fs_index={fs_index}
            childrenPaper={
              <>
                <div className='show-insights-block'>
                  <div
                    className={`btn sh-ins-button ${
                      isInsightsLoading ? 'disabled' : ''
                    }`}
                    onClick={() => getInsights(params, state, setState)}
                  >
                    <Typography variant='body1' element='span'>
                      Refresh
                    </Typography>
                    {isInsightsLoading ? (
                      <span
                        className='insights-spinner'
                        style={{ marginTop: '1px' }}
                      >
                        <ClipLoader
                          size={15}
                          color={'#ffffff'}
                          loading={isInsightsLoading}
                        />
                      </span>
                    ) : null}
                  </div>
                </div>
                {insightsData ? (
                  <div dangerouslySetInnerHTML={{ __html: insightsData }}></div>
                ) : null}
                <a
                  href='https://www.yseop.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img className='yseop-logo' src={logo_YSEOP} alt='' />
                </a>
              </>
            }
          />
        ) : null}
        {activeChartView === 18 ? (
          <ChartTemplate
            key='chart_MA'
            chart={{}}
            chartVisible={chartVisible}
            setChartVisible={setChartVisible}
            setState={setState}
            fs_index={fs_index}
            childrenPaper={
              <>
                <MarketAccessTable
                  data={market_access}
                  countries={uniqueCountries}
                />
                <div className='market_access-footnote'>
                  Last Update Date: {market_access.columns.slice(-1)[0]}
                </div>

                {chartVisible['chart_MA'] ? (
                  <div className={classes.chartIconsContainer}>
                    <Box
                      mr={0.5}
                      onClick={() =>
                        actionSetFsIndex(fs_index === null ? 'chart_MA' : null)
                      }
                    >
                      {fs_index === null ? (
                        <FullscreenIcon />
                      ) : (
                        <FullscreenExitIcon />
                      )}
                    </Box>
                    <SimpleDropdown
                      className={classes.root}
                      header={<GetAppIcon fontSize='small' />}
                      dropdownPositioning={{
                        top: 25,
                        right: 0,
                      }}
                      dropdown={
                        <Paper className={classes.dropdown}>
                          <a
                            href='#downloadXLS'
                            onClick={() => downloadXLS(market_access)}
                          >
                            <Typography variant='body2' component='span'>
                              .xls
                            </Typography>
                          </a>
                        </Paper>
                      }
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            }
          />
        ) : null}
        {tab_footnote[leftButtonsCharts[activeChartView].title] ? (
          <div className='tab-footnote'>
            {tab_footnote[leftButtonsCharts[activeChartView].title]}
          </div>
        ) : null}
      </Grid>
    );
  },
  (prev, next) => {
    const { isShowRP, fs_index, activeChartView } = prev.params;
    return (
      _.isEqual(prev.charts, next.charts) &&
      isShowRP === next.params.isShowRP &&
      fs_index === next.params.fs_index &&
      activeChartView === next.params.activeChartView
    );
  }
);

export default Charts;
