import * as React from 'react';
import { Modal as MUIModal, Fade, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
// import { Typography } from '../typography';
import { colors } from '../../theme';

const useModalStyles = makeStyles((theme) => ({
  general: {
    padding: '1rem',
  },
  contentNoPadding: {
    padding: 0,
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontSize: '1.1rem',
    fontWeight: 500,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '&:focus': {
      outline: 'none',
    },
  },
  content: {
    display: 'flex',
  },
}));

export const Modal = ({
  open,
  hideModal,
  content,
  noPadding = false,
  closeAfterTransition = true,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  headerClassName,
  contentClassName,
  headerText,
}) => {
  const classes = useModalStyles();

  return (
    <MUIModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      open={open}
      onClose={hideModal}
      closeAfterTransition={closeAfterTransition}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      BackdropComponent={Backdrop}>
      <Fade in={open}>
        <div className={classes.paper}>
          <div
            className={classnames(
              classes.general,
              classes.header,
              headerClassName
            )}>
            {headerText}
          </div>
          <div
            className={classnames(
              classes.general,
              classes.content,
              contentClassName
            )}>
            {content}
          </div>
        </div>
      </Fade>
    </MUIModal>
  );
};

Modal.displayName = 'Modal';
