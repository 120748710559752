import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Box, TextField, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonSample from '../../Other/ButtonSample';
import SimpleMaterialTable from '../../Other/SimpleMaterialTable';

import { actionHideModal } from '../actions';

const emails = [
  {
    email: 'asd1@gmail.com',
  },
  {
    email: 'asddfa4@gmail.com',
  },
  {
    email: 'gsssd2581@mail.com',
  },
  {
    email: 'kd2581@view.com',
  },
  {
    email: 'maks1@gmail.com',
  },
  {
    email: 'gasc1@gmail.com',
  },
  {
    email: 'makg2@mail.com',
  },
  {
    email: 'google41267903@gmail.com',
  },
  {
    email: 'mbds098562814@gmail.com',
  },
  {
    email: 'wtfQWERTYUIOP@yandex.com',
  },
  {
    email: 'view@mail.com',
  },
  {
    email: 'oneASDFGHJKL@tut.com',
  },
  {
    email: 'twomxcnzbxzv@gmail.com',
  },
];

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative',
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 350,
    },
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      top: -67,
      right: -11,
      color: theme.palette.common.white,
    },
  };
});

const Table = ({ data, setData }) => {
  const columns = [
    {
      title: 'E-mail',
      field: 'email',
    },
  ];

  return (
    <SimpleMaterialTable
      columns={columns}
      data={data}
      loader={!Boolean(data)}
      isSearch={false}
      removeRow={(row) => setData(data.filter((el) => el.email !== row.email))}
      tableContainerProps={{
        style: {
          width: '100%',
        },
      }}
    />
  );
};

function AddConfigModal({ actionHideModal }) {
  const classes = useStyles();

  const [dataTable, setDataTable] = useState([
    {
      email: 'asd1@gmail.com',
    },
    {
      email: 'asddfa4@gmail.com',
    },
    {
      email: 'asssssd2581@gmail.com',
    },
  ]);

  const [select, setSelect] = useState();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const query = useCallback((value) => {
    setSearch(value);
    setSelect(undefined);

    if (value) {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        setIsOpen(true);

        const newOptions = emails.filter(
          (el) => el.email.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
        setOptions(newOptions);

        const obj = newOptions.find((el) => value === el.email);
        if (obj) setSelect(obj);
      }, 1000);
    } else {
      setOptions();
      setIsOpen(false);
    }
  }, []);

  const clickShare = useCallback(() => {
    if (select && !dataTable.find((el) => el.email === select.email)) {
      setDataTable([...dataTable, select]);
    }
    setSelect(undefined);
    setSearch('');
  }, [select, dataTable]);

  return (
    <div className={classes.root}>
      <Box mb={2} display="flex">
        <Box mr={1} width="100%">
          <Autocomplete
            id="search-email-combo-box"
            value={select || { email: search }}
            options={options || []}
            getOptionSelected={(option, value) => option.email === value.email}
            getOptionLabel={(option) => (option ? option.email : '')}
            onInputChange={(event, value, reason) =>
              reason !== 'reset' ? query(value) : ''
            }
            onChange={(event, value) => setSelect(value)}
            fullWidth
            open={isOpen}
            handleHomeEndKeys
            onOpen={(event) => (search ? setIsOpen(true) : '')}
            onClose={(event, reason) => setIsOpen(false)}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.search}
                id="search-email-textfield"
                placeholder="E-mail"
                //error={search.error}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box>
          <ButtonSample
            name="Share"
            size="small"
            method={clickShare}
            size="small"
          />
        </Box>
      </Box>
      <div>
        <Table data={dataTable} setData={setDataTable} />
      </div>
      <CloseIcon
        className={classes.closeIcon}
        fontSize="small"
        onClick={actionHideModal}
      />
    </div>
  );
}

const mapStateToProps = (rootState, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionHideModal: () => dispatch(actionHideModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddConfigModal);
