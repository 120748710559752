import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
  },
}));

function ButtonSample({
  type = 'button',
  name,
  data = null,
  color = 'primary',
  size = 'medium',
  disabled = false,
  method = () => {},
  icon,
  fullWidth,
  style = {},
}) {
  const classes = useStyles();
  return (
    <Button
      type={type}
      variant="contained"
      color={color}
      size={size}
      className={classes.button}
      startIcon={icon}
      disabled={disabled}
      style={style ? style : {}}
      onClick={() => method(data)}
      fullWidth={fullWidth}
    >
      {name}
    </Button>
  );
}

export default ButtonSample;
