import React, { useState, useCallback, useRef, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    dropdown: {
      position: "absolute",
      zIndex: 10,
    },
  };
});

export default function Dropdown(props) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const refDropdownBlock = useRef(null);

  const offFocus = useCallback(
    (e) => {
      if (
        !(
          refDropdownBlock.current &&
          refDropdownBlock.current.contains(e.target)
        )
      )
        setIsOpen(false);
    },
    [isOpen]
  );

  useEffect(() => {
    window.addEventListener("click", offFocus);
    return () => {
      window.removeEventListener("click", offFocus);
    };
  });

  return (
    <Box position="relative" className={props.className} ref={refDropdownBlock}>
      <div onClick={() => setIsOpen(!isOpen)}>{props.header}</div>

      {isOpen ? (
        <div className={classes.dropdown} style={{ ...props.dropdownPositioning }}>
          {props.dropdown}
        </div>
      ) : (
        ""
      )}
    </Box>
  );
}
