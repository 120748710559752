import { getDataItem } from "../../../store/reducers/config/helper";
import getXMLString from "./getXMLString";

export const getInsights = (props, state, setState) => {
  if (state.isInsightsLoading) return;
  if (props.controlsData[3].data[0].controls.find((d) => d.active).name === "1")
    return setState({
      ...props,
      insightsData: "Please select Monthly frequency.",
    });

  let data, country, market, metric;

  const all_dates = props.datasource.subsetMap["market"].dates;
  const dateEndIndex = all_dates.indexOf(props.dateEnd["market"]) + 1;
  // if (dateEndIndex < 36) return setState({ insightsData: "No enougth data. Please use more recent Time Period if possible." });
  const dates = all_dates.map((d) => props.dateParse(d));

  // max number of month we send to yseop
  const n = 36;

  if (props.view === "market") {
    const { actives } = props;
    if (actives.headerCountries.length !== 1)
      return setState({
        ...props,
        insightsData: "Please filter a single country.",
      });
    if (actives.headerCountries[0].indexOf("Worldwide") > -1)
      return setState({
        ...props,
        insightsData: "Please filter a single country.",
      });
    data = props.aggrData.valueYSEOP_charts.map((p) => {
      const _values = p.values
        .map((d, i) => ({ date: dates[i], value: d }))
        .slice(Math.max(dateEndIndex - n, 0), dateEndIndex);
      return {
        ...p,
        isNewLaunch: Math.abs(_values[0].value) < 0.000001,
        values: _values,
      };
    });
    country = actives.headerCountries[0];
    market = actives.headerCategories.join(", ");
    metric = props.controlsData[3].data[1].controls.find((d) => d.active).name;
  } else if (props.view === "executive") {
    const useCategory = props.dims.includes("Category5");
    const useProduct = props.dims.includes("Product Group 1");

    // find ids
    const splittedId = props.selectedId && props.selectedId.split(":");

    // 1st level in the table has id == <Country>
    // 2nd level in the table has id == <Reference Market:Country:Product Portfolio:Product Group 1>
    // if 1st level selectedId => return no data
    // if (!splittedId || splittedId.length < 3 || splittedId.length > 4 || (!useCategory && splittedId.length > 3)) {
    if (!splittedId || splittedId.length < 3) return;

    country = splittedId[1];

    // const reference_market = tableData
    //   .find(d => d.id === country).children
    //   .find(d => d.id === selectedId);
    let reference_market = props.tableData.data.find((d) => d.id === country);
    const pathLength = useProduct ? props.dims.length - 1 : props.dims.length;

    for (let i = 2; i <= pathLength; i++) {
      const id =
        i === pathLength ? splittedId.slice(0, i + 1).join(":") : splittedId[i];
      reference_market = reference_market.children.find((d) => d.id === id);
    }

    market = reference_market.key;

    const ids = reference_market.last_children.map((d) => d.id);
    const default_metric = reference_market.default_metric;
    const filteredValue = ids.map((id) =>
      getDataItem(props.aggrData.value, id)
    );

    const isDefaultMetrics =
      props.controlsData[3].data[1].controls.find((d) => d.active).name ===
      "Default";
    const metricDemand = props.controlsData[3].data[1].controls.find(
      (d) => d.active
    );
    metric = isDefaultMetrics ? default_metric : metricDemand.name;

    data = [];
    filteredValue.forEach((pg1) => {
      if (pg1.key && pg1.key !== "null") {
        pg1.value.children.forEach((cg1) => {
          if (cg1.key && cg1.key !== "null") {
            cg1.value.children.forEach((pg2) => {
              if (pg2.key && pg2.key !== "null") {
                const _values = pg2.value.total[metric]
                  .map((d, i) => ({ date: dates[i], value: d }))
                  .slice(Math.max(dateEndIndex - n, 0), dateEndIndex);
                data.push({
                  productGroup1: pg1.key,
                  corporationGroup1: cg1.key,
                  productGroup2: pg2.key,
                  isNewLaunch: Math.abs(_values[0].value) < 0.000001,
                  values: _values,
                });
              }
            });
          }
        });
      }
    });
  } else return;

  if (!data) return setState({ ...props, insightsData: "Data is empty." });
  if (!data.length)
    return setState({ ...props, insightsData: "Data is empty." });

  const XMLString = getXMLString(country, market, data, metric);

  setState({
    ...props,
    isInsightsLoading: true,
    insightsData: "",
  });

  fetch("http://flashcard.eu-west-3.elasticbeanstalk.com/api/yseop", {
    method: "POST",
    headers: {
      "Content-Type": "application/soap+xml;charset=UTF-8",
      Accept: "text/html",
    },
    body: XMLString,
  })
    .then((resp) => resp.text())
    .then((resp) => {
      setState({ ...props, isInsightsLoading: false, insightsData: resp });
    })
    .catch((error) => {
      console.log("Error get insights: " + error);
      setState({ ...props, isInsightsLoading: false });
    });
}
