import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PortfolioIcon(props) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(-5, 29) scale(0.022, -0.022)">
      <path d="M632 1196 c-147 -55 -237 -149 -288 -299 -106 -313 180 -645 507
-589 299 51 468 374 340 648 -34 73 -115 164 -179 201 -112 66 -264 82 -380
39z m302 -115 c97 -48 158 -123 187 -232 21 -78 15 -83 -104 -76 -56 4 -113 7
-125 7 -22 0 -21 3 17 46 44 50 56 88 47 139 -13 68 -102 99 -176 61 -34 -18
-204 -198 -216 -230 -5 -13 -21 -16 -74 -16 l-67 0 8 50 c12 74 46 135 111
195 76 71 145 96 250 91 62 -2 89 -9 142 -35z m-54 -126 c17 -21 6 -41 -54
-105 l-49 -52 -30 30 -30 30 48 56 c50 59 89 73 115 41z m-320 -267 c0 -7 3
-30 6 -50 l7 -38 -57 0 c-60 0 -64 3 -82 75 -6 25 -5 25 60 25 46 0 66 -4 66
-12z m346 -5 c-2 -10 -7 -33 -11 -50 l-7 -33 -118 0 c-107 0 -118 2 -123 19
-4 16 3 19 46 25 36 4 59 13 76 31 22 21 35 25 84 25 51 0 58 -2 53 -17z m214
5 c0 -7 -5 -30 -11 -50 l-10 -38 -65 0 -66 0 7 43 c4 23 9 45 12 50 8 13 133
8 133 -5z m-526 -194 c4 -15 5 -29 2 -31 -4 -5 -76 45 -76 53 0 2 15 4 34 4
28 0 35 -4 40 -26z m271 -21 c-4 -27 -9 -51 -12 -55 -7 -13 -138 -9 -155 5 -9
6 -19 31 -23 55 l-7 42 102 0 102 0 -7 -47z m131 12 c-46 -41 -61 -44 -52 -12
3 12 6 28 6 35 0 8 15 12 43 12 l42 -1 -39 -34z"/>
      </g>
    </SvgIcon>
  );
}
