import { createMuiTheme } from "@material-ui/core/styles";

export const colors = {
  white: "#FFFFFF",
  tabsBackgroundDark: "#e3e4ec",
  tabsBackground: "#EFF0F6",
  yellowBackground: "#ffffe9",
  black: "#181f28",
  primary: {
    main: "#566f8f",
    light: "#e2e6e9",
    dark: "#394a60",
  },
};

// export const fonts = {
//   primary: 'Lato',
//   secondary: 'Open Sans',
// };

export const customTheme = createMuiTheme({
  palette: {
    primary: colors.primary
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    subtitle1: {
      fontWeight: 600
    },
    subtitle2: {
      fontWeight: 600
    },
    body2: {
      fontSize: "0.8rem"
    },
    caption: {
      fontSize: "0.6rem",
    },
    overline: {
      fontSize: "0.5rem",      
      textTransform: "uppercase"
    }
  }
});
