import * as d3 from 'd3';
import { selectFormat } from './selectFormat';

const renderHorizontalBarChart = ({
  data: _data,
  g,
  x,
  y,
  format,
  tooltip,
  labelFontSize,
}) => {
  const data = _data[0].data;

  g.select('.barChart').selectAll('.bar-item').remove();

  if (!data) return;
  if (!data.length) return;

  const bars = g.select('.barChart').selectAll('.bar-item').data(data).enter();

  const barItem = bars.append('g').attr('class', 'bar-item');
  barItem
    .append('rect')
    .attr('y', (d) => y(d.name))
    .attr('x', (d) => 0.5)
    .attr('height', y.bandwidth())
    .attr('width', (d) => x(d.value) + 0.5)
    .attr('fill', _data[0].colors[0])
    .on('mouseover', function (d) {
      const tpl =
        `<ul><li>${
          d.name
        }</li><li><div class="square" style="background-color: ${
          _data[0].colors[0]
        }"></div>${_data[0].legend[0]}: ${selectFormat(format, d.value)}</li>` +
        `<li><div class="square" style="background-color: ${
          _data[0].colors[1]
        }"></div>${_data[0].legend[1]}: ${selectFormat(
          format,
          d.innerValue
        )}</li></ul>`;

      tooltip.transition().duration(200).style('opacity', 0.9);
      tooltip
        .html(tpl)
        .style('left', `${d3.event.pageX + 8}px`)
        .style('top', `${d3.event.pageY - 48}px`);
    })
    .on('mouseout', function (d) {
      tooltip.transition().duration(500).style('opacity', 0);
    });
  barItem
    .append('rect')
    .attr('y', (d) => y(d.name) + y.bandwidth() / 6)
    .attr('x', (d) => 0.5)
    .attr('height', y.bandwidth() - y.bandwidth() / 3)
    .attr('width', (d) => x(d.innerValue) + 0.5)
    .attr('fill', (d) => _data[0].colors[1])
    .style('pointer-events', 'none');
  barItem
    .append('text')
    .attr('y', (d) => y(d.name) + y.bandwidth() / 2 + 4)
    .attr('x', (d) => x(d.value) + 4.5)
    .text((d) => selectFormat(format, d.value))
    .style('pointer-events', 'none')
    .style('font-size', labelFontSize);
  barItem
    .append('text')
    .attr('y', (d) => y(d.name) + y.bandwidth() / 2 + 4)
    .attr('x', (d) => x(d.innerValue) + 4.5)
    .text((d) => selectFormat(format, d.innerValue))
    .style('pointer-events', 'none')
    .style('font-size', labelFontSize);
};

export { renderHorizontalBarChart };
