import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { actionHideModal } from '../actions';
import ButtonSample from '../../Other/ButtonSample';
import { Form, Select } from '../../../shared';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '12rem',
  },
  buttonsContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function RsuiteTableModal(props) {
  const {
    modalType,
    modalData,
    datasource,
    metrics,
    sfeControls,
    handleCloseModal,
  } = props.config;
  const { actionHideModal } = props;
  const classes = useStyles();
  const [data, setData] = useState({});

  useEffect(() => {
    setData({ ...modalData });
  }, []);

  if (!Object.keys(data).length) return <div className={classes.root}></div>;

  return (
    <div className={classes.root}>
      <Form
        initialValues={{
          group: data.group ? data.group : '',
          metric: data.metric ? data.metric : '',
          kpi: data.kpi ? data.kpi : '',
          aggr: data.aggr ? data.aggr : '',
          customer_segment: data.customer_segment ? data.customer_segment : '',
          customer_specialty: data.customer_specialty
            ? data.customer_specialty
            : '',
        }}
        onSubmit={() => handleCloseModal(data, modalType)}
        // validate={createValidatorWithSchema(constraints)}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              {modalType === 'Add' ? (
                <Select
                  label="Group"
                  style={{ marginBottom: '1rem' }}
                  value={values.group}
                  values={datasource.kpiGroups.map((d) => ({
                    label: d.name,
                    value: d.name,
                  }))}
                  handleChange={(value) => {
                    const group = datasource.kpiGroups.find(
                      (d) => d.name === value
                    );
                    setFieldValue('group', value);
                    if (data.metric)
                      setFieldValue(
                        'metric',
                        group.dataType === 'finance'
                          ? metrics[8].controls[0].name
                          : ['sfe', 'target'].includes(group.dataType)
                          ? null
                          : metrics[1].controls[0].name
                      );
                    if (data.kpi)
                      setFieldValue(
                        'kpi',
                        group.dataType === 'finance'
                          ? metrics[9].controls[0].name
                          : group.dataType === 'forecast'
                          ? metrics[10].controls[0].name
                          : group.dataType === 'sfe'
                          ? metrics[13].controls[0].name
                          : group.dataType === 'target'
                          ? metrics[14].controls[0].name
                          : metrics[2].controls[0].name
                      );
                    if (data.aggr)
                      setFieldValue(
                        'aggr',
                        group.dataType === 'target'
                          ? null
                          : metrics[3].controls[0].name
                      );
                    if (data.customer_segment)
                      setFieldValue(
                        'customer_segment',
                        group.dataType === 'sfe' ? 'Tgt' : null
                      );
                    if (data.customer_specialty)
                      setFieldValue(
                        'customer_specialty',
                        group.dataType === 'sfe' ? 'Any' : null
                      );
                    return setData((old) => ({
                      ...old,
                      title: group.name,
                      dataType: group.dataType,
                      metric:
                        group.dataType === 'finance'
                          ? metrics[8].controls[0].name
                          : ['sfe', 'target'].includes(group.dataType)
                          ? null
                          : metrics[1].controls[0].name,
                      kpi:
                        group.dataType === 'finance'
                          ? metrics[9].controls[0].name
                          : group.dataType === 'forecast'
                          ? metrics[10].controls[0].name
                          : group.dataType === 'sfe'
                          ? metrics[13].controls[0].name
                          : group.dataType === 'target'
                          ? metrics[14].controls[0].name
                          : metrics[2].controls[0].name,
                      aggr:
                        group.dataType === 'target'
                          ? null
                          : metrics[3].controls[0].name,
                      group: group.name,
                      customer_segment: group.dataType === 'sfe' ? 'Tgt' : null,
                      customer_specialty:
                        group.dataType === 'sfe' ? 'Any' : null,
                    }));
                  }}
                />
              ) : null}
              {data.metric ? (
                <Select
                  label="Metric"
                  style={{ marginBottom: '1rem' }}
                  value={values.metric}
                  values={
                    data.dataType === 'finance'
                      ? metrics[8].controls.map((d) => ({
                          label: d.title,
                          value: d.name,
                        }))
                      : metrics[1].controls
                          .filter((d) => !d.hidden)
                          .concat({
                            title: 'Selected',
                            name: 'Selected',
                          })
                          .map((d) => ({ label: d.title, value: d.name }))
                  }
                  handleChange={(value) => {
                    setFieldValue('metric', value);
                    return setData((old) => ({
                      ...old,
                      metric: value,
                    }));
                  }}
                />
              ) : null}
              {data.kpi ? (
                <Select
                  label="KPI"
                  style={{ marginBottom: '1rem' }}
                  value={values.kpi}
                  values={
                    data.dataType === 'finance'
                      ? metrics[9].controls.map((d) => ({
                          label: d.title || d.name,
                          value: d.name,
                        }))
                      : data.dataType === 'forecast'
                      ? metrics[10].controls.map((d) => ({
                          label: d.title || d.name,
                          value: d.name,
                        }))
                      : data.dataType === 'sfe'
                      ? metrics[13].controls.map((d) => ({
                          label: d.title || d.name,
                          value: d.name,
                        }))
                      : data.dataType === 'target'
                      ? metrics[14].controls.map((d) => ({
                          label: d.title || d.name,
                          value: d.name,
                        }))
                      : metrics[2].controls.map((d) => ({
                          label: d.title || d.name,
                          value: d.name,
                        }))
                  }
                  handleChange={(value) => {
                    setFieldValue('kpi', value);
                    return setData((old) => ({ ...old, kpi: value }));
                  }}
                />
              ) : null}
              {data.aggr ? (
                <Select
                  label="Aggregation"
                  style={{ marginBottom: '1rem' }}
                  value={values.aggr}
                  values={metrics[3].controls.map((d) => ({
                    label: d.title || d.name,
                    value: d.name,
                  }))}
                  handleChange={(value) => {
                    setFieldValue('aggr', value);
                    return setData((old) => ({
                      ...old,
                      aggr: value,
                    }));
                  }}
                />
              ) : null}
              {data.customer_segment ? (
                <Select
                  label="Customer Segment"
                  style={{ marginBottom: '1rem' }}
                  value={values.customer_segment}
                  values={sfeControls[0].options.map((d) => ({
                    label: d.title || d.name,
                    value: d.name,
                  }))}
                  handleChange={(value) => {
                    setFieldValue('customer_segment', value);
                    return setData((old) => ({
                      ...old,
                      customer_segment: value,
                    }));
                  }}
                />
              ) : null}
              {data.customer_specialty ? (
                <Select
                  label="Customer Specialty"
                  style={{ marginBottom: '1rem' }}
                  value={values.customer_specialty}
                  values={sfeControls[1].options.map((d) => ({
                    label: d.title || d.name,
                    value: d.name,
                  }))}
                  handleChange={(value) => {
                    setFieldValue('customer_specialty', value);
                    return setData((old) => ({
                      ...old,
                      customer_specialty: value,
                    }));
                  }}
                />
              ) : null}
              <div>
                {modalType === 'Add' ? null : (
                  <div
                    className="uk-margin-right"
                    style={{ color: 'red' }}
                    onClick={(e) => handleCloseModal(data, 'Delete')}
                    data-uk-icon="icon: trash;"></div>
                )}
                <div className={classes.buttonsContainer}>
                  <ButtonSample
                    name="cancel"
                    color="default"
                    method={() => actionHideModal()}
                  />
                  <ButtonSample
                    type="submit"
                    name={modalType === 'Add' ? 'Add' : 'Apply'}
                    disabled={!Object.keys(data).length}
                  />
                </div>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
}

const mapStateToProps = ({ modal: state }, props) => {
  return {
    ...state,
    config: state.config.modalConfig,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionHideModal: () => dispatch(actionHideModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RsuiteTableModal);
