import React, { useEffect, useState } from 'react';
import  history from './../../services/history';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Badge,
  Menu,
  MenuItem,
  Divider,
  Avatar,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Loader } from './../Other/Loader';
import { email } from './../../services/auth';

import { colors } from '../../shared/theme/theme'

import logo from './../../icons/flashcard_logo/Flashcard-Logo-1-Blanc-Sans-Fond.png';

// import icon_sanofi from './../../icons/flashcard_logo/logo-sanofi-blanco.png';

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 0,
  },
  grow: {
    flexGrow: 1,
  },
  header: {
    boxShadow: 'none',
  },
  toolbar: {
    height: '3.5rem',
    minHeight: '3.5rem'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logo: {
    width: '150px',
    maxWidth: '150px',
    marginLeft: '-20px',
    cursor: "pointer"
  },
  logo_sanofi: {
    height: '36px',
    maxHeight: '36px',
    borderRight: '1px solid #ffffff',
    paddingRight: '1rem',
  },
  divider: {
    backgroundColor: '#ffffff',
    margin: '.8rem .3rem'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: '0!important',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {},
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuHeader: {
    cursor: "auto",
    "&:hover": {
      backgroundColor: colors.white,
    }
  },
  gap: {
    width: "100%",
    paddingTop: 5,
    marginBottom: 5,
    borderBottom: "1px solid " + theme.palette.grey[300],
  },
  menuPaper: {
    top: "47px !important",
    "& ul": {
      padding: "4px 0px",
    }
  }, 
  avatar: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.primary.main,
    backgroundColor: colors.tabsBackground,
    width: 24,
    height: 24,
  },
}));

const CustomAppBar = withStyles((theme) => ({
  root: {
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#181f28',
    // '&:hover': {
    //   backgroundColor: '#2d3a4b',
    // },
  },
}))(AppBar);

const Header = ({ name = '', query_loading, actionLogout }) => {
  const classes = useStyles();
  // const location = useLocation().pathname.split("/projects/") // ["", "0"]
  const [projectName, setProjectName] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  
  const user = { email: email() };

  useEffect(() => {
    setProjectName(name);
  }, [name]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{
        paper: classes.menuPaper
      }}
    >
      <MenuItem className={classes.menuHeader}><span>Signed in as <b>{user.email}</b></span></MenuItem>
      <div className={classes.gap}></div>
      <MenuItem 
        onClick={() => {
          handleMenuClose()
          history.push("/workspace")
        }}
      >
        Your Workspace
      </MenuItem>
      <MenuItem 
        onClick={() => {
          handleMenuClose()
          history.push("/profile")
        }}
        >
          Your Profile
        </MenuItem>
      <div className={classes.gap}></div>
      <MenuItem onClick={() => actionLogout()}>Sign out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.main}>
      <CustomAppBar className={classes.header} position="fixed">
        <Toolbar className={classes.toolbar}>
          {/* {projectName ?
            <Typography className={classes.title} variant="h6" noWrap>
              {projectName}
            </Typography>
            : null} */}
          <a href="/workspace"><img className={classes.logo} src={logo} alt="Flashcard" /></a>
          {!projectName ? (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          ) : null}
          <div className={classes.grow}></div>
          {query_loading ? <Loader /> : null}
          {/* <img className={classes.logo_sanofi} src={icon_sanofi} alt="Sanofi" /> */}
          <IconButton aria-label="help" color="inherit">
            <HelpIcon />
          </IconButton>
          <Divider orientation="vertical" className={classes.divider} flexItem />
          <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit">    
                <Avatar
                  src={user && user.avatar ? user.avatar.src : undefined}            
                  alt={"avatar" || user.avatar.alt}
                  className={classes.avatar}
                  children={user && user.avatar ? undefined : user.email.substr(0, 2).toUpperCase()}
                />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </CustomAppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

export default Header;
