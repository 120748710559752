import * as d3 from 'd3';
import { selectFormat } from './selectFormat';

const renderBarChart = ({
  data,
  g,
  innerWidth,
  labelFontSize,
  color_codes,
  tooltip,
  format,
  x,
  y,
  showLabels,
}) => {
  g.select('.barChart').selectAll('.bar').remove();
  g.select('.barChart').selectAll('text').remove();

  if (!data) return;
  if (!data.length) return;

  if (data.length > 1) {
    const barData = data[0].data.map((d, i) => {
      const _d = {
        date: d.date,
      };
      data.forEach(
        (item) =>
          (_d[item.name] =
            item.data[i] !== undefined ? item.data[i].value : null)
      );
      return _d;
    });
    const keys = data.map((d) => d.name);
    const groupKey = 'date';

    const x0 = d3
      .scaleBand()
      .domain(barData.map((d) => d[groupKey]))
      .rangeRound([0, innerWidth])
      .padding(0.2);

    const x1 = d3
      .scaleBand()
      .domain(keys)
      .rangeRound([0, x0.bandwidth() - 1])
      .padding(0.1);

    g.select('.barChart')
      .selectAll('g')
      .data(barData)
      .join('g')
      .attr('transform', (d) => `translate(${x0(d[groupKey])},0)`)
      .selectAll('rect')
      .data((d) =>
        keys.map((key) => ({
          key,
          value: d[key],
          date: d.date,
          color: data.find((q) => q.name === key).color,
        }))
      )
      .join('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x1(d.key))
      .attr('y', (d) => (d.value < 0 ? y(0) + 0.5 : y(d.value) + 0.5))
      .attr('width', x1.bandwidth())
      .attr('height', (d) =>
        d.value < 0 ? y(d.value) - y(0) : y(0) - y(d.value)
      )
      .attr('fill', (d) => color_codes[d.name] || d.color)
      .on('mouseover', function (d) {
        const tpl =
          `<ul><li><div class="square" style="background-color: ${
            color_codes[d.name] || d.color
          }"></div>${d.key}: ${selectFormat(format, d.value)}</li>` +
          `<li>Date: ${
            typeof d.date === 'string' ? d.date : d3.utcFormat('%b %Y')(d.date)
          }</li><ul>`;

        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(tpl)
          .style('left', `${d3.event.pageX + 8}px`)
          .style('top', `${d3.event.pageY - 48}px`);
      })
      .on('mouseout', function (d) {
        tooltip.transition().duration(500).style('opacity', 0);
      });
  } else {
    const barData = data[0].data.filter(
      (d) => !isNaN(d.value) && d.value !== null
    );

    const barChart = g.select('.barChart').selectAll('.bar').data(barData);

    const color = (d, i) => {
      return d.value < 0
        ? '#ffafb1'
        : color_codes[d.name] || d.color || data[0].color || '#caf299';
    };

    const lastObj = barData[barData.length - 1] || {};

    // bar chart
    barChart
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .style('fill', color)
      .attr('x', (d) => x(d.date))
      .attr('y', (d) => (d.value < 0 ? y(0) + 0.5 : (y(d.value) || 0) + 0.5))
      .attr('width', x.bandwidth())
      .attr('height', (d) =>
        d.value < 0 ? y(d.value) - y(0) || 0 : y(0) - y(d.value) || 0
      )
      .on('mouseover', function (d) {
        const tpl = `<ul><li><div class="square" style="background-color: ${color(
          d
        )}"></div>${
          typeof d.date === 'string' ? d.date : d3.utcFormat('%b %Y')(d.date)
        }: ${selectFormat(data[0].format || format, d.value)}</li><ul>`;

        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(tpl)
          .style('left', `${d3.event.pageX + 8}px`)
          .style('top', `${d3.event.pageY - 48}px`);
      })
      .on('mouseout', function (d) {
        tooltip.transition().duration(500).style('opacity', 0);
      });

    if (showLabels) {
      g.select('.barChart')
        .selectAll('.label')
        .data(barData)
        .enter()
        .append('text')
        .attr('class', 'label')
        .text((d) => selectFormat(data[0].format || format, d.value))
        .attr(
          'transform',
          (d) =>
            'translate(' +
            (x(d.date) + x.bandwidth() / 2 || 0) +
            ',' +
            (y(d.value) || 0) +
            ')'
        )
        .style('font-size', labelFontSize)
        .style('text-anchor', 'middle')
        .attr('dy', (d) => (d.value < 0 ? '.8rem' : '-.3rem'));
    } else {
      g.select('.barChart')
        .append('text')
        .attr('class', 'singleText')
        .text(selectFormat(data[0].format || format, lastObj.value))
        .attr(
          'transform',
          'translate(' +
            (x(lastObj.date) + x.bandwidth() / 2 || 0) +
            ',' +
            (y(lastObj.value) || 0) +
            ')rotate(-90)'
        )
        .style('font-size', labelFontSize)
        .attr('dx', lastObj.value >= 0 ? '10px' : '-45px')
        .attr('dy', '3px');
    }

    barChart.exit().remove();
  }
};

const redrawBarChart = ({
  data,
  g,
  innerWidth,
  labelFontSize,
  color_codes,
  tooltip,
  format,
  x,
  y,
  showLabels,
}) => {
  if (!data) return;
  if (!data.length) return;

  if (data.length > 1) {
    const barData = data[0].data.map((d, i) => {
      const _d = {
        date: d.date,
      };
      data.forEach(
        (item) =>
          (_d[item.name] =
            item.data[i] !== undefined ? item.data[i].value : null)
      );
      return _d;
    });
    const keys = data.map((d) => d.name);
    const groupKey = 'date';

    const x0 = d3
      .scaleBand()
      .domain(barData.map((d) => d[groupKey]))
      .rangeRound([0, innerWidth])
      .padding(0.2);

    const x1 = d3
      .scaleBand()
      .domain(keys)
      .rangeRound([0, x0.bandwidth() - 1])
      .padding(0.1);

    g.select('.barChart')
      .selectAll('g')
      .data(barData)
      .join('g')
      .attr('transform', (d) => `translate(${x0(d[groupKey])},0)`)
      .selectAll('rect')
      .data((d) =>
        keys.map((key) => ({
          key,
          value: d[key],
          date: d.date,
          color: data.find((q) => q.name === key).color,
        }))
      )
      .join('rect')
      .transition()
      .duration(1200)
      .attr('x', (d) => x1(d.key))
      .attr('y', (d) => (d.value < 0 ? y(0) + 0.5 : y(d.value) + 0.5))
      .attr('width', x1.bandwidth())
      .attr('height', (d) =>
        d.value < 0 ? y(d.value) - y(0) : y(0) - y(d.value)
      )
      .attr('fill', (d) => color_codes[d.name] || d.color);
  } else {
    const barData = data[0].data.filter(
      (d) => !isNaN(d.value) && d.value !== null
    );

    const barChart = g
      .select('.barChart')
      .selectAll('.bar')
      .data(barData, (d) => d.date);

    const color = (d, i) => {
      return d.value < 0
        ? '#ffafb1'
        : color_codes[d.name] || d.color || data[0].color || '#caf299';
    };

    const lastObj = barData[barData.length - 1] || {};

    // bar chart
    barChart.join(
      (enter) =>
        enter
          .append('rect')
          .attr('class', 'bar')
          .style('fill', color)
          .attr('x', (d) => x(d.date))
          .attr('y', (d) =>
            d.value < 0 ? y(0) + 0.5 : (y(d.value) || 0) + 0.5
          )
          .attr('width', x.bandwidth())
          .attr('height', (d) =>
            d.value < 0 ? y(d.value) - y(0) || 0 : y(0) - y(d.value) || 0
          ),
      (update) =>
        update
          .transition()
          .duration(1200)
          .style('fill', color)
          .attr('x', (d) => x(d.date))
          .attr('y', (d) =>
            d.value < 0 ? y(0) + 0.5 : (y(d.value) || 0) + 0.5
          )
          .attr('width', x.bandwidth())
          .attr('height', (d) =>
            d.value < 0 ? y(d.value) - y(0) || 0 : y(0) - y(d.value) || 0
          ),
      (exit) => exit.call((exit) => exit.remove())
    );

    if (showLabels) {
      g.select('.barChart').selectAll('.singleText').remove();
      g.select('.barChart')
        .selectAll('.label')
        .data(barData, (d) => d.date)
        .join(
          (enter) =>
            enter
              .append('text')
              .attr('class', 'label')
              .text((d) => selectFormat(data[0].format || format, d.value))
              .attr(
                'transform',
                (d) =>
                  'translate(' +
                  (x(d.date) + x.bandwidth() / 2 || 0) +
                  ',' +
                  (y(d.value) || 0) +
                  ')'
              )
              .style('font-size', labelFontSize)
              .style('text-anchor', 'middle')
              .attr('dy', (d) => (d.value < 0 ? '.8rem' : '-.3rem')),
          (update) =>
            update
              .transition()
              .duration(1200)
              .text((d) => selectFormat(data[0].format || format, d.value))
              .attr(
                'transform',
                (d) =>
                  'translate(' +
                  (x(d.date) + x.bandwidth() / 2 || 0) +
                  ',' +
                  (y(d.value) || 0) +
                  ')'
              )
              .attr('dy', (d) => (d.value < 0 ? '.8rem' : '-.3rem')),
          (exit) => exit.call((exit) => exit.remove())
        );
    } else {
      g.select('.barChart').selectAll('.label').remove();
      g.select('.barChart').selectAll('.singleText').remove();
      g.select('.barChart')
        .append('text')
        .attr('class', 'singleText')
        .text(selectFormat(data[0].format || format, lastObj.value))
        .attr(
          'transform',
          'translate(' +
            (x(lastObj.date) + x.bandwidth() / 2 || 0) +
            ',' +
            (y(lastObj.value) || 0) +
            ')rotate(-90)'
        )
        .style('font-size', labelFontSize)
        .attr('dx', lastObj.value >= 0 ? '10px' : '-45px')
        .attr('dy', '3px');
    }
  }
};

export { renderBarChart, redrawBarChart };
