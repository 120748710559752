import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { sum } from "d3";

import TabPanel from "./../../Other/TabPanel";

import ListControls from "./ListControls";

import {
  actionSelectControlTab,
  actionControlReset,
} from "./../../../store/actions";

import InputSearch from "../../Other/InputSearch";

const useStyles = makeStyles((theme) => {
  return {
    tabs: {
      minHeight: "0px",
      "& div button": {
        minWidth: "0px",
        minHeight: "0px",
        fontSize: "10px",
        padding: "3px",
      },
      paddingBottom: "1.1rem",

      "& > div": {
        overflow: "visible !important",
      },
    },
    clearAll: {
      position: "absolute",
      zIndex: 1,
      right: "5px",
      top: "3px",
      color: "red",
      fontSize: "14px",
      opacity: ".7",
      cursor: "pointer",

      "&:hover": {
        opacity: 1,
        textDecoration: "underline",
      },
    },
    numberFound: {
      position: "absolute",
      top: 23,
      left: 0,
      width: "100%",
      textAlign: "center",
    },
    tab: {
      position: "relative",
      overflow: "visible",
    },
    tabsStar: {
      position: "absolute",
      top: -5,
      right: -7,
    },
  };
});

const RightPanel = (props) => {
  const {
    config_id,
    controlsData: _controlsData,
    selectedTabId,
    actionSelectControlTab,
    datasource,
    dateParse,
  } = props;

  const classes = useStyles();

  const [filter, setFilter] = useState("");

  const controlsData = _controlsData
    .filter((d) => !d.hidden)
    .sort((a, b) => a.order - b.order);

  const isActiveControls = controlsData.map((m) => {
    const arr = m.data
      .map((mm) => mm.controls.filter((f) => f.active).length !== 0)
      .filter((f) => f);
    return arr;
  });

  const isEmpty =
    isActiveControls[0].length +
      isActiveControls[1].length +
      isActiveControls[2].length ===
    0;

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `right-panel-tab-${index}`,
      className: classes.tab,
    };
  }

  const handleChange = (event, newValue) => {
    actionSelectControlTab(newValue);
  };

  return (
    <div>
      <Box mb={1} px={0.5} display="flex" alignItems="center">
        <InputSearch
          id="input-search-right-panel"
          size="small"
          setFilter={setFilter}
          filter={filter}
          inputWidth="160px"
        />
        {!isEmpty ? (
          <div
            className={classes.clearAll}
            onClick={() => props.actionControlReset(-1)}
          >
            Clear All
          </div>
        ) : null}
      </Box>

      <Tabs
        value={selectedTabId}
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor="primary"
        aria-label="right panel tabs"
        variant="fullWidth"
      >
        {controlsData.map((el, i) => {
          const filtered =
            filter && ![3].includes(el.id)
              ? sum(el.data, (c) =>
                  !c.hidden
                    ? c.controls.filter(
                        (d) =>
                          d.name.toLowerCase().indexOf(filter.toLowerCase()) >
                          -1
                      ).length
                    : 0
                )
              : null;
          return (
            <Tab
              key={`right-panel-tab-${i}`}
              label={
                <Box>
                  <Box display="inline" position="relative">
                    {el.name}
                    {el.name !== "Metrics" && isActiveControls[i].length ? (
                      <Typography
                        className={classes.tabsStar}
                        color="error"
                        variant="body1"
                        component="span"
                      >
                        *
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                  {filtered ? (
                    <Typography
                      variant="caption"
                      component="span"
                      className={classes.numberFound}
                    >
                      [
                      <Typography
                        color="primary"
                        variant="caption"
                        component="span"
                      >
                        {filtered}
                      </Typography>
                      ]
                    </Typography>
                  ) : null}
                </Box>
              }
              {...tabProps(el.id)}
            />
          );
        })}
      </Tabs>

      {controlsData.map((t, i) => (
        <TabPanel key={"tab_" + t.id} value={selectedTabId} index={t.id}>
          <ListControls
            config_id={config_id}
            tabId={t.id}
            active={t.active}
            filter={filter.toLowerCase()}
            resetFilter={() => setFilter("")}
            controlsData={controlsData}
            datasource={datasource}
            dateParse={dateParse}
          />
        </TabPanel>
      ))}
    </div>
  );
};

const mapStateToProps = (state, props) => props;

const mapDispatchToProps = (dispatch, props) => {
  const { config_id } = props;
  return {
    actionSelectControlTab: (id) =>
      dispatch(actionSelectControlTab(config_id, id)),
    actionControlReset: (i, ii) =>
      dispatch(actionControlReset(config_id, i, ii)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
