import React from "react";
import { utcFormat } from "d3";

import "./right_panel/RightPanel.scss";
import { Typography } from "@material-ui/core";

export default function ChartHead(props) {
  const { chart, controlsData, names, dateParse } = props;
  const chartName = chart.selectedDate
    ? chart.name + utcFormat("%b %Y")(dateParse(chart.selectedDate))
    : chart.name;

  let aggr = chart.aggr; //controlsData[3].data[4].controls.filter(f => f.active)[0];
  // hide time aggr for patient charts
  if (["patient"].includes(chart.dataType)) aggr = null;

  if (
    aggr &&
    chart.aggregatePeriods &&
    chart.aggregatePeriods.length &&
    !chart.aggregatePeriods.includes(aggr.period)
  ) {
    aggr = controlsData[3].data[4].controls.find(
      (d) => d.period === chart.aggregatePeriods[0]
    );
  }

  const content = () => {
    if (chart.dataType === "finance") {
      return (
        <>
          {chartName} (
          {controlsData[3].data[8].controls.filter((d) => d.active)[0].title}),{" "}
          {aggr ? aggr.name : ""} - {names[0]}
          {names.length > 1 ? " in " + names.slice(1).join(", ") : null}
        </>
      );
    }
    if (
      [
        "AMS_FMS_F",
        "AS_FS_F",
        "ABF_F",
        "MS_T_NSA_PT",
        "MS_T_ADC_PT",
        "YTD_YTG_F",
      ].includes(chart.id)
    ) {
      return (
        <>
          {chartName}{" "}
          {chart.channel
            ? `(${chart.channel.metrics} - ${chart.channel.channels})`
            : ""}{" "}
          {aggr ? ", " + aggr.name : ""} - {names[0]}
        </>
      );
    }
    if (["PS_SOB", "PC_SOB", "PPC_SOB", "NS_SOB"].includes(chart.id)) {
      return (
        <>
          {names.length ? names[0] + " - " : ""}
          {chartName}{" "}
          {chart.channel
            ? `(${chart.channel.metrics} - ${chart.channel.channels})`
            : ""}{" "}
          {aggr ? " - " + aggr.name : ""}
        </>
      );
    }

    return (
      <>
        {chartName}{" "}
        {chart.channel
          ? `(${chart.channel.metrics} - ${chart.channel.channels})`
          : ""}{" "}
        {aggr ? " - " + aggr.name : ""}
      </>
    );
  };

  return (
    <div>
      <Typography variant="subtitle2" component="h5">
        {content()}
      </Typography>
    </div>
  );
}
