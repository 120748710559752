import React from "react"
import { TextField, InputAdornment, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => {
  return {
    clearIcon: {
      cursor: "pointer"
    }
  };
});

export default function InputSearch({ filter, setFilter, id, inputWidth, size, color }) {
const classes = useStyles()

  return (
    <TextField
      id={id}
      //className={classes.inputSearch}
      size={size}
      color={color}
      style={{
        width: inputWidth
      }}
      placeholder="Search..."
      onChange={(e) => setFilter(e.target.value)}
      value={filter}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: filter ? (
          <InputAdornment position="end" onClick={() => setFilter("")}>
            <ClearIcon className={classes.clearIcon} color="error" />
          </InputAdornment>
        ) : (
          ""
        ),
      }}
    />
  );
}
