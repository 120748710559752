import React, { useRef, useCallback, useState } from 'react';
import {
  getEndDate,
  serializeConfig,
} from './../../store/reducers/config/helper';
import { useRectBounding } from '../../services/hooks';

import { ModalTypes } from '../Modals/constants';

import {
  makeStyles,
  withStyles,
  Paper,
  Box,
  Typography,
} from '@material-ui/core';

import {
  CloseRounded,
  MenuRounded,
  // FolderOpenRounded,
  SaveRounded,
  // OpenInNewRounded,
  // HelpOutlineRounded,
  EditRounded,
  ShareRounded,
} from '@material-ui/icons';

import './Main.scss';

import { useIsIpad } from '../../shared/theme/mediaQuries';
import { colors } from '../../shared/theme/theme';

import SimpleTooltip from '../Other/SimpleTooltip';
import SimpleDropdown from '../Other/SimpleDropdown';

const color = '#181f28';

const MyPaper = withStyles((theme) => ({
  root: {
    backgroundColor: colors.yellowBackground,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '.8rem 1rem 0 1rem',
    padding: '.25rem 1rem',
    width: 'calc(100% - 2rem)',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}))(Paper);

const aStyles = (theme) => ({
  '& a': {
    textDecoration: 'none',
    color: theme.palette.grey[500],
  },
  '& a:hover': {
    color: theme.palette.text.primary,
  },
});

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'fixed',
      zIndex: '100',
      width: '100%',
      backgroundColor: '#ffffff',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    dropdown_0: {
      height: '60vh',
      width: '500px',
      overflowY: 'auto',
      ...aStyles(theme),
      '& a p': {
        padding: '0.5rem',
        marginBottom: '0.5rem',
        borderBottom: `1px solid ${theme.palette.common.black}`,
      },
    },
    dropdown_1: {
      width: '220px',
      ...aStyles(theme),
      '& a p': {
        padding: '0px 0.5rem 0.5rem',
      },
      '& a:first-child p': {
        padding: '0.5rem',
        marginBottom: '0.8rem',
        borderBottom: `1px solid ${theme.palette.common.black}`,
      },
    },
    dropdown_3: {
      width: '200px',
      ...aStyles(theme),
      '& a p': {
        padding: '0px 0.5rem 0.5rem',
      },
      '& a:first-child p': {
        padding: '0.5rem',
      },
    },
    leftContainer: {
      [theme.breakpoints.down('xs')]: {
        marginTop: '1.5rem',
      },
    },
    rightContainer: {
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: '24px',
        top: '14px',
      },
    },
    iconBox: {
      width: '24px',
      heigth: '24px',
      [theme.breakpoints.down('xs')]: {
        width: '20px',
        heigth: '20px',
      },
    },
  };
});

export default function Header(props) {
  const {
    controlsData,
    actives,
    links,
    questionLinks,
    datasource,
    actionLoadData,
    isShowRP,
    isTooltip,
    dateEnd,
    config,
    actionSaveConfig,
    actionShowModal,
    actionFirstHeaderIcon,
    actionLastHeaderIcon,
  } = props;

  const classes = useStyles();

  const fileLoaderRef = useRef(null);
  const [rect, ref] = useRectBounding();

  const isIpad = useIsIpad();

  const handlerIcons = useCallback(
    (e, i) => {
      e.stopPropagation();
      switch (i) {
        case 1:
          actionFirstHeaderIcon(isTooltip);
          break;
        case 5:
          actionLastHeaderIcon(isShowRP);
          break;
        case 6: {
          actionShowModal(ModalTypes.UpdateModal, config);
          break;
        }
        case 7: {
          actionShowModal(ModalTypes.ShareModal, config);
          break;
        }
        case 8: {
          actionSaveConfig();
          break;
        }
        default:
      }
    },
    [actionFirstHeaderIcon, isShowRP, isTooltip, actionShowModal, config]
  );

  const openFile = useCallback(
    (e) => {
      const { actionLoadData } = props;
      const reader = new FileReader();
      reader.onload = onReaderLoad;
      reader.readAsText(e.target.files[0]);

      function onReaderLoad(event) {
        const config = JSON.parse(event.target.result);
        actionLoadData(-1, config); // -1 is for default dataset
      }
    },
    [actionLoadData]
  );

  const saveFile = useCallback(() => {
    function download(text, name, type) {
      const a = document.createElement('a');
      const file = new Blob([text], { type: type });
      a.href = URL.createObjectURL(file);
      a.download = name;
      a.click();
    }
    download(
      JSON.stringify(serializeConfig(props.data), null, 2),
      'Flashcard-Config.json',
      'text/plain'
    );
  }, []);

  const saveDefaultConfig = useCallback(
    (config) => {
      const { user } = props;
      const id = user.UserID.toLowerCase().split('\\').pop();

      fetch('./api/save_config.aspx?user_id=' + id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(config || serializeConfig(props), null, 2),
      })
        .then((e) => e.text())
        .then((e) => {
          console.log('Server Response: ', e);

          // log this action
          fetch('./api/save_log.aspx?user_id=' + id, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify(
              {
                timestamp: new Date(),
                action: 'Save Default Config',
                result: e,
              },
              null,
              2
            ),
          });
        });
    },
    [props.user]
  );

  const resetConfig = useCallback(async () => {
    const { user } = props;
    const { data_url } = await (await fetch('./app.json')).json();

    // select one of default configs
    const market = user['Market_default'].toUpperCase();
    const default_user_config = await (
      await fetch(`${data_url}/config/DefaultUserConfigs/FCO_${market}.json`)
    ).json();

    saveDefaultConfig(default_user_config);
  }, [props.user]);

  const dataIcons = [
    {
      id: 5,
      icon: isShowRP ? (
        <CloseRounded style={{ color }} />
      ) : (
        <MenuRounded style={{ color }} />
      ),
      tooltip: isShowRP ? 'Close filter menu' : 'Open filter menu',
    },
    // {
    //   id: 0,
    //   icon: <FolderOpenRounded style={{ color }} />,
    //   items: [],
    // },
    {
      id: 6,
      icon: <EditRounded style={{ color }} />,
      tooltip: 'Edit Analysis',
    },
    // {
    //   id: 1,
    //   icon: <SaveRounded style={{ color }} />,
    //   tooltip: 'Save Analysis'
    // },
    {
      id: 8,
      icon: <SaveRounded style={{ color }} />,
      tooltip: 'Save Analysis',
    },
    // {
    //   id: 2,
    //   icon: <OpenInNewRounded style={{ color }} />,
    //   hidden: isIpad,
    // },
    // {
    //   id: 3,
    //   icon: <HelpOutlineRounded style={{ color }} />,
    //   hidden: isIpad,
    // },
    {
      id: 7,
      icon: <ShareRounded style={{ color }} />,
      tooltip: 'Share Analysis',
    },
  ];

  const renderLeft = () => (
    <div className={classes.leftContainer} style={{ flex: 1 }}>
      <Box>
        <Typography variant="subtitle1" component="span">
          {datasource.name}:{' '}
        </Typography>
        <Typography variant="body1" component="span">
          {actives.headerCountries
            ? actives.headerCountries.length > 7
              ? `${actives.headerCountries.length} Countries - `
              : actives.headerCountries.join(', ') + ' - '
            : ''}
          {actives.headerProducts
            ? actives.headerProducts.length > 5
              ? `${actives.headerProducts.length} Products in `
              : actives.headerProducts.join(', ') + ' in '
            : ''}
          {actives.headerCategories
            ? actives.headerCategories.length > 7
              ? `${actives.headerCategories.length} Market`
              : actives.headerCategories
                  .map((c) => {
                    const rm = c.split(':');
                    return rm.length > 1 ? rm[1] + ` (${rm[0]})` : rm[0];
                  })
                  .join(', ') +
                (actives.headerCategories.indexOf('Libtayo Scorecard') > -1
                  ? ''
                  : ' Market')
            : ''}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" component="span">
          {dateEnd['market'] ? (
            <span>
              Demand Sales in{' '}
              {
                (controlsData[3].data[1].controls.find((d) => d.active) || {})
                  .title
              }{' '}
              {controlsData[3].data[6].controls[0].active ||
              controlsData[3].data[6].customDate.active
                ? ' - ' +
                  getEndDate(
                    props,
                    'market',
                    controlsData[3].data[0].controls[1].active
                  )
                : ''}
              {' - '}
              {
                (controlsData[3].data[0].controls.find((d) => d.active) || {})
                  .title
              }
            </span>
          ) : null}
          {datasource.subsetMap['finance'] &&
          datasource.subsetMap['finance'].dataLength &&
          dateEnd['finance']
            ? [
                dateEnd['market'] ? (
                  <span key="slash" style={{ margin: '0px .5rem' }}>
                    {' / '}
                  </span>
                ) : null,
                <span key="finance-title">
                  Finance Sales in{' '}
                  {
                    (
                      controlsData[3].data[8].controls.find((d) => d.active) ||
                      {}
                    ).title
                  }{' '}
                  {' - ' + getEndDate(props, 'finance')}
                </span>,
              ]
            : null}
          {datasource.subsetMap['sit'] &&
          datasource.subsetMap['sit'].dataLength &&
          dateEnd['sit']
            ? [
                <span key="slash" style={{ margin: '0px .5rem' }}>
                  {' '}
                  /{' '}
                </span>,
                <span key="sit-title">SIT - {getEndDate(props, 'sit')}</span>,
              ]
            : null}
          {datasource.subsetMap['sfe'] &&
          datasource.subsetMap['sfe'].dataLength &&
          dateEnd['sfe']
            ? [
                <span key="slash" style={{ margin: '0px .5rem' }}>
                  {' '}
                  /{' '}
                </span>,
                <span key="sfe-title">SFE - {getEndDate(props, 'sfe')}</span>,
              ]
            : null}
          {datasource.subsetMap['crm'] &&
          datasource.subsetMap['crm'].dataLength &&
          dateEnd['crm']
            ? [
                <span key="slash" style={{ margin: '0px .5rem' }}>
                  {' '}
                  /{' '}
                </span>,
                <span key="crm-title">CRM - {getEndDate(props, 'crm')}</span>,
              ]
            : null}
        </Typography>
      </Box>
    </div>
  );

  const dropdown_3 = useCallback(
    () => (
      <Paper className={classes.dropdown_3}>
        {questionLinks.map((item, j) => (
          <a
            href={item.url + (item.subject ? `?subject=${item.subject}` : '')}
            target="_blank"
            rel="noopener noreferrer"
            key={j}>
            <Typography
              variant="body2"
              component="p"
              onClick={(e) => resetConfig(e)}>
              {item.name}
            </Typography>
          </a>
        ))}
      </Paper>
    ),
    [questionLinks]
  );

  const dropdown_2 = useCallback(
    () => (
      <Paper className={classes.dropdown_3}>
        {links.map((item, j) => (
          <a key={j} href={item.url} target="_blank" rel="noopener noreferrer">
            <Typography variant="body2" component="p">
              {item.name}
            </Typography>
          </a>
        ))}
      </Paper>
    ),
    [links]
  );

  const renderRight = useCallback(
    () => (
      <Box className={classes.rightContainer} display="flex">
        {dataIcons
          .filter((m) => !m.hidden)
          .map((m) => (
            <Box key={m.id} m={0.5} className={classes.iconBox}>
              {m.id === 0 ? (
                <SimpleTooltip title="Open Analysis" placement="top">
                  <div>
                    <SimpleDropdown
                      header={
                        <div className={classes.cursorPointer}>{m.icon}</div>
                      }
                      dropdownPositioning={{
                        top: '2rem',
                        right: '0px',
                      }}
                      dropdown={
                        <Paper className={classes.dropdown_0}>
                          <a href="#">
                            <Typography
                              variant="body2"
                              component="p"
                              onClick={(e) => fileLoaderRef.click()}>
                              Open Saved Analysis
                            </Typography>
                          </a>
                          <Box pl={1}>
                            <Typography variant="overline" component="p">
                              <b>Standard Analysis</b>
                            </Typography>

                            {m.items.map((item) => (
                              <a
                                href="#"
                                key={item.index}
                                onClick={() =>
                                  !item.disabled && actionLoadData(item.index)
                                }>
                                <Typography variant="overline" component="span">
                                  {item.name}
                                </Typography>
                              </a>
                            ))}
                          </Box>
                        </Paper>
                      }
                    />
                  </div>
                </SimpleTooltip>
              ) : m.id === 1 ? (
                <SimpleTooltip title={m.tooltip} placement="top">
                  <div>
                    <SimpleDropdown
                      header={
                        <div className={classes.cursorPointer}>{m.icon}</div>
                      }
                      dropdownPositioning={{
                        top: '2rem',
                        right: '0px',
                      }}
                      dropdown={
                        <Paper className={classes.dropdown_1}>
                          <a href="">
                            <Typography
                              variant="body2"
                              component="p"
                              onClick={(e) => saveFile(e)}>
                              Save Current Analysis
                            </Typography>
                          </a>
                          <a href="">
                            <Typography
                              variant="body2"
                              component="p"
                              onClick={() => saveDefaultConfig()}>
                              Save Current Analysis by Default
                            </Typography>
                          </a>
                          <a href="">
                            <Typography
                              variant="body2"
                              component="p"
                              onClick={(e) => resetConfig(e)}>
                              Reset Default Analysis
                            </Typography>
                          </a>
                        </Paper>
                      }
                    />
                  </div>
                </SimpleTooltip>
              ) : m.id === 3 ? (
                <SimpleTooltip title="Help" placement="top">
                  <div>
                    <SimpleDropdown
                      header={
                        <div className={classes.cursorPointer}>{m.icon}</div>
                      }
                      dropdownPositioning={{
                        top: '2rem',
                        right: '0px',
                      }}
                      dropdown={dropdown_3()}
                    />
                  </div>
                </SimpleTooltip>
              ) : m.id === 2 ? (
                <SimpleTooltip title="Cube Analysis" placement="top">
                  <div>
                    <SimpleDropdown
                      header={
                        <div className={classes.cursorPointer}>{m.icon}</div>
                      }
                      dropdownPositioning={{
                        top: '2rem',
                        right: '0px',
                      }}
                      dropdown={dropdown_2()}
                    />
                  </div>
                </SimpleTooltip>
              ) : (
                <SimpleTooltip
                  title={m.tooltip}
                  placement="top"
                  onClick={(e) => handlerIcons(e, m.id)}>
                  <div className={classes.cursorPointer}>{m.icon}</div>
                </SimpleTooltip>
              )}
            </Box>
          ))}
      </Box>
    ),
    [dataIcons]
  );

  return (
    <>
      <div ref={ref} className={classes.root}>
        <MyPaper>
          {renderLeft()}
          {renderRight()}
          <input
            type="file"
            ref={fileLoaderRef}
            id="fileLoader"
            name="files"
            title="Open"
            accept=".json"
            style={{ display: 'none' }}
            onChange={(e) => openFile(e)}
          />
        </MyPaper>
      </div>
      <div style={{ height: rect.height }}></div>
    </>
  );
}
