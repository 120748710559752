import React from "react";

import XLSX from "xlsx";

import { Table } from "rsuite";

import "rsuite/dist/styles/rsuite-default.min.css";
import "./RsuiteTable.scss";
import { Icon } from 'rsuite';

import icon_13 from "./../../icons/FCO_icons/FCO_icon.png-13.png";

const { Column, HeaderCell, Cell } = Table;

class MarketAccessTable extends React.Component {
  constructor(props) {
    super(props);
  }

  downloadXLS = data => {
    // add headers(first/second rows)
    var ws = XLSX.utils.aoa_to_sheet([[""], [""]]);
    
    XLSX.utils.sheet_add_json(ws, data, {
      origin: "A1"
    }); // start from third row

    if (!ws["!merges"]) ws["!merges"] = [];

    /* generate workbook and add worksheet */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Table", {
      bookType: "xlsx",
      type: "array",
      cellDates: true
    });

    var fileName = "market_access_table.xlsx";

    //  generate an XLSX file and export
    XLSX.writeFile(wb, fileName);
  }

  render() {
    const { data, countries } = this.props;
    const filteredData = data.filter(d => countries.includes(d.Country.toUpperCase()));

    const rowdata = (rowData, key) => {
      if (key === "Commercial launch") 
        return rowData[key] === null ? "" : rowData[key];

      const check = <Icon icon='check' style={{color: "green"}}/>;
      const close = <Icon icon='close' style={{color: "red"}}/>;
      return rowData[key] === null ? "" : (rowData[key] === 1 ? check : (rowData[key] === 0 ? close : rowData[key]))
    };

    return (
      <div id="market_access_table">
        <div className="download-icon" onClick={() => this.downloadXLS(filteredData)}>
          <img src={icon_13} alt=""/>
        </div>
        <Table
            bordered
            cellBordered
            height={262}
            headerHeight={55}
            rowHeight={30}
            data={filteredData}
          >
            <Column width={100} align="left" verticalAlign="middle" fixed>
              <HeaderCell>Country</HeaderCell>
              <Cell dataKey="Country" />
            </Column>

            <Column width={80} verticalAlign="middle" colSpan={3}>
              <HeaderCell className="header-cell-group">
                <div className="header-cell-group-title">Reimbursment status</div>
                <div className="header-cell-group-subtitle">
                  <span style={{ width: 80, height: 37, display: 'table-cell', verticalAlign: 'middle' }}>Regional</span>
                  <span style={{ width: 80, height: 37 }}>National</span>
                  <span style={{ width: 80, height: 37}}>Hospital</span>
                </div>
              </HeaderCell>
              <Cell dataKey="Regional">{ rowData => rowdata(rowData, "Regional") }</Cell>
            </Column>

            <Column width={80} verticalAlign="middle">
              <HeaderCell />
              <Cell dataKey="National">{ rowData => rowdata(rowData, "National") }</Cell>
            </Column>

            <Column width={80} verticalAlign="middle">
              <HeaderCell />
              <Cell dataKey="Hospital">{ rowData => rowdata(rowData, "Hospital") }</Cell>
            </Column>

            <Column width={1}>
              <HeaderCell />
              <Cell dataKey=""></Cell>
            </Column>

            <Column width={130} verticalAlign="middle" colSpan={2}>
              <HeaderCell className="header-cell-group">
                <div className="header-cell-group-title">Status</div>
                <div className="header-cell-group-subtitle">
                  <span style={{ width: 130, height: 37 }}>Expected reimbursement</span>
                  <span style={{ width: 130, height: 37 }}>Commercial launch</span>
                </div>
              </HeaderCell>
              <Cell dataKey="Expected reimbursement">{ rowData => rowdata(rowData, "Expected reimbursement") }</Cell>
            </Column>

            <Column width={130} verticalAlign="middle">
              <HeaderCell />
              <Cell dataKey="Commercial launch">{ rowData => rowdata(rowData, "Commercial launch") }</Cell>
            </Column>

            <Column width={800} align="left" verticalAlign="middle">
              <HeaderCell>Comments</HeaderCell>
              <Cell dataKey="Comments">{ rowData => rowdata(rowData, "Comments") }</Cell>
            </Column>
          </Table>
      </div>
    );
  }
}

export default MarketAccessTable;