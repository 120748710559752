import * as d3 from 'd3';
import { selectFormat } from './selectFormat';

const tickValues = (domain) =>
  domain.length > 25
    ? domain.filter((d, i) => i % 3 === 2)
    : domain.length > 13
    ? domain.filter((d, i) => i % 2 === 1)
    : domain;

const updateAxes = ({
  svg,
  g,
  x,
  y,
  y1,
  innerHeight,
  type,
  chartType,
  selectedDate,
  id,
  axisFontSize,
  format,
  secondYAxis,
  secondYAxisFormat,
  center,
}) => {
  if (selectedDate) {
    g.selectAll('.selected-date').remove();
    g
      .append('line')
      .attr('class', 'selected-date')
      .attr('x1', (x(selectedDate) || 0) + x.bandwidth() / 2)
      .attr('y1', 0)
      .attr('x2', (x(selectedDate) || 0) + x.bandwidth() / 2)
      .attr('y2', innerHeight)
      .style('stroke', 'darkgray')
      .style('stroke-dasharray', 3)
      .style('stroke-width', '2px');
  }

  if (type === 'horizontal-bar') {
    svg
      .select('.x-axis')
      .transition()
      .duration(1200)
      .attr('transform', 'translate(0,' + innerHeight + ')')
      .call(d3.axisBottom(x));

    // Add the Y Axis
    svg
      .select('.y-axis')
      .transition()
      .duration(1200)
      .call(d3.axisLeft(y))
      .selectAll('text')
      .style('font-size', axisFontSize);
  } else {
    if (chartType === 'sales') {
      if (['NG_MG_10_SISO', 'EOG'].includes(id)) {
        svg
          .select('.x-axis')
          .transition()
          .duration(1200)
          .attr('transform', 'translate(0,' + innerHeight + ')')
          .call(d3.axisBottom(x))
          .selectAll('text')
          .style('text-anchor', 'middle')
          .style('font-size', axisFontSize);
      } else {
        svg
          .select('.x-axis')
          .transition()
          .duration(1200)
          .attr('transform', 'translate(0,' + innerHeight + ')')
          .call(
            d3
              .axisBottom(x)
              .tickFormat(d3.utcFormat('%b %Y'))
              .tickValues(tickValues(x.domain()))
          )
          .selectAll('text')
          .style('text-anchor', 'end')
          .style('font-size', axisFontSize)
          .attr('dx', '-.55em')
          .attr('dy', '.45em')
          .attr('transform', 'rotate(-35)');
      }
    } else if (chartType === 'launch') {
      svg
        .select('.x-axis')
        .transition()
        .duration(1200)
        .attr('transform', 'translate(0,' + innerHeight + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('font-size', axisFontSize);
    } else if (chartType === 'finance') {
      svg
        .select('.x-axis')
        .transition()
        .duration(1200)
        .attr('transform', 'translate(0,' + innerHeight + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'middle')
        .style('font-size', axisFontSize);
    } else if (['SoD_AT', 'SoD_Prev_AT'].includes(id)) {
      svg
        .select('.x-axis')
        .transition()
        .duration(1200)
        .attr('transform', 'translate(0,' + innerHeight + ')')
        .call(d3.axisBottom(x)) //.tickValues(this.props.data[0].data.map(d => d.date).filter((d, i) => i % 3 === 0)))
        .selectAll('text')
        .style('text-anchor', 'end')
        .style('font-size', axisFontSize)
        .attr('dx', '-.55em')
        .attr('dy', '.45em')
        .attr('transform', 'rotate(-35)');
    } else if (chartType === 'wk_sales') {
      svg
        .select('.x-axis')
        .transition()
        .duration(1200)
        .attr('transform', 'translate(0,' + innerHeight + ')')
        .call(
          d3
            .axisBottom(x)
            .tickFormat((d) =>
              d3.utcFormat('%d/%m/%Y')(d3.utcParse('%Y W%W')(d))
            )
            .tickValues(tickValues(x.domain()))
        )
        .selectAll('text')
        .style('text-anchor', 'end')
        .style('font-size', axisFontSize)
        .attr('dx', '-.55em')
        .attr('dy', '.45em')
        .attr('transform', 'rotate(-35)');
    }

    // Add the Y Axis
    svg
      .select('.y-axis')
      .transition()
      .duration(1200)
      .call(
        d3
          .axisLeft(y)
          // .ticks(10)
          .tickFormat((d) => selectFormat(format, d))
      )
      .selectAll('text')
      .style('font-size', axisFontSize);

    if (secondYAxis) {
      svg
        .select('.y1-axis')
        .transition()
        .duration(1200)
        .call(
          d3
            .axisRight(y1)
            // .ticks(10)
            .tickFormat((d) => selectFormat(secondYAxisFormat, d))
        )
        .selectAll('text')
        .style('font-size', axisFontSize);
    }

    if (
      type === 'bar-line' ||
      type === 'stacked' ||
      type === 'stacked-line' ||
      type === 'multiline'
    ) {
      const zeroline = svg.select('.zero-line');
      if (
        type === 'bar-line' ||
        type === 'stacked' ||
        type === 'stacked-line' ||
        id === 'DMS'
      ) {
        // + delta market share
        if (id !== 'DMS') zeroline.raise();
        zeroline
          .attr('transform', 'translate(0,' + (y(0) || 0) + ')')
          .call(d3.axisBottom(center).ticks(0));
      } else {
        zeroline
          .attr('transform', 'translate(0,' + innerHeight + ')')
          .call(d3.axisBottom(center).ticks(0));
      }
    }
  }
};

export { updateAxes };
