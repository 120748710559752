import { all, put } from 'redux-saga/effects';
import watchAuthSaga from './auth';
import watchWorkspaceSaga from './workspace';

import * as actions from './../actions';
import { isAuth } from './../../services/auth';

function* startupSaga() {
  console.log('SAGA: startup');

  if (isAuth()) {
    console.log('is authorized');
    yield put(actions.actionLoadData());
  }
}

export default function* rootSaga() {
  yield all([startupSaga(), watchAuthSaga(), watchWorkspaceSaga()]);
}
