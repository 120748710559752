import { makeRequest } from './http';

/**
 * API SERVICE
 */

// Token retrieval API
// Authenticate user with email and password
export const userLogin = (data) => {
  return makeRequest({
    path: '/auth/login',
    method: 'POST',
    params: JSON.stringify(data),
  });
};

export const userLogout = () => {
  return makeRequest({
    path: '/auth/logout',
    method: 'GET',
  });
};

// Get project config
export const getProjectConfig = () => {
  return makeRequest({
    path: '/',
    method: 'GET',
  });
};

// Get all configs in use
export const getConfig = () => {
  return makeRequest({
    path: '/config',
    method: 'GET',
  });
};

// Close config
export const closeConfig = (config_id) => {
  return makeRequest({
    path: `/config/${config_id}/close`,
    method: 'GET',
  });
};

// Update config
export const updateConfig = (config_id, data = {}) => {
  return makeRequest({
    path: `/config/${config_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
};

// Open Saved config
export const openSavedConfig = (config_id) => {
  return makeRequest({
    path: `/config/${config_id}/open`,
    method: 'PUT',
    params: JSON.stringify({}),
  });
};

// Delete Saved config
export const deleteSavedConfig = (config_id) => {
  return makeRequest({
    path: `/config/${config_id}`,
    method: 'DELETE',
    params: '',
  });
};

// Get datasource by Id
export const getDatasourceById = (datasource_id) => {
  return makeRequest({
    path: `/datasource/${datasource_id}`,
    method: 'GET',
  });
};

// Get Saved Config List
export const getSavedConfigList = ({ success, error }) => {
  return makeRequest({
    path: `/config/saved`,
    method: 'GET',
    success,
    error,
  });
};

// Get Datasource
export const getDatasource = () => {
  return makeRequest({
    path: `/datasource`,
    method: 'GET',
  });
};

// Post query
export const postQuery = (data) => {
  return makeRequest({
    path: '/query',
    method: 'POST',
    params: JSON.stringify(data),
  });
};

export const postAddNewWorksheet = ({ data = {} }) => {
  return makeRequest({
    path: '/config',
    method: 'POST',
    params: JSON.stringify(data),
  });
};
