import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

import { actionDidLoadData } from './actions';
// import { initState } from './reducers/config/initialize';
import 'whatwg-fetch';
import { isPeq, prepareColors } from './reducers/config/helper';
import { csvParse, autoType, utcFormat } from 'd3';

export async function awsLoadData(store, state, payload) {
  const {
    config,
    user,
    channels,
    color_codes,
    userConfig: defaultConfig,
    activeDataset,
  } = payload;

  if (!user)
    return store.dispatch(actionDidLoadData({ type: 'AccessIsDenied' }));

  // filter data groups
  if (
    user.Data_Group &&
    config.dataGroups &&
    config.dataGroups[user.Data_Group]
  ) {
    config.dataConfig = config.dataConfig.filter((c) =>
      config.dataGroups[user.Data_Group].includes(c.name)
    );
  } else if (config.dataGroups && config.dataGroups['Default']) {
    // apply filter for non-admin users
    if (user.Rights !== 'admin') {
      config.dataConfig = config.dataConfig.filter((c) =>
        config.dataGroups['Default'].includes(c.name)
      );
    }
  }

  //Override KPI names
  if (
    defaultConfig &&
    defaultConfig.controlsData &&
    defaultConfig.controlsData.Metrics &&
    defaultConfig.controlsData.Metrics['Board KPI - Finance']
  ) {
    const index = defaultConfig.controlsData.Metrics[
      'Board KPI - Finance'
    ].indexOf('%Gt vs PY');
    if (index > -1) {
      defaultConfig.controlsData.Metrics['Board KPI - Finance'][index] =
        '%Gt vs PY Finance';
    }
  }

  // get datasets
  const datasets =
    state.datasets ||
    config.dataConfig.map((d, i) => {
      d.index = i;
      d.raw_data = null;
      return d;
    });
  // get active dataset index
  if (activeDataset < 0) {
    if (defaultConfig && defaultConfig.filename) {
      // try to get default dataset from config
      const index = config.dataConfig.findIndex(
        (d) => d.filename === defaultConfig.filename
      );
      activeDataset = index > -1 ? index : 0;
    } else {
      // othervise select the first dataset
      activeDataset = 0;
    }
  }

  // get market access data
  // const market_access = await (
  //   await fetch(
  //     `${data_url}/market_access/${config.dataConfig[
  //       activeDataset
  //     ].filename.replace('.json', '')}.csv`
  //   )
  // ).text();
  const market_access = '';

  // assign filename to default config
  defaultConfig.filename = config.dataConfig[activeDataset].filename;
  defaultConfig.links = config.links;
  defaultConfig.questionLinks = config.questionLinks;
  defaultConfig.user = user;
  defaultConfig.channels = channels && csvParse(channels, autoType);
  defaultConfig.color_codes = prepareColors(
    color_codes && csvParse(color_codes, autoType)
  );
  defaultConfig.market_access =
    market_access && csvParse(market_access, autoType);

  // tab footnotes
  if (config.dataConfig[activeDataset].tab_footnote) {
    defaultConfig.tab_footnote = config.dataConfig[activeDataset].tab_footnote;
  }

  // get market target data
  // const target_data = await (
  //   await fetch(
  //     `${data_url}/target/${datasets[activeDataset].filename.replace(
  //       '.json',
  //       '.csv'
  //     )}`
  //   )
  // ).text();
  const target_data = '';
  if (target_data && target_data.indexOf('<!DOCTYPE') === -1) {
    defaultConfig.target_data = csvParse(target_data, autoType).map((d) => {
      // "Country,Product,Market,Key Competitor,Period,Pdt_Growth,Mkt_Growth,Sanofi MS,Key Comp MS"
      ['Country', 'Product', 'Market', 'Key Competitor'].forEach(
        (key) => (d[key] = d[key].trim().toUpperCase())
      );
      d['Period'] = '' + d['Period'];
      return d;
    });
  } else {
    defaultConfig.target_data = null;
  }

  if (datasets[activeDataset].daily) {
    // daily data: sales, stocks, social
    // const daily_sales = await (
    //   await fetch(`${data_url}/daily/sales.csv`)
    // ).text();
    // const daily_stocks = await (
    //   await fetch(`${data_url}/daily/stocks.csv`)
    // ).text();
    // const daily_socials = await (
    //   await fetch(`${data_url}/daily/socials.csv`)
    // ).text();
    const daily_sales = '';
    const daily_stocks = '';
    const daily_socials = '';

    const dailySalesDateFormat = utcFormat('%d %b %Y');
    defaultConfig.daily_data = {
      sales:
        daily_sales &&
        csvParse(daily_sales, autoType).map((d) => {
          d.date = dailySalesDateFormat(d.date);
          return d;
        }),
      stocks:
        daily_stocks &&
        csvParse(daily_stocks, autoType).map((d) => {
          d.date = dailySalesDateFormat(d.date);
          return d;
        }),
      socials:
        daily_socials &&
        csvParse(daily_socials, autoType).map((d) => {
          d.date = dailySalesDateFormat(d.date);
          return d;
        }),
    };
  } else {
    defaultConfig.daily_data = null;
  }

  // store.dispatch(actionInitLoading({ analysis: datasets[activeDataset].name }));

  // log this action
  fetch(
    './api/save_log.aspx?user_id=' +
      user.UserID.toLowerCase().split('\\').pop(),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(
        {
          timestamp: new Date(),
          action: 'OPEN FCO',
          data: config.dataConfig[activeDataset].name,
        },
        null,
        2
      ),
    }
  );

  if (datasets[activeDataset].raw_data) {
    console.log('!!!!!!! DATASET EXISTS');

    // TODO: remove the same code
    // kpis
    if (config.userTypes) {
      datasets[activeDataset].kpiGroups = config.kpiGroups.filter((d) => {
        return (
          (datasets[activeDataset].subsetMap[d.dataType] &&
            datasets[activeDataset].subsetMap[d.dataType].dataLength > 0) ||
          (d.dataType === 'target' && defaultConfig.target_data)
        );
      });
      const groups = datasets[activeDataset].kpiGroups.map((d) => d.name);
      // check first if user save custom kpis in User Default Config, if not => take it from common Config
      datasets[activeDataset].kpis =
        defaultConfig.customKPIs && defaultConfig.customKPIs.length
          ? defaultConfig.customKPIs.filter((d) => groups.includes(d.group))
          : (
              config.userTypes.find(
                (d) => d.name === (user.User_Type || 'User Type 0')
              ) || { kpis: [] }
            ).kpis.filter((d) => groups.includes(d.group));

      if (datasets[activeDataset].kpiGroups && datasets[activeDataset].kpis) {
        datasets[activeDataset].kpis.forEach((d, i) => {
          d.order = i;
          d.id =
            datasets[activeDataset].kpiGroups.find((g) => g.name === d.group)
              .order +
            ':' +
            d.order;
        });
      }
    }

    // store.dispatch(
    //   actionInitData(initState({ activeDataset, datasets }, defaultConfig))
    // );
    // store.dispatch(actionInitLoading(false));
  } else {
    console.log('!!!!!!! THERE IS NO DATASET UPLOADED');

    // initialze data
    datasets[activeDataset].raw_data = [];
    datasets[activeDataset].subsets = [];
    datasets[activeDataset].subsetMap = {};
    // datasets[activeDataset].subsetMap = {market: {columns:['EU'], dates: []}}

    // save map from column names to control titles
    datasets[activeDataset].titles = config.dataConfig[activeDataset].titles;

    // save datasetType
    datasets[activeDataset].datasetType =
      config.dataConfig[activeDataset].datasetType;

    const filename = datasets[activeDataset].filename;
    const datasetType = datasets[activeDataset].datasetType;

    console.log(`Start downloading ${filename}.zip ...`);

    //============== upload data subsets ===============

    // apply filters if any specified
    if (config.dataConfig[activeDataset].filters) {
      const filters = Object.keys(config.dataConfig[activeDataset].filters);
      datasets[activeDataset].raw_data = datasets[
        activeDataset
      ].raw_data.filter((d) => {
        let include = true;
        filters.forEach((f) => {
          if (!config.dataConfig[activeDataset].filters[f].includes(d[f]))
            include = false;
        });
        return include;
      });
    }

    // available charts
    if (config.dataConfig[activeDataset].charts) {
      datasets[activeDataset].charts = config.dataConfig[activeDataset].charts;
    }

    // excluded charts
    if (config.dataConfig[activeDataset].excludeCharts) {
      datasets[activeDataset].excludeCharts =
        config.dataConfig[activeDataset].excludeCharts;
    }

    // available metrics
    if (config.dataConfig[activeDataset].metrics) {
      datasets[activeDataset].metrics =
        config.dataConfig[activeDataset].metrics;
    }

    // exclude columns
    if (config.dataConfig[activeDataset].excludeColumns) {
      datasets[activeDataset].excludeColumns =
        config.dataConfig[activeDataset].excludeColumns;
    }

    // available reference markets default metrics
    datasets[activeDataset].default_metrics =
      config.dataConfig[activeDataset].default_metrics || {};

    // kpis
    if (config.userTypes) {
      datasets[activeDataset].kpiGroups = config.kpiGroups.filter((d) => {
        return (
          (datasets[activeDataset].subsetMap[d.dataType] &&
            datasets[activeDataset].subsetMap[d.dataType].dataLength > 0) ||
          (d.dataType === 'target' && defaultConfig.target_data)
        );
      });
      const groups = datasets[activeDataset].kpiGroups.map((d) => d.name);
      // check first if user save custom kpis in User Default Config, if not => take it from common Config
      datasets[activeDataset].kpis =
        defaultConfig.customKPIs && defaultConfig.customKPIs.length
          ? defaultConfig.customKPIs.filter((d) => groups.includes(d.group))
          : (
              config.userTypes.find(
                (d) => d.name === (user.User_Type || 'User Type 0')
              ) || { kpis: [] }
            ).kpis.filter((d) => groups.includes(d.group));

      if (datasets[activeDataset].kpiGroups && datasets[activeDataset].kpis) {
        datasets[activeDataset].kpis.forEach((d, i) => {
          d.order = i;
          d.id =
            datasets[activeDataset].kpiGroups.find((g) => g.name === d.group)
              .order +
            ':' +
            d.order;
        });
      }
    }

    console.log(
      `Finished downloading & data preparation of ${filename.replace(
        '.json',
        ''
      )} dataset.`
    );

    // store.dispatch(
    //   actionInitData(initState({ activeDataset, datasets }, defaultConfig))
    // );
    // store.dispatch(actionInitLoading(false));
  }
}

// const asyncDispatchMiddleware = (store) => (next) => (action) => {
//   // console.log('dispatching', action)

//   let result;
//   if (action.type === 'ASYNC_LOAD_DATA') {
//     const state = store.getState().data;

//     if (state.datasets) {
//       const name =
//         state.datasets[action.payload.activeDataset] &&
//         state.datasets[action.payload.activeDataset].name;
//       result = next(actionInitLoading(name ? { analysis: name } : true));
//     } else {
//       result = next(actionInitLoading(true));
//     }

//     awsLoadData(store, state, action.payload);
//   } else {
//     result = next(action);
//   }

//   return result;
// };

export default function configureStore(initState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initState,
    applyMiddleware(sagaMiddleware)
  );
  sagaMiddleware.run(rootSaga);
  return store;
}
