import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const MyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
    margin: 5,
  },
}))(Tooltip);

export default function SimpleTooltip(props) {
  return <MyTooltip {...props} />;
}
