import * as React from 'react';
import { TextField as MUITextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {},
  helper: {
    marginBottom: '22px',
  },
  error: {
    borderColor: 'red',
  },
}));

const Component = (props) => {
  const {
    variant = 'outlined',
    className,
    onChange,
    value,
    size = 'small',
    ...restProps
  } = props;

  const classes = useStyles();

  const textFieldClassName = classnames(classes.root, className, {
    [classes.helper]: restProps.helperText,
    [classes.error]: restProps.error,
  });

  return (
    <MUITextField
      variant={variant}
      className={textFieldClassName}
      onChange={onChange}
      value={value}
      size={size}
      {...restProps}
    />
  );
};

export const TextField = (props) => <Component {...props} />;

TextField.displayName = 'TextField';
