import 'whatwg-fetch';

import _ from './../constants';
import { isAuth, logout } from './auth';
import history from './history';

export const makeRequest = async ({
  path,
  method = 'GET',
  params = {},
  contentType = 'application/json',
  formData,
  success,
  error,
}) => {
  const options = {
    method: method,
    body: params,
    headers: {
      'Content-Type': contentType,
      'Cache-Control': 'no-cache, must-revalidate',
    },
    mode: 'cors',
    timeout: 5,
  };
  if (method === 'GET') delete options.body;
  if (method === 'GET') delete options.headers['Content-Type'];
  if (formData) delete options.headers['Content-Type'];

  const token = isAuth();
  if (token) options.headers['Authorization'] = `Bearer ${token}`;

  let response = await fetch(
    path.indexOf('http') === 0 ? path : _.SERVER_API + path,
    options
  )
    .then((response) => {
      if (response.status === 500) {
        return Promise.reject({
          status: 500,
          message: 'Something went wrong. Contact us for more information.',
        });
      }

      if (response.status === 404) {
        return Promise.reject({
          status: 404,
          message: path + ' not found.',
        });
      }

      if (response.status === 401) {
        return Promise.reject({
          status: 401,
          message: response.statusText,
        })
      }

      if (!response.ok) {
        // throw Error(response.statusText);
        return response.json().then((res) => Promise.reject(res));
      }

      // check if content type non json object => return plain text
      if (
        response.headers.get('content-type') &&
        response.headers.get('content-type').indexOf('application/json') === -1
      ) {
        return response.text();
      }

      return response.json();
    })
    .then((data) => {
      if (success) return success(data);

      return data;
    })
    .catch((err) => {
      if (error) error(err);      
      if (err && err.message === 'Unauthorized') {
        logout();
        history.push("/login");
        return { error: 'Unauthorized', status: 401 }
      };
      return { error: err };
    });

  return response;
};
