import React, { useState, useCallback, useRef, useEffect } from "react";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Box, Typography } from "@material-ui/core";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import SimpleDropdown from "./../../Other/SimpleDropdown";

const dateFormat = d3.utcFormat("%b %Y");

const dates = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "calc(50% - 4px)",
    marginTop: "-5px",
    textAlign: "center",
    position: "relative",
  },
  month: {
    cursor: "pointer",
    minHeight: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,

      "& span": {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  changeYear: {
    cursor: "pointer",
    color: theme.palette.grey[400],
    marginTop: 12,

    "&:hover": {
      color: theme.palette.grey[700],
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,

    "& span": {
      color: theme.palette.primary.contrastText,
    },
  },
  dropdown: {
    width: "200%",
  },
}));

export default function Calendar(props) {
  const classes = useStyles();

  const [date, setDate] = useState(props.date || new Date());

  const applyDate = useCallback(() => {
    if (props.selectDate) props.selectDate(date);
  }, [date]);

  const changeMonth = useCallback(
    (i) => {
      const newDate = date;
      newDate.setUTCMonth(i);
      setDate(newDate);
      applyDate();
    },
    [date]
  );

  const changeYear = useCallback(
    (delta) => {
      const { startDate, endDate } = props;
      const newDate = date;

      const newYear = newDate.getUTCFullYear() + delta;

      if (startDate && startDate.getUTCFullYear() > newYear) return;
      if (endDate && endDate.getUTCFullYear() < newYear) return;

      newDate.setFullYear(newYear);
      setDate(newDate);
      applyDate();
    },
    [date]
  );

  return (
    <SimpleDropdown
      className={classes.root}
      header={
        <button
          className={props.className}
          onClick={() => props.onClick()}
          type="button"
          style={props.style}
        >
          {dateFormat(date)}
        </button>
      }
      dropdownPositioning={{
        top: 25,
        right: 0,
      }}
      dropdown={
        <Paper className={classes.dropdown}>
          <Grid container spacing={1}>
            <Grid item container>
              <Grid
                item
                xs={3}
                onClick={() => changeYear(-1)}
                className={classes.changeYear}
              >
                <ChevronLeftIcon size="large" />
              </Grid>
              <Grid item xs={6}>
                <Box pt={1.5}>
                  <Typography variant="subtitle1" component="span">
                    {date.getUTCFullYear()}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                onClick={() => changeYear(1)}
                className={classes.changeYear}
              >
                <ChevronRightIcon size="large" />
              </Grid>
            </Grid>

            <Grid item container>
              {dates.map((d, i) => (
                <Grid item xs={4} key={i}>
                  <Box
                    m={0.2}
                    onClick={() => changeMonth(i)}
                    className={`${classes.month} ${
                      date.getUTCMonth() === i ? classes.active : ""
                    }`}
                  >
                    <Typography variant="subtitle1" component="span">
                      {d}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Paper>
      }
    />
  );
}
