export const ModalTypes = {
  UpdateModal: 'UpdateModal',
  AddConfigModal: 'AddConfigModal',
  RsuiteTableModal: 'RsuiteTableModal',
  ShareModal: 'ShareModal',
};

export const ModalOptions = {
  [ModalTypes.UpdateModal]: {
    headerText: 'Update Analysis',
  },
  [ModalTypes.AddConfigModal]: {
    headerText: 'Open/Add new Analysis',
  },
  [ModalTypes.ShareModal]: {
    headerText: 'Share',
  },
};
