import * as d3 from 'd3';
import colors from '../../../store/reducers/config/colors';
import { selectFormat } from './selectFormat';

const renderStackChart = ({
  data,
  g,
  x,
  y,
  id,
  format,
  tooltip,
  showLabels,
  color_codes,
}) => {
  g.select('.stackChart').selectAll('.layer').remove();

  const layers = g
    .select('.stackChart')
    .selectAll('layer')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'layer');

  layers.each((d, i) => {
    const layer = d3.select(layers.nodes()[i]);

    layer
      .selectAll('rect')
      .data((_d) => _d)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (dd) => x(dd.data.date))
      .attr('y', (dd) => y(dd[1]))
      .attr('width', x.bandwidth())
      .attr('height', (dd) => y(dd[0]) - y(dd[1]))
      .style('fill', color_codes[d.key] || d.color || colors[i])
      .on('mouseover', function (dd, k) {
        const tpl =
          `<ul><li><div class="square" style="background-color: ${
            color_codes[d.key] || d.color || colors[i]
          }"></div>${d.key}: ${selectFormat(format, dd[1] - dd[0])}</li>` +
          `<li>${['SoD_AT', 'SoD_Prev_AT'].includes(id) ? 'Detail' : 'Date'}: ${
            typeof dd.data.date === 'string'
              ? dd.data.date
              : d3.utcFormat('%b %Y')(dd.data.date)
          }</li><ul>`;

        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(tpl)
          .style('left', `${d3.event.pageX + 8}px`)
          .style('top', `${d3.event.pageY - 48}px`);
      })
      .on('mouseout', function (d) {
        tooltip.transition().duration(500).style('opacity', 0);
      });
  });

  if (showLabels) {
    layers.each((d, i) => {
      const layer = d3.select(layers.nodes()[i]);

      layer
        .selectAll('.bar-label')
        .data((_d) => _d.filter((dd) => Math.abs(dd[1] - dd[0]) > 2))
        .enter()
        .append('text')
        .attr('class', 'bar-label')
        .attr('x', (dd) => x(dd.data.date) + x.bandwidth() / 2)
        .attr('y', (dd) => (y(dd[0]) + y(dd[1])) / 2)
        .attr('dy', '4px')
        .style('text-anchor', 'middle')
        .style('font-size', '12px')
        .text((dd) => selectFormat(format, dd[1] - dd[0]));
    });
  }

  layers.exit().remove();
};

// const redrawStackChart = ({
//   data,
//   g,
//   x,
//   y,
//   id,
//   format,
//   tooltip,
//   showLabels,
//   color_codes,
// }) => {
//   // g.select('.stackChart').selectAll('.layer').remove();

//   const layers = g
//     .select('.stackChart')
//     .selectAll('layer')
//     .data(data, (d, i) => {
//       console.log('data', d);
//       return d.key;
//     })
//     .join(
//       (enter) => {
//         const _layers = enter.append('g').attr('class', 'layer');
//         console.log('enter', _layers);
//         return _layers.each((d, i) => {
//           const nodeIdx = _layers
//             .nodes()
//             .findIndex((dd) => dd.__data__.key === d.key);
//           console.log('nodeIdx', nodeIdx, d, _layers.nodes());
//           const layerG = d3.select(_layers.nodes()[nodeIdx]);

//           layerG
//             .selectAll('rect')
//             .data(d, (d) => {
//               console.log('ddddd', d);
//               return d.data.date;
//             })
//             .join(
//               (enter) =>
//                 enter
//                   .append('rect')
//                   .attr('class', 'bar')
//                   .attr('x', (dd) => x(dd.data.date))
//                   // .attr('y', (dd) => y(dd[1]))
//                   .attr('width', x.bandwidth())
//                   // .attr('height', (dd) => y(dd[0]) - y(dd[1]))
//                   .style('fill', color_codes[d.key] || d.color || colors[i])
//                   .on('mouseover', function (dd, k) {
//                     const tpl =
//                       `<ul><li><div class="square" style="background-color: ${
//                         color_codes[d.key] || d.color || colors[i]
//                       }"></div>${d.key}: ${selectFormat(
//                         format,
//                         dd[1] - dd[0]
//                       )}</li>` +
//                       `<li>${
//                         ['SoD_AT', 'SoD_Prev_AT'].includes(id)
//                           ? 'Detail'
//                           : 'Date'
//                       }: ${
//                         typeof dd.data.date === 'string'
//                           ? dd.data.date
//                           : d3.utcFormat('%b %Y')(dd.data.date)
//                       }</li><ul>`;

//                     tooltip.transition().duration(200).style('opacity', 0.9);
//                     tooltip
//                       .html(tpl)
//                       .style('left', `${d3.event.pageX + 8}px`)
//                       .style('top', `${d3.event.pageY - 48}px`);
//                   })
//                   .on('mouseout', function (d) {
//                     tooltip.transition().duration(500).style('opacity', 0);
//                   })
//                   .call((enter) =>
//                     enter
//                       .transition()
//                       .duration(1200)
//                       .attr('y', (dd) => y(dd[1]))
//                       .attr('height', (dd) => y(dd[0]) - y(dd[1]))
//                   ),
//               (update) =>
//                 update
//                   .transition()
//                   .duration(1200)
//                   .attr('x', (dd) => x(dd.data.date))
//                   .attr('y', (dd) => y(dd[1]))
//                   .attr('width', x.bandwidth())
//                   .attr('height', (dd) => y(dd[0]) - y(dd[1]))
//                   .style('fill', color_codes[d.key] || d.color || colors[i])
//                   .on('mouseover', function (dd, k) {
//                     const tpl =
//                       `<ul><li><div class="square" style="background-color: ${
//                         color_codes[d.key] || d.color || colors[i]
//                       }"></div>${d.key}: ${selectFormat(
//                         format,
//                         dd[1] - dd[0]
//                       )}</li>` +
//                       `<li>${
//                         ['SoD_AT', 'SoD_Prev_AT'].includes(id)
//                           ? 'Detail'
//                           : 'Date'
//                       }: ${
//                         typeof dd.data.date === 'string'
//                           ? dd.data.date
//                           : d3.utcFormat('%b %Y')(dd.data.date)
//                       }</li><ul>`;

//                     tooltip.transition().duration(200).style('opacity', 0.9);
//                     tooltip
//                       .html(tpl)
//                       .style('left', `${d3.event.pageX + 8}px`)
//                       .style('top', `${d3.event.pageY - 48}px`);
//                   })
//                   .on('mouseout', function (d) {
//                     tooltip.transition().duration(500).style('opacity', 0);
//                   }),
//               (exit) => exit.call((exit) => exit.remove())
//             );
//         });
//       },
//       (update) => {
//         const _layers = update.append('g').attr('class', 'layer');
//         console.log('update', _layers);
//         return _layers.each((d, i) => {
//           const nodeIdx = _layers
//             .nodes()
//             .findIndex((dd) => dd.__data__.key === d.key);
//           const layerG = d3.select(_layers.nodes()[nodeIdx]);

//           layerG
//             .selectAll('rect')
//             .data(d, (d) => d.data.date)
//             .join(
//               (enter) =>
//                 enter
//                   .append('rect')
//                   .attr('class', 'bar')
//                   .attr('x', (dd) => x(dd.data.date))
//                   // .attr('y', (dd) => y(dd[1]))
//                   .attr('width', x.bandwidth())
//                   // .attr('height', (dd) => y(dd[0]) - y(dd[1]))
//                   .style('fill', color_codes[d.key] || d.color || colors[i])
//                   .on('mouseover', function (dd, k) {
//                     const tpl =
//                       `<ul><li><div class="square" style="background-color: ${
//                         color_codes[d.key] || d.color || colors[i]
//                       }"></div>${d.key}: ${selectFormat(
//                         format,
//                         dd[1] - dd[0]
//                       )}</li>` +
//                       `<li>${
//                         ['SoD_AT', 'SoD_Prev_AT'].includes(id)
//                           ? 'Detail'
//                           : 'Date'
//                       }: ${
//                         typeof dd.data.date === 'string'
//                           ? dd.data.date
//                           : d3.utcFormat('%b %Y')(dd.data.date)
//                       }</li><ul>`;

//                     tooltip.transition().duration(200).style('opacity', 0.9);
//                     tooltip
//                       .html(tpl)
//                       .style('left', `${d3.event.pageX + 8}px`)
//                       .style('top', `${d3.event.pageY - 48}px`);
//                   })
//                   .on('mouseout', function (d) {
//                     tooltip.transition().duration(500).style('opacity', 0);
//                   })
//                   .call((enter) =>
//                     enter
//                       .transition()
//                       .duration(1200)
//                       .attr('y', (dd) => y(dd[1]))
//                       .attr('height', (dd) => y(dd[0]) - y(dd[1]))
//                   ),
//               (update) =>
//                 update
//                   .transition()
//                   .duration(1200)
//                   .attr('x', (dd) => x(dd.data.date))
//                   .attr('y', (dd) => y(dd[1]))
//                   .attr('width', x.bandwidth())
//                   .attr('height', (dd) => y(dd[0]) - y(dd[1]))
//                   .style('fill', color_codes[d.key] || d.color || colors[i])
//                   .on('mouseover', function (dd, k) {
//                     const tpl =
//                       `<ul><li><div class="square" style="background-color: ${
//                         color_codes[d.key] || d.color || colors[i]
//                       }"></div>${d.key}: ${selectFormat(
//                         format,
//                         dd[1] - dd[0]
//                       )}</li>` +
//                       `<li>${
//                         ['SoD_AT', 'SoD_Prev_AT'].includes(id)
//                           ? 'Detail'
//                           : 'Date'
//                       }: ${
//                         typeof dd.data.date === 'string'
//                           ? dd.data.date
//                           : d3.utcFormat('%b %Y')(dd.data.date)
//                       }</li><ul>`;

//                     tooltip.transition().duration(200).style('opacity', 0.9);
//                     tooltip
//                       .html(tpl)
//                       .style('left', `${d3.event.pageX + 8}px`)
//                       .style('top', `${d3.event.pageY - 48}px`);
//                   })
//                   .on('mouseout', function (d) {
//                     tooltip.transition().duration(500).style('opacity', 0);
//                   }),
//               (exit) => exit.call((exit) => exit.remove())
//             );
//         });
//       },
//       (exit) => exit.call((exit) => exit.remove())
//     );

//   // .enter()
//   // .append('g')
//   // .attr('class', 'layer');

//   // layers.each((d, i) => {
//   //   const layer = d3.select(layers.nodes()[i]);

//   //   layer
//   //     .selectAll('rect')
//   //     .data((_d) => _d)
//   //     .enter()
//   //     .append('rect')
//   //     .attr('class', 'bar')
//   //     .attr('x', (dd) => x(dd.data.date))
//   //     .attr('y', (dd) => y(dd[1]))
//   //     .attr('width', x.bandwidth())
//   //     .attr('height', (dd) => y(dd[0]) - y(dd[1]))
//   //     .style('fill', color_codes[d.key] || d.color || colors[i])
//   //     .on('mouseover', function (dd, k) {
//   //       const tpl =
//   //         `<ul><li><div class="square" style="background-color: ${
//   //           color_codes[d.key] || d.color || colors[i]
//   //         }"></div>${d.key}: ${selectFormat(format, dd[1] - dd[0])}</li>` +
//   //         `<li>${['SoD_AT', 'SoD_Prev_AT'].includes(id) ? 'Detail' : 'Date'}: ${
//   //           typeof dd.data.date === 'string'
//   //             ? dd.data.date
//   //             : d3.utcFormat('%b %Y')(dd.data.date)
//   //         }</li><ul>`;

//   //       tooltip.transition().duration(200).style('opacity', 0.9);
//   //       tooltip
//   //         .html(tpl)
//   //         .style('left', `${d3.event.pageX + 8}px`)
//   //         .style('top', `${d3.event.pageY - 48}px`);
//   //     })
//   //     .on('mouseout', function (d) {
//   //       tooltip.transition().duration(500).style('opacity', 0);
//   //     });
//   // });

//   // if (showLabels) {
//   //   layers.each((d, i) => {
//   //     const layer = d3.select(layers.nodes()[i]);

//   //     layer
//   //       .selectAll('.bar-label')
//   //       .data((_d) => _d.filter((dd) => Math.abs(dd[1] - dd[0]) > 2))
//   //       .enter()
//   //       .append('text')
//   //       .attr('class', 'bar-label')
//   //       .attr('x', (dd) => x(dd.data.date) + x.bandwidth() / 2)
//   //       .attr('y', (dd) => (y(dd[0]) + y(dd[1])) / 2)
//   //       .attr('dy', '4px')
//   //       .style('text-anchor', 'middle')
//   //       .style('font-size', '12px')
//   //       .text((dd) => selectFormat(format, dd[1] - dd[0]));
//   //   });
//   // }
// };

export { renderStackChart };
