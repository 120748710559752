import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function MarketIcon(props) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(-6, -5) scale(0.022, 0.022)">
      <path d="M687 1225 c-163 -45 -299 -185 -336 -346 -54 -234 99 -482 334 -544
        259 -67 522 105 567 373 39 230 -110 455 -343 517 -66 18 -158 18 -222 0z
        m269 -123 c218 -110 271 -391 108 -572 -50 -55 -95 -83 -166 -104 -70 -20
        -114 -20 -190 0 -193 49 -306 244 -258 444 26 108 131 220 241 256 73 24 192
        13 265 -24z"
      />
      <path d="M653 985 c-39 -17 -63 -61 -63 -114 0 -34 8 -49 47 -93 182 -207 220
        -236 286 -217 51 14 87 60 87 113 0 51 -9 64 -129 195 -118 128 -156 148 -228
        116z m140 -85 l62 -60 -60 -55 -60 -55 -52 58 c-55 59 -65 91 -42 133 11 21
        42 37 72 38 11 1 47 -26 80 -59z"
      />
      </g>
    </SvgIcon>
  );
}
