import React, { useState, useCallback } from 'react';
import {
  makeStyles,
  TextField,
  Box,
  Typography,
  Avatar,
} from '@material-ui/core';
import { full_name, email, update_user_info } from '../../services/auth';

import { colors } from '../../shared/theme';
import { useIsSmall } from '../../shared/theme/mediaQuries';

import ButtonSample from '../Other/ButtonSample';

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.primary.main,
    backgroundColor: colors.tabsBackground,
    width: 150,
    height: 150,
  },
}));

const fieldsData = [
  {
    name: 'email',
    label: 'E-mail',
    type: 'email',
    disabled: true,
  },
  {
    name: 'full_name',
    label: 'FullName',
    type: 'text',
  },
  {
    name: 'phone',
    label: 'Phone Number',
    type: 'tel',
  },
];

export default function GeneralTab() {
  const classes = useStyles();
  const isSmall = useIsSmall();

  const user = { email: email(), full_name: full_name(), avatar: '' };

  const [fields, setFields] = useState(
    fieldsData.map((el) => ({
      ...el,
      value: user[el.name],
    }))
  );

  const handleChange = useCallback(
    (e) => {
      setFields(
        fields.map((el) =>
          e.target.name === el.name ? { ...el, value: e.target.value } : el
        )
      );
    },
    [fields]
  );

  return (
    <Box
      display={isSmall ? '' : 'flex'}
      p={'2.5rem .5rem .5rem .5rem'}
      justifyContent="center">
      <Box px={isSmall ? 5 : 10} pb={5}>
        <Box pb={4} display="flex" justifyContent="center">
          <Avatar
            src={user && user.avatar ? user.avatar.src : undefined}
            alt={'avatar' || user.avatar.alt}
            className={classes.avatar}
            children={
              user && user.avatar
                ? undefined
                : user.email.substr(0, 2).toUpperCase()
            }
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <ButtonSample
            size="small"
            name="Upload New Picture"
            color="primary"
            method={() => console.log('Upload Picture')}
          />
        </Box>
      </Box>
      <Box width={isSmall ? 300 : 400} mx={isSmall ? 'auto' : 0}>
        <Box mb={1.5}>
          <Typography variant="h6">Profile Info</Typography>
        </Box>
        {fields.map((el, i) => (
          <Box key={i} mb={i === fields.length - 1 ? 3 : 2}>
            <TextField
              disabled={el.disabled}
              type={el.type}
              name={el.name}
              id={'general-field-' + el.name}
              size="small"
              label={el.label}
              value={el.value}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Box>
        ))}
        <ButtonSample
          fullWidth
          name="Save Changes"
          color="primary"
          method={() => {
            const obj = {};
            fields.forEach((d) => (obj[d.name] = d.value || ''));
            update_user_info(obj);
          }}
        />
      </Box>
    </Box>
  );
}
