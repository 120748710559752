import * as d3 from "d3";
import colors from "./colors";
import d3nest from "./nest";
import calcs from "./calcs";
import controlTemplates from './controlTemplates';
import numeral from 'numeral';

// import { query } from './../../../services/api'

const initData = (controlsData) => {  
  const _ = {}
  _.currentFilter = {}

  for (let i = 0; i < 3; i++) {
    controlsData[i].data.forEach(group => {
      if (group.controls.length) {
        _.currentFilter[group.name] = [] // selected items
        
        // apply filters
        const activeFilters = group.controls.filter(c => c.active).map(c => c.name);
        if (activeFilters.length) {
          _.currentFilter[group.name] = activeFilters;
        }
      }
    });
  }

  return _;
}

// fill controlsData with corresponding unique values from data
const initControlsData = (state, controlsData, config) => {
  const groups = state.datasource.groups;

  for (let i = 0; i < 3; i++) {
    controlsData[i].data.forEach(group => {
      if (group.name === "Reference_Market") {
        // group.controls = groups[group.name].map(d => ({ name: d, title: d.split(":").join(" in ") }));
      } else {
        group.controls = groups[group.name].map(d => ({ name: d }));
      }
    });

    if (config[controlsData[i].name]) {
      controlsData[i].data.forEach(d => {
        if (config[controlsData[i].name][d.name]) {
          d.controls.forEach(c => {
            if (config[controlsData[i].name][d.name].includes(c.name)) {
              c.active = true;
            }
          })
        }
      });
    }
  }

  // Customer -------------------
  // customer segment
  const segments = groups["Customer Segment"] || [];

  controlsData[4].data[0].controls = segments.map(d => ({ name: d, value: d })).concat([{ name: "NA", value: "NA" }]);
  controlsData[4].data[0].options = [{ name: "Tgt", values: segments.filter(d => d !== "Others") }]
    .concat(segments.map(d => ({ name: d, values: [d] }))
    .concat([{ name: "NA", values: ["NA"] }, { name: "All", values: segments.concat(["NA"]) }]));
  
  // customer specialty
  const specialties = groups["Customer Specialty"] || [];

  controlsData[4].data[1].controls = specialties.map(d => ({ name: d, value: d }));
  controlsData[4].data[1].options = specialties.map(d => ({ name: d, values: [d] }));
  // ----------------------------

  // metric
  if (config[controlsData[3].name]) {
    controlsData[3].data.forEach(d => {
      if (config[controlsData[3].name][d.name]) {
        d.controls.forEach(c => {
          if (config[controlsData[3].name][d.name].includes(c.name)) {
            c.active = true;
          }
        })
      }
    });  
  }
  
  // hide if no data is available
  if (!(state.datasource.subsetMap['finance'] && state.datasource.subsetMap['finance'].dataLength > 0)) {
    controlsData[3].data[8].hidden = true; // Metric finance
    controlsData[3].data[9].hidden = true; // Board KPI Finance
  }
  if (!(state.datasource.subsetMap['forecast'] && state.datasource.subsetMap['forecast'].dataLength > 0)) {
    controlsData[3].data[10].hidden = true; // Board KPI Forecast
  }
  if (!(state.datasource.subsetMap['sit'] && state.datasource.subsetMap['sit'].dataLength > 0)) {
    controlsData[3].data[12].hidden = true; // Board KPI SIT
  }
  if (!(state.datasource.subsetMap['sfe'] && state.datasource.subsetMap['sfe'].dataLength > 0)) {
    controlsData[3].data[13].hidden = true; // Board KPI SFE
  }

  // disable Monthly for datasetType == quarterly (AIS)
  if (state.datasource.datasetType === 'quarterly') {
    controlsData[3].data[0].controls[0].disabled = true;
  }

  const frequency = controlsData[3].data[0].controls.filter(d => d.active)[0];
  if (frequency.name === "0") {
    // Board-Time Aggr.
    controlsData[3].data[3].controls[0].disabled = false; // MTH

    // Chart-Time Aggr.
    controlsData[3].data[4].controls[0].disabled = false; //MTH
  } else {
    // Board-Time Aggr.
    controlsData[3].data[3].controls[0].disabled = true;

    // Chart-Time Aggr.
    controlsData[3].data[4].controls[0].disabled = true; //MTH
  }


  // period aligned / latest
  controlsData[3].data[6].controls[1].disabled = state.view === 'market';

  // dimensions
  controlsData[3].data[7].controls.forEach(c => c.active = state.dims.includes(c.name))
  
  return controlsData;
}

const updateHighlights = (state) => {
  const { controlsData, aggrData: { highlightKeys } } = state;

  if (highlightKeys) {
    for (let index = 0; index < 3; index++) {  
      controlsData[index].data.forEach(d => {
        if (d.controls.length ) {
          d.controls.sort((a, b) => a.name > b.name ? 1 : -1);
          const keys = highlightKeys[d.name];
          if (keys && keys.length) {
            let i = 0, j = 0;
            while (i < d.controls.length && j < keys.length) {
              if (keys[j] === d.controls[i].name) {
                d.controls[i].disabled = false;
                j++;
              } else {
                d.controls[i].disabled = true;
              }
              i++;
            }
            // d.controls.forEach(control => control.disabled = !highlightKeys[d.name].includes(control.name))
          } else {
            d.controls.forEach(control => control.disabled = false);
          }
        }
      })  
    }
  }
}

const calculateActives = (state, controlsData, tab_index = 0, group_index = 0) => {
  // return {
  //   headerCountries: [],
  //   headerCategories: [],
  //   headerProducts: []
  // }
  
  // const allCountries = state.data.group[controlsData[0].data[2].name].all().map(d => d.key);
  const allCountries = controlsData[0].data[2].controls.filter(d => !d.disabled).map(d => d.name);
  const WORLDWIDE = allCountries.length > 5 ? ['Worldwide' + ' (' + allCountries.length + ' countries)'] : allCountries;

  // not country, market, product
  if (tab_index === 3) return state.actives;
  
  const isLibtayo = state.datasource.slug === 'LB';
    
  const matchGroups = (tab_index, level, actives) => {
    const groups = state.datasource.controlsGroups[tab_index][level];
    const nextGroups = state.datasource.controlsGroups[tab_index].length > level 
      ? state.datasource.controlsGroups[tab_index][level + 1] || []
      : [];

    if (level === 0) {
      // check if Country tab
      if (tab_index === 0 && groups[0].items.length === actives.length) return WORLDWIDE;

      // check if Category tab
      if (tab_index === 1 && groups[0].items.length === actives.length && isLibtayo) return ['Libtayo Scorecard'];

      // check if Product tab
      if (tab_index === 2 && groups[0].items.length === actives.length) return ["All Products"];
      
      return actives;
    }
    // return actives;
    const temp = groups.map(d => ({
      items: d.items,
      parent: d.parent, 
      level: d.level,
      actives: []
    }));
    actives.forEach(active => {
      groups.forEach((group, i) => {
        if (group.items.includes(active)) temp[i].actives.push(active);
      })
    });
    
    let all = [];
    temp.filter(d => (d.items.length !== d.actives.length) || d.actives.length === 1).forEach(d => {
      d.actives.forEach(active => {
        const gr = nextGroups.find(group => group.parent === active);
        if (gr && gr.items.length === 1) {
          all = all.concat(gr.items);
        } else {
          all.push(active);
        }
      })
    });
    const grouped = temp.filter(d => (d.items.length === d.actives.length) && d.actives.length > 1).map(d => d.parent);

    if (grouped.length) all = all.concat(matchGroups(tab_index, level - 1, grouped));
    
    return d3.set(all).values();
  }

  const getGroups = (tab_index, level) => {
    if (level < 0) {
      // check if Country tab
      if (tab_index === 0) return WORLDWIDE;

      // check if Category tab
      if (tab_index === 1 && isLibtayo) return ['Libtayo Scorecard'];

      // check if Product tab
      if (tab_index === 2) return ["All Products"];
      
      return state.datasource.controlsGroups[tab_index][0][0].items;
    }

    // reference market || segment
    if (tab_index === 1 && (level === 7 || level === 6)) {
      const actives = controlsData[tab_index].data[level].controls.filter(f => f.active).map(m => m.name);
      return actives.length ? actives : getGroups(tab_index, controlsData[tab_index].lastAggregatedLevel);
    }

    const data = controlsData[tab_index].data[level];
    const actives = data.controls.filter(f => f.active).map(m => m.name);
    return actives.length ? matchGroups(tab_index, level, actives) : getGroups(tab_index, level - 1)
  }
  let headerCountries;
  let headerCategories;
  let headerProducts;

  switch (tab_index) {
    case 0: {
      headerCountries = (state.actives && getGroups(tab_index, Math.min(group_index, controlsData[0].lastAggregatedLevel))) || getGroups(0, controlsData[0].lastAggregatedLevel);
      headerCategories = (state.datasource.datasetType === 'quarterly') 
        ? []
        : (state.actives && state.actives.headerCategories) || getGroups(1, controlsData[1].lastAggregatedLevel)
      headerProducts = (state.datasource.datasetType === 'quarterly') 
        ? []
        : (state.actives && state.actives.headerProducts) || getGroups(2, controlsData[2].lastAggregatedLevel);
      break;
    }
    case 1: {
      headerCountries = getGroups(0, controlsData[0].lastAggregatedLevel)//(state.actives && state.actives.headerCountries) || getGroups(0, controlsData[0].lastAggregatedLevel)
      headerCategories = (state.datasource.datasetType === 'quarterly') 
        ? []
        : (state.actives && getGroups(tab_index, group_index)) || getGroups(1, controlsData[1].lastAggregatedLevel)
      headerProducts = (state.datasource.datasetType === 'quarterly') 
        ? []
        : (state.actives && state.actives.headerProducts) || getGroups(2, controlsData[2].lastAggregatedLevel)
      break;
    }
    case 2: {
      headerCountries = getGroups(0, controlsData[0].lastAggregatedLevel)//(state.actives && state.actives.headerCountries) || getGroups(0, controlsData[0].lastAggregatedLevel)
      headerCategories = (state.datasource.datasetType === 'quarterly') 
        ? []
        : (state.actives && state.actives.headerCategories) || getGroups(1, controlsData[1].lastAggregatedLevel)
      headerProducts = (state.datasource.datasetType === 'quarterly') 
        ? []
        : (state.actives && getGroups(tab_index, group_index)) || getGroups(2, controlsData[2].lastAggregatedLevel)
      break;
    }
    default: {}
  }

  const activeClusters = controlsData[0].data[3].controls
    .filter(f => f.active)
    .map(m => m.name);

  const activeChannelGroups = controlsData[0].data[4].controls
    .filter(f => f.active)
    .map(m => m.name);

  // calculate footer categories
  const getItems = (level, values) => {
    if (level === 6) return values;
    let arr = [];
    state.datasource.controlsGroups[1][level].forEach(d => {
      if (values.includes(d.parent)) {
        arr = arr.concat(d.items);
      }
    })
    return getItems(level + 1, arr)
  }

  // return {
  //   headerCountries: [],//d3.set(headerCountries.concat(activeClusters, activeChannelGroups)).values(),
  //   headerCategories: [],
  //   headerProducts: []
  // }
  return {
    headerCountries: d3.set(headerCountries.concat(activeClusters, activeChannelGroups)).values(),
    headerCategories,
    headerProducts
  }
}

const getDates = (state, dateStart, dateEnd) => {
  return state.dateInterval
    .range(state.dateParse(dateStart), state.dateInterval.offset(state.dateParse(dateEnd), 1))
    .map(d => state.dateFormat(d));
}
  

//==================================================================

const getAggregationData = (state) => {  
  return { 
    value: [], 
    valueTotal:{}, 
    valueATU_Share:{},
    valueATU_Aware: {},
    valuePEQ: {},
    sisoValue:{},
    valueSFE_table:{},
    valueSFE_charts:{},
    valueYSEOP_charts:{}
  }
}

const getTableData = (state) => {
  // return {data: [], total: {}}
  console.log('Get Table Data', state)

  let data;
  let date1 = new Date();
  if (state.view === 'market') {
    data = getCalcTableDataMarket({
      dateEnd: state.dateEnd,
      kpis: state.defaultKPIs[state.activeTableView].kpis,
      subsetMap: state.datasource.subsetMap,
      target_data: state.target_data
    }, state.aggrData);
  }

  else if (state.view === 'country') {
    data = getCalcTableDataPortfolio({
      dateEnd: state.dateEnd,
      kpis: state.defaultKPIs[state.activeTableView].kpis,
      subsetMap: state.datasource.subsetMap,
      dims: state.dims,
      isLatestPeriod: state.controlsData[3].data[6].controls[1].active
    }, state.aggrData)  
  }

  else if (state.view === 'portfolio') {
    data = getCalcTableDataPortfolio({
      dateEnd: state.dateEnd,
      kpis: state.defaultKPIs[state.activeTableView].kpis,
      subsetMap: state.datasource.subsetMap,
      dims: state.dims,
      isLatestPeriod: state.controlsData[3].data[6].controls[1].active
    }, state.aggrData)  
  }

  else if (state.view === 'executive') {
    data = getCalcTableDataExecutive({
      dateEnd: state.dateEnd,
      kpis: state.defaultKPIs[state.activeTableView].kpis,
      subsetMap: state.datasource.subsetMap,
      dims: state.dims,
      useDefaultMetrics: state.controlsData[3].data[1].controls.find(d => d.active).name === 'Default',
      target_data: state.target_data
    }, state.aggrData)
  }
  
  let date2 = new Date();
  
  console.log('Time spent on NEW table:', date2 - date1, data)
  
  return getSortTree(data, state.sortId, state.sortOrder);
}

const getCalcTableDataMarket = (opts, { value, valueTotal, valueSFE_table }) => {
  const {
    dateEnd,
    kpis,
    subsetMap
  } = opts;

  const total = {};
  kpis.forEach(kpi => {
    if (['market'].includes(kpi.dataType)) {
      const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
      const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
      const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
      const datesFirst = datesLast - period;
      const datesLastPrev = datesLast - kpi.prevPeriod;
      const datesFirstPrev = datesLastPrev - period;
    
      total[kpi.id] = {
        total: {},
        value: {},
        valuePrev: {}
      }
      kpi.columns.forEach(col => {
        total[kpi.id].value[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
        total[kpi.id].valuePrev[col] = getAggrValue(valueTotal, col, datesFirstPrev, datesLastPrev, period);
      });
      total[kpi.id].total = kpi.func(kpi, {
        value: total[kpi.id].value,
        total: total[kpi.id].value,
        valuePrev: total[kpi.id].valuePrev,
        totalPrev: total[kpi.id].valuePrev
      });
    }
    if (['forecast'].includes(kpi.dataType)) {
      const _dateEnd = kpi.dateEnd || dateEnd["market"];
      const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
      const datesLast = subsetMap['market'].dates.indexOf(_dateEnd) + 1;
      const datesFirst = datesLast - period;
      const datesLastPrev = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
      const datesFirstPrev = datesLastPrev - period;
    
      total[kpi.id] = {
        value: {}
      }
      kpi.marketColumns.forEach(col => {
        total[kpi.id].value[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
      });
      kpi.columns.forEach(col => {
        total[kpi.id].value[col] = getAggrValue(valueTotal, col, datesFirstPrev, datesLastPrev, period);
      });

      total[kpi.id].total = kpi.func(kpi, {
        value: total[kpi.id].value,
        total: total[kpi.id].value
      });
    }
  });

  const calcTableData = (data, kpis) => {
    return data.map(d => {
      // initialize table row
      const _d = {};
      _d.id = d.value.id;
      _d.dim = d.value.dim;
      _d.launchDate = d.value.launchDate;
      _d.key = d.value.id.split(':').pop();

      kpis.forEach(kpi => {
        // market kpis
        if (['market'].includes(kpi.dataType)) {
          const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
          const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
          const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
          const datesFirst = datesLast - period;
          const datesLastPrev = datesLast - kpi.prevPeriod;
          const datesFirstPrev = datesLastPrev - period;
  
          const item = {
            value: {},
            valuePrev: {},
            total: total[kpi.id].value,
            totalPrev: total[kpi.id].valuePrev
          }
  
          kpi.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
          });  

          _d[kpi.id] = kpi.func(kpi, item);
        } 

        else if (['forecast'].includes(kpi.dataType)) {
          const _dateEnd = kpi.dateEnd || dateEnd["market"];
          const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
          const datesLast = subsetMap['market'].dates.indexOf(_dateEnd) + 1;
          const datesFirst = datesLast - period;
          const datesLastPrev = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
          const datesFirstPrev = datesLastPrev - period;
          
          const item = {
            value: {},
            total: total[kpi.id].value
          }
          
          // market
          kpi.marketColumns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });
          // forecast
          kpi.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
          });
          
          _d[kpi.id] = kpi.func(kpi, item);
        }
        
        // finance kpis
        else if (['finance'].includes(kpi.dataType)) {
          const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
          const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
          const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
          const datesFirst = datesLast - period;
          const datesLastPrev = datesLast - kpi.prevPeriod;
          const datesFirstPrev = datesLastPrev - period;
          const datesLastPrev_1 = datesLast - period;
          const datesFirstPrev_1 = datesLast - 2 * period;
  
          const item = {
            value: {},
            valuePrev: {},
            valuePrev_1: {},
            valueTotalYear: {}, // only for YTG + YTD
            month: kpi.dateParse(_dateEnd).getUTCMonth() + 1
          }

          kpi.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
            item.valuePrev_1[col] = getAggrValue(d.value.total, col, datesFirstPrev_1, datesLastPrev_1, period); 
          });

          if (['YTDYTG', 'YTDAvg YTG'].includes(kpi.id) || ['YTDYTG', 'YTDAvg YTG'].includes(kpi.aggr.name + kpi.name)) {
            kpi.columns.forEach(col => {
              item.valueTotalYear[col] = getAggrValue(d.value.total, col, datesFirst, datesLast - period + 12);
            });
          }

          if (kpi.aggr.name === "MAT") {
            _d[kpi.id] = null;  
          } else {
            _d[kpi.id] = kpi.func(kpi, item);
          }
        } 

        // sit kpis
        else if (['sit'].includes(kpi.dataType)) {
          const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
          const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
          const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
          const datesFirst = Math.max(0, datesLast - period);

          const item = {
            value: {}
          }
  
          kpi.columns.forEach(col => {
            item.value[col] = (kpi.customValue && kpi.customValue[col])
              ? calcs[kpi.customValue[col]](d.value.total, col, datesFirst, datesLast, period)
              : getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });

          _d[kpi.id] = kpi.func(kpi, item);
        } 

        // sef kpis
        else if (['sfe'].includes(kpi.dataType)) {
          const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
          const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd);
          
          const item = {
            value: {}
          }
  
          kpi.columns.forEach(col => {
            item.value[col] = d.value.total[col][datesLast];
          });  

          _d[kpi.id] = kpi.func(kpi, item);
        } 
      });

      if (d.value.children) _d.children = calcTableData(d.value.children, kpis).filter(d => Math.abs(d3.sum(kpis, kpi => d[kpi.id])) > .0000001);

      return _d;
    })
  }

  const tableData = calcTableData(value, kpis.filter(kpi => kpi.dataType !== "sfe")).filter(d => Math.abs(d3.sum(kpis, kpi => d[kpi.id])) > .0000001);

  const dataSFE = []
  Object.keys(valueSFE_table).forEach(id => {
    dataSFE.push({
      id,
      data: calcTableData(valueSFE_table[id], kpis.filter(kpi => kpi.id === id))
    })
  })

  dataSFE.forEach(sfe => {
    const merge = (data, tableData) => {
      data.forEach(d => {
        const td = tableData.find(q => q.id === d.id);
        if (td) { 
          td[sfe.id] = d[sfe.id];
          if (td.children && d.children) merge(d.children, td.children);
        } else {
          tableData.push(d);
        };
      })
    }
    merge(sfe.data, tableData);
  });

  return { data: tableData, total }
}

// country & portfolio view
const getCalcTableDataPortfolio = (opts, { value, valueTotal, valueSFE_table }) => {
  const {
    dateEnd,
    kpis,
    subsetMap,
    dateParse,
    isLatestPeriod
  } = opts;

  const total = {};
  kpis.forEach(kpi => {
    if (['market'].includes(kpi.dataType)) {
      const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
      const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
      const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
      const datesFirst = datesLast - period;
      const datesLastPrev = datesLast - kpi.prevPeriod;
      const datesFirstPrev = datesLastPrev - period;
    
      total[kpi.id] = {
        total: {},
        value: {},
        valuePrev: {}
      }
      kpi.columns.forEach(col => {
        //if(valueTotal["_Other"] && valueTotal["_Other"]["_Other"]) {
        total[kpi.id].value[col] = getAggrValue(valueTotal["_Other"]["_Other"], col, datesFirst, datesLast, period);
        total[kpi.id].valuePrev[col] = getAggrValue(valueTotal["_Other"]["_Other"], col, datesFirstPrev, datesLastPrev, period);
        //}
      });
      total[kpi.id].total = kpi.func(kpi, {
        value: total[kpi.id].value,
        total: total[kpi.id].value,
        valuePrev: total[kpi.id].valuePrev,
        totalPrev: total[kpi.id].valuePrev
      });
    }
    if (['forecast'].includes(kpi.dataType)) {
      const _dateEnd = kpi.dateEnd || dateEnd["market"];
      const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
      const datesLast = subsetMap['market'].dates.indexOf(_dateEnd) + 1;
      const datesFirst = datesLast - period;
      const datesLastPrev = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
      const datesFirstPrev = datesLastPrev - period;
    
      total[kpi.id] = {
        value: {}
      }
      kpi.marketColumns.forEach(col => {
        total[kpi.id].value[col] = getAggrValue(valueTotal["_Other"]["_Other"], col, datesFirst, datesLast, period);
      });
      kpi.columns.forEach(col => {
        total[kpi.id].value[col] = getAggrValue(valueTotal["_Other"]["_Other"], col, datesFirstPrev, datesLastPrev, period);
      });

      total[kpi.id].total = kpi.func(kpi, {
        value: total[kpi.id].value,
        total: total[kpi.id].value
      });
    }
  });

  const calcTableData = (data, kpis) => {
    return data.map(d => {
      // initialize table row
      const _d = {};
      _d.id = d.value.id;
      _d.dim = d.value.dim;
      _d.launchDate = d.value.launchDate;
      _d.key = d.value.id.split(':').pop();
      _d.dateEnd = d.value.dateEnd;
      if (d.value.country_dim) _d.country_dim = d.value.country_dim;
      if (d.value.country_value) _d.country_value = d.value.country_value;

      kpis.forEach(kpi => {
        // market kpis
        if (['market'].includes(kpi.dataType)) {
          const _dateEnd = isLatestPeriod ? _d.dateEnd : (kpi.dateEnd || dateEnd[kpi.dataType]);
          const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
          const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
          const datesFirst = datesLast - period;
          const datesLastPrev = datesLast - kpi.prevPeriod;
          const datesFirstPrev = datesLastPrev - period;
  
          const item = {
            value: {},
            valuePrev: {},
            total: {},//total[kpi.id][d.value.country_dim][d.value.country_value].value,
            totalPrev: {}//total[kpi.id][d.value.country_dim][d.value.country_value].valuePrev
          }
  
          kpi.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);

            // //if((d.value.market_dim && d.value.market_value && d.value.country_value) || (d.value.country_dim && d.value.country_value)) {
            // item.total[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirst, datesLast, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirst, datesLast, period);

            // item.totalPrev[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirstPrev, datesLastPrev, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirstPrev, datesLastPrev, period);
            // //}

            item.total[col] = getAggrValue(d.value.total, col + '_total', datesFirst, datesLast, period);
            item.totalPrev[col] = getAggrValue(d.value.total, col + '_total', datesFirstPrev, datesLastPrev, period)
          });  

          _d[kpi.id] = kpi.func(kpi, item);
        } 

        else if (['forecast'].includes(kpi.dataType)) {
          const _dateEnd = isLatestPeriod ? _d.dateEnd : (kpi.dateEnd || dateEnd["market"]);
          const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
          const datesLast = subsetMap['market'].dates.indexOf(_dateEnd) + 1;
          const datesFirst = datesLast - period;
          const datesLastPrev = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
          const datesFirstPrev = datesLastPrev - period;
          
          const item = {
            value: {},
            total: {}
          }
          
          // market
          kpi.marketColumns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            
            // item.total[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirst, datesLast, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirst, datesLast, period);

            item.total[col] = getAggrValue(d.value.total, col + '_total', datesFirst, datesLast, period);  
          });
          // forecast
          kpi.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);

            // item.total[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirstPrev, datesLastPrev, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirstPrev, datesLastPrev, period);

            item.total[col] = getAggrValue(d.value.total, col + '_total', datesFirstPrev, datesLastPrev, period);
          });
          
          _d[kpi.id] = kpi.func(kpi, item);
        }
        
        // finance kpis
        else if (kpi.dataType === 'finance') {
          const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
          const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
          const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
          const datesFirst = datesLast - period;
          const datesLastPrev = datesLast - kpi.prevPeriod;
          const datesFirstPrev = datesLastPrev - period;
          const datesLastPrev_1 = datesLast - period;
          const datesFirstPrev_1 = datesLast - 2 * period;
  
          const item = {
            value: {},
            valuePrev: {},
            valuePrev_1: {},
            valueTotalYear: {}, // only for YTG + YTD
            month: kpi.dateParse(_dateEnd).getUTCMonth() + 1
          }
  
          kpi.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
            item.valuePrev_1[col] = getAggrValue(d.value.total, col, datesFirstPrev_1, datesLastPrev_1, period);
          });

          if (['YTDYTG', 'YTDAvg YTG'].includes(kpi.id) || ['YTDYTG', 'YTDAvg YTG'].includes(kpi.aggr.name + kpi.name)) {
            kpi.columns.forEach(col => {
              item.valueTotalYear[col] = getAggrValue(d.value.total, col, datesFirst, datesLast - period + 12);
            });
          }
          
          if (kpi.aggr.name === "MAT") {
            _d[kpi.id] = null;  
          } else {
            _d[kpi.id] = kpi.func(kpi, item);
          }
        } 

        // sit kpis
        else if (['sit'].includes(kpi.dataType)) {
          const _dateEnd = isLatestPeriod ? _d.dateEnd : (kpi.dateEnd || dateEnd[kpi.dataType]);
          const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
          const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
          const datesFirst = Math.max(0, datesLast - period);

          const item = {
            value: {}
          }
  
          kpi.columns.forEach(col => {
            item.value[col] = (kpi.customValue && kpi.customValue[col])
              ? calcs[kpi.customValue[col]](d.value.total, col, datesFirst, datesLast, period)
              : getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });

          _d[kpi.id] = kpi.func(kpi, item);
        } 

        // sef kpis
        else if (['sfe'].includes(kpi.dataType)) {
          const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
          const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd);
          
          const item = {
            value: {}
          }
  
          kpi.columns.forEach(col => {
            item.value[col] = d.value.total[col][datesLast];
          });  

          _d[kpi.id] = kpi.func(kpi, item);
        } 
      });

      if (d.value.children) _d.children = calcTableData(d.value.children, kpis).filter(d => Math.abs(d3.sum(kpis, kpi => d[kpi.id])) > .0000001);
      
      return _d;
    })
  }

  const tableData = calcTableData(value, kpis.filter(kpi => kpi.dataType !== "sfe")).filter(d => Math.abs(d3.sum(kpis, kpi => d[kpi.id])) > .0000001);

  const dataSFE = []
  Object.keys(valueSFE_table).forEach(id => {
    dataSFE.push({
      id,
      data: calcTableData(valueSFE_table[id], kpis.filter(kpi => kpi.id === id))
    })
  })

  dataSFE.forEach(sfe => {
    const merge = (data, tableData) => {
      data.forEach(d => {
        const td = tableData.find(q => q.id === d.id);
        if (td) { 
          td[sfe.id] = d[sfe.id];
          if (td.children && d.children) merge(d.children, td.children);
        } else {
          tableData.push(d);
        };
      })
    }
    merge(sfe.data, tableData);
  });

  return { data: tableData, total }
}

const getCalcTableDataExecutive = (opts, { value, valueTotal, valueSFE_table }) => {
  const {
    dateEnd,
    kpis,
    subsetMap,
    dims,
    dateParse,
    useDefaultMetrics,
    target_data
  } = opts;

  const useCategory = dims.includes("Category5");
  const useProduct = dims.includes("Product Group 1");

  const calcTableData = (data, default_metric, isKP) => {
    return data.map(d => {
      // initialize table row
      const _d = {};
      _d.id = d.value.id;
      _d.dim = d.value.dim;
      _d.launchDate = d.value.launchDate;
      _d.key = d.value.id.split(':').pop();

      // find reference market
      const [RM, country, ] = d.value.id.split(':');
      if (country) {
        kpis.forEach(kpi => {
          // market kpi + default metrics
          if (['market'].includes(kpi.dataType) && useDefaultMetrics) {
            const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
            const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
            const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
            const datesFirst = datesLast - period;
            const datesLastPrev = datesLast - kpi.prevPeriod;
            const datesFirstPrev = datesLastPrev - period;
    
            const item = {
              value: {},
              valuePrev: {},
              total: {},
              totalPrev: {}
            }

            // kpi.columns.forEach(column => {
            const col = (default_metric || d.value.default_metric);
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, isKP);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period, isKP);
            item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirst, datesLast, period);
            item.totalPrev[col] = getAggrValue(valueTotal[country][RM], col, datesFirstPrev, datesLastPrev, period);
            // });  
  
            _d.default_metric = default_metric || d.value.default_metric;
            _d[kpi.id] = kpi.func({...kpi, columns: [_d.default_metric]}, item);
          } 
  
          // market kpis
          else if (['market'].includes(kpi.dataType)) {
            const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
            const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
            const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
            const datesFirst = datesLast - period;
            const datesLastPrev = datesLast - kpi.prevPeriod;
            const datesFirstPrev = datesLastPrev - period;
    
            const item = {
              value: {},
              valuePrev: {},
              total: {},
              totalPrev: {}
            }
    
            kpi.columns.forEach(col => {
              item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, isKP);
              item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period, isKP);
              item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirst, datesLast, period);
              item.totalPrev[col] = getAggrValue(valueTotal[country][RM], col, datesFirstPrev, datesLastPrev, period);
            });  
  
            _d[kpi.id] = kpi.func(kpi, item);
          } 
  
          else if (['forecast'].includes(kpi.dataType) && useDefaultMetrics) {
            const _dateEnd = kpi.dateEnd || dateEnd["market"];
            const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
            const datesLast = subsetMap['market'].dates.indexOf(_dateEnd) + 1;
            const datesFirst = datesLast - period;
            const datesLastPrev = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
            const datesFirstPrev = datesLastPrev - period;
            
            const item = {
              value: {},
              total: {}
            }
            
            // market
            const col = (default_metric || d.value.default_metric);
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, isKP);
            item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirst, datesLast, period);

            // forecast
            kpi.columns.forEach(col => {
              item.value[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period, isKP);
              item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirstPrev, datesLastPrev, period);
            });
            
            _d.default_metric = default_metric || d.value.default_metric;
            _d[kpi.id] = kpi.func({...kpi, marketColumns: [_d.default_metric]}, item);
          }

          else if (['forecast'].includes(kpi.dataType)) {
            const _dateEnd = kpi.dateEnd || dateEnd["market"];
            const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
            const datesLast = subsetMap['market'].dates.indexOf(_dateEnd) + 1;
            const datesFirst = datesLast - period;
            const datesLastPrev = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
            const datesFirstPrev = datesLastPrev - period;
            
            const item = {
              value: {},
              total: {}
            }
            
            // market
            kpi.marketColumns.forEach(col => {
              item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, isKP);
              item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirst, datesLast, period);
            });
            // forecast
            kpi.columns.forEach(col => {
              item.value[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period, isKP);
              item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirstPrev, datesLastPrev, period);
            });
            
            _d[kpi.id] = kpi.func(kpi, item);
          }
          
          // finance kpis
          else if (kpi.dataType === 'finance') {
            const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
            const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
            const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
            const datesFirst = datesLast - period;
            const datesLastPrev = datesLast - kpi.prevPeriod;
            const datesFirstPrev = datesLastPrev - period;
            const datesLastPrev_1 = datesLast - period;
            const datesFirstPrev_1 = datesLast - 2 * period;
    
            const item = {
              value: {},
              valuePrev: {},
              valuePrev_1: {},
              valueTotalYear: {}, // only for YTG + YTD
              month: kpi.dateParse(_dateEnd).getUTCMonth() + 1
            }
    
            kpi.columns.forEach(col => {
              item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, isKP);
              item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period, isKP);
              item.valuePrev_1[col] = getAggrValue(d.value.total, col, datesFirstPrev_1, datesLastPrev_1, period, isKP);
            });
  
            if (['YTDYTG', 'YTDAvg YTG'].includes(kpi.id) || ['YTDYTG', 'YTDAvg YTG'].includes(kpi.aggr.name + kpi.name)) {
              kpi.columns.forEach(col => {
                item.valueTotalYear[col] = getAggrValue(d.value.total, col, datesFirst, datesLast - period + 12, 1, isKP);
              });
            }

            if (kpi.aggr.name === "MAT") {
              _d[kpi.id] = null;  
            } else {
              _d[kpi.id] = kpi.func(kpi, item);
            }
          } 

          // sit kpis
          else if (['sit'].includes(kpi.dataType)) {
            const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
            const period = kpi.aggr.period === 9 ? (_dateEnd ? kpi.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : kpi.aggr.period; // YTD, aggregation period
            const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd) + 1;
            const datesFirst = Math.max(0, datesLast - period);

            const item = {
              value: {}
            }
    
            kpi.columns.forEach(col => {
              item.value[col] = (kpi.customValue && kpi.customValue[col])
                ? calcs[kpi.customValue[col]](d.value.total, col, datesFirst, datesLast, period, isKP)
                : getAggrValue(d.value.total, col, datesFirst, datesLast, period, isKP);
            });

            _d[kpi.id] = kpi.func(kpi, item);
          } 

           // sef kpis
          else if (['sfe'].includes(kpi.dataType)) {
            const _dateEnd = kpi.dateEnd || dateEnd[kpi.dataType];
            const datesLast = subsetMap[kpi.dataType].dates.indexOf(_dateEnd);
            
            const item = {
              value: {}
            }
    
            kpi.columns.forEach(col => {
              item.value[col] = d.value.total[col + ":KP"][datesLast];
            });  

            _d[kpi.id] = kpi.func(kpi, item);
          } 

          else if (['target'].includes(kpi.dataType)) {
            const _dateEnd = kpi.dateEnd || dateEnd["market"];
            const target = target_data.find(t => t["Country"] === country && t["Market"] === RM && t["Product"] === d.key && t["Period"] === _dateEnd);
            _d[kpi.id] = target ? target[kpi.columns[0]]: 0;
          }
        });  
      }
      
      if (d.value.children) {
        // change "children" to "last_children" to NOT display it in the table
        if ((_d.dim === "Product Portfolio" && !useProduct) || _d.dim === "Product Group 1") {
          _d.last_children = calcTableData(d.value.children, default_metric || d.value.default_metric);  
        } else if (_d.dim === "Product Portfolio") {
          _d.children = calcTableData(d.value.children, default_metric || d.value.default_metric, true).filter(d => Math.abs(d3.sum(kpis, kpi => d[kpi.id])) > .0000001);
          _d.last_children = calcTableData(d.value.children, default_metric || d.value.default_metric);
        } else {
          _d.children = calcTableData(d.value.children, default_metric || d.value.default_metric, true);
        }
      };

      return _d;
    })
  }

  const data = calcTableData(value);
  const flatData = {};

  data.forEach(RM => {
    RM.children.forEach(c => {
      if (!flatData[c.key]) flatData[c.key] = [];
      c.children.forEach(pp => {
        if (useCategory) {
          pp.children.forEach(cat => {
            const splittedId = cat.id.split(':');
            cat.key = splittedId[3] + ' in ' + splittedId[0];
          })
        } else {
          const splittedId = pp.id.split(':');
          pp.key = splittedId[2] + ' in ' + splittedId[0];
        }
        flatData[c.key].push(pp);
      })
    })
  })

  // when we aggregate countries -> then aggregate categories
  if (useCategory) {
    Object.keys(flatData).forEach(c => {
      const flatDataCat = {};

      flatData[c].forEach(cat => {
        if (!flatDataCat[cat.key]) flatDataCat[cat.key] = [];
        flatDataCat[cat.key] = flatDataCat[cat.key].concat(cat.children);
      })

      flatData[c] = Object.keys(flatDataCat).map(cat => ({
        key: cat,
        id: cat,
        children: flatDataCat[cat],
        sortOrder: 'asc'
      }));
    })
  }
  
  const tableData = Object.keys(flatData).map(d => ({
    key: d,
    id: d,
    children: flatData[d],
    sortOrder: 'asc'
  }));

  return { data: tableData }
}

////////////////////////////////////////////////////////////
// Charts Data
const getChartData = (state) => {
  console.log('Get Chart Data')

  let date1 = new Date();
  let chartData;
  
  if (state.view === 'market') {
    chartData = getCalcChartDataMarket({
      dims: state.dims,
      data: state.data,
      charts: state.charts,
      chartControls: state.chartControls,
      dateEnd: state.dateEnd,
      subsetMap: state.datasource.subsetMap,
      selectedId: state.selectedId
    }, 
    state.aggrData,
    state.tableData.data);
  } 
  
  else if (state.view === 'country') {
    chartData = getCalcChartDataPortfolio({
      dims: state.dims,
      data: state.data,
      charts: state.charts,
      chartControls: state.chartControls,
      dateEnd: state.dateEnd,
      subsetMap: state.datasource.subsetMap,
      selectedId: state.selectedId,
      isLatestPeriod: state.controlsData[3].data[6].controls[1].active
    }, 
    state.aggrData,
    state.tableData.data);
  }
  
  else if (state.view === 'portfolio') {
    chartData = getCalcChartDataPortfolio({
      dims: state.dims,
      data: state.data,
      charts: state.charts,
      chartControls: state.chartControls,
      dateEnd: state.dateEnd,
      subsetMap: state.datasource.subsetMap,
      selectedId: state.selectedId,
      isLatestPeriod: state.controlsData[3].data[6].controls[1].active
    }, 
    state.aggrData,
    state.tableData.data);
  }

  else if (state.view === 'executive') {
    chartData = getCalcChartDataExecutive({
      dims: state.dims,
      data: state.data,
      charts: state.charts,
      chartControls: state.chartControls,
      dateEnd: state.dateEnd,
      subsetMap: state.datasource.subsetMap,
      selectedId: state.selectedId,
      useDefaultMetrics: state.controlsData[3].data[1].controls.find(d => d.active).name === 'Default'
    }, 
    state.aggrData,
    state.tableData.data);
  }
  
  state.charts.forEach(chart => {
    const multiplier = chart.format === "percent" || chart.format === "pts" ? 100 : 1;
    const value = chartData.find(d => d.id === chart.id);

    const isQuarterly = chart.columns[0].slice(-4) === '_QRT';
    const quarterFilter = d => [2, 5, 8, 11].includes(d.date.getUTCMonth());
    const quarterFilterDateString = d => ['03', '06', '08', '12'].includes(d.date.slice(-2));

    if (!state.selectedId && !["MSE_WK", "AS_WK"].includes(chart.id)) {
      chart.data = [{data: []}];
      return [];
    }

    if (chart.id === "MG") {
      // bar-line chart
      const key = state.selectedId.split(':').pop();
      const series = state.view === "executive"
        ? value.series.find(d => d.key === key) || {data: []}
        : value.series[0];

      chart.data = [
        {
          name: "Δ Grth vs Mkt",
          type: "bar",
          color: '#b4ec76', // green
          data: series["GtMkt"] ? series["GtMkt"].map(d => ({
            date: state.dateParse(d.date),
            value: 100 * d.value
          })) : [],
          format: "pts"
        },
        {
          name: state.view === "executive"
            ? state.selectedId.split(':').shift()
            : state.actives.headerCategories.join(', '),
          type: "line",
          styles: {
            strokeWidth: "1.5px",
            strokeDasharray: "6, 3", 
            color: "#000000"
          },
          data: series["GtPY_total"] ? series["GtPY_total"].map(d => ({
            date: state.dateParse(d.date),
            value: 100 * d.value || 0
          })) : []
        },
        {
          name: key,
          type: "line",
          styles: {
            color: colors[0]
          },
          dots: true,
          data: series.data.map(d => ({
            date: state.dateParse(d.date),
            value: 100 * d.value
          }))
        }
      ]
    }
    
    // launch
    else if (["MSE_L", "AS_L", "F_AS_L", "0PLP"].includes(chart.id)) {
      chart.data = value.series.map(p => ({
        name: p.key + ' - ' + d3.utcFormat("%b %Y")(state.dateParse(p.launchDate)),
        type: "line",
        dots: true,
        data: [{ date: 0, value: 0 }].concat(
          (isQuarterly ? p.data.filter(quarterFilterDateString) : p.data).slice(p.data.findIndex(d => d.value > 0) || 0).map((d, i) => ({
            date: i + 1,
            value: d.value !== null ? d.value * multiplier : null
          }))
        )
      }))
    } 
    
    // finance
    else if (["F_AT_M"].includes(chart.id)) {
      chart.data = value.series.filter(d => d.filterType ? state.chartControls.finance[d.filterType].value : true)
    }
    else if (["F_AT_T"].includes(chart.id)) {
      value.series.forEach(s => s.data = s.data.filter(d => d.filterType ? state.chartControls.finance[d.filterType].value : true))
      chart.data = value.series;
    }
    else if (["F_GtP_1"].includes(chart.id)) {
      chart.data = value.series;
    }

    // weekly
    else if (["MSE_WK", "AS_WK"].includes(chart.id)){
      // check if all zeros
      if (Math.abs(d3.sum(value.series, c => d3.sum(c.data, d => d.value))) < .0000001) {
        chart.data = [{data: []}];
      } else {
        chart.data = value.series.map(p => ({
          name: p.key,
          id: p.id,
          type: chart.id === "AG" ? "bar" : "line",
          dots: true,
          data: p.data.map(d => ({
            date: d.date,
            value: Math.round(100000000 * d.value * multiplier)/100000000
          })).slice(-40)
        }))
      }
    }

    // forecast
    else if (["AMS_FMS_F", "AS_FS_F"].includes(chart.id)) {
      chart.data = value.series.filter(d => d.filterType ? state.chartControls.forecast[d.filterType].value : true)
    }
    else if (["YTD_YTG_F"].includes(chart.id)) {
      value.series.forEach(s => s.data = s.data.filter(d => d.filterType ? state.chartControls.forecast[d.filterType].value : true))
      chart.data = value.series;
    }
    else if (["ABF_F"].includes(chart.id)) {
      chart.data = value.series.filter(d => d.filterType ? state.chartControls.forecast[d.filterType].value : true)
    }

    // patients
    else if (["MS_T_NSA_PT", "MS_T_ADC_PT", "MS_T_NSA_PT_WK"].includes(chart.id)) {
      chart.data = value.series
    }

    // ATU data
    else if (["SoD_AT", "AoT_AT", "SoD_Prev_AT", "AoT_Prev_AT"].includes(chart.id)) {
      chart.data = value.series;
    }

    // siso
    else if (["NG_MG_10_SISO", "NG_MG_SISO"].includes(chart.id)) {
      chart.data = value.series
    }

    // EOG
    else if (["EOG"].includes(chart.id)) {
      value.series.forEach(s => s.data.forEach(d => d.value = Math.round(100000000 * d.value * multiplier)/100000000));
      chart.data = value.series;
    }

    // MSE_Comp
    else if (["MSE_Comp"].includes(chart.id)) {
      value.series.forEach(s => s.data.forEach(d => d.value = Math.round(100000000 * d.value * multiplier)/100000000));
      chart.data = value.series
    }

    // SIT
    else if (["SIT_STOCK_SISO_VOL", "SIT_STOCK_SISO_VAL", "SIT_STOCK_COVERAGE"].includes(chart.id)) {
      value.series.forEach(d => d.data = d.data.slice(-chart.backData.period));
      chart.data = value.series;
    }

    // PEQ
    else if (["PL3SC_PEQ", "PL4SC_PEQ", "L3SC_PEQ", "L4SC_PEQ"].includes(chart.id)) {
      chart.data = value.series;
    }

    else {
      if (d3.sum(value.series, c => d3.sum(c.data, d => Math.abs(d.value))) < .0000001) {
        chart.data = [{data: []}];
      } else {
        chart.data = value.series.map(p => ({
          name: p.key,
          id: p.id,
          type: [
            "AG", 
            "NR_FA",
  
            "SoD_AT",
            "SoD_Prev_AT",
  
            // patient
            "MS_T_PT",
            "MS_T_N_PT",
            "MS_T_NS_PT",
            "MS_T1_PT",
            "MS_T1_N_PT",
            "MS_T1_NS_PT",
            "MS_T2_PT",
            "MS_T2_N_PT",
            "MS_T2_NS_PT",
            "MS_T_NSA_PT",
            "MS_T_ADC_PT",

            // patient_weekly
            "MS_T_PT_WK",
            "MS_T_N_PT_WK",
            "MS_T_NS_PT_WK",
            "MS_T1_PT_WK",
            "MS_T1_N_PT_WK",
            "MS_T1_NS_PT_WK",
            "MS_T2_PT_WK",
            "MS_T2_N_PT_WK",
            "MS_T2_NS_PT_WK",
            "MS_T_NSA_PT_WK",

            // sob
            "MN_SOB",
            "PS_SOB",
            "PC_SOB",
            "PPC_SOB",
            "NS_SOB",

            // PEQ
            "PL3SC_PEQ",
            "PL4SC_PEQ",
            "L3SC_PEQ",
            "L4SC_PEQ"
          ].includes(chart.id) ? "bar" : "line",
          dots: true,
          data: p.data.map(d => ({
            date: chart.chartType === "wk_sales" ? d.date : state.dateParse(d.date),
            value: Math.round(100000000 * d.value * multiplier)/100000000
          }))
        }))
      }
    }

    if (isQuarterly && !["MSE_L", "AS_L", "0PLP", "YTD_YTG_F", "NG_MG_10_SISO"].includes(chart.id)) {
      chart.data.forEach(d => {
        d.data = d.data.filter(quarterFilter);
      })
    }
  })

  let date2 = new Date()
  console.log('Time spent on NEW charts:', date2 - date1, chartData)
  return [];
}

const getCalcChartDataMarket = (opts, { 
  value, 
  valueTotal, 
  valueATU_Share, 
  valueATU_Aware, 
  sisoValue, 
  valueSFE_charts, 
  valuePEQ,
  uniquePatient,
  uniquePatientWeekly,
  uniqueWeekly,
  uniquePromo,
  uniqueSob,
  dataCrm,
  dataSob
}, tableData) => {
  const {
    dims,
    charts,
    chartControls,
    subsetMap,
    selectedId
  } = opts;

  const _calcs = (chart) => {
    const dateEnd = chart.dateEnd ? ({ [chart.dataType]: chart.dateEnd }) : opts.dateEnd

    if (!tableData.length && !["MSE_WK", "AS_WK"].includes(chart.id)) {
      return {
        id: chart.id,
        series: [{data: []}]
      }
    }

    // launch
    if (["MSE_L", "AS_L", "F_AS_L"].includes(chart.id)) {
      const ids = tableData.filter(d => d.launchDate !== '0').slice(0, chart.number).map(d => d.id);    
      const filteredValue = ids.map(id => getDataItem(value, id));  
  
      const all_dates = subsetMap[chart.dataType].dates;
      const _dates = chart.dates || all_dates.slice(
        all_dates.indexOf(d3.min(d3.set(filteredValue.map(d => d.value.launchDate)).values())), 
        dateEnd ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length
      );
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => _d.series.push({ 
        id: d.value.id, 
        key: d.value.id.split(':').pop(),
        launchDate: d.value.launchDate,
        data: [] 
      }));
      
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        // calc total
        const total = {};
          
        chart.columns.forEach(col => {
          total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
        });
        
        _d.total.push({
            date,
            value: chart.func(chart, {
              value: total,
              total: total
            })
          }
        );
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total
          }
  
          chart.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    } 
    // finance
    else if (["F_AT_M", "F_AT_T", "F_GtP_1"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => isPeq(c) ? d3.sum(filteredValue.value.total[c] + filteredValue.value.total[c + '_Sanofi']) : d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      // const currentYear = (new Date()).getUTCFullYear();
      const currentYear = chart.dateParse(dateEnd[chart.dataType]).getUTCFullYear();
      let all_dates = [];
      let shift = 0; // fill dates with zeros if not enough back data

      const dates = subsetMap[chart.dataType].dates;
      // const _dateEndIndex = dateEnd ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      // const _dates = chart.dates || all_dates.slice(_dateEndIndex - chart.backData.period, _dateEndIndex);
  
      // check if not enough back data
      const _date = new Date(currentYear - 1, 0);
      if (chart.dateFormat(_date) < dates[0]) {
        all_dates = chart.dateInterval.range(_date, chart.dateParse(dates[0])).map(d => chart.dateFormat(d));
        shift = all_dates.length;
      }
      all_dates = all_dates.concat(dates);
      const all_dates_actuals = all_dates.slice(0, all_dates.indexOf(dateEnd[chart.dataType]) + 1);
      const dateFormat = d3.utcFormat("%B");

      function getAllIndexes(arr, val) {
        let indexes = [], i;
        for (i = 0; i < arr.length; i++)
            if (arr[i] === val)
                indexes.push(i);
        return indexes;
      }

      const currentYearIndexes = getAllIndexes(all_dates.map(d => chart.dateParse(d).getUTCFullYear()), currentYear);
      const currentYearIndexesActuals = getAllIndexes(all_dates_actuals.map(d => chart.dateParse(d).getUTCFullYear()), currentYear);
      const previousYearIndexes = getAllIndexes(all_dates.map(d => chart.dateParse(d).getUTCFullYear()), currentYear - 1);

      // time aggregation period
      let period = chart.aggr.period;
      const YTD = period === 9;

      const preparedData = all_dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
        if (YTD) {
          period = _d.date.getUTCMonth() + 1;
        }
        chart.columns.forEach(col => {
          _d.value[col] = getAggrValue(filteredValue.value.total, col, Math.max(0, i - period + 1 - shift), Math.max(0, i + 1 - shift));
        });
        return _d;
      });

      let series = [];
      switch(chart.id) {
        case "F_AT_M": {
          series = [
            {
              filterType: "act-1",
              name: "Actuals - " + (currentYear - 1),
              type: "bar",
              color: "#d3d3d3", // lightgray
              data: previousYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.A](chart, preparedData[i]) // 0 - actuals calculation
              }))
            },
            {
              filterType: "act",
              name: "Actuals - " + currentYear,
              type: "bar",
              color: "#94a3de",
              data: currentYearIndexesActuals.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.A](chart, preparedData[i]) // 0 - actuals calculation
              }))
            },
            {
              filterType: "bud",
              name: "Budget",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#b4ec76"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.B](chart, preparedData[i]) // 1 - budget calculation
              }))
            },
            {
              filterType: "f1",
              name: "Forecast 1",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#47a6c7"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.F1](chart, preparedData[i])
              }))
            },
            {
              filterType: "f2",
              name: "Forecast 2",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#ffc000"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.F2](chart, preparedData[i])
              }))
            }
          ]
          break;
        }
        case "F_AT_T": {
          series = [
            {
              name: "Actuals",
              type: "bar",
              data: [
                {
                  filterType: "act-1",
                  date: "Actuals - " + (currentYear - 1),
                  value: calcs[chart.calcs.A](chart, preparedData[previousYearIndexes[currentYearIndexesActuals.length - 1]]),
                  color: "#d3d3d3" // lightgray
                },
                {
                  filterType: "act",
                  date: "Actuals - " + (currentYear),
                  value: calcs[chart.calcs.A](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#94a3de"
                },
                {
                  filterType: "bud",
                  date: "Budget",
                  value: calcs[chart.calcs.B](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#b4ec76"
                },
                {
                  filterType: "f1",
                  date: "Forecast 1",
                  value: calcs[chart.calcs.F1](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#47a6c7"
                },
                {
                  filterType: "f2",
                  date: "Forecast 2",
                  value: calcs[chart.calcs.F2](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#ffc000"
                }
              ],
              singleLegendMode: true
            }
          ]
          break;
        }
        case "F_GtP_1": {
          series = [        
            {
              name: "%Gt vs Per-1",
              type: "line",
              dots: true,
              labels: true,
              styles: {
                strokeWidth: "2px",
                color: "#94a3de"
              },
              data: currentYearIndexesActuals.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: 100 * calcs[chart.calcs.GtP_1](chart, { value: preparedData[i].value, valuePrev_1: preparedData[i - 1].value })
              })),
              domain: previousYearIndexes.map(i => dateFormat(preparedData[i].date))
            }
          ]
          break;
        }
        default:
      }

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    } 

    // epi
    else if (["PLP"].includes(chart.id)) {
      const ids = [selectedId];
      if (chart.number) {
        tableData.slice(0, chart.number).forEach(d => (d.id !== selectedId) && ids.push(d.id))
      }
    
      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd['market']) ? all_dates.indexOf(dateEnd['market']) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1;
        const datesFirstPrev = datesLastPrev - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {}
          }
  
          chart.columns.forEach(col => {
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
          });
          chart.marketColumns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }
    // epi
    else if (["0PLP"].includes(chart.id)) {
      const ids = tableData.filter(d => d.launchDate !== '0').slice(0, chart.number).map(d => d.id);    
      const filteredValue = ids.map(id => getDataItem(value, id));  
  
      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd['market']) ? all_dates.indexOf(dateEnd['market']) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(
        all_dates.indexOf(d3.min(d3.set(filteredValue.map(d => d.value.launchDate)).values())), 
        _dateEndIndex
      );
  
      const _d = {
        id: chart.id,
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          launchDate: d.value.launchDate,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1;
        const datesFirstPrev = datesLastPrev - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {}
          }
  
          chart.columns.forEach(col => {
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
          });
          chart.marketColumns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }

    // promo
    else if (["SoV_PR", "ASoV_PR"].includes(chart.id)) {
      const categoryGroup = chartControls.promo.selectedCategoryGroup;
      const ids = [selectedId];

      if (chart.number) {
        uniquePromo.slice(0, chart.number).forEach(d => (d !== selectedId) && ids.push(d));
      }

      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        const datesLastPrev = datesLast - chart.prevPeriod;
        const datesFirstPrev = datesLastPrev - period;
      
        // const parsedDate = chart.dateParse(date);

        // calc total
        const total = {}
            , totalPrev = {};
          
        chart.columns.forEach(col => {
          const _col = categoryGroup === "All" ? col : col + ':' + categoryGroup;
          total[col] = getAggrValue(valueTotal, _col, datesFirst, datesLast, period);
          totalPrev[col] = getAggrValue(valueTotal, _col, datesFirstPrev, datesLastPrev, period);
        });
        
        _d.total.push({
            date,
            value: chart.func(chart, {
              value: total,
              total: total,
              valuePrev: totalPrev,
              totalPrev: totalPrev
            })
          }
        );
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {},
            total,
            totalPrev
          }
  
          chart.columns.forEach(col => {
            const _col = categoryGroup === "All" ? col : col + ':' + categoryGroup;
            item.value[col] = getAggrValue(d.value.total, _col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, _col, datesFirstPrev, datesLastPrev, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }

    // forecast
    else if (["AMS_FMS_F"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
      
      const _dateEndIndexMarket = all_dates_market.indexOf(_dates[0]);
      const _datesMarket = all_dates_market.slice(Math.max(_dateEndIndexMarket - 12, 0), _dateEndIndexMarket).concat(_dates);
      
      const _d = {
        id: chart.id,
        series: [
          {
            filterType: "act",
            name: "Actuals MS",
            type: "line",
            styles: {
              color: "#94a3de",
            },
            dots: true,
            data: []
          },
          {
            filterType: "bud",
            name: "Budget MS",
            type: "line",
            styles: {
              color: "#b4ec76",
              strokeDasharray: [4, 3]
            },
            dots: true,
            data: []
          },
          {
            filterType: "f1",
            name: "Forecast 1 MS",
            type: "line",
            styles: {
              color: "#47a6c7",
              strokeDasharray: [2, 2]
            },
            dots: true,
            data: []
          },
          {
            filterType: "f2",
            name: "Forecast 2 MS",
            type: "line",
            styles: {
              color: "#ffc000",
              strokeDasharray: [5, 5]
            },
            dots: true,
            data: []
          }
        ]  
      };
  
      _datesMarket.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1; // market
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1; // forecast
        const datesFirstPrev = datesLastPrev - period;
      
        // calc data and total
        const item = {
          value: {},
          total: {}
        }
        
        // market
        chart.marketColumns.forEach(col => {
          item.total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
        });
        // forecast
        chart.columns.forEach(col => {
          item.total[col] = getAggrValue(valueTotal, col, datesFirstPrev, datesLastPrev, period);
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(date);
  
        const AMS = chart.func({ columns: [chart.marketColumns[0]]}, item);
        const BMS = chart.func({ columns: [chart.columns[0]]}, item);
        const F1MS = chart.func({ columns: [chart.columns[1]]}, item);
        const F2MS = chart.func({ columns: [chart.columns[2]]}, item);

        _d.series[0].data.push({
          date: parsedDate, 
          value: 100 * AMS
        });
        _d.series[1].data.push({
          date: parsedDate, 
          value: 100 * BMS
        });
        _d.series[2].data.push({
          date: parsedDate,
          value: 100 * F1MS
        });
        _d.series[3].data.push({
          date: parsedDate,
          value: 100 * F2MS
        });
      });

      return _d;
    }
    else if (["AS_FS_F"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _dateEndIndexMarket = all_dates_market.indexOf(_dates[0]);
      const _datesMarket = all_dates_market.slice(Math.max(_dateEndIndexMarket - 12, 0), _dateEndIndexMarket).concat(_dates);
      
      const _d = {
        id: chart.id,
        series: [
          {
            filterType: "act",
            name: "Actual Sales",
            type: "line",
            styles: {
              color: "#94a3de",
            },
            dots: true,
            data: []
          },
          {
            filterType: "bud",
            name: "Budget",
            type: "line",
            styles: {
              color: "#b4ec76",
              strokeDasharray: [4, 3]
            },
            dots: true,
            data: []
          },
          {
            filterType: "f1",
            name: "Forecast 1 Sales",
            type: "line",
            styles: {
              color: "#47a6c7",
              strokeDasharray: [2, 2]
            },
            dots: true,
            data: []
          },
          {
            filterType: "f2",
            name: "Forecast 2 Sales",
            type: "line",
            styles: {
              color: "#ffc000",
              strokeDasharray: [5, 5]
            },
            dots: true,
            data: []
          }
        ]  
      };
  
      _datesMarket.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1; // market
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1; // forecast
        const datesFirstPrev = datesLastPrev - period;
      
        // calc data and total
        const item = {
          value: {},
          total: {}
        }
        
        // market
        chart.marketColumns.forEach(col => {
          item.total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
        });
        // forecast
        chart.columns.forEach(col => {
          item.total[col] = getAggrValue(valueTotal, col, datesFirstPrev, datesLastPrev, period);
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(date);
  
        const AMS = chart.func({ columns: [chart.marketColumns[0]]}, item);
        const BMS = chart.func({ columns: [chart.columns[0]]}, item);
        const F1MS = chart.func({ columns: [chart.columns[1]]}, item);
        const F2MS = chart.func({ columns: [chart.columns[2]]}, item);

        _d.series[0].data.push({
          date: parsedDate, 
          value: AMS // AMS
        });
        _d.series[1].data.push({
          date: parsedDate, 
          value: BMS // BMS
        });
        _d.series[2].data.push({
          date: parsedDate,
          value: F1MS // F1MS 
        });
        _d.series[3].data.push({
          date: parsedDate,
          value: F2MS // F2MS 
        });
      });

      return _d;
    }
    else if (["YTD_YTG_F"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      // const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      // const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);


      const _dateEnd = (dateEnd && dateEnd[chart.dataType] && (dateEnd[chart.dataType] <= dateEnd['market'])) ? dateEnd[chart.dataType] : dateEnd['market'];
      const period = chart.dateParse(_dateEnd).getUTCMonth() + 1; // YTD, aggregation period
      const datesLast = all_dates_market.indexOf(_dateEnd) + 1; // market
      const datesFirst = datesLast - period;
      const datesLastPrev = all_dates.indexOf(_dateEnd) + 1; // forecast
      const datesFirstPrev = datesLastPrev - period;
    
      // calc data and total
      const item = {
        value: {},
        valueTotalYear: {}
      }
      
      // market YTD
      chart.marketColumns.forEach(col => {
        // item.total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
        item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
      });
      // forecast YTD
      chart.columns.forEach(col => {
        item.valueTotalYear[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesFirstPrev + 12);
        item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
      });

      // const parsedDate = chart.dateParse(_dateEnd);
      
      const _d = {
        id: chart.id,
        series: [
          {
            name: "YTD",
            type: "bar",
            data: [
              {
                filterType: "act",
                date: "Actuals",
                value: calcs[chart.calcs.YTD]({ columns: [chart.marketColumns[0]]}, item),
                color: "#94a3de"//"#d3d3d3" // lightgray
              },
              {
                filterType: "bud",
                date: "Budget",
                value: calcs[chart.calcs.YTD]({ columns: [chart.columns[0]]}, item),
                color: "#b4ec76"
              },
              {
                filterType: "f1",
                date: "Forecast 1",
                value: calcs[chart.calcs.YTD]({ columns: [chart.columns[1]]}, item),
                color: "#47a6c7"
              },
              {
                filterType: "f2",
                date: "Forecast 2",
                value: calcs[chart.calcs.YTD]({ columns: [chart.columns[2]]}, item),
                color: "#ffc000"
              }
            ],
            singleLegendMode: true
          },
          {
            name: "YTG",
            type: "line",
            styles: {
              color: "#cc6966", // lightgray
              strokeDasharray: [2, 2]
            },
            dots: true,
            data: [
              {
                filterType: "bud",
                date: "Budget",
                value: calcs[chart.calcs.YTG]({ columns: [chart.columns[0], chart.marketColumns[0]]}, item),
                color: "#b4ec76"
              },
              {
                filterType: "f1",
                date: "Forecast 1",
                value: calcs[chart.calcs.YTG]({ columns: [chart.columns[1], chart.marketColumns[0]]}, item),
                color: "#47a6c7"
              },
              {
                filterType: "f2",
                date: "Forecast 2",
                value: calcs[chart.calcs.YTG]({ columns: [chart.columns[2], chart.marketColumns[0]]}, item),
                color: "#ffc000"
              }
            ]
          }
        ],
      }
      return _d;
    }
    else if (["ABF_F"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _d = {
        id: chart.id,
        series: [
          {
            filterType: "bud",
            name: "Actual vs Budget",
            type: "bar",
            color: "#b4ec76", // blue
            data: []
          },
          {
            filterType: "f1",
            name: "Actual vs Forecast 1",
            type: "bar",
            color: "#47a6c7", // lightgray
            data: []
          },
          {
            filterType: "f2",
            name: "Actual vs Forecast 2",
            type: "bar",
            color: "#ffc000", // lightgray
            data: []
          },
          {
            filterType: "bud",
            name: "% Actual vs Budget",
            type: "line",
            styles: {
              color: "#b4ec76",
            },
            dots: true,
            data: [],
            secondYAxis: true
          },
          {
            filterType: "f1",
            name: "% Actual vs Forecast 1",
            type: "line",
            styles: {
              color: "#47a6c7", // lightgray
            },
            dots: true,
            data: [],
            secondYAxis: true
          },
          {
            filterType: "f2",
            name: "% Actual vs Forecast 2",
            type: "line",
            styles: {
              color: "#ffc000", // lightgray
            },
            dots: true,
            data: [],
            secondYAxis: true
          }
        ]  
      };
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1; // market
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1; // forecast
        const datesFirstPrev = datesLastPrev - period;
      
        // calc data and total
        const item = {
          value: {},
          total: {}
        }
        
        // market
        chart.marketColumns.forEach(col => {
          item.total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
        });
        // forecast
        chart.columns.forEach(col => {
          item.total[col] = getAggrValue(valueTotal, col, datesFirstPrev, datesLastPrev, period);
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(date);
  
        const AMS = chart.func({ columns: [chart.marketColumns[0]]}, item);
        const BMS = chart.func({ columns: [chart.columns[0]]}, item);
        const AF1 = chart.func({ columns: [chart.columns[1]]}, item);
        const AF2 = chart.func({ columns: [chart.columns[2]]}, item);

        _d.series[0].data.push({
          date: parsedDate, 
          value: AMS ? AMS - BMS : 0 // AMS - BMS
        });
        _d.series[1].data.push({
          date: parsedDate, 
          value: AMS ? AMS - AF1 : 0 // AMS - FMS
        });
        _d.series[2].data.push({
          date: parsedDate, 
          value: AMS ? AMS - AF2 : 0 // AMS - FMS
        });
        _d.series[3].data.push({
          date: parsedDate, 
          value: (AMS && BMS) ? 100 * (AMS / BMS - 1) : 0 // AMS - FMS
        });
        _d.series[4].data.push({
          date: parsedDate, 
          value: (AMS && AF1) ? 100 * (AMS / AF1 - 1) : 0 // AMS - FMS
        });
        _d.series[5].data.push({
          date: parsedDate, 
          value: (AMS && AF2) ? 100 * (AMS / AF2 - 1) : 0
        });
      });

      return _d;
    }

    // patients
    else if ([
      "MS_T_PT", "MS_T1_PT", "MS_T2_PT",
      "MS_T_N_PT", "MS_T1_N_PT", "MS_T2_N_PT",
      "MS_T_NS_PT", "MS_T1_NS_PT", "MS_T2_NS_PT"
    ].includes(chart.id)) {
      const ids = [selectedId];

      if (chart.number) {
        uniquePatient.slice(0, chart.number).forEach(d => (d !== selectedId) && ids.push(d));
      }
    
      const filteredValue = ids.map(id => getDataItem(value, id)); 
      // const filteredValueTotal = {};
      // chart.columns.forEach(column => {
      //   if (['UN_PT'].includes(column)) {
      //     chart.patientType.forEach(pt => {
      //       chart.dynamicCleaned && chart.dynamicCleaned.forEach(dc => {
      //         const col = pt + ':' + dc;
      //         filteredValueTotal[col] = filteredValue[0].value.total[col].map(d => 0);
      //         filteredValue.forEach(d => d.value.total[col].forEach((p, i) => filteredValueTotal[col][i] += p));
      //       });
      //       const col = pt;
      //       filteredValueTotal[col] = filteredValue[0].value.total[col].map(d => 0);
      //       filteredValue.forEach(d => d.value.total[col].forEach((p, i) => filteredValueTotal[col][i] += p));
      //     })
      //   }
      // });

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total: {}
          }
  
          chart.columns.forEach(column => {
            if (['UN_PT'].includes(column)) {
              chart.patientType.forEach(pt => {
                chart.dynamicCleaned && chart.dynamicCleaned.forEach(dc => {
                  const col = pt + ':' + dc;
                  item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
                  item.total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
                  // item.total[col] = getAggrValue(filteredValueTotal, col, datesFirst, datesLast, period);
                });
                const col = pt;
                item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
                item.total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
                // item.total[col] = getAggrValue(filteredValueTotal, col, datesFirst, datesLast, period);
              })
            }
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }

    // patients
    else if ([
      "MS_T_NSA_PT", "MS_T1_NSA_PT", "MS_T2_NSA_PT"
    ].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        series: [
          {
            name: "New Patients",
            type: "bar",
            color: "#008000",
            data: [],
            calc: (NP, SP, AP) => NP
          },
          {
            name: "Switch Patients",
            type: "bar",
            color: "#90ee90",
            data: [],
            calc: (NP, SP, AP) => SP
          },
          {
            name: "Add on Patients",
            type: "bar",
            color: "#ffff00",
            data: [],
            calc: (NP, SP, AP) => AP
          },
          {
            name: "% New Patients",
            type: "line",
            styles: {
              color: "008000",
              strokeWidth: "1.5px",
              strokeDasharray: "6, 3"
            },
            dots: true,
            data: [],
            calc: (NP, SP, AP) => (NP + SP + AP) > 0 ? 100 * NP / (NP + SP + AP) : 0,
            secondYAxis: true
          },
          {
            name: "% Switch Patients",
            type: "line",
            styles: {
              color: "90ee90",
              strokeWidth: "1.5px",
              strokeDasharray: "6, 3"
            },
            dots: true,
            data: [],
            calc: (NP, SP, AP) => (NP + SP + AP) > 0 ? 100 * SP / (NP + SP + AP) : 0,
            secondYAxis: true
          },
          {
            name: "% Add on Patients",
            type: "line",
            styles: {
              color: "ffff00",
              strokeWidth: "1.5px",
              strokeDasharray: "6, 3"
            },
            dots: true,
            data: [],
            calc: (NP, SP, AP) => (NP + SP + AP) > 0 ? 100 * AP / (NP + SP + AP) : 0,
            secondYAxis: true
          }
        ]  
      };
  
      const { patientType: [ PT ], dynamicCleaned: [ N, S, A ] } = chart;

      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        // calc data and total
        const item = {
          value: {}
        }
        
        chart.columns.forEach(column => {
          if (['UN_PT'].includes(column)) {
            chart.patientType.forEach(pt => {
              chart.dynamicCleaned && chart.dynamicCleaned.forEach(dc => {
                const col = pt + ':' + dc;
                item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
              });
              const col = pt;
              item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
            })
          }
        });

        const parsedDate = chart.dateParse(date);

        const NP = item.value[PT + ':' + N];
        const SP = item.value[PT + ':' + S];
        const AP = item.value[PT + ':' + A];

        _d.series.forEach(d => d.data.push({
          date: parsedDate, 
          value: d.calc(NP, SP, AP)
        }))
      });

      // check if all zeros
      if (Math.abs(d3.sum(_d.series, c => d3.sum(c.data, d => d.value))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      return _d;
    }

    else if (["MS_T_ADC_PT"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        series: chart.dynamicCleaned ? chart.dynamicCleaned.map((DC, i) => ({
          name: DC +  " Patients",
          type: "bar",
          color: colors[i],
          data: [],
          calc: P => P
        })) : [{}] 
      };
  
      const { patientType: [ PT ] } = chart;

      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        // calc data and total
        const item = {
          value: {}
        }
        
        chart.columns.forEach(column => {
          if (['UN_PT'].includes(column)) {
            chart.patientType.forEach(pt => {
              chart.dynamicCleaned && chart.dynamicCleaned.forEach(dc => {
                const col = pt + ':' + dc;
                item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
              });
              const col = pt;
              item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
            })
          }
        });

        const parsedDate = chart.dateParse(date);

        chart.dynamicCleaned.forEach((DC, i) => {
          const P = item.value[PT + ':' + DC];
          _d.series[i].data.push({
            date: parsedDate, 
            value: _d.series[i].calc(P)
          })
        })
      });

      // check if all zeros
      if (Math.abs(d3.sum(_d.series, c => d3.sum(c.data, d => d.value))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      return _d;
    }

    // patients_weekly
    else if ([
      "MS_T_PT_WK", "MS_T1_PT_WK", "MS_T2_PT_WK",
      "MS_T_N_PT_WK", "MS_T1_N_PT_WK", "MS_T2_N_PT_WK",
      "MS_T_NS_PT_WK", "MS_T1_NS_PT_WK", "MS_T2_NS_PT_WK"
    ].includes(chart.id)) {
      const ids = [selectedId];

      if (chart.number) {
        tableData.filter(d => uniquePatientWeekly.includes(d.id)).slice(0, chart.number).forEach(d => (d.id !== selectedId) && ids.push(d.id))
      }
    
      const filteredValue = ids.map(id => getDataItem(value, id)); 

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total: {}
          }
  
          chart.columns.forEach(column => {
            if (['UN_PT_WK'].includes(column)) {
              chart.patientType.forEach(pt => {
                chart.dynamicCleaned && chart.dynamicCleaned.forEach(dc => {
                  const col = pt + ':' + dc;
                  item.value[col] = getAggrValue(d.value.total, col + '_WK', datesFirst, datesLast, period);
                  item.total[col] = getAggrValue(valueTotal, col + '_WK', datesFirst, datesLast, period);
                });
                const col = pt;
                item.value[col] = getAggrValue(d.value.total, col + '_WK', datesFirst, datesLast, period);
                item.total[col] = getAggrValue(valueTotal, col + '_WK', datesFirst, datesLast, period);
              })
            }
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }

    // patients_weekly
    else if ([
      "MS_T_NSA_PT_WK", "MS_T1_NSA_PT_WK", "MS_T2_NSA_PT_WK"
    ].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        series: [
          {
            name: "New Patients",
            type: "bar",
            color: "#008000",
            data: [],
            calc: (NP, SP, AP) => NP
          },
          {
            name: "Switch Patients",
            type: "bar",
            color: "#90ee90",
            data: [],
            calc: (NP, SP, AP) => SP
          },
          {
            name: "Add on Patients",
            type: "bar",
            color: "#ffff00",
            data: [],
            calc: (NP, SP, AP) => AP
          },
          {
            name: "% New Patients",
            type: "line",
            styles: {
              color: "008000",
              strokeWidth: "1.5px",
              strokeDasharray: "6, 3"
            },
            dots: true,
            data: [],
            calc: (NP, SP, AP) => (NP + SP + AP) > 0 ? 100 * NP / (NP + SP + AP) : 0,
            secondYAxis: true
          },
          {
            name: "% Switch Patients",
            type: "line",
            styles: {
              color: "90ee90",
              strokeWidth: "1.5px",
              strokeDasharray: "6, 3"
            },
            dots: true,
            data: [],
            calc: (NP, SP, AP) => (NP + SP + AP) > 0 ? 100 * SP / (NP + SP + AP) : 0,
            secondYAxis: true
          },
          {
            name: "% Add on Patients",
            type: "line",
            styles: {
              color: "ffff00",
              strokeWidth: "1.5px",
              strokeDasharray: "6, 3"
            },
            dots: true,
            data: [],
            calc: (NP, SP, AP) => (NP + SP + AP) > 0 ? 100 * AP / (NP + SP + AP) : 0,
            secondYAxis: true
          }
        ]  
      };
  
      const { patientType: [ PT ], dynamicCleaned: [ N, S, A ] } = chart;

      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        // calc data and total
        const item = {
          value: {}
        }
        
        chart.columns.forEach(column => {
          if (['UN_PT_WK'].includes(column)) {
            chart.patientType.forEach(pt => {
              chart.dynamicCleaned && chart.dynamicCleaned.forEach(dc => {
                const col = pt + ':' + dc;
                item.value[col] = getAggrValue(filteredValue.value.total, col + '_WK', datesFirst, datesLast, period);
              });
              const col = pt;
              item.value[col] = getAggrValue(filteredValue.value.total, col + '_WK', datesFirst, datesLast, period);
            })
          }
        });

        //const parsedDate = chart.dateParse(date);

        const NP = item.value[PT + ':' + N];
        const SP = item.value[PT + ':' + S];
        const AP = item.value[PT + ':' + A];

        _d.series.forEach(d => d.data.push({
          date, //: parsedDate, 
          value: d.calc(NP, SP, AP)
        }))
      });

      // check if all zeros
      if (Math.abs(d3.sum(_d.series, c => d3.sum(c.data, d => d.value))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      return _d;
    }

    // ATU Share of drug
    else if ([ "SoD_AT", "SoD_Prev_AT" ].includes(chart.id)) {  
      if (!valueATU_Share.length) return {
        id: chart.id,
        series: [{data: []}]
      };

      const all_dates = subsetMap[chart.dataType].dates;
      const dateIndex = all_dates.indexOf(chart.date);

      const contributors = Object.keys(valueATU_Share[0].value);
      const _d = {
        id: chart.id,
        series: contributors.map(cont => {
          const d = {
            name: cont,
            type: 'bar',
            data: valueATU_Share.map(det => {
              if (det.value[cont]) {
                const total = d3.sum(contributors, c => (det.value[c] && det.value[c][dateIndex]) || 0);
                return {
                  date: det.key,
                  value: det.value[cont][dateIndex] ? 100 * det.value[cont][dateIndex] / total : 0
                }
              } else {
                return {
                  date: det.key,
                  value: 0
                }
              }
            })
          };
          return d;
        })
      };
      return _d;
    }

     // ATU
    else if ([ "AoT_AT", "AoT_Prev_AT" ].includes(chart.id)) {
      const all_dates = subsetMap[chart.dataType].dates;
      const dateIndex = all_dates.indexOf(chart.date);

      const stype = ["AIDED", "UNAIDED"]
      const _d = {
        id: chart.id,
        series: [
          {
            name: "New Patients",
            type: "bar",
            colors: ['#47a6c7', '#ff9600'],
            data: valueATU_Aware.map(c => {
              const d = {
                name: c.key
              };
              
              d.value = c.value[stype[0]] ? 100 * c.value[stype[0]]['NoRes_AT'][dateIndex] / c.value[stype[0]]['ToRes_AT'][dateIndex] : 100
              d.innerValue = c.value[stype[1]] ? 100 * c.value[stype[1]]['NoRes_AT'][dateIndex] / c.value[stype[1]]['ToRes_AT'][dateIndex] : 1;

              return d;
            }),
            // data: [
            //   {
            //     name: 'Qwe',
            //     value: 66,
            //     innerValue: 25
            //   },
            //   {
            //     name: 'Asd',
            //     value: 26,
            //     innerValue: 12
            //   },
            //   {
            //     name: 'Zxc',
            //     value: 50,
            //     innerValue: 5
            //   }
            // ],
            legend: stype.map(d => d.toUpperCase())
          }
        ]  
      };

      return _d;
    }
    
    // Finance Accounts
    else if ([ "NR_FA" ].includes(chart.id)) {
      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _d = {
        id: chart.id,
        series: chart.columns.map((c, i) => ({
          col: c,
          key: chart.names[i],
          data: []
        }))
      };
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        _d.series.forEach(s => s.data.push({
          date, 
          value: getAggrValue(valueTotal, s.col, datesFirst, datesLast, period)
        }))
      });

      return _d;
    }

    // siso
    else if (["NG_MG_10_SISO"].includes(chart.id)) {
      if (!sisoValue) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      // const sortedValues = sisoValue.sort((a, b) => b.value.total[chart.marketColumns[0]].slice(-1)[0] - a.value.total[chart.marketColumns[0]].slice(-1)[0])
      // const sortedValues = sisoValue.sort((a, b) => b.value.total[chart.marketColumns[0]].slice(-1)[0] - a.value.total[chart.marketColumns[0]].slice(-1)[0])
      // const filteredValues = sisoValue.slice(0, chart.number)//.map(d => getDataItem(sisoValue, sisoValue[0].key));  

      let filteredValues = [];
      tableData.forEach(d => {
        if (filteredValues.length < chart.number) {
          const item = sisoValue.find(s => s.key === d.key);
          if (item) filteredValues.push(item);
        }
      });
      if (!filteredValues.length) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEnd = (dateEnd && dateEnd[chart.dataType] && (dateEnd[chart.dataType] <= dateEnd['market'])) ? dateEnd[chart.dataType] : dateEnd['market'];

      const _d = {
        id: chart.id,
        series: [
          {
            name: "Net Sales Growth",
            type: "bar",
            color: "#47a6c7", // lightgray
            data: []
          },
          {
            name: "Market Sales Growth",
            type: "bar",
            color: "#b4ec76", // blue
            data: []
          },
          {
            name: "Delta Growth vs Market Growth",
            type: "line",
            styles: {
              color: "#94a3de", // lightgray
            },
            dots: true,
            data: [],
            secondYAxis: true
          }
        ]  
      };
  
      filteredValues.forEach(filteredValue => {
        // calc data and total
        const item = {
          value: {},
          valuePrev: {}
        }

        const period = chart.aggr.period === 9 ? (_dateEnd ? chart.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        
        let datesLast = all_dates_market.indexOf(_dateEnd) + 1; // market
        let datesFirst = datesLast - period;
        let datesLastPrev = datesLast - chart.prevPeriod;
        let datesFirstPrev = datesLastPrev - period;
        
        // market
        chart.marketColumns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        datesLast = all_dates.indexOf(_dateEnd) + 1; // net
        datesFirst = datesLast - period;
        datesLastPrev = datesLast - chart.prevPeriod;
        datesFirstPrev = datesLastPrev - period;
        
        // net
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });
  
        const NG = chart.func({ columns: [chart.columns[0]]}, item);
        const MG = chart.func({ columns: [chart.marketColumns[0]]}, item);

        _d.series[0].data.push({
          date: filteredValue.key, 
          value: NG ? 100 * NG : 0 // Net
        });
        _d.series[1].data.push({
          date: filteredValue.key, 
          value: MG ? 100 * MG : 0 // Market
        });
        _d.series[2].data.push({
          date: filteredValue.key, 
          value: 100 * (NG - MG)
        });
      });

      return _d;
    }
    // siso
    else if (["NG_MG_SISO"].includes(chart.id)) {
      if (!sisoValue) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }
      
      const keys = sisoValue.map(d => d.key);
      if (!keys.includes(selectedId)) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }
      
      const filteredValue = getDataItem(sisoValue, selectedId);  
      
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _d = {
        id: chart.id,
        series: [
          {
            name: "Net Sales Growth",
            type: "bar",
            color: "#47a6c7", // lightgray
            data: []
          },
          {
            name: "Market Sales Growth",
            type: "bar",
            color: "#b4ec76", // blue
            data: []
          },
          {
            name: "Delta Growth vs Market Growth",
            type: "line",
            styles: {
              color: "#94a3de", // lightgray
            },
            dots: true,
            data: [],
            secondYAxis: true
          }
        ]  
      };
  
      _dates.forEach((date, i) => {
        // calc data and total
        const item = {
          value: {},
          valuePrev: {}
        }

        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        
        let datesLast = all_dates_market.indexOf(date) + 1; // market
        let datesFirst = datesLast - period;
        let datesLastPrev = datesLast - chart.prevPeriod;
        let datesFirstPrev = datesLastPrev - period;
        
        // market
        chart.marketColumns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        datesLast = all_dates.indexOf(date) + 1; // net
        datesFirst = datesLast - period;
        datesLastPrev = datesLast - chart.prevPeriod;
        datesFirstPrev = datesLastPrev - period;
        
        // net
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(date);
  
        const NG = chart.func({ columns: [chart.columns[0]]}, item);
        const MG = chart.func({ columns: [chart.marketColumns[0]]}, item);

        _d.series[0].data.push({
          date: parsedDate, 
          value: NG ? 100 * NG : 0 // Net
        });
        _d.series[1].data.push({
          date: parsedDate, 
          value: MG ? 100 * MG : 0 // Market
        });
        _d.series[2].data.push({
          date: parsedDate, 
          value: 100 * (NG - MG)
        });
      });

      return _d;
    }

    // eog
    else if (["EOG"].includes(chart.id)) {
      if (!value) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }
      const ids = tableData.slice(0, chart.number).map(d => d.id);    
      const filteredValues = ids.map(id => getDataItem(value, id));  
  
      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEnd = (dateEnd && dateEnd[chart.dataType]) ? dateEnd[chart.dataType] : dateEnd['market'];

      const _d = {
        id: chart.id,
        series: [
          {
            name: "Launch",
            type: "bar",
            color: "#94a3de",
            data: []
          },
          {
            name: "Price",
            type: "bar",
            color: "#47a6c7",
            data: []
          },
          {
            name: "Volume",
            type: "bar",
            color: "#ffc000",
            data: []
          },
          {
            name: "Mix",
            type: "bar",
            color: "#cc66a4",
            data: []
          }
        ]  
      };
  
      filteredValues.forEach(filteredValue => {
        // calc data and total
        const item = {
          value: {},
          valuePrev: {}
        }

        const period = chart.aggr.period === 9 ? (_dateEnd ? chart.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        
        let datesLast = all_dates.indexOf(_dateEnd) + 1; // market
        let datesFirst = datesLast - period;
        let datesLastPrev = datesLast - chart.prevPeriod;
        let datesFirstPrev = datesLastPrev - period;
        
        // market
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });
  
        const LE = calcs[chart.calcs.Eff]({ columns: [chart.columns[3], chart.columns[0]]}, item);
        const PE = calcs[chart.calcs.Eff]({ columns: [chart.columns[2], chart.columns[0]]}, item);
        const VE = calcs[chart.calcs.MG]({ columns: [chart.columns[1]]}, item);
        const TE = calcs[chart.calcs.MG]({ columns: [chart.columns[0]]}, item);
        const ME = TE - (LE + PE + VE);
        const key = filteredValue.key + ` (${numeral(TE).format("0.0%")})`;

        _d.series[0].data.push({ date: key, value: LE });
        _d.series[1].data.push({ date: key, value: PE });
        _d.series[2].data.push({ date: key, value: VE });
        _d.series[3].data.push({ date: key, value: ME });
      });

      return _d;
    }

    // MSE_Comp
    else if (["MSE_Comp"].includes(chart.id)) {
      if (!chartControls["MSE_Comp"].selectedItem1 || !chartControls["MSE_Comp"].selectedItem2) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const ids = [chartControls["MSE_Comp"].selectedItem1, chartControls["MSE_Comp"].selectedItem2];

      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        series: [
          {
            key: filteredValue[0].key,
            name: filteredValue[0].key,
            type: "line",
            styles: {
              color: "#47a6c7"
            },
            dots: true,
            data: []
          },
          {
            key: filteredValue[1].key,
            name: filteredValue[1].key,
            type: "line",
            styles: {
              color: "#ffc000"
            },
            dots: true,
            data: []
          },
          {
            key: "Difference",
            name: "Difference",
            type: "bar",
            color: "#94a3de",
            data: []
          }
        ]
      };
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        
        const datesLastPrev = datesLast - chart.prevPeriod;
        const datesFirstPrev = datesLastPrev - period;
        
        const parsedDate = chart.dateParse(date);

        // calc data
        let temp = [];
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {},
            total: {},
            totalPrev: {}
          }
  
          chart.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
            item.total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
            item.totalPrev[col] = getAggrValue(valueTotal, col, datesFirstPrev, datesLastPrev, period);
          });
  
          const res = chart.func(chart, item);
          temp.push(res);

          _d.series[i].data.push({
            date: parsedDate,
            value: res
          });
        });

        _d.series[2].data.push({
          date: parsedDate,
          value: Math.abs(temp[1] - temp[0])
        });
      });

      return _d;
    }

    // SIT
    else if (["SIT_STOCK_SISO_VOL", "SIT_STOCK_SISO_VAL"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = all_dates.slice(0, _dateEndIndex);

      // check if not enough back data
      const dateFormat = d3.utcFormat("%B");

      // time aggregation period
      let period = chart.aggr.period;

      const preparedData = _dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
      
        chart.columns.forEach(col => {
          _d.value[col] = getAggrValue(filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1));
        });
        return _d;
      });

      let series = [
        {
          name: "SI",
          type: "bar",
          color: "#d3d3d3",
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.SI](chart, d)
          }))
        },
        {
          name: "SO",
          type: "bar",
          color: "#94a3de",
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.SO](chart, d)
          }))
        },
        {
          name: "Stock",
          type: "line",
          dots: true,
          styles: {
            strokeWidth: "2px",
            color: "#b4ec76"
          },
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.Stock](chart, d)
          }))
        }
      ];


      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }
    // SIT
    else if (["SIT_STOCK_COVERAGE"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = all_dates.slice(0, _dateEndIndex);

      // check if not enough back data
      const dateFormat = d3.utcFormat("%B");

      // time aggregation period
      let period = chart.aggr.period;

      const preparedData = _dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
      
        chart.columns.forEach(col => {
          _d.value[col] = (chart.customValue && chart.customValue[col]) 
            ? calcs[chart.customValue[col]](filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1), period)
            : getAggrValue(filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1), period);
        });
        return _d;
      });

      let series = [        
        {
          name: "Stock Coverage [Days]",
          type: "line",
          dots: true,
          styles: {
            strokeWidth: "2px",
            color: "#47a6c7"
          },
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.Coverage_Vol](chart, d)
          }))
        },
        // {
        //   name: "Stock Coverage in Value",
        //   type: "line",
        //   dots: true,
        //   styles: {
        //     strokeWidth: "2px",
        //     color: "#ffc000"
        //   },
        //   data: preparedData.map(d => ({
        //     date: dateFormat(d.date),
        //     value: calcs[chart.calcs.Coverage_Val](chart, d)
        //   }))
        // }
      ]

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

    // SFE
    else if ([
      "CPA_ES_SFE",
      "PC_ES_SFE",
      "C_ES_SFE",
      "CT_ES_SFE",
      "CV_ES_SFE",
      "Cvg_ES_SFE",
      "F_ES_SFE"
    ].includes(chart.id)) {
      const filteredValue = getDataItem(valueSFE_charts, selectedId);

      // check if all zeros
      if (!Object.keys(filteredValue).length) {//(Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;//.map(date => chart.dateParse(date));
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(0, _dateEndIndex);
  
      // const dateFormat = d3.utcFormat("%B");

      let series = filteredValue.value.children && filteredValue.value.children.filter(d => chart.id === "Cvg_ES_SFE" ? d.key !== "NA" : true).sort((a, b) => 
        (a.key === "NA") 
          ? 1 
          : ((b.key === "NA") 
              ? -1 
              : ((a.key > b.key) ? 1 : -1)
            )
      ).map(d => ({
        key: d.key,
        type: "line",
        dots: true,
        styles: {
          strokeWidth: "2px",
          // color: "#b4ec76"
        },
        data: _dates.map((date, i) => ({
          date, //: dateFormat(date),
          value: chart.func(chart, { value: d.value.total }, i)
        }))
      }));

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

    // CRM
    else if ([
      "CPA_ES_CRM",
      "PC_ES_CRM",
      "C_ES_CRM",
      "CT_ES_CRM",
      "CV_ES_CRM",
      "Cvg_ES_CRM",
      "F_ES_CRM"
    ].includes(chart.id)) {
      const { selectedChartsBy, displayType, selectedChannel, selectedSegment } = chartControls.crm;
      const isSegment = selectedChartsBy === "Segment";
      const all_dates = subsetMap[chart.dataType].dates;

      const selectedIdSplit = selectedId.split(':');

      const filteredValue = d3nest()
        .key(d => d[isSegment ? "Customer Segment" : "Channel Type"])
        .rollup(leaves => {
          const initObj = {};
          chart.columns.forEach(col => {
            initObj[col] = all_dates.map(d => 0);
          });

          return leaves.reduce((acc, cur) => {
            chart.columns.forEach(col => {
              if (cur[col]) {
                cur[col].forEach((d, i) => acc[col][i] += +d || 0);
              }
            });
            return acc;
          }, initObj);
        })
        .entries(dataCrm.filter(d => 
          d[dims[0]] === selectedIdSplit[0] && (selectedIdSplit.length > 1 ? d[dims[1]] === selectedIdSplit[1] : true) &&
          // d["Product Group 1"] === "SARCLISA" &&
          d["HCP"] === displayType &&
          selectedChannel.includes(d["Channel Type"]) &&
          selectedSegment.includes(d["Customer Segment"])
        ))

      // check if all zeros
      if (!filteredValue.length) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      // const all_dates = subsetMap[chart.dataType].dates;//.map(date => chart.dateParse(date));
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(0, _dateEndIndex);

      let series = filteredValue.map(d => ({
        key: d.key,
        type: "line",
        dots: true,
        styles: {
          strokeWidth: "2px",
          // color: "#b4ec76"
        },
        data: _dates.map((date, i) => ({
          date, //: dateFormat(date),
          value: chart.func(chart, d, i)
        }))
      }))
      //.filter(d => d3.sum(d.data, p => Math.abs(p.value)) > 0.0000001);

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

    // sob
    else if (["MN_SOB"].includes(chart.id)) {
      const { selectedPType, selectedAge, selectedDynamic } = chartControls.sob;

      let ids = [selectedId];

      if (chart.number) {
        uniqueSob.slice(0, chart.number).forEach(d => (d !== selectedId) && ids.push(d));
      }

      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;

        const col = selectedPType + ':' + selectedAge + ':' + selectedDynamic;

        // calc total
        const total = {};
        total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);  
        
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total
          }
  
          item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
  
          _d.series[i].data.push({
            date,
            value: chart.func({ col: col }, item)
          });
        });
      });
  
      return _d;
    }

    else if (["PS_SOB"].includes(chart.id)) {
      const { selectedPType, selectedAge, selectedDynamic } = chartControls.sob;

      const all_dates = subsetMap[chart.dataType].dates;

      const selectedIdSplit = selectedId.split(':');

      const filteredValue = d3nest()
        .key(d => d["Partner"])
        .rollup(leaves => {
          const initObj = {};
          chart.columns.forEach(col => {
            initObj[col] = all_dates.map(d => 0);
          });

          return leaves.reduce((acc, cur) => {
            chart.columns.forEach(col => {
              if (cur[col]) {
                cur[col].forEach((d, i) => acc[col][i] += +d || 0);
              }
            });
            return acc;
          }, initObj);
        })
        .entries(dataSob.filter(d =>
          d[dims[0]] === selectedIdSplit[0] && (selectedIdSplit.length > 1 ? d[dims[1]] === selectedIdSplit[1] : true) &&
          d["Dynamic_Cleaned"] === selectedDynamic &&
          d["Patient_Type"] === selectedPType &&
          d["Age_Group"] === selectedAge))

      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.key, 
          key: d.key,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        const col = chart.columns[0];

        let total = { [col]: 0 };
        filteredValue.forEach(d => total[col] += getAggrValue(d.value, col, datesFirst, datesLast, period));

        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total
          }
  
          item.value[col] = getAggrValue(d.value, col, datesFirst, datesLast, period);

          _d.series[i].data.push({
            date,
            value: chart.func({ col }, item)
          });
        });
      });
  
      return _d;
    }

    else if (["PC_SOB", "PPC_SOB"].includes(chart.id)) {
      const { selectedPType, selectedAge } = chartControls.sob;

      const all_dates = subsetMap[chart.dataType].dates;

      const selectedIdSplit = selectedId.split(':');

      const filteredValue = d3nest()
        .key(d => d["Dynamic_Cleaned"])
        .rollup(leaves => {
          const initObj = {};
          chart.columns.forEach(col => {
            initObj[col] = all_dates.map(d => 0);
          });

          return leaves.reduce((acc, cur) => {
            chart.columns.forEach(col => {
              if (cur[col]) {
                cur[col].forEach((d, i) => acc[col][i] += +d || 0);
              }
            });
            return acc;
          }, initObj);
        })
        .entries(dataSob.filter(d => 
          d[dims[0]] === selectedIdSplit[0] && (selectedIdSplit.length > 1 ? d[dims[1]] === selectedIdSplit[1] : true) &&
          chart.ftype.includes(d["Filter_Type"]) &&
          d["Patient_Type"] === selectedPType &&
          d["Age_Group"] === selectedAge))

      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.key, 
          key: d.key,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        const col = chart.columns[0];

        let total = { [col]: 0 };
        filteredValue.forEach(d => total[col] += getAggrValue(d.value, col, datesFirst, datesLast, period));

        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total
          }
  
          item.value[col] = getAggrValue(d.value, col, datesFirst, datesLast, period);

          _d.series[i].data.push({
            date,
            value: chart.func({ col }, item)
          });
        });
      });
  
      return _d;
    }

    else if (["NS_SOB"].includes(chart.id)) {
      const { selectedPType, age, selectedDynamic } = chartControls.sob;

      const filteredValue = getDataItem(value, selectedId);  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      age.filter(d => d !== 'All ages').forEach(d => {
        const series = { 
          id: filteredValue.value.id + d, 
          key: d,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        
        // calc data
        age.filter(d => d !== 'All ages').forEach((d, i) => {
          const item = {
            value: {},
            total: {}
          }

          const col = selectedPType + ':' + d + ':' + selectedDynamic;
          const col_total_ages = selectedPType + ':' + 'All ages' + ':' + selectedDynamic;
  
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.total[col] = getAggrValue(filteredValue.value.total, col_total_ages, datesFirst, datesLast, period);  
  
          _d.series[i].data.push({
            date,
            value: chart.func({ col: col }, item)
          });
        });
      });
  
      return _d;
    }

    // PEQ
    else if (["PL3SC_PEQ", "PL4SC_PEQ", "L3SC_PEQ", "L4SC_PEQ"].includes(chart.id)) {  
      if (!valuePEQ.length) return {
        id: chart.id,
        series: [{data: []}]
      };

      const all_dates = subsetMap[chart.dataType].dates.map(d => chart.dateParse(d));

      const filteredValue = valuePEQ.find(d => d.key === chart.driver[0]).value;
      const contributors = Object.keys(filteredValue);
      const _d = {
        id: chart.id,
        series: contributors.map(cont => {
          const d = {
            name: cont,
            type: 'bar',
            data: all_dates.map((date, i) => {
              if (filteredValue[cont]) {
                const total = d3.sum(contributors, c => (filteredValue[c] && filteredValue[c][i]) || 0);
                return {
                  date,
                  value: filteredValue[cont][i] ? (chart.format === "percent" ? 100 * filteredValue[cont][i] / total : filteredValue[cont][i]) : 0
                }
              } else {
                return {
                  date,
                  value: 0
                }
              }
            })
          };
          return d;
        })
      };
      return _d;
    }
    
    // all others
    else {

      let ids = [selectedId];

      if (chart.number) {
        if (["MSE_WK", "AS_WK"].includes(chart.id)) {
          ids = uniqueWeekly.slice(0, chart.number);
        } else {
          tableData.slice(0, chart.number).forEach(d => (d.id !== selectedId) && ids.push(d.id))
        }
      }

      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
        
        if (chart.extraCalcs) {
          chart.extraCalcs.forEach(d => series[d.id] = []);
        }
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        // delta market share
        const prevPeriodLast = chart.id === 'DMS' ? all_dates.indexOf(chart.selectedDate) + 1 : -1;
        
        const datesLastPrev = datesLast - (prevPeriodLast > -1 ? datesLast - prevPeriodLast : chart.prevPeriod);
        const datesFirstPrev = datesLastPrev - period;
      
        // calc total
        const total = {}
            , totalPrev = {};
          
        chart.columns.forEach(col => {
          total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
          totalPrev[col] = getAggrValue(valueTotal, col, datesFirstPrev, datesLastPrev, period);
        });
        
        _d.total.push({
            date,
            value: chart.func(chart, {
              value: total,
              total: total,
              valuePrev: totalPrev,
              totalPrev: totalPrev
            })
          }
        );
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {},
            total,
            totalPrev
          }
  
          chart.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
  
          if (chart.extraCalcs) {
            chart.extraCalcs.forEach(d => _d.series[i][d.id].push({
              date,
              value: d.func(chart, item)
            }));
          }
        });
      });
  
      return _d;
    }
    
  }
   
  return charts.map(chart => _calcs(chart))
}

// country & portfolio view
const getCalcChartDataPortfolio = (opts, { 
  value, 
  valueTotal, 
  sisoValue, 
  valueSFE_charts,
  uniqueWeekly,
  uniquePromo,
  uniqueSob,
  dataCrm,
  dataSob
}, tableData) => {
  const {
    dims,
    charts,
    chartControls,
    subsetMap,
    selectedId,
    isLatestPeriod
  } = opts;

  // const uniqueSobPartner = d3.set(dataSob.filter(d => d["Partner"] === selectedId), d => d[dims[0]]).values();

  const _calcs = (chart) => {
    const dateEnd = chart.dateEnd ? ({ [chart.dataType]: chart.dateEnd }) : opts.dateEnd

    if (!tableData.length && !["MSE_WK", "AS_WK"].includes(chart.id)) {
      return {
        id: chart.id,
        series: [{data: []}]
      }
    }

    // launch
    if (["MSE_L", "AS_L", "F_AS_L"].includes(chart.id)) {
      const ids = tableData.filter(d => d.launchDate !== '0').slice(0, chart.number).map(d => d.id);    
      const filteredValue = ids.map(id => getDataItem(value, id));  
  
      const all_dates = subsetMap[chart.dataType].dates;
      const _dates = chart.dates || all_dates.slice(
        all_dates.indexOf(d3.min(d3.set(filteredValue.map(d => d.value.launchDate)).values())), 
        dateEnd ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length
      );
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => _d.series.push({ 
        id: d.value.id, 
        key: d.value.id.split(':').pop(),
        launchDate: d.value.launchDate,
        data: [] 
      }));
      
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total: {}
          }
  
          chart.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);            
            
            // //if((d.value.market_dim && d.value.market_value && d.value.country_value) || (d.value.country_dim && d.value.country_value)) {
            // item.total[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirst, datesLast, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirst, datesLast, period);
            // //}
            item.total[col] = getAggrValue(d.value.total, col + '_total', datesFirst, datesLast, period);
          });

          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    } 

    // finance
    else if (["F_AT_M", "F_AT_T", "F_GtP_1"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => isPeq(c) ? d3.sum(filteredValue.value.total[c] + filteredValue.value.total[c + '_Sanofi']) : d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      // const currentYear = (new Date()).getUTCFullYear();
      const currentYear = chart.dateParse(dateEnd[chart.dataType]).getUTCFullYear();
      let all_dates = [];
      let shift = 0; // fill dates with zeros if not enough back data

      const dates = subsetMap[chart.dataType].dates;
      // const _dateEndIndex = dateEnd ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      // const _dates = chart.dates || all_dates.slice(_dateEndIndex - chart.backData.period, _dateEndIndex);
  
      // check if not enough back data
      const _date = new Date(currentYear - 1, 0);
      if (chart.dateFormat(_date) < dates[0]) {
        all_dates = chart.dateInterval.range(_date, chart.dateParse(dates[0])).map(d => chart.dateFormat(d));
        shift = all_dates.length;
      }
      all_dates = all_dates.concat(dates);
      const all_dates_actuals = all_dates.slice(0, all_dates.indexOf(dateEnd[chart.dataType]) + 1);
      const dateFormat = d3.utcFormat("%B");

      function getAllIndexes(arr, val) {
        let indexes = [], i;
        for (i = 0; i < arr.length; i++)
            if (arr[i] === val)
                indexes.push(i);
        return indexes;
      }

      const currentYearIndexes = getAllIndexes(all_dates.map(d => chart.dateParse(d).getUTCFullYear()), currentYear);
      const currentYearIndexesActuals = getAllIndexes(all_dates_actuals.map(d => chart.dateParse(d).getUTCFullYear()), currentYear);
      const previousYearIndexes = getAllIndexes(all_dates.map(d => chart.dateParse(d).getUTCFullYear()), currentYear - 1);

      // time aggregation period
      let period = chart.aggr.period;
      const YTD = period === 9;

      const preparedData = all_dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
        if (YTD) {
          period = _d.date.getUTCMonth() + 1;
        }
        chart.columns.forEach(col => 
          _d.value[col] = getAggrValue(filteredValue.value.total, col, Math.max(0, i - period + 1 - shift), Math.max(0, i + 1 - shift))
        );
        return _d;
      });

      let series = [];
      switch(chart.id) {
        case "F_AT_M": {
          series = [
            {
              filterType: "act-1",
              name: "Actuals - " + (currentYear - 1),
              type: "bar",
              color: "#d3d3d3", // lightgray
              data: previousYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.A](chart, preparedData[i]) // 0 - actuals calculation
              }))
            },
            {
              filterType: "act",
              name: "Actuals - " + currentYear,
              type: "bar",
              color: "#94a3de",
              data: currentYearIndexesActuals.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.A](chart, preparedData[i]) // 0 - actuals calculation
              }))
            },
            {
              filterType: "bud",
              name: "Budget",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#b4ec76"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.B](chart, preparedData[i]) // 1 - budget calculation
              }))
            },
            {
              filterType: "f1",
              name: "Forecast 1",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#47a6c7"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.F1](chart, preparedData[i])
              }))
            },
            {
              filterType: "f2",
              name: "Forecast 2",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#ffc000"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.F2](chart, preparedData[i])
              }))
            }
          ]
          break;
        }
        case "F_AT_T": {
          series = [
            {
              name: "Actuals",
              type: "bar",
              data: [
                {
                  filterType: "act-1",
                  date: "Actuals - " + (currentYear - 1),
                  value: calcs[chart.calcs.A](chart, preparedData[previousYearIndexes[currentYearIndexesActuals.length - 1]]),
                  color: "#d3d3d3" // lightgray
                },
                {
                  filterType: "act",
                  date: "Actuals - " + (currentYear),
                  value: calcs[chart.calcs.A](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#94a3de"
                },
                {
                  filterType: "bud",
                  date: "Budget",
                  value: calcs[chart.calcs.B](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#b4ec76"
                },
                {
                  filterType: "f1",
                  date: "Forecast 1",
                  value: calcs[chart.calcs.F1](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#47a6c7"
                },
                {
                  filterType: "f2",
                  date: "Forecast 2",
                  value: calcs[chart.calcs.F2](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#ffc000"
                }
              ],
              singleLegendMode: true
            }
          ]
          break;
        }
        case "F_GtP_1": {
          series = [        
            {
              name: "%Gt vs Per-1",
              type: "line",
              dots: true,
              labels: true,
              styles: {
                strokeWidth: "2px",
                color: "#94a3de"
              },
              data: currentYearIndexesActuals.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: 100 * calcs[chart.calcs.GtP_1](chart, { value: preparedData[i].value, valuePrev_1: preparedData[i - 1].value })
              })),
              domain: previousYearIndexes.map(i => dateFormat(preparedData[i].date))
            }
          ]
          break;
        }
        default:
      }

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    } 

    // epi
    else if (["PLP"].includes(chart.id)) {
      const ids = [selectedId];
      if (chart.number) {
        tableData.slice(0, chart.number).forEach(d => (d.id !== selectedId) && ids.push(d.id))
      }
    
      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd['market']) ? all_dates.indexOf(dateEnd['market']) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1;
        const datesFirstPrev = datesLastPrev - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {}
          }
  
          chart.columns.forEach(col => {
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
          });
          chart.marketColumns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }
    // epi
    else if (["0PLP"].includes(chart.id)) {
      const ids = tableData.filter(d => d.launchDate !== '0').slice(0, chart.number).map(d => d.id);    
      const filteredValue = ids.map(id => getDataItem(value, id));  
  
      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd['market']) ? all_dates.indexOf(dateEnd['market']) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(
        all_dates.indexOf(d3.min(d3.set(filteredValue.map(d => d.value.launchDate)).values())), 
        _dateEndIndex
      );
  
      const _d = {
        id: chart.id,
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          launchDate: d.value.launchDate,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1;
        const datesFirstPrev = datesLastPrev - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {}
          }
  
          chart.columns.forEach(col => {
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
          });
          chart.marketColumns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }

    // promo
    else if (["SoV_PR", "ASoV_PR"].includes(chart.id)) {
      const categoryGroup = chartControls.promo.selectedCategoryGroup;
      const ids = [selectedId];

      if (chart.number) {
        uniquePromo.slice(0, chart.number).forEach(d => (d !== selectedId) && ids.push(d));
      }

      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        const datesLastPrev = datesLast - chart.prevPeriod;
        const datesFirstPrev = datesLastPrev - period;
      
        // const parsedDate = chart.dateParse(date);
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {},
            total: {},
            totalPrev: {}
          }
  
          chart.columns.forEach(col => {
            const _col = categoryGroup === "All" ? col : col + ':' + categoryGroup;
            item.value[col] = getAggrValue(d.value.total, _col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, _col, datesFirstPrev, datesLastPrev, period);

            // item.total[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], _col, datesFirst, datesLast, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], _col, datesFirst, datesLast, period);

            // item.totalPrev[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], _col, datesFirstPrev, datesLastPrev, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], _col, datesFirstPrev, datesLastPrev, period);

            item.total[col] = getAggrValue(d.value.total, _col + '_total', datesFirst, datesLast, period);
            item.totalPrev[col] = getAggrValue(d.value.total, _col + '_total', datesFirstPrev, datesLastPrev, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }

    // forecast
    else if (["AMS_FMS_F"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId); 
      
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _dateEndIndexMarket = all_dates_market.indexOf(_dates[0]);
      const _datesMarket = all_dates_market.slice(Math.max(_dateEndIndexMarket - 12, 0), _dateEndIndexMarket).concat(_dates);
      
      const _d = {
        id: chart.id,
        series: [
          {
            filterType: "act",
            name: "Actual MS",
            type: "line",
            styles: {
              color: "#94a3de",
            },
            dots: true,
            data: []
          },
          {
            filterType: "bud",
            name: "Budget MS",
            type: "line",
            styles: {
              color: "#b4ec76",
              strokeDasharray: [4, 3]
            },
            dots: true,
            data: []
          },
          {
            filterType: "f1",
            name: "Forecast 1 MS",
            type: "line",
            styles: {
              color: "#47a6c7",
              strokeDasharray: [2, 2]
            },
            dots: true,
            data: []
          },
          {
            filterType: "f2",
            name: "Forecast 2 MS",
            type: "line",
            styles: {
              color: "#ffc000",
              strokeDasharray: [5, 5]
            },
            dots: true,
            data: []
          }
        ]  
      };
  
      _datesMarket.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1; // market
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1; // forecast
        const datesFirstPrev = datesLastPrev - period;
      
        // calc data and total
        const item = {
          value: {},
          total: {}
        }
        
        // market
        chart.marketColumns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          
          // item.total[col] = (filteredValue.value.market_dim && filteredValue.value.market_value)
          //     ? getAggrValue(valueTotal[filteredValue.value.market_dim][filteredValue.value.country_value][filteredValue.value.market_value], col, datesFirst, datesLast, period)
          //     : getAggrValue(valueTotal[filteredValue.value.country_dim][filteredValue.value.country_value], col, datesFirst, datesLast, period);
          
          item.total[col] = getAggrValue(filteredValue.value.total, col + '_total', datesFirst, datesLast, period);
        });
        // forecast
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
          
          // item.total[col] = (filteredValue.value.market_dim && filteredValue.value.market_value)
          //     ? getAggrValue(valueTotal[filteredValue.value.market_dim][filteredValue.value.country_value][filteredValue.value.market_value], col, datesFirstPrev, datesLastPrev, period)
          //     : getAggrValue(valueTotal[filteredValue.value.country_dim][filteredValue.value.country_value], col, datesFirstPrev, datesLastPrev, period);

          item.total[col] = getAggrValue(filteredValue.value.total, col + '_total', datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(date);

        const AMS = chart.func({ columns: [chart.marketColumns[0]]}, item);
        const BMS = chart.func({ columns: [chart.columns[0]]}, item);
        const F1MS = chart.func({ columns: [chart.columns[1]]}, item);
        const F2MS = chart.func({ columns: [chart.columns[2]]}, item);

        _d.series[0].data.push({
          date: parsedDate, 
          value: 100 * AMS
        });
        _d.series[1].data.push({
          date: parsedDate, 
          value: 100 * BMS
        });
        _d.series[2].data.push({
          date: parsedDate,
          value: 100 * F1MS
        });
        _d.series[3].data.push({
          date: parsedDate,
          value: 100 * F2MS
        });
      });

      return _d;
    }
    else if (["AS_FS_F"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId); 
      
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _dateEndIndexMarket = all_dates_market.indexOf(_dates[0]);
      const _datesMarket = all_dates_market.slice(Math.max(_dateEndIndexMarket - 12, 0), _dateEndIndexMarket).concat(_dates);
      
      const _d = {
        id: chart.id,
        series: [
          {
            filterType: "act",
            name: "Actual Sales",
            type: "line",
            styles: {
              color: "#94a3de",
            },
            dots: true,
            data: []
          },
          {
            filterType: "bud",
            name: "Budget",
            type: "line",
            styles: {
              color: "#b4ec76",
              strokeDasharray: [4, 3]
            },
            dots: true,
            data: []
          },
          {
            filterType: "f1",
            name: "Forecast 1 Sales",
            type: "line",
            styles: {
              color: "#47a6c7",
              strokeDasharray: [2, 2]
            },
            dots: true,
            data: []
          },
          {
            filterType: "f2",
            name: "Forecast 2 Sales",
            type: "line",
            styles: {
              color: "#ffc000",
              strokeDasharray: [5, 5]
            },
            dots: true,
            data: []
          }
        ]  
      };
  
      _datesMarket.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1; // market
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1; // forecast
        const datesFirstPrev = datesLastPrev - period;
      
        // calc data and total
        const item = {
          value: {},
          total: {}
        }
        
        // market
        chart.marketColumns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          
          // item.total[col] = (filteredValue.value.market_dim && filteredValue.value.market_value)
          //     ? getAggrValue(valueTotal[filteredValue.value.market_dim][filteredValue.value.country_value][filteredValue.value.market_value], col, datesFirst, datesLast, period)
          //     : getAggrValue(valueTotal[filteredValue.value.country_dim][filteredValue.value.country_value], col, datesFirst, datesLast, period);

          item.total[col] = getAggrValue(filteredValue.value.total, col + '_total', datesFirst, datesLast, period);
        });
        // forecast
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
          
          // item.total[col] = (filteredValue.value.market_dim && filteredValue.value.market_value)
          //     ? getAggrValue(valueTotal[filteredValue.value.market_dim][filteredValue.value.country_value][filteredValue.value.market_value], col, datesFirstPrev, datesLastPrev, period)
          //     : getAggrValue(valueTotal[filteredValue.value.country_dim][filteredValue.value.country_value], col, datesFirstPrev, datesLastPrev, period);

          item.total[col] = getAggrValue(filteredValue.value.total, col + '_total', datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(date);
  
        const AMS = chart.func({ columns: [chart.marketColumns[0]]}, item);
        const BMS = chart.func({ columns: [chart.columns[0]]}, item);
        const F1MS = chart.func({ columns: [chart.columns[1]]}, item);
        const F2MS = chart.func({ columns: [chart.columns[2]]}, item);

        _d.series[0].data.push({
          date: parsedDate, 
          value: AMS // AMS
        });
        _d.series[1].data.push({
          date: parsedDate, 
          value: BMS // BMS
        });
        _d.series[2].data.push({
          date: parsedDate,
          value: F1MS // F1MS 
        });
        _d.series[3].data.push({
          date: parsedDate,
          value: F2MS // F2MS 
        });
      });

      return _d;
    }
    else if (["YTD_YTG_F"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);  
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      // const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      // const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);


      const _dateEnd = (dateEnd && dateEnd[chart.dataType] && (dateEnd[chart.dataType] <= dateEnd['market'])) ? dateEnd[chart.dataType] : dateEnd['market'];
      const period = chart.dateParse(_dateEnd).getUTCMonth() + 1; // YTD, aggregation period
      const datesLast = all_dates_market.indexOf(_dateEnd) + 1; // market
      const datesFirst = datesLast - period;
      const datesLastPrev = all_dates.indexOf(_dateEnd) + 1; // forecast
      const datesFirstPrev = datesLastPrev - period;
    
      // calc data and total
      const item = {
        value: {},
        valueTotalYear: {}
      }
      
      // market YTD
      chart.marketColumns.forEach(col => {
        // item.total[col] = getAggrValue(valueTotal, col, datesFirst, datesLast, period);
        item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
      });
      // forecast YTD
      chart.columns.forEach(col => {
        item.valueTotalYear[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesFirstPrev + 12);
        item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
      });

      // const parsedDate = chart.dateParse(_dateEnd);
      
      const _d = {
        id: chart.id,
        series: [
          {
            name: "YTD",
            type: "bar",
            data: [
              {
                filterType: "act",
                date: "Actuals",
                value: calcs[chart.calcs.YTD]({ columns: [chart.marketColumns[0]]}, item),
                color: "#94a3de"//"#d3d3d3" // lightgray
              },
              {
                filterType: "bud",
                date: "Budget",
                value: calcs[chart.calcs.YTD]({ columns: [chart.columns[0]]}, item),
                color: "#b4ec76"
              },
              {
                filterType: "f1",
                date: "Forecast 1",
                value: calcs[chart.calcs.YTD]({ columns: [chart.columns[1]]}, item),
                color: "#47a6c7"
              },
              {
                filterType: "f2",
                date: "Forecast 2",
                value: calcs[chart.calcs.YTD]({ columns: [chart.columns[2]]}, item),
                color: "#ffc000"
              }
            ],
            singleLegendMode: true
          },
          {
            name: "YTG",
            type: "line",
            styles: {
              color: "#cc6966", // lightgray
              strokeDasharray: [2, 2]
            },
            dots: true,
            data: [
              {
                filterType: "bud",
                date: "Budget",
                value: calcs[chart.calcs.YTG]({ columns: [chart.columns[0], chart.marketColumns[0]]}, item),
                color: "#b4ec76"
              },
              {
                filterType: "f1",
                date: "Forecast 1",
                value: calcs[chart.calcs.YTG]({ columns: [chart.columns[1], chart.marketColumns[0]]}, item),
                color: "#47a6c7"
              },
              {
                filterType: "f2",
                date: "Forecast 2",
                value: calcs[chart.calcs.YTG]({ columns: [chart.columns[2], chart.marketColumns[0]]}, item),
                color: "#ffc000"
              }
            ]
          }
        ],
      }
      return _d;
    }
    else if (["ABF_F"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId); 
      
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _d = {
        id: chart.id,
        series: [
          {
            filterType: "bud",
            name: "Actual vs Budget",
            type: "bar",
            color: "#b4ec76", // blue
            data: []
          },
          {
            filterType: "f1",
            name: "Actual vs Forecast 1",
            type: "bar",
            color: "#47a6c7", // lightgray
            data: []
          },
          {
            filterType: "f2",
            name: "Actual vs Forecast 2",
            type: "bar",
            color: "#ffc000", // lightgray
            data: []
          },
          {
            filterType: "bud",
            name: "% Actual vs Budget",
            type: "line",
            styles: {
              color: "#b4ec76",
            },
            dots: true,
            data: [],
            secondYAxis: true
          },
          {
            filterType: "f1",
            name: "% Actual vs Forecast 1",
            type: "line",
            styles: {
              color: "#47a6c7", // lightgray
            },
            dots: true,
            data: [],
            secondYAxis: true
          },
          {
            filterType: "f2",
            name: "% Actual vs Forecast 2",
            type: "line",
            styles: {
              color: "#ffc000", // lightgray
            },
            dots: true,
            data: [],
            secondYAxis: true
          }
        ] 
      };
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1; // market
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1; // forecast
        const datesFirstPrev = datesLastPrev - period;
      
        // calc data and total
        const item = {
          value: {},
          total: {}
        }
        
        // market
        chart.marketColumns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          
          // item.total[col] = (filteredValue.value.market_dim && filteredValue.value.market_value)
          //     ? getAggrValue(valueTotal[filteredValue.value.market_dim][filteredValue.value.country_value][filteredValue.value.market_value], col, datesFirst, datesLast, period)
          //     : getAggrValue(valueTotal[filteredValue.value.country_dim][filteredValue.value.country_value], col, datesFirst, datesLast, period);

          item.total[col] = getAggrValue(filteredValue.value.total, col + '_total', datesFirst, datesLast, period);
        });
        // forecast
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
          
          // item.total[col] = (filteredValue.value.market_dim && filteredValue.value.market_value)
          //     ? getAggrValue(valueTotal[filteredValue.value.market_dim][filteredValue.value.country_value][filteredValue.value.market_value], col, datesFirstPrev, datesLastPrev, period)
          //     : getAggrValue(valueTotal[filteredValue.value.country_dim][filteredValue.value.country_value], col, datesFirstPrev, datesLastPrev, period);

          item.total[col] = getAggrValue(filteredValue.value.total, col + '_total', datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(date);
  
        const AMS = chart.func({ columns: [chart.marketColumns[0]]}, item);
        const BMS = chart.func({ columns: [chart.columns[0]]}, item);
        const AF1 = chart.func({ columns: [chart.columns[1]]}, item);
        const AF2 = chart.func({ columns: [chart.columns[2]]}, item);

        _d.series[0].data.push({
          date: parsedDate, 
          value: AMS ? AMS - BMS : 0 // AMS - BMS
        });
        _d.series[1].data.push({
          date: parsedDate, 
          value: AMS ? AMS - AF1 : 0 // AMS - FMS
        });
        _d.series[2].data.push({
          date: parsedDate, 
          value: AMS ? AMS - AF2 : 0 // AMS - FMS
        });
        _d.series[3].data.push({
          date: parsedDate, 
          value: (AMS && BMS) ? 100 * (AMS / BMS - 1) : 0 // AMS - FMS
        });
        _d.series[4].data.push({
          date: parsedDate, 
          value: (AMS && AF1) ? 100 * (AMS / AF1 - 1) : 0 // AMS - FMS
        });
        _d.series[5].data.push({
          date: parsedDate, 
          value: (AMS && AF2) ? 100 * (AMS / AF2 - 1) : 0
        });
      });

      return _d;
    }

    // atu + finance accounts
    else if ([ "SoD_AT", "AoT_AT", "SoD_Prev_AT", "AoT_Prev_AT", "NR_FA" ].includes(chart.id)) {
      return {
        id: chart.id,
        series: [{data: []}]
      }
    }

    // siso
    else if (["NG_MG_10_SISO"].includes(chart.id)) {
      if (!sisoValue) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      // const sortedValues = sisoValue.sort((a, b) => b.value.total[chart.marketColumns[0]].slice(-1)[0] - a.value.total[chart.marketColumns[0]].slice(-1)[0])
      // const sortedValues = sisoValue.sort((a, b) => b.value.total[chart.marketColumns[0]].slice(-1)[0] - a.value.total[chart.marketColumns[0]].slice(-1)[0])
      // const filteredValues = sisoValue.slice(0, chart.number)//.map(d => getDataItem(sisoValue, sisoValue[0].key));  
      
      let filteredValues = [];
      tableData.forEach(d => {
        if (filteredValues.length < chart.number) {
          const item = sisoValue.find(s => s.key === d.key);
          if (item) filteredValues.push(item);
        }
      });
      if (!filteredValues.length) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEnd = (dateEnd && dateEnd[chart.dataType] && (dateEnd[chart.dataType] <= dateEnd['market'])) ? dateEnd[chart.dataType] : dateEnd['market'];

      const _d = {
        id: chart.id,
        series: [
          {
            name: "Net Sales Growth",
            type: "bar",
            color: "#47a6c7", // lightgray
            data: []
          },
          {
            name: "Market Sales Growth",
            type: "bar",
            color: "#b4ec76", // blue
            data: []
          },
          {
            name: "Delta Growth vs Market Growth",
            type: "line",
            styles: {
              color: "#94a3de", // lightgray
            },
            dots: true,
            data: [],
            secondYAxis: true
          }
        ]  
      };
  
      filteredValues.forEach(filteredValue => {
        // calc data and total
        const item = {
          value: {},
          valuePrev: {}
        }

        const period = chart.aggr.period === 9 ? (_dateEnd ? chart.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        
        let datesLast = all_dates_market.indexOf(_dateEnd) + 1; // market
        let datesFirst = datesLast - period;
        let datesLastPrev = datesLast - chart.prevPeriod;
        let datesFirstPrev = datesLastPrev - period;
        
        // market
        chart.marketColumns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        datesLast = all_dates.indexOf(_dateEnd) + 1; // net
        datesFirst = datesLast - period;
        datesLastPrev = datesLast - chart.prevPeriod;
        datesFirstPrev = datesLastPrev - period;
        
        // net
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(_dateEnd);
  
        const NG = chart.func({ columns: [chart.columns[0]]}, item);
        const MG = chart.func({ columns: [chart.marketColumns[0]]}, item);

        _d.series[0].data.push({
          date: filteredValue.key, 
          value: NG ? 100 * NG : 0 // Net
        });
        _d.series[1].data.push({
          date: filteredValue.key, 
          value: MG ? 100 * MG : 0 // Market
        });
        _d.series[2].data.push({
          date: filteredValue.key, 
          value: 100 * (NG - MG)
        });
      });

      return _d;
    }
    // siso
    else if (["NG_MG_SISO"].includes(chart.id)) {
      if (!sisoValue) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }
      
      const keys = sisoValue.map(d => d.key);
      if (!keys.includes(selectedId)) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }
      
      const filteredValue = getDataItem(sisoValue, selectedId);  
      
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);

      const _d = {
        id: chart.id,
        series: [
          {
            name: "Net Sales Growth",
            type: "bar",
            color: "#47a6c7", // lightgray
            data: []
          },
          {
            name: "Market Sales Growth",
            type: "bar",
            color: "#b4ec76", // blue
            data: []
          },
          {
            name: "Delta Growth vs Market Growth",
            type: "line",
            styles: {
              color: "#94a3de", // lightgray
            },
            dots: true,
            data: [],
            secondYAxis: true
          }
        ]  
      };
  
      _dates.forEach((date, i) => {
        // calc data and total
        const item = {
          value: {},
          valuePrev: {}
        }

        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        
        let datesLast = all_dates_market.indexOf(date) + 1; // market
        let datesFirst = datesLast - period;
        let datesLastPrev = datesLast - chart.prevPeriod;
        let datesFirstPrev = datesLastPrev - period;
        
        // market
        chart.marketColumns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        datesLast = all_dates.indexOf(date) + 1; // net
        datesFirst = datesLast - period;
        datesLastPrev = datesLast - chart.prevPeriod;
        datesFirstPrev = datesLastPrev - period;
        
        // net
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });

        const parsedDate = chart.dateParse(date);
  
        const NG = chart.func({ columns: [chart.columns[0]]}, item);
        const MG = chart.func({ columns: [chart.marketColumns[0]]}, item);

        _d.series[0].data.push({
          date: parsedDate, 
          value: NG ? 100 * NG : 0 // Net
        });
        _d.series[1].data.push({
          date: parsedDate, 
          value: MG ? 100 * MG : 0 // Market
        });
        _d.series[2].data.push({
          date: parsedDate, 
          value: 100 * (NG - MG)
        });
      });

      return _d;
    }

    // eog
    else if (["EOG"].includes(chart.id)) {
      if (!value) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }
      const ids = tableData.slice(0, chart.number).map(d => d.id);    
      const filteredValues = ids.map(id => getDataItem(value, id));  
  
      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEnd = (dateEnd && dateEnd[chart.dataType]) ? dateEnd[chart.dataType] : dateEnd['market'];

      const _d = {
        id: chart.id,
        series: [
          {
            name: "Launch",
            type: "bar",
            color: "#94a3de",
            data: []
          },
          {
            name: "Price",
            type: "bar",
            color: "#47a6c7",
            data: []
          },
          {
            name: "Volume",
            type: "bar",
            color: "#ffc000",
            data: []
          },
          {
            name: "Mix",
            type: "bar",
            color: "#cc66a4",
            data: []
          }
        ]  
      };
  
      filteredValues.forEach(filteredValue => {
        // calc data and total
        const item = {
          value: {},
          valuePrev: {}
        }

        const period = chart.aggr.period === 9 ? (_dateEnd ? chart.dateParse(_dateEnd).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        
        let datesLast = all_dates.indexOf(_dateEnd) + 1; // market
        let datesFirst = datesLast - period;
        let datesLastPrev = datesLast - chart.prevPeriod;
        let datesFirstPrev = datesLastPrev - period;
        
        // market
        chart.columns.forEach(col => {
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.valuePrev[col] = getAggrValue(filteredValue.value.total, col, datesFirstPrev, datesLastPrev, period);
        });
  
        const LE = calcs[chart.calcs.Eff]({ columns: [chart.columns[3], chart.columns[0]]}, item);
        const PE = calcs[chart.calcs.Eff]({ columns: [chart.columns[2], chart.columns[0]]}, item);
        const VE = calcs[chart.calcs.MG]({ columns: [chart.columns[1]]}, item);
        const TE = calcs[chart.calcs.MG]({ columns: [chart.columns[0]]}, item);
        const ME = TE - (LE + PE + VE);
        const key = filteredValue.key + ` (${numeral(TE).format("0.0%")})`;

        _d.series[0].data.push({ date: key, value: LE });
        _d.series[1].data.push({ date: key, value: PE });
        _d.series[2].data.push({ date: key, value: VE });
        _d.series[3].data.push({ date: key, value: ME });
      });

      return _d;
    }

    // MSE_Comp
    else if (["MSE_Comp"].includes(chart.id)) {
      if (!chartControls["MSE_Comp"].selectedItem1 || !chartControls["MSE_Comp"].selectedItem2) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const ids = [chartControls["MSE_Comp"].selectedItem1, chartControls["MSE_Comp"].selectedItem2];

      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        series: [
          {
            key: filteredValue[0].key,
            name: filteredValue[0].key,
            type: "line",
            styles: {
              color: "#47a6c7"
            },
            dots: true,
            data: []
          },
          {
            key: filteredValue[1].key,
            name: filteredValue[1].key,
            type: "line",
            styles: {
              color: "#ffc000"
            },
            dots: true,
            data: []
          },
          {
            key: "Difference",
            name: "Difference",
            type: "bar",
            color: "#94a3de",
            data: []
          }
        ]
      };
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        
        const datesLastPrev = datesLast - chart.prevPeriod;
        const datesFirstPrev = datesLastPrev - period;
        
        const parsedDate = chart.dateParse(date);

        // calc data
        let temp = [];
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {},
            total: {},
            totalPrev: {}
          }
  
          chart.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);

            // //if((d.value.market_dim && d.value.market_value && d.value.country_value) || (d.value.country_dim && d.value.country_value)) {
            // item.total[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirst, datesLast, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirst, datesLast, period);

            // item.totalPrev[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirstPrev, datesLastPrev, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirstPrev, datesLastPrev, period);
            // //}

            item.total[col] = getAggrValue(d.value.total, col + '_total', datesFirst, datesLast, period);
            item.totalPrev[col] = getAggrValue(d.value.total, col + '_total', datesFirstPrev, datesLastPrev, period);
          });
  
          const res = chart.func(chart, item);
          temp.push(res);

          _d.series[i].data.push({
            date: parsedDate,
            value: res
          });
        });

        _d.series[2].data.push({
          date: parsedDate,
          value: Math.abs(temp[1] - temp[0])
        });
      });

      return _d;
    }

    // SIT
    else if (["SIT_STOCK_SISO_VOL", "SIT_STOCK_SISO_VAL"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = all_dates.slice(0, _dateEndIndex);

      // check if not enough back data
      const dateFormat = d3.utcFormat("%B");

      // time aggregation period
      let period = chart.aggr.period;

      const preparedData = _dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
      
        chart.columns.forEach(col => {
          _d.value[col] = getAggrValue(filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1));
        });
        return _d;
      });

      let series = [
        {
          name: "SI",
          type: "bar",
          color: "#d3d3d3",
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.SI](chart, d)
          }))
        },
        {
          name: "SO",
          type: "bar",
          color: "#94a3de",
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.SO](chart, d)
          }))
        },
        {
          name: "Stock",
          type: "line",
          dots: true,
          styles: {
            strokeWidth: "2px",
            color: "#b4ec76"
          },
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.Stock](chart, d)
          }))
        }
      ];
      

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }
    // SIT
    else if (["SIT_STOCK_COVERAGE"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = all_dates.slice(0, _dateEndIndex);

      // check if not enough back data
      const dateFormat = d3.utcFormat("%B");

      // time aggregation period
      let period = chart.aggr.period;

      const preparedData = _dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
      
        chart.columns.forEach(col => {
          _d.value[col] = (chart.customValue && chart.customValue[col]) 
            ? calcs[chart.customValue[col]](filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1), period)
            : getAggrValue(filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1), period);
        });
        return _d;
      });

      let series = [        
        {
          name: "Stock Coverage [Days]",
          type: "line",
          dots: true,
          styles: {
            strokeWidth: "2px",
            color: "#47a6c7"
          },
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.Coverage_Vol](chart, d)
          }))
        },
        // {
        //   name: "Stock Coverage in Value",
        //   type: "line",
        //   dots: true,
        //   styles: {
        //     strokeWidth: "2px",
        //     color: "#ffc000"
        //   },
        //   data: preparedData.map(d => ({
        //     date: dateFormat(d.date),
        //     value: calcs[chart.calcs.Coverage_Val](chart, d)
        //   }))
        // }
      ]

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

    // SFE
    else if ([
      "CPA_ES_SFE",
      "PC_ES_SFE",
      "C_ES_SFE",
      "CT_ES_SFE",
      "CV_ES_SFE",
      "Cvg_ES_SFE",
      "F_ES_SFE"
    ].includes(chart.id)) {
      const filteredValue = getDataItem(valueSFE_charts, selectedId);

      // check if all zeros
      if (!Object.keys(filteredValue).length) {//(Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;//.map(date => chart.dateParse(date));
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(0, _dateEndIndex);
  
      // const dateFormat = d3.utcFormat("%B");

      let series = filteredValue.value.children && filteredValue.value.children.filter(d => chart.id === "Cvg_ES_SFE" ? d.key !== "NA" : true).sort((a, b) => 
        (a.key === "NA") 
          ? 1 
          : ((b.key === "NA") 
              ? -1 
              : ((a.key > b.key) ? 1 : -1)
            )
      ).map(d => ({
        key: d.key,
        type: "line",
        dots: true,
        styles: {
          strokeWidth: "2px",
          // color: "#b4ec76"
        },
        data: _dates.map((date, i) => ({
          date, //: dateFormat(date),
          value: chart.func(chart, { value: d.value.total }, i)
        }))
      }));

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

      // CRM
    else if ([
      "CPA_ES_CRM",
      "PC_ES_CRM",
      "C_ES_CRM",
      "CT_ES_CRM",
      "CV_ES_CRM",
      "Cvg_ES_CRM",
      "F_ES_CRM"
    ].includes(chart.id)) {
      const { selectedChartsBy, displayType, selectedChannel, selectedSegment } = chartControls.crm;
      const isSegment = selectedChartsBy === "Segment";
      const all_dates = subsetMap[chart.dataType].dates;

      const selectedIdSplit = selectedId.split(':');

      const filteredValue = d3nest()
        .key(d => d[isSegment ? "Customer Segment" : "Channel Type"])
        .rollup(leaves => {
          const initObj = {};
          chart.columns.forEach(col => {
            initObj[col] = all_dates.map(d => 0);
          });

          return leaves.reduce((acc, cur) => {
            chart.columns.forEach(col => {
              if (cur[col]) {
                cur[col].forEach((d, i) => acc[col][i] += +d || 0);
              }
            });
            return acc;
          }, initObj);
        })
        .entries(dataCrm.filter(d =>
          d[dims[0]] === selectedIdSplit[0] && (selectedIdSplit.length > 1 ? d[dims[1]] === selectedIdSplit[1] : true) &&
          // d["Product Group 1"] === "SARCLISA" &&
          d["HCP"] === displayType &&
          selectedChannel.includes(d["Channel Type"]) &&
          selectedSegment.includes(d["Customer Segment"])
        ))

      // check if all zeros
      if (!filteredValue.length) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      // const all_dates = subsetMap[chart.dataType].dates;//.map(date => chart.dateParse(date));
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(0, _dateEndIndex);

      let series = filteredValue.map(d => ({
        key: d.key,
        type: "line",
        dots: true,
        styles: {
          strokeWidth: "2px",
          // color: "#b4ec76"
        },
        data: _dates.map((date, i) => ({
          date, //: dateFormat(date),
          value: chart.func(chart, d, i)
        }))
      }))
      //.filter(d => d3.sum(d.data, p => Math.abs(p.value)) > 0.0000001);

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

    // SOB
    else if (["MN_SOB"].includes(chart.id)) {
      const { selectedPType, selectedAge, selectedDynamic } = chartControls.sob;
      const ids = [selectedId];

      if (chart.number) {
        uniqueSob.slice(0, chart.number).forEach(d => (d !== selectedId) && ids.push(d));
      }

      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        // const parsedDate = chart.dateParse(date);

        const col = selectedPType + ':' + selectedAge + ':' + selectedDynamic;
        
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total: {}
          }
  
          item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          // item.total[col] = (d.value.market_dim && d.value.market_value)
          //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirst, datesLast, period)
          //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirst, datesLast, period);

          item.total[col] = getAggrValue(d.value.total, col + '_total', datesFirst, datesLast, period);

          _d.series[i].data.push({
            date,
            value: chart.func({ col: col }, item)
          });
        });
      });
  
      return _d;
    }

    else if (["PS_SOB"].includes(chart.id)) {
      const { selectedPType, selectedAge, selectedDynamic } = chartControls.sob;

      const all_dates = subsetMap[chart.dataType].dates;

      const filteredValue = d3nest()
        .key(d => d["Partner"])
        .rollup(leaves => {
          const initObj = {};
          chart.columns.forEach(col => {
            initObj[col] = all_dates.map(d => 0);
          });

          return leaves.reduce((acc, cur) => {
            chart.columns.forEach(col => {
              if (cur[col]) {
                cur[col].forEach((d, i) => acc[col][i] += +d || 0);
              }
            });
            return acc;
          }, initObj);
        })
        .entries(dataSob.filter(d =>
          d["Product Group 1"] === selectedId &&
          d["Dynamic_Cleaned"] === selectedDynamic &&
          d["Patient_Type"] === selectedPType &&
          d["Age_Group"] === selectedAge))

      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.key, 
          key: d.key,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        const col = chart.columns[0];

        let total = { [col]: 0 };
        filteredValue.forEach(d => total[col] += getAggrValue(d.value, col, datesFirst, datesLast, period));

        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total
          }
  
          item.value[col] = getAggrValue(d.value, col, datesFirst, datesLast, period);

          _d.series[i].data.push({
            date,
            value: chart.func({ col }, item)
          });
        });
      });
  
      return _d;
    }

    else if (["PC_SOB", "PPC_SOB"].includes(chart.id)) {
      const { selectedPType, selectedAge } = chartControls.sob;

      const all_dates = subsetMap[chart.dataType].dates;

      const filteredValue = d3nest()
        .key(d => d["Dynamic_Cleaned"])
        .rollup(leaves => {
          const initObj = {};
          chart.columns.forEach(col => {
            initObj[col] = all_dates.map(d => 0);
          });

          return leaves.reduce((acc, cur) => {
            chart.columns.forEach(col => {
              if (cur[col]) {
                cur[col].forEach((d, i) => acc[col][i] += +d || 0);
              }
            });
            return acc;
          }, initObj);
        })
        .entries(dataSob.filter(d =>
          d["Product Group 1"] === selectedId &&
          chart.ftype.includes(d["Filter_Type"]) &&
          d["Patient_Type"] === selectedPType &&
          d["Age_Group"] === selectedAge))

      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.key, 
          key: d.key,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
      
        const col = chart.columns[0];

        let total = { [col]: 0 };
        filteredValue.forEach(d => total[col] += getAggrValue(d.value, col, datesFirst, datesLast, period));

        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            total
          }
  
          item.value[col] = getAggrValue(d.value, col, datesFirst, datesLast, period);

          _d.series[i].data.push({
            date,
            value: chart.func({ col }, item)
          });
        });
      });
  
      return _d;
    }

    else if (["NS_SOB"].includes(chart.id)) {
      const { selectedPType, age, selectedDynamic } = chartControls.sob;

      const filteredValue = getDataItem(value, selectedId);  

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      age.filter(d => d !== 'All ages').forEach(d => {
        const series = { 
          id: filteredValue.value.id + d, 
          key: d,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        
        // calc data
        age.filter(d => d !== 'All ages').forEach((d, i) => {
          const item = {
            value: {},
            total: {}
          }

          const col = selectedPType + ':' + d + ':' + selectedDynamic;
          const col_total_ages = selectedPType + ':' + 'All ages' + ':' + selectedDynamic;
  
          item.value[col] = getAggrValue(filteredValue.value.total, col, datesFirst, datesLast, period);
          item.total[col] = getAggrValue(filteredValue.value.total, col_total_ages, datesFirst, datesLast, period);  
  
          _d.series[i].data.push({
            date,
            value: chart.func({ col: col }, item)
          });
        });
      });
  
      return _d;
    }

    // PEQ
    else if (["PL3SC_PEQ", "PL4SC_PEQ", "L3SC_PEQ", "L4SC_PEQ"].includes(chart.id)) {  
    return {
      id: chart.id,
      series: [{data: []}]
    }
    }

    // all others
    else {

      let ids = [selectedId];
      if (chart.number) {
        if (["MSE_WK", "AS_WK"].includes(chart.id)) {
          ids = uniqueWeekly.slice(0, chart.number);
        } else {
          tableData.slice(0, chart.number).forEach(d => (d.id !== selectedId) && ids.push(d.id))
        }
      }
    
      const filteredValue = ids.map(id => getDataItem(value, id));  

      let _dateEnd = dateEnd && dateEnd[chart.dataType]
      if (isLatestPeriod) {
        _dateEnd = d3.max(filteredValue, d => d.dateEnd); // take max date end from all available items, to make all visible on the chart
      }
      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = _dateEnd ? all_dates.indexOf(_dateEnd) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
        
        if (chart.extraCalcs) {
          chart.extraCalcs.forEach(d => series[d.id] = []);
        }
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;

        // delta market share
        const prevPeriodLast = chart.id === 'DMS' ? all_dates.indexOf(chart.selectedDate) + 1 : -1;
        
        const datesLastPrev = datesLast - (prevPeriodLast > -1 ? datesLast - prevPeriodLast : chart.prevPeriod);
        const datesFirstPrev = datesLastPrev - period;
      
        // calc data
        filteredValue.forEach((d, i) => {
          if (isLatestPeriod && d.value.dateEnd < date) return;

          const item = {
            value: {},
            valuePrev: {},
            total: {},
            totalPrev: {}
          }
  
          chart.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);

            // //if((d.value.market_dim && d.value.market_value && d.value.country_value) || (d.value.country_dim && d.value.country_value)) {
            // item.total[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirst, datesLast, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirst, datesLast, period);

            // item.totalPrev[col] = (d.value.market_dim && d.value.market_value)
            //   ? getAggrValue(valueTotal[d.value.market_dim][d.value.country_value][d.value.market_value], col, datesFirstPrev, datesLastPrev, period)
            //   : getAggrValue(valueTotal[d.value.country_dim][d.value.country_value], col, datesFirstPrev, datesLastPrev, period);
            // //}

            item.total[col] = getAggrValue(d.value.total, col + '_total', datesFirst, datesLast, period);
            item.totalPrev[col] = getAggrValue(d.value.total, col + '_total', datesFirstPrev, datesLastPrev, period);
          });

          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
  
          if (chart.extraCalcs) {
            chart.extraCalcs.forEach(d => _d.series[i][d.id].push({
              date,
              value: d.func(chart, item)
            }));
          }
        });
      });
  
      return _d;
    }
    
  }

  return charts.map(chart => _calcs(chart))
}

const getCalcChartDataExecutive = (opts, { 
  value, 
  valueTotal, 
  valueSFE_charts,
  uniqueWeekly,
  dataCrm
}, tableData) => {
  const {
    charts,
    chartControls,
    subsetMap,
    selectedId,
    useDefaultMetrics,
    dims
  } = opts;

  if (!tableData.length) {
    return charts.map(chart => ({
      id: chart.id,
      series: [{data: []}]
    }))
  }

  const useCategory = dims.includes('Category5');
  const useProduct = dims.includes('Product Group 1');

  // find ids
  const splittedId = selectedId && selectedId.split(':');

  // 1st level in the table has id == <Country>
  // 2nd level in the table has id == <Reference Market:Country:Product Portfolio:Product Group 1>
  // if 1st level selectedId => return no data
  // if (!splittedId || splittedId.length < 3 || splittedId.length > 4 || (!useCategory && splittedId.length > 3)) {
  if (!splittedId || splittedId.length < 3) {
    return charts.map(chart => ({
      id: chart.id,
      series: [{data: []}]
    }))
  }
  const country = splittedId[1]; 

  let reference_market = tableData.find(d => d.id === country);
  const pathLength = useProduct ? dims.length - 1 : dims.length;

  for (let i = 2; i <= pathLength; i++) {
    const id = i === pathLength ? splittedId.slice(0, i + 1).join(':') : splittedId[i];
    reference_market = reference_market.children.find(d => d.id === id);
  }
  
  const _ids = reference_market.last_children;
  const default_metric = reference_market.default_metric;
  
  if (!_ids) {
    return charts.map(chart => ({
      id: chart.id,
      series: [{data: []}]
    }))
  }

  const _calcs = (chart) => {
    const dateEnd = chart.dateEnd ? ({ [chart.dataType]: chart.dateEnd }) : opts.dateEnd
    // launch
    if (["MSE_L", "AS_L", "F_AS_L"].includes(chart.id)) {
      const ids = _ids
        .filter(d => d.launchDate !== '0')
        .slice(0, chart.number)
        .map(d => d.id);    

      const filteredValue = ids.map(id => getDataItem(value, id));  
  
      const all_dates = subsetMap[chart.dataType].dates;
      const _dates = chart.dates || all_dates.slice(
        all_dates.indexOf(d3.min(d3.set(filteredValue.map(d => d.value.launchDate)).values())), 
        dateEnd ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length
      );
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => _d.series.push({ 
        id: d.value.id, 
        key: d.value.id.split(':').pop(),
        launchDate: d.value.launchDate,
        data: [] 
      }));
      
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const RM = d.value.id.split(':').shift();

          const item = {
            value: {},
            total: {}
          }

          if (useDefaultMetrics) {
            // chart.columns.forEach(column => {
            const col = default_metric;
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
            item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirst, datesLast, period);
            // });
    
            _d.series[i].data.push({
              date,
              value: chart.func({...chart, columns: [default_metric]}, item)
            });
          } 
          
          else {
            chart.columns.forEach(col => {
              item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
              item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirst, datesLast, period);
            });
    
            _d.series[i].data.push({
              date,
              value: chart.func(chart, item)
            });
          }          
        });
      });
  
      return _d;
    } 
    // finance
    else if (["F_AT_M", "F_AT_T", "F_GtP_1"].includes(chart.id)) {
      
      const filteredValue = getDataItem(value, selectedId);  

      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => isPeq(c) ? d3.sum(filteredValue.value.total[c + ':KP'] + filteredValue.value.total[c + '_Sanofi' + ':KP']) : d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      // const currentYear = (new Date()).getUTCFullYear();
      const currentYear = chart.dateParse(dateEnd[chart.dataType]).getUTCFullYear();
      let all_dates = [];
      let shift = 0; // fill dates with zeros if not enough back data

      const dates = subsetMap[chart.dataType].dates;
      
      // check if not enough back data
      const _date = new Date(currentYear - 1, 0);
      if (chart.dateFormat(_date) < dates[0]) {
        all_dates = chart.dateInterval.range(_date, chart.dateParse(dates[0])).map(d => chart.dateFormat(d));
        shift = all_dates.length;
      }
      all_dates = all_dates.concat(dates);
      const all_dates_actuals = all_dates.slice(0, all_dates.indexOf(dateEnd[chart.dataType]) + 1);
      const dateFormat = d3.utcFormat("%B");

      function getAllIndexes(arr, val) {
        let indexes = [], i;
        for (i = 0; i < arr.length; i++)
            if (arr[i] === val)
                indexes.push(i);
        return indexes;
      }

      const currentYearIndexes = getAllIndexes(all_dates.map(d => chart.dateParse(d).getUTCFullYear()), currentYear);
      const currentYearIndexesActuals = getAllIndexes(all_dates_actuals.map(d => chart.dateParse(d).getUTCFullYear()), currentYear);
      const previousYearIndexes = getAllIndexes(all_dates.map(d => chart.dateParse(d).getUTCFullYear()), currentYear - 1);

      // time aggregation period
      let period = chart.aggr.period;
      const YTD = period === 9;

      const preparedData = all_dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
        if (YTD) {
          period = _d.date.getUTCMonth() + 1;
        }
        chart.columns.forEach(col => 
          _d.value[col] = getAggrValue(filteredValue.value.total, col + ':KP', Math.max(0, i - period + 1 - shift), Math.max(0, i + 1 - shift), true)
        );
        return _d;
      });

      let series = [];
      switch(chart.id) {
        case "F_AT_M": {
          series = [
            {
              filterType: "act-1",
              name: "Actuals - " + (currentYear - 1),
              type: "bar",
              color: "#d3d3d3", // lightgray
              data: previousYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.A](chart, preparedData[i]) // 0 - actuals calculation
              }))
            },
            {
              filterType: "act",
              name: "Actuals - " + currentYear,
              type: "bar",
              color: "#94a3de",
              data: currentYearIndexesActuals.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.A](chart, preparedData[i]) // 0 - actuals calculation
              }))
            },
            {
              filterType: "bud",
              name: "Budget",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#b4ec76"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.B](chart, preparedData[i]) // 1 - budget calculation
              }))
            },
            {
              filterType: "f1",
              name: "Forecast 1",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#47a6c7"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.F1](chart, preparedData[i])
              }))
            },
            {
              filterType: "f2",
              name: "Forecast 2",
              type: "line",
              dots: true,
              styles: {
                strokeWidth: "2px",
                color: "#ffc000"
              },
              data: currentYearIndexes.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: calcs[chart.calcs.F2](chart, preparedData[i])
              }))
            }
          ]
          break;
        }
        case "F_AT_T": {
          series = [
            {
              name: "Actuals",
              type: "bar",
              data: [
                {
                  filterType: "act-1",
                  date: "Actuals - " + (currentYear - 1),
                  value: calcs[chart.calcs.A](chart, preparedData[previousYearIndexes[currentYearIndexesActuals.length - 1]]),
                  color: "#d3d3d3" // lightgray
                },
                {
                  filterType: "act",
                  date: "Actuals - " + (currentYear),
                  value: calcs[chart.calcs.A](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#94a3de"
                },
                {
                  filterType: "bud",
                  date: "Budget",
                  value: calcs[chart.calcs.B](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#b4ec76"
                },
                {
                  filterType: "f1",
                  date: "Forecast 1",
                  value: calcs[chart.calcs.F1](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#47a6c7"
                },
                {
                  filterType: "f2",
                  date: "Forecast 2",
                  value: calcs[chart.calcs.F2](chart, preparedData[currentYearIndexesActuals[currentYearIndexesActuals.length - 1]]),
                  color: "#ffc000"
                }
              ],
              singleLegendMode: true
            }
          ]
          break;
        }
        case "F_GtP_1": {
          series = [        
            {
              name: "%Gt vs Per-1",
              type: "line",
              dots: true,
              labels: true,
              styles: {
                strokeWidth: "2px",
                color: "#94a3de"
              },
              data: currentYearIndexesActuals.map(i => ({
                date: dateFormat(preparedData[i].date),
                value: 100 * calcs[chart.calcs.GtP_1](chart, { value: preparedData[i].value, valuePrev_1: preparedData[i - 1].value })
              })),
              domain: previousYearIndexes.map(i => dateFormat(preparedData[i].date))
            }
          ]
          break;
        }
        default:
      }

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    } 

    else if (["F_AS"].includes(chart.id)) {
      let ids = _ids
        .slice(0, chart.number)
        .map(d => d.id);  
      
      let index = ids.map(d => d.split(':').pop()).indexOf(selectedId.split(':').pop());
      index = index < 0 ? ids.length - 1 : index;
      ids = [...ids.slice(0, index), ...ids.slice(index + 1)]; // remove id with the same key
      ids.unshift(selectedId); // push key product

      const filteredValue = ids.map(id => getDataItem(value, id));

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
        
        if (chart.extraCalcs) {
          chart.extraCalcs.forEach(d => series[d.id] = []);
        }
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        
        // delta market share
        const prevPeriodLast = chart.id === 'DMS' ? all_dates.indexOf(chart.selectedDate) + 1 : -1;
        
        const datesLastPrev = datesLast - (prevPeriodLast > -1 ? datesLast - prevPeriodLast : chart.prevPeriod);
        const datesFirstPrev = datesLastPrev - period;
  
        // calc data
        filteredValue.forEach((d, i) => {

          const item = {
            value: {}
          }
  
          chart.columns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, true);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
  
          if (chart.extraCalcs) {
            chart.extraCalcs.forEach(d => _d.series[i][d.id].push({
              date,
              value: d.func(chart, item)
            }));
          }  
        });
      });
  
      return _d;
    }

    // epi
    else if (["PLP"].includes(chart.id)) {
      const ids = _ids
        .slice(0, chart - 1)
        .map(d => d.id);   
      
      ids.unshift(selectedId);  
    
      const filteredValue = ids.map(id => getDataItem(value, id));  

      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd['market']) ? all_dates.indexOf(dateEnd['market']) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1;
        const datesFirstPrev = datesLastPrev - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {}
          }
  
          chart.columns.forEach(col => {
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period, i === 0);
          });
          chart.marketColumns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, true);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }
    // epi
    else if (["0PLP"].includes(chart.id)) {
      const ids = _ids
        .filter(d => d.launchDate !== '0')
        .slice(0, chart.number)
        .map(d => d.id);    

      const filteredValue = ids.map(id => getDataItem(value, id));  
  
      const all_dates = subsetMap[chart.dataType].dates;
      const all_dates_market = subsetMap['market'].dates;
      // take market dateEnd because we calculate like (IN - PEq) or (Epi - Market)
      const _dateEndIndex = (dateEnd && dateEnd['market']) ? all_dates.indexOf(dateEnd['market']) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(
        all_dates.indexOf(d3.min(d3.set(filteredValue.map(d => d.value.launchDate)).values())), 
        _dateEndIndex
      );
  
      const _d = {
        id: chart.id,
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          launchDate: d.value.launchDate,
          data: [] 
        };
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates_market.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        const datesLastPrev = all_dates.indexOf(date) + 1;
        const datesFirstPrev = datesLastPrev - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const item = {
            value: {},
            valuePrev: {}
          }
  
          chart.columns.forEach(col => {
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period);
          });
          chart.marketColumns.forEach(col => {
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period);
          });
  
          _d.series[i].data.push({
            date,
            value: chart.func(chart, item)
          });
        });
      });
  
      return _d;
    }

    // forecast
    else if (["AMS_FMS_F", "AS_FS_F", "YTD_YTG_F", "ABF_F"].includes(chart.id)) {
      // TODO: fix forecast for executive view if needed
      return {
        id: chart.id,
        series: [{data: []}]
      }
    }

    else if (["EOG"].includes(chart.id)) {
      return {
        id: chart.id,
        series: [{data: []}]
      }
    }

    else if (["MSE_Comp"].includes(chart.id)) {
      return {
        id: chart.id,
        series: [{data: []}]
      }
    }

    // atu
    else if (["SoD_AT", "AoT_AT", "SoD_Prev_AT", "AoT_Prev_AT", "NR_FA"].includes(chart.id)) {
      return {
        id: chart.id,
        series: [{data: []}]
      }
    }

    // SIT
    else if (["SIT_STOCK_SISO_VOL", "SIT_STOCK_SISO_VAL"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = all_dates.slice(0, _dateEndIndex);

      // check if not enough back data
      const dateFormat = d3.utcFormat("%B");

      // time aggregation period
      let period = chart.aggr.period;

      const preparedData = _dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
      
        chart.columns.forEach(col => {
          _d.value[col] = getAggrValue(filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1), true);
        });
        return _d;
      });

      let series = [
        {
          name: "SI",
          type: "bar",
          color: "#d3d3d3",
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.SI](chart, d)
          }))
        },
        {
          name: "SO",
          type: "bar",
          color: "#94a3de",
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.SO](chart, d)
          }))
        },
        {
          name: "Stock",
          type: "line",
          dots: true,
          styles: {
            strokeWidth: "2px",
            color: "#b4ec76"
          },
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.Stock](chart, d)
          }))
        }
      ];
      

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }
    // SIT
    else if (["SIT_STOCK_COVERAGE"].includes(chart.id)) {
      const filteredValue = getDataItem(value, selectedId);
      // check if all zeros
      if (Math.abs(d3.sum(chart.columns, c => d3.sum(filteredValue.value.total[c]))) < .0000001) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = all_dates.slice(0, _dateEndIndex);

      // check if not enough back data
      const dateFormat = d3.utcFormat("%B");

      // time aggregation period
      let period = chart.aggr.period;

      const preparedData = _dates.map((date, i) => {
        const _d = {
          date: chart.dateParse(date),
          value: {}
        };
      
        chart.columns.forEach(col => {
          _d.value[col] = (chart.customValue && chart.customValue[col]) 
            ? calcs[chart.customValue[col]](filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1), period, true)
            : getAggrValue(filteredValue.value.total, col, Math.max(0, i - period + 1), Math.max(0, i + 1), period, true);
        });
        return _d;
      });

      let series = [        
        {
          name: "Stock Coverage [Days]",
          type: "line",
          dots: true,
          // labels: true,
          styles: {
            strokeWidth: "2px",
            color: "#47a6c7"
          },
          data: preparedData.map(d => ({
            date: dateFormat(d.date),
            value: calcs[chart.calcs.Coverage_Vol](chart, d)
          }))
        }
      ]

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

    // SFE
    else if ([
      "CPA_ES_SFE",
      "PC_ES_SFE",
      "C_ES_SFE",
      "CT_ES_SFE",
      "CV_ES_SFE",
      "Cvg_ES_SFE",
      "F_ES_SFE"
    ].includes(chart.id)) {
      
      let filteredValue = getDataItem(valueSFE_charts, selectedId);
      // check if all zeros
      if (!Object.keys(filteredValue).length) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      const all_dates = subsetMap[chart.dataType].dates;//.map(date => chart.dateParse(date));
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(0, _dateEndIndex);
  
      // const dateFormat = d3.utcFormat("%B");

      let series = filteredValue.value.children && filteredValue.value.children.filter(d => chart.id === "Cvg_ES_SFE" ? d.key !== "NA" : true).sort((a, b) => 
        (a.key === "NA") 
          ? 1 
          : ((b.key === "NA") 
              ? -1 
              : ((a.key > b.key) ? 1 : -1)
            )
      ).map(d => ({
        key: d.key,
        type: "line",
        dots: true,
        styles: {
          strokeWidth: "2px",
          // color: "#b4ec76"
        },
        data: _dates.map((date, i) => ({
          date, //: dateFormat(date),
          value: chart.func(chart, { value: d.value.total }, i)
        }))
      }));

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

     // CRM
     else if ([
      "CPA_ES_CRM",
      "PC_ES_CRM",
      "C_ES_CRM",
      "CT_ES_CRM",
      "CV_ES_CRM",
      "Cvg_ES_CRM",
      "F_ES_CRM"
    ].includes(chart.id)) {
      const { selectedChartsBy, displayType, selectedChannel, selectedSegment } = chartControls.crm;
      const isSegment = selectedChartsBy === "Segment";
      const all_dates = subsetMap[chart.dataType].dates;

      const selectedIdSplit = selectedId.split(':');

      const filteredValue = d3nest()
        .key(d => d[isSegment ? "Customer Segment" : "Channel Type"])
        .rollup(leaves => {
          const initObj = {};
          chart.columns.forEach(col => {
            initObj[col] = all_dates.map(d => 0);
          });

          return leaves.reduce((acc, cur) => {
            chart.columns.forEach(col => {
              if (cur[col]) {
                cur[col].forEach((d, i) => acc[col][i] += +d || 0);
              }
            });
            return acc;
          }, initObj);
        })
        .entries(dataCrm.filter(d =>
          d[dims[0]] === selectedIdSplit[0] && (selectedIdSplit.length > 1 ? d[dims[1]] === selectedIdSplit[1] : true) &&
          // d["Product Group 1"] === "SARCLISA" &&
          d["HCP"] === displayType &&
          selectedChannel.includes(d["Channel Type"]) &&
          selectedSegment.includes(d["Customer Segment"])
        ))

      // check if all zeros
      if (!filteredValue.length) {
        return {
          id: chart.id,
          series: [{data: []}]
        }
      }

      // const all_dates = subsetMap[chart.dataType].dates;//.map(date => chart.dateParse(date));
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(0, _dateEndIndex);

      let series = filteredValue.map(d => ({
        key: d.key,
        type: "line",
        dots: true,
        styles: {
          strokeWidth: "2px",
          // color: "#b4ec76"
        },
        data: _dates.map((date, i) => ({
          date, //: dateFormat(date),
          value: chart.func(chart, d, i)
        }))
      }))
      //.filter(d => d3.sum(d.data, p => Math.abs(p.value)) > 0.0000001);

      const _d = {
        id: chart.id,
        series
      };

      return _d 
    }

    // all others
    else {
      let ids = [];
      if (["MSE_WK", "AS_WK"].includes(chart.id)) {
        ids = _ids
          .filter(d => uniqueWeekly.includes(d.key))
          .slice(0, chart.number)
          .map(d => d.id);
      } else {
        ids = _ids
          .slice(0, chart.number)
          .map(d => d.id);  
      }
      
      let index = ids.map(d => d.split(':').pop()).indexOf(selectedId.split(':').pop());
      index = index < 0 ? ids.length - 1 : index;
      ids = [...ids.slice(0, index), ...ids.slice(index + 1)]; // remove id with the same key
      ids.unshift(selectedId); // push key product
    
      const filteredValue = ids.map(id => getDataItem(value, id));

      const all_dates = subsetMap[chart.dataType].dates;
      const _dateEndIndex = (dateEnd && dateEnd[chart.dataType]) ? all_dates.indexOf(dateEnd[chart.dataType]) + 1 : all_dates.length;
      const _dates = chart.dates || all_dates.slice(Math.max(_dateEndIndex - chart.backData.period, 0), _dateEndIndex);
  
      const _d = {
        id: chart.id,
        total: [],
        series: []
      };
      // initialize series
      filteredValue.forEach(d => {
        const series = { 
          id: d.value.id, 
          key: d.value.id.split(':').pop(),
          data: [] 
        };
        
        if (chart.extraCalcs) {
          chart.extraCalcs.forEach(d => series[d.id] = []);
        }
  
        _d.series.push(series);
      });
  
      _dates.forEach((date, i) => {
        const period = chart.aggr.period === 9 ? (date ? chart.dateParse(date).getUTCMonth() + 1 : 0) : chart.aggr.period; // YTD, aggregation period
        const datesLast = all_dates.indexOf(date) + 1;
        const datesFirst = datesLast - period;
        
        // delta market share
        const prevPeriodLast = chart.id === 'DMS' ? all_dates.indexOf(chart.selectedDate) + 1 : -1;
        
        const datesLastPrev = datesLast - (prevPeriodLast > -1 ? datesLast - prevPeriodLast : chart.prevPeriod);
        const datesFirstPrev = datesLastPrev - period;
  
        // calc data
        filteredValue.forEach((d, i) => {
          const RM = d.value.id.split(':').shift();

          const item = {
            value: {},
            valuePrev: {},
            total: {},
            totalPrev: {}
          }
  
          if (useDefaultMetrics && !(["MSE_WK", "AS_WK"].includes(chart.id))) {
            // chart.columns.forEach(column => {
            const col = default_metric;
            item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, i === 0);
            item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period, i === 0);
            item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirst, datesLast, period);
            item.totalPrev[col] = getAggrValue(valueTotal[country][RM], col, datesFirstPrev, datesLastPrev, period);
            // });
    
            _d.series[i].data.push({
              date,
              value: chart.func({...chart, columns: [default_metric]}, item)
            });
    
            if (chart.extraCalcs) {
              chart.extraCalcs.forEach(d => _d.series[i][d.id].push({
                date,
                value: d.func({...chart, columns: [default_metric]}, item)
              }));
            }
          }

          else {
            chart.columns.forEach(col => {
              item.value[col] = getAggrValue(d.value.total, col, datesFirst, datesLast, period, i === 0);
              item.valuePrev[col] = getAggrValue(d.value.total, col, datesFirstPrev, datesLastPrev, period, i === 0);
              item.total[col] = getAggrValue(valueTotal[country][RM], col, datesFirst, datesLast, period);
              item.totalPrev[col] = getAggrValue(valueTotal[country][RM], col, datesFirstPrev, datesLastPrev, period);
            });
    
            _d.series[i].data.push({
              date,
              value: chart.func(chart, item)
            });
    
            if (chart.extraCalcs) {
              chart.extraCalcs.forEach(d => _d.series[i][d.id].push({
                date,
                value: d.func(chart, item)
              }));
            }  
          }
        });
      });
  
      return _d;
    }
    
  }

  return charts.map(chart => _calcs(chart))
}

////////////////////////////////////////////////////////////

const getDataItem = (data, id) => {
  const idx = id.split(':');
  const dive = (data, i) => {
    if (!data) return {};
    const _id = idx.slice(0, i + 1).join(':')
    return (i < idx.length) ? dive(data.value.children.find(d => d.value.id === _id), i + 1) : (data || {});
  };
  return dive(data.find(d => d.value.id === idx[0]), 1)
}

const getDataItemNew = (data, id) => {
  const idx = id.split(':');
  const dive = (data, i) => {
    const _id = idx.slice(0, i + 1).join(':')
    return (i < idx.length) ? dive(data.children.find(d => d.id === _id), i + 1) : (data || {});
  };
  return dive(data.find(d => d.id === idx[0]), 1)
}

const isCountryDim = dim => [
  "Region",
  "Zone",
  "Country"
].includes(dim)

const isMarketDim = dim => [
  "Category5",
  "Category4",
  "Category3",
  "Category2",
  "Category1",
  "Category0"
].includes(dim)

const getAggrValue = (data, column, datesFirst, datesLast, aggregatedPeriod, isKP) => {
  if (datesFirst < 0) return 0;

  // if Key Product - for executive view
  // for PEq we take average for Non-Sanofi and take latest for Sanofi corporations in calculating aggregation
  if (isKP) {
    return isPeq(column) 
      ? Math.round(d3.sum(data[column + ':KP'].slice(datesFirst, datesLast)) / (aggregatedPeriod || 1) + data[column + '_Sanofi' + ':KP'][datesLast - 1])
      : d3.sum(data[column + ':KP'].slice(datesFirst, datesLast))
  } else {
    return isPeq(column) 
      ? (data[column] ? Math.round(d3.sum(data[column].slice(datesFirst, datesLast)) / (aggregatedPeriod || 1) + data[column + '_Sanofi'][datesLast - 1]) : 0)
      : (data[column] ? d3.sum(data[column].slice(datesFirst, datesLast)) : 0)
  }
};

export const isPeq = col => [
  "PEq", "PEq_QRT", 
  "Peq", "Peq_QRT", 
  "PAA", "PAA_QRT", 
  "PAB", "PAB_QRT", 
  "PAF1", "PAF1_QRT", 
  "PAF2", "PAF2_QRT", 
  "PAT", "PAT_QRT"
].includes(col);

const getTableSortId = (metrics) => {
  const firstAggr = metrics[3].controls.filter(calc => calc.active);
  const firstCalc = metrics[2].controls.filter(calc => calc.active)
    .concat(metrics[9].controls.filter(calc => calc.active));
  return (firstAggr.length && firstCalc.length) ? firstAggr[0].name + firstCalc[0].name : null;
}

const getSortTree = (data, sortId, sortOrder) => {
  const sortTree = (data, sortId, sortOrder, type) => {
    data.forEach(d => {
      if (type === 'last_children') {
        if (d.last_children && d.last_children.length) d.last_children = sortTree(d.last_children, sortId, sortOrder, 'last_children');
      } else {
        if (d.children && d.children.length) d.children = sortTree(d.children, sortId, sortOrder);
        if (d.last_children && d.last_children.length) d.last_children = sortTree(d.last_children, sortId, sortOrder, 'last_children');
      }
    });
    
    return data.slice(0).sort((a, b) => {
      if (a.noData) {
        const _a = sortOrder === "desc" ? d3.max(a.children, d => d[sortId]) : d3.min(a.children, d => d[sortId]);
        const _b = sortOrder === "desc" ? d3.max(b.children, d => d[sortId]) : d3.min(b.children, d => d[sortId]);
        return sortOrder === "desc" 
        ? (_a < _b ? 1 : -1) 
        : (_a < _b ? -1 : 1)
      }
      if (a.sortOrder === "asc") {
        return ((a.key||0) < (b.key||0) ? -1 : 1); // for executive view
      }
      return sortOrder === "desc" 
        ? ((a[sortId]||0) < (b[sortId]||0) ? 1 : -1) 
        : ((a[sortId]||0) < (b[sortId]||0) ? -1 : 1)
    })
  }

  return {
    data: data.data,//sortTree(data.data, sortId, sortOrder),
    total: data.total
  }
}

const getEndDate = (state, type, isQuarterly) => {
  let date = state.dateParse(state.dateEnd[type]);
  if (isQuarterly) {
    const month = date.getUTCMonth()
    if (month < 2) {
      date = d3.timeYear.offset(date, -1);
      date.setUTCMonth(11);
    } else if (month < 5) {
      date.setUTCMonth(2);
    } else if (month < 8) {
      date.setUTCMonth(5);
    } else if (month < 11) {
      date.setUTCMonth(8);
    } else if (month === 11) {
      date.setUTCMonth(11);
    }
  }
  return d3.utcFormat("%b %Y")(date)
}

const calculateLastDate = (state) => {
  const checkQuarter = (dateEnd) => {
    // isQuarterly
    if (state.controlsData[3].data[0].controls[1].active) {
      let date = state.dateParse(dateEnd);
      const month = date.getUTCMonth()
      if (month < 2) {
        date = d3.timeYear.offset(date, -1);
        date.setUTCMonth(11);
      } else if (month < 5) {
        date.setUTCMonth(2);
      } else if (month < 8) {
        date.setUTCMonth(5);
      } else if (month < 11) {
        date.setUTCMonth(8);
      } else if (month === 11) {
        date.setUTCMonth(11);
      }

      return state.dateFormat(date)
    }
    return dateEnd;
  }

  const { lastDates } = state.aggrData || {};
  const dataset = state.datasource;
  const lastDate = {};
  if (lastDates) {
    lastDates.forEach(d => lastDate[d.key] = dataset.subsetMap[d.key].dates[d.value]);
  }

  const customDate = state.controlsData[3].data[6].customDate;
  if (customDate && customDate.active) {
    lastDate['market'] = state.dateFormat(customDate.date);
    lastDate['finance'] = state.dateFormat(customDate.date);
    lastDate['sit'] = state.dateFormat(customDate.date);
    lastDate['sfe'] = state.dateFormat(customDate.date);
    lastDate['crm'] = state.dateFormat(customDate.date);
  }

  const market = dataset.subsetMap['market'] && dataset.subsetMap['market'].dates && dataset.subsetMap['market'].dates.slice(-1)[0];
  lastDate['market'] = (market && lastDate['market'] <= market) ? lastDate['market'] : market;

  const finance = dataset.subsetMap['finance'] && dataset.subsetMap['finance'].dates && dataset.subsetMap['finance'].dates.slice(-1)[0];
  lastDate['finance'] = (finance && lastDate['finance'] <= finance) ? lastDate['finance'] : finance;

  // set endDate for epi and promo
  const epi = dataset.subsetMap['epi'] && dataset.subsetMap['epi'].dates && dataset.subsetMap['epi'].dates.slice(-1)[0];
  lastDate['epi'] = (epi && lastDate['market'] <= epi) ? lastDate['market'] : epi;

  const promo = dataset.subsetMap['promo'] && dataset.subsetMap['promo'].dates && dataset.subsetMap['promo'].dates.slice(-1)[0];
  lastDate['promo'] = promo; //(promo && lastDate['market'] <= promo) ? lastDate['market'] : promo;

  // siso
  const siso = dataset.subsetMap['siso'] && dataset.subsetMap['siso'].dates && dataset.subsetMap['siso'].dates.slice(-1)[0];
  lastDate['siso'] = (siso && lastDate['market'] <= siso) ? lastDate['market'] : siso;

  // sit
  const sit = dataset.subsetMap['sit'] && dataset.subsetMap['sit'].dates && dataset.subsetMap['sit'].dates.slice(-1)[0];
  lastDate['sit'] = (sit && lastDate['sit'] <= sit) ? lastDate['sit'] : sit;

  // sfe
  const sfe = dataset.subsetMap['sfe'] && dataset.subsetMap['sfe'].dates && dataset.subsetMap['sfe'].dates.slice(-1)[0];
  lastDate['sfe'] = (sfe && lastDate['sfe'] <= sfe) ? lastDate['sfe'] : sfe;

  // crm
  const crm = dataset.subsetMap['crm'] && dataset.subsetMap['crm'].dates && dataset.subsetMap['crm'].dates.slice(-1)[0];
  lastDate['crm'] = (crm && lastDate['crm'] <= crm) ? lastDate['crm'] : crm;

  // check quarterly
  lastDate['market'] = lastDate['market'] && checkQuarter(lastDate['market']);
  lastDate['finance'] = lastDate['finance'] && checkQuarter(lastDate['finance']);
  lastDate['epi'] = lastDate['epi'] && checkQuarter(lastDate['epi']);
  lastDate['promo'] = lastDate['promo'] && checkQuarter(lastDate['promo']);
  lastDate['siso'] = lastDate['siso'] && checkQuarter(lastDate['siso']);

  // delete patien_weeekly
  delete lastDate['patient_weekly'];
  
  return lastDate;
}


const serializeConfig = (state) => {
  const dataset = state.datasource;
  const controlsData = {};
  
  state.controlsData.forEach(cd => {
    controlsData[cd.name] = {};
    
    cd.data.forEach(d => {
      const active = d.controls.filter(c => c.active)
      if (active.length) controlsData[cd.name][d.name] = active.map(c => c.name)
    })
  })

  // remove dimensions: Metrics -> Dimensions
  delete controlsData[state.controlsData[3].name][state.controlsData[3].data[7].name];

  const res = {
    view: state.view,
    selectedTabId: state.selectedTabId,
    dims: state.dims,
    isTooltip: state.isTooltip,
    isShowRP: state.isShowRP,
    activeTableView: state.activeTableView,
    activeChartView: state.activeChartView,
    // activeCharts: state.charts.map(c => c.activeIndex),
    controlsData,
    customKPIs: dataset.kpis.map(kpi => {
      const d = { ...kpi };
      if (kpi.metric === null) delete d["metric"];
      if (kpi.aggr === null) delete d["aggr"];
      if (kpi.customer_segment === null) delete d["customer_segment"];
      if (kpi.customer_specialty === null) delete d["customer_specialty"];
      return d;
    })
  };

  return res;
}


const updateDimensions = controlsData => {
  let str0 = "", str1 = "", str2 = "";
  controlsData[3].data[7].controls.forEach((d, i) => {
    if (i < 5) {
      // Country dims
      const control = controlsData[0].data.find(p => (p.name === d.name) && (!p.hidden))
      if (control) {
        d.title = str0 + (control.title || control.name);
        str0 += "_";
      } else {
        d.hidden = true;
      }
    } else if (i < 12) {
      // Market dims
      const control = controlsData[1].data.find(p => (p.name === d.name) && (!p.hidden))
      if (control) {
        d.title = str1 + (control.title || control.name);
        str1 += "_";
      } else {
        d.hidden = true;
      }
    } else {
      // Product dims
      const control = controlsData[2].data.find(p => (p.name === d.name) && (!p.hidden))
      if (control) {
        d.title = str2 + (control.title || control.name);
        str2 += "_";
      } else {
        d.hidden = true;
      }
    }
  })
}

const findSelectedId = (state) => {
  let id = null;
  const isCategory5 = state.dims.includes("Category5");

  if (state.view === 'executive') {
    if (state.tableData.data.length && state.tableData.data[0].children.length) {
      id =  state.tableData.data[0].children[0].id;
      if (isCategory5 && state.tableData.data[0].children[0].children.length) {
        id =  state.tableData.data[0].children[0].children[0].id;
      }
    }
  } else {
    id = state.tableData.data.length && state.tableData.data[0].id;
  }
  return id;
}

const getKPIs = (state, controlsData) => {
  const kpis = [];

  const metricDemand = controlsData[3].data[1].controls.find(metric => metric.active);
  const metricFinance = controlsData[3].data[8].controls.find(metric => metric.active);
  const isQuarterly = controlsData[3].data[0].controls.find(d => d.active).name === '1';

  controlsData[3].data[3].controls.filter(aggr => aggr.active).forEach(aggr => {

    // metric demand
    controlsData[3].data[2].controls.filter(calc => calc.active).forEach(calc => {
      const kpi = {
        id: aggr.name + calc.name,
        dataType: 'market',
        isQuarterly,
        columns: calc.type === "eog" ? calc.columns : [metricDemand.name].map(d => d + (isQuarterly ? '_QRT' : '')),
        format: calc.format,
        aggr,
        calcID: calc.calc,
        func: calcs[calc.calc],
        prevPeriod: state.datasource.datasetType === 'quarterly' ? 4 : 12,
        dateParse: state.dateParse
      };

      kpis.push(kpi);
    })

    // metric finance
    if (!controlsData[3].data[9].hidden) {
      controlsData[3].data[9].controls.filter(calc => calc.active).forEach(calc => {
        const kpi = {
          id: aggr.name + calc.name,
          dataType: 'finance',
          isQuarterly,
          columns: metricFinance.cols,//.map(d => d + (isQuarterly ? '_QRT' : '')),
          format: calc.format,
          aggr,
          calcID: calc.calc,
          func: calcs[calc.calc],
          prevPeriod: state.datasource.datasetType === 'quarterly' ? 4 : 12,
          dateParse: state.dateParse
        };
  
        kpis.push(kpi);
      })
    }

    // forecast demand
    if (!controlsData[3].data[10].hidden) {
      controlsData[3].data[10].controls.filter(calc => calc.active).forEach(calc => {
        const kpi = {
          id: aggr.name + calc.name,
          dataType: 'forecast',
          isQuarterly,
          columns: ['UNB_FT', 'UNF1_FT', 'UNF2_FT'].map(d => d + (isQuarterly ? '_QRT' : '')),
          marketColumns: [metricDemand.name].map(d => d + (isQuarterly ? '_QRT' : '')),
          format: calc.format,
          aggr,
          calcID: calc.calc,
          func: calcs[calc.calc],
          dateParse: state.dateParse
        };

        kpis.push(kpi);
      })
    }

    // Boards KPI - SIT
    if (!controlsData[3].data[12].hidden) {
      controlsData[3].data[12].controls.filter(calc => calc.active).forEach(calc => {
          const kpi = {
            id: aggr.name + calc.name,
            dataType: 'sit',
            isQuarterly,
            columns: calc.columns,
            customValue: calc.customValue,
            format: calc.format,
            aggr,
            calcID: calc.calc,
            func: calcs[calc.calc],
            dateParse: state.dateParse
          };
    
          kpis.push(kpi);
      })
    }

  });

  return kpis;
}

const getCustomKPIs = (state, controlsData) => {
  const kpis = [];
  const dataset = state.datasource;
  const metricDemand = controlsData[3].data[1].controls.find(metric => metric.active);

  dataset.kpis.forEach(kpi => {
    const group = dataset.kpiGroups.find(g => g.name === kpi.group);
    if (group) {
      const isQuarterly = typeof kpi.isQuarterly === 'undefined' 
        ? controlsData[3].data[0].controls.find(d => d.active).name === '1'
        : kpi.isQuarterly;

      const aggr = controlsData[3].data[3].controls.find(aggr => aggr.name === kpi.aggr);
      let calc, columns;
      
      if (kpi.dataType === 'market') {
        calc = controlsData[3].data[2].controls.find(d => d.name === kpi.name);
        columns = [kpi.metric === "Selected" ? metricDemand.name : kpi.metric].map(d => d + (isQuarterly ? '_QRT' : ''));
      }
      else if (kpi.dataType === 'finance') {

        calc = controlsData[3].data[9].controls.find(d => { 
          let name = d.name;
          if(d.name === '%Gt vs PY Finance') {
            name = '%Gt vs PY';
          } 

          return name === kpi.name
        
        });
        columns = controlsData[3].data[8].controls.find(metric => metric.name === kpi.metric).cols//.map(d => d + (isQuarterly ? '_QRT' : ''));
      } 
      else if (kpi.dataType === 'forecast') {
        calc = controlsData[3].data[10].controls.find(d => d.name === kpi.name);
        columns = ['UNB_FT', 'UNF1_FT', 'UNF2_FT'].map(d => d + (isQuarterly ? '_QRT' : ''));
      }
      else if (kpi.dataType === 'sfe') {
        calc = controlsData[3].data[13].controls.find(d => d.name === kpi.name);
        columns = calc.columns;
      } 
      else if (kpi.dataType === 'target') {
        calc = controlsData[3].data[14].controls.find(d => d.name === kpi.name);
        columns = calc.columns;
      }

      const _kpi = {
        id: kpi.id,
        title: kpi.title,
        name: kpi.name,
        dataType: kpi.dataType,
        metric: kpi.metric, // metric demand / metric finance
        group: kpi.group,
        columns,
        format: calc.format,
        func: calcs[calc.calc],
        aggr,
        prevPeriod: state.datasource.datasetType === 'quarterly' ? 4 : 12,
        dateParse: state.dateParse,
        customer_segment: kpi.customer_segment,
        customer_specialty: kpi.customer_specialty
      };

      if (kpi.dataType === 'forecast') _kpi.marketColumns = [kpi.metric + (isQuarterly ? '_QRT' : '')];

      kpis.push(_kpi);
    }
  })
  
  // "title": "Finance Actual",
  // "name": "Act",
  // "metric": "Euro",
  // "aggr": "YTD",
  // "order": 1,
  // "group": "Finance"

  return kpis;
}

const getCharts = (state) => {
  const { controlsData, aggrData: { uniquePatientDynamicCleaned } } = state;
  const isQuarterly = controlsData[3].data[0].controls.find(d => d.active).name === '1';
  const metricDemand = controlsData[3].data[1].controls.find(d => d.active);
  const aggr = controlsData[3].data[4].controls.find(d => d.active);
  const backData = controlsData[3].data[5].controls.find(d => d.active);
  // const timePeriod = controlsData[3].data[6].controls.find(d => d.active).name;
  const metricFinance = controlsData[3].data[8].controls.find(d => d.active);

  const quarterly = state.datasource.datasetType === 'quarterly';

  // get channels info
  const weeklyChannel = (state.channels && (state.data.currentFilter["Country"].length || state.data.currentFilter["Category0"].length))
    ? state.channels.filter(d => 
      (d["DataSource"] === "Weekly") && 
      (state.data.currentFilter["Country"].length ? state.data.currentFilter["Country"].includes(d["Country"]) : true) &&
      (state.data.currentFilter["Category0"].length ? state.data.currentFilter["Category0"].includes(d["Category"]) : true)
    ) : null;

  const forecastChannel = (state.channels && (state.data.currentFilter["Country"].length || state.data.currentFilter["Category0"].length))
    ? state.channels.filter(d => 
      (d["DataSource"] === "Forecast") && 
      (state.data.currentFilter["Country"].length ? state.data.currentFilter["Country"].includes(d["Country"]) : true) &&
      (state.data.currentFilter["Category0"].length ? state.data.currentFilter["Category0"].includes(d["Category"]) : true)
    ) : null;

  const charts = state.charts.map(d => {
    
    if (d.calc) {
      d.func = calcs[d.calc];
    }
    if (d.extraCalcs) {
      d.extraCalcs.forEach(c => {
        if (c.calc) c.func = calcs[c.calc]; 
      });
    }
    if (d.useControl) {
      d.control = controlTemplates[d.id];
    }

    if (d.dataType === 'weekly') {
      d.dateEnd = state.datasource.subsetMap[d.dataType].dates.slice(-1)[0];
      d.channel = weeklyChannel ? { 
        channels: d3.set(weeklyChannel, d => d["Channel"]).values().join(', '),
        metrics: d3.set(weeklyChannel, d => d["Metric"]).values().join(', ')
      } : null
    } else if (d.dataType === 'market'){
      if (!["EOG"].includes(d.id)) {
        d.columns = [metricDemand.name].map(d => d + (isQuarterly ? '_QRT' : ''));
      }
      d.aggr = aggr;
      d.backData = backData;
      d.prevPeriod = quarterly ? 4 : 12;
      if (["MSE_Comp"].includes(d.id)) {
        d.disabled = state.view === "executive";
      }

      if (["DMS"].includes(d.id) && state.dateEnd) {
        const all_dates = state.datasource.subsetMap[d.dataType].dates;
        const dateEndIndex = state.dateEnd[d.dataType] ? all_dates.indexOf(state.dateEnd[d.dataType]) : all_dates.length - 1;
        const dateStartIndex = Math.max(dateEndIndex - d.backData.period + 1, 0);

        // if (d.selectedDate === null || d.selectedDate < all_dates[dateStartIndex] || d.selectedDate > all_dates[dateEndIndex]) {
        d.selectedDate = all_dates[dateStartIndex];
        // }
      }
    } else if (d.dataType === 'finance') {
      d.columns = metricFinance.cols;//.map(d => d + (isQuarterly ? '_QRT' : ''));
      if (d.id === 'F_AS') {
        d.columns = metricFinance.cols.map(d => d + (isQuarterly ? '_QRT' : ''));
        d.prevPeriod = quarterly ? 4 : 12;
      }
      if (aggr.name === "MAT") {
        d.aggr = { name: "MTH", title: "Month", period: 1 }
      } else {
        d.aggr = aggr;
      }
      if (d.id === 'F_AT_T') {
        d.aggr = { name: "YTD", title: "Year to Date", period: 9 };
      }
      d.backData = backData;
      d.dateFormat = state.dateFormat;
      // d.dateParse = state.dateParse;
      d.dateInterval = state.dateInterval;  

      const financeYear = state.dateParse(state.dateEnd[d.dataType]).getUTCFullYear()
      state.chartControls.finance['act-1'] = {...state.chartControls.finance['act-1'], name: `Act-${financeYear - 1}`};
    } else if (d.dataType === 'epi') {
      d.columns = d.columns
                    .map(d => d.slice(-4) === '_QRT' ? d.slice(0, -4) : d)
                    .map(d => d + (isQuarterly ? '_QRT' : ''));
      d.aggr = { name: "MTH", title: "Month", period: 1 };
      d.backData = backData;
      d.marketColumns = ["PEq"].map(d => d + (isQuarterly ? '_QRT' : '')); //[metricDemand.name].map(d => d + (isQuarterly ? '_QRT' : ''));
    } else if (d.dataType === 'promo') {
      d.columns = d.columns
                    .map(d => d.slice(-4) === '_QRT' ? d.slice(0, -4) : d)
                    .map(d => d + (isQuarterly ? '_QRT' : ''));
      d.aggr = { name: "RQTR", title: "Rolling Quarter", period: 1 };
      d.backData = backData;
      d.prevPeriod = quarterly ? 4 : 12;
    } else if (d.dataType === 'forecast') {
      d.columns = d.columns
                    .map(d => d.slice(-4) === '_QRT' ? d.slice(0, -4) : d)
                    .map(d => d + (isQuarterly ? '_QRT' : ''));
      d.backData = { period: quarterly ? 4 : 12 };
      d.aggr = aggr;
      // d.dateParse = state.dateParse;
      d.marketColumns = [metricDemand.name].map(d => d + (isQuarterly ? '_QRT' : ''));
      d.channel = forecastChannel ? { 
        channels: d3.set(forecastChannel, d => d["Channel"]).values().join(', '),
        metrics: d3.set(forecastChannel, d => d["Metric"]).values().join(', ')
      } : null
    } else if (d.dataType === 'patient') {
      const chartControl = state.chartControls.patient;
      const dcMap = {
        new: ["NEW"],
        switch: ["SWITCH"],
        "new+switch": ["NEW", "SWITCH"] 
      }
      if (["MS_T_PT", "MS_T1_PT", "MS_T2_PT"].includes(d.id)) {
        // d.func = chartControl.displayType === "percents" ? calcs["PTP"] : calcs["PTA"] // percents | absolute
        d.func = chartControl.displayType === "percents" ? calcs["PP"] : calcs["PA"] // percents | absolute
        d.yDomain = chartControl.displayType === "percents" ? [0, 100] : null;
        // d.name = 'Patients - ' + d.patientType;
        d.name = d.patientType;
        if (d.id !== "MS_T_PT") d.name += " Total Patients"
        d.format = chartControl.displayType === "percents" ? "percent" : "number";
        d.name += ' - MTH'; 
      } else if (["MS_T_N_PT", "MS_T1_N_PT", "MS_T2_N_PT"].includes(d.id)) {
        d.func = chartControl.displayType === "percents" ? calcs["PP"] : calcs["PA"] // percents | absolute
        d.yDomain = chartControl.displayType === "percents" ? [0, 100] : null;
        d.dynamicCleaned = dcMap[chartControl.dynamicCleaned];
        d.name = d.dynamicCleaned.join(' + ') + ' Patients - ' + d.patientType + ' - MTH';
        d.format = chartControl.displayType === "percents" ? "percent" : "number";
      } else if (["MS_T_ADC_PT"].includes(d.id)) {
        d.dynamicCleaned = uniquePatientDynamicCleaned.filter(d => !["TOTAL PATIENT", "TOTAL PATIENTS"].includes(d));
      }

      // d.dateParse = state.dateParse;
    } else if (d.dataType === 'patient_weekly') {
      const chartControl = state.chartControls.patient_weekly;
      const dcMap = {
        new: ["NEW"],
        switch: ["SWITCH"],
        "new+switch": ["NEW", "SWITCH", "NEW + SWITCH"] 
      }
      if (["MS_T_PT_WK", "MS_T1_PT_WK", "MS_T2_PT_WK"].includes(d.id)) {
        // d.func = chartControl.displayType === "percents" ? calcs["PTP"] : calcs["PTA"] // percents | absolute
        d.func = chartControl.displayType === "percents" ? calcs["PPNS"] : calcs["PANS"] // percents | absolute
        d.yDomain = chartControl.displayType === "percents" ? [0, 100] : null;
        // d.name = 'Patients - ' + d.patientType;
        d.name = d.patientType;
        if (d.id !== "MS_T_PT_WK") d.name += " Total Patients"
        d.format = chartControl.displayType === "percents" ? "percent" : "number";
        // d.name += ' - MTH'; 
      } else if (["MS_T_N_PT_WK", "MS_T1_N_PT_WK", "MS_T2_N_PT_WK"].includes(d.id)) {
        d.func = chartControl.displayType === "percents" ? calcs["PPNS"] : calcs["PANS"] // percents | absolute
        d.yDomain = chartControl.displayType === "percents" ? [0, 100] : null;
        d.dynamicCleaned = dcMap[chartControl.dynamicCleaned];
        // d.name = d.dynamicCleaned.join(' + ') + ' Patients - ' + d.patientType; //+ ' - MTH';
        d.name = d.dynamicCleaned[d.dynamicCleaned.length - 1] + ' Patients - ' + d.patientType; //+ ' - MTH';
        d.format = chartControl.displayType === "percents" ? "percent" : "number";
      }
      // d.dateParse = state.dateParse;
    } else if (d.dataType === 'finance_account') {
      // d.aggr = aggr;
    } else if (d.dataType === 'siso') {
      d.columns = d.columns
                    .map(d => d.slice(-4) === '_QRT' ? d.slice(0, -4) : d)
                    .map(d => d + (isQuarterly ? '_QRT' : ''));
      d.backData = { period: quarterly ? 4 : 12 };
      d.aggr = aggr;
      // d.dateParse = state.dateParse;
      d.marketColumns = [metricDemand.name].map(d => d + (isQuarterly ? '_QRT' : ''));
      d.prevPeriod = quarterly ? 4 : 12;
    } else if (d.dataType === 'atu') {
      d.name = d.name.split(' - ')[0] + ' - ' + d3.utcFormat("%b %Y")(state.dateParse(d.date));
    } else if (d.dataType === 'sit') {
      d.aggr = aggr;//{ name: "MTH", title: "Month", period: 1 };
      d.backData = { period: 6 };
    } else if (d.dataType === 'sfe') {
      d.aggr = aggr
    } else if (d.dataType === 'crm') {
      const chartControl = state.chartControls.crm;
      d.aggr = aggr;
      d.name = d.title + " by " + chartControl.selectedChartsBy;
    } else if (d.dataType === 'sob') {
      const chartControl = state.chartControls.sob;
      if (["MN_SOB"].includes(d.id)) {
        d.func = chartControl.displayType === "percents" ? calcs["SOB_TAD_P"] : calcs["SOB_TAD_A"] // percents | absolute
        d.yDomain = chartControl.displayType === "percents" ? [0, 100] : null;
        d.format = chartControl.displayType === "percents" ? "percent" : "number";
        d.name = [d.title, chartControl.selectedDynamic, chartControl.selectedPType, chartControl.selectedAge].join(' - ');
      } else if (["PS_SOB"].includes(d.id)) {
        d.func = chartControl.displayType === "percents" ? calcs["SOB_TAD_P"] : calcs["SOB_TAD_A"] // percents | absolute
        d.yDomain = chartControl.displayType === "percents" ? [0, 100] : null;
        d.format = chartControl.displayType === "percents" ? "percent" : "number";
        d.name = [chartControl.selectedDynamic, chartControl.selectedPType, chartControl.selectedAge].join(' - ');
      } else if (["PC_SOB", "PPC_SOB",].includes(d.id)) {
        d.func = d.format === "percent" ? calcs["SOB_TAD_P"] : calcs["SOB_TAD_A"] // percents | absolute
        d.yDomain = d.format === "percent" ? [0, 100] : null;
        d.name = [d.title, chartControl.selectedPType, chartControl.selectedAge].join(' - ');
      } if (["NS_SOB"].includes(d.id)) {
        d.func = chartControl.displayType === "percents" ? calcs["SOB_TAD_P"] : calcs["SOB_TAD_A"] // percents | absolute
        d.yDomain = chartControl.displayType === "percents" ? [0, 100] : null;
        d.format = chartControl.displayType === "percents" ? "percent" : "number";
        d.name = [d.title, chartControl.selectedPType, chartControl.selectedDynamic].join(' - ');
      }
    }

    d.dateParse = state.dateParse;

    return d;
  })

  return charts;
}

const getChartControls = (state) => {
  const { aggrData: { 
    uniqueTotalPromoCategoryGroup, 
    uniqueTotalSobPatientType,
    uniqueTotalSobAgeGroup,
    uniqueTotalSobDynamicCleaned,
    uniqueTotalCrmChannelType,
    uniqueTotalCrmCustomerSegment
  } } = state;
  
  const len = state.tableData.data.length;

  let categoryGroups = [], ptype = [], age = [], dynamic = [], channels = [], segments = [];
  
  if (uniqueTotalPromoCategoryGroup) {
    categoryGroups = uniqueTotalPromoCategoryGroup.filter(d => !["undefined", "null"].includes(d));
  }

  if (uniqueTotalSobPatientType) {
    ptype = uniqueTotalSobPatientType;
    age = uniqueTotalSobAgeGroup.sort((a, b) => {
      const _a = parseInt(a === "All ages" ? -1 : a === "> 75" ? 75 : a.split('-')[0]);
      const _b = parseInt(b === "All ages" ? -1 : b === "> 75" ? 75 : b.split('-')[0]);
      return _a - _b
    });
    dynamic = uniqueTotalSobDynamicCleaned;
  }

  if (uniqueTotalCrmChannelType) {
    channels = uniqueTotalCrmChannelType;
    segments = uniqueTotalCrmCustomerSegment;
  }

  return {
    ...state.chartControls,
    promo: {
      ...state.chartControls.promo,
      categoryGroups: ["All"].concat(categoryGroups)
    },
    sob: {
      ...state.chartControls.sob,
      ptype,
      age,
      dynamic
    },
    crm: {
      ...state.chartControls.crm,
      selectedChannel: !state.chartControls.crm.selectedChannel ? channels : state.chartControls.crm.selectedChannel,
      channels,
      selectedSegment: !state.chartControls.crm.selectedSegment ? segments : state.chartControls.crm.selectedSegment,
      segments
    },
    MSE_Comp: {
      ...state.chartControls.MSE_Comp,
      selectedItem1: state.view === "executive" || len < 2 ? "" : state.tableData.data[0].id,
      selectedItem2: state.view === "executive" || len < 2 ? "" : state.tableData.data[1].id
    }
  };
}

const getMetric = (metric) => {
  const metricMap = {
    EU: "EU (MNF)",
    PB: "EU (PUB)"
  }
  return metricMap[metric] || metric;
}

// download csv
const checkMillionBillion = _value => {
  const value = Math.abs(_value);
  switch (true) {
    case value >= 1000 && value < 1000000:
      return '0.0,"K"';
    case value >= 1000000 && value < 1000000000:
      return '0.0,,"M"';
    case value >= 1000000000:
      return '0.0,,,"B"';
    default:
      return '0.0,';
  }
};

const formatXLSXCell = (col_name, arrNames) => {
  const idx = arrNames.map(d => d.original_name).indexOf(col_name);
  const _fmt = arrNames[idx].fm;
  switch (true) {
    case _fmt === "pts":
      return { fmt: '0.0"pts"', name: "pts" };
    case _fmt === "percent":
      return { fmt: "0.0%", name: "percent" };
    default:
      return { fmt: "#", name: "number" };
  }
};

const prepareColors = (data) => {
  if (!data) return {};

  const component2Hex = c => {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  const rgb2Hex = (r, g, b) => "#" + component2Hex(r) + component2Hex(g) + component2Hex(b);

  const res = {};
  data.forEach(d => res[d.Items.toUpperCase()] = rgb2Hex(+d.R, +d.G, +d.B));
  return res;
}

export {
  initData,
  initControlsData,
  updateHighlights,
  getDates,
  getAggregationData,
  getTableData,
  getChartData,
  getDataItem,
  getDataItemNew,
  isCountryDim,
  getTableSortId,
  getSortTree,
  getEndDate,
  calculateLastDate,
  calculateActives,
  serializeConfig,
  updateDimensions,
  findSelectedId,
  getKPIs,
  getCustomKPIs,
  getCharts,
  getChartControls,
  getMetric,
  checkMillionBillion,
  formatXLSXCell,
  prepareColors
};
