import React, { useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Icon from "@material-ui/core/Icon";
import { colors } from "../../shared/theme";

import {
  actionSelectConfig,
  actionCloseConfig,
  actionSelectTab,
  actionSetFsTable,
  actionSetFsIndex,
} from "./../../store/actions";
import { actionShowModal } from "../Modals/actions";
import { ModalTypes } from "../Modals/constants";

import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const pseudoStyles = (selected) => ({
  content: '""',
  position: "absolute",
  width: "50%",
  height: "100%",
  zIndex: selected ? 1 : 0,
  background: selected ? colors.white : colors.tabsBackgroundDark,
  transformOrigin: "100% 0",
});

const useTabsStyles = makeStyles({
  rootTabs: {
    backgroundColor: colors.tabsBackground,
    paddingTop: "8px",
    minHeight: "36px",
  },
  flexContainer: {
    marginLeft: "10px",
  },
  rootTab: {
    marginLeft: "-16px",
    padding: "0rem 1.5rem",
    minHeight: "28px",
    "& > span": {
      zIndex: 2,
    },
    // borderBottom: '1px solid #d4d5dd',
    position: "relative",
    "&::after": {
      ...pseudoStyles(false),
      left: "13px",
      borderTopLeftRadius: "5px",
      transform: "skewX(-15deg)",
      borderLeft: "1px solid #d4d5dd",
      borderTop: "1px solid #d4d5dd",
    },
    "&::before": {
      ...pseudoStyles(false),
      right: "13px",
      borderTopRightRadius: "5px",
      transform: "skewX(15deg)",
      borderRight: "1px solid #d4d5dd",
      borderTop: "1px solid #d4d5dd",
    },
  },
  selected: {
    position: "relative",
    // borderBottom: 0,
    "&::after": {
      ...pseudoStyles(true),
      left: "13px",
      borderTopLeftRadius: "5px",
      transform: "skewX(-15deg)",
      borderLeft: "1px solid #d4d5dd",
      borderTop: "1px solid #d4d5dd",
    },
    "&::before": {
      ...pseudoStyles(true),
      right: "13px",
      borderTopRightRadius: "5px",
      transform: "skewX(15deg)",
      borderRight: "1px solid #d4d5dd",
      borderTop: "1px solid #d4d5dd",
    },
  },
  indicator: {
    display: "none",
  },
});

const useStyles = makeStyles((theme) => ({
  addBar: {
    paddingLeft: "2rem",
    top: "3.5rem",
    zIndex: 9,
    boxShadow: "none",
    backgroundColor: colors.tabsBackground,
  },
  addButton: {
    position: "fixed",
    zIndex: 10,
    padding: "3px",
    marginTop: "6px",
    marginLeft: "3px",
  },
  tabText: {
    maxWidth: "12rem",
    minWidth: "6rem",
    marginRight: "1.2rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    textTransform: "none",
  },
  tabIcons: {
    position: "absolute",
    right: "1.15rem",
    marginTop: "6px",
    "& .MuiIcon-root": {
      fontSize: "1rem",
      marginLeft: ".2rem",
      "&:hover": {
        color: "rgb(220, 0, 78)",
      },
    },
  },
}));

function ScrollableTabsButtonAuto(props) {
  const {
    selectedTab,
    worksheets,
    actionSelectConfig,
    actionCloseConfig,
    actionSelectTab,
    actionShowModal,
    fs_index,
    fs_table,
  } = props;
  const classes = useStyles();
  const classesTabs = useTabsStyles();

  const handleChange = useCallback(
    (event, tab_index) => {
      if (selectedTab !== tab_index) {
        actionSelectConfig(worksheets[tab_index].config_id);
        actionSelectTab(tab_index);
      }
    },
    [actionSelectConfig, worksheets]
  );

  const handleCloseConfig = (config_id) => {
    actionCloseConfig({ action: "close", config_id });
  };

  return fs_index || fs_table ?  (
    ""
  ) : (
    <>
      <IconButton
        className={classes.addButton}
        color="primary"
        aria-label="add new worksheet"
        onClick={() => actionShowModal(ModalTypes.AddConfigModal)}
      >
        <AddIcon />
      </IconButton>
      <AppBar className={classes.addBar} position="fixed" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            root: classesTabs.rootTabs,
            flexContainer: classesTabs.flexContainer,
            indicator: classesTabs.indicator,
          }}
          aria-label="scrollable auto tabs example"
        >
          {selectedTab !== null && worksheets.length
            ? worksheets.map((worksheet, i) => (
                <Tab
                  key={i}
                  label={
                    <>
                      <span className={classes.tabText}>{worksheet.name}</span>
                      <span className={classes.tabIcons}>
                        <Icon
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCloseConfig(worksheet.config_id);
                          }}
                        >
                          close
                        </Icon>
                      </span>
                    </>
                  }
                  classes={{
                    root: classesTabs.rootTab,
                    selected: classesTabs.selected,
                  }}
                  {...a11yProps(i)}
                />
              ))
            : null}
        </Tabs>
      </AppBar>
    </>
  );
}

const mapStateToProps = ({ data: state }, props) => {
  return {
    ...state,
    selectedTab: state.selectedTab,
    datasources: state.datasources || [],
    worksheets: state.configs
      ? Object.keys(state.configs).map((config_id) => ({
          config_id,
          datasource_id: state.configs[config_id].config.datasource_id,
          name: state.configs[config_id].config.name,
        }))
      : [],
    fs_index: state.fs_index,
    fs_table: state.fs_table,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionSelectConfig: (config_id) => dispatch(actionSelectConfig(config_id)),
    actionCloseConfig: (id) => dispatch(actionCloseConfig(id)),
    actionSelectTab: (index) => dispatch(actionSelectTab(index)),
    actionShowModal: (modalType, config) =>
      dispatch(actionShowModal(modalType, config)),
    actionSetFsTable: (value) => dispatch(actionSetFsTable(value)),
    actionSetFsIndex: (value) => dispatch(actionSetFsIndex(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollableTabsButtonAuto);
