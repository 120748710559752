import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Worksheet from "./../Worksheet/Main";
import Box from "@material-ui/core/Box";

import {
  actionGetDatasources,
} from "./../../store/actions";

import TabsHeader from "./TabsHeader"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
    marginTop: "3.5rem",
  },
}));

function ScrollableTabsButtonAuto(props) {
  const {
    selectedTab,
    worksheets,
    actionGetDatasources,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    actionGetDatasources();
  }, []);

  return (
    <div className={classes.root}>
      <TabsHeader />
      {selectedTab !== null && worksheets.length
        ? worksheets.map((worksheet, i) => (
            <TabPanel key={i} value={selectedTab} index={i}>
              <Worksheet
                config_id={worksheet.config_id}
                selectedTab={selectedTab}
              />
            </TabPanel>
          ))
        : null}
    </div>
  );
}

const mapStateToProps = ({ data: state }, props) => {
  return {
    ...state,
    selectedTab: state.selectedTab,
    worksheets: state.configs
      ? Object.keys(state.configs).map((config_id) => ({
          config_id,
          datasource_id: state.configs[config_id].config.datasource_id,
          name: state.configs[config_id].config.name,
        }))
      : [],
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionGetDatasources: () => dispatch(actionGetDatasources()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollableTabsButtonAuto);
