import React from "react";
import { connect } from "react-redux";

import Control from "./Control";

import { actionControl, actionControlReset } from "./../../../store/actions";

const ListControls = (props) => {
  const { controlsData, tabId, active, filter, actionControlReset } = props;  

  const [expanded, setExpanded] = React.useState(active);

  return (
    <div>
        {controlsData &&
          controlsData[tabId].data
            .slice(0)
            .sort((a, b) => a.order - b.order)
            .map((data, i) => {
              return data.controls.length && !data.hidden ? (
                <Control
                  {...props}
                  key={i}
                  data={data}
                  tabId={tabId}
                  index={data.index !== undefined ? data.index : i}
                  filter={filter}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  actionControlReset={actionControlReset}
                />
              ) : null;
            })}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const dates = props.datasource.subsetMap["market"].dates;
  return {
    controlsData: props.controlsData,
    dateStartInit: props.dateParse(dates[0]),
    dateEndInit: props.dateParse(dates[dates.length - 1]),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { config_id } = props;
  return {
    actionControl: (active, i, ii, iii, date) => {
      props.resetFilter();
      dispatch(actionControl(config_id, active, i, ii, iii, date));
    },
    actionControlReset: (i, ii) =>
      dispatch(actionControlReset(config_id, i, ii)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListControls);
