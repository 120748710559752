import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5, 2),
    // marginTop: '2rem',
    marginTop: 'auto',
    textAlign: 'center',
    // backgroundColor:
    //   theme.palette.type === 'light'
    //     ? theme.palette.grey[200]
    //     : theme.palette.grey[800],
  },
}));

export default function StickyFooter({ version, backgroundColor }) {
  const classes = useStyles();

  return (
    <footer className={classes.root} style={{ backgroundColor }}>
      <Container maxWidth="sm">
        <Typography variant="body2">
          © Queryable Inc, Flashcard {version}
        </Typography>
      </Container>
    </footer>
  );
}
