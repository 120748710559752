import * as d3 from 'd3';

const renderNoData = ({ data, svg, width, height }) => {
  svg.selectAll('.no_data_overlay').remove();

  const dataLength = d3.sum(data.map((d) => d.data.length));

  if (dataLength < 1) {
    const no_data = svg.append('g').attr('class', 'no_data_overlay');

    no_data
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', '#fff');
    no_data
      .append('text')
      .attr('x', width / 2)
      .attr('y', height / 2)
      .attr('text-anchor', 'middle')
      .text('No Data');

    return true;
  }

  return false;
};

export { renderNoData };
