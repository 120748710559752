import * as React from 'react';
import { Form as FormikForm, Formik } from 'formik';

export function Form({ initialValues, onSubmit, validate, children }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      children={(props) => <FormikForm>{children(props)}</FormikForm>}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    />
  );
}

Form.displayName = 'Form';
