import XLSX from "xlsx";
import PptxGenJS from "pptxgenjs";
import * as d3 from "d3";
import colors from "../../../store/reducers/config/colors";
import out$ from "save-svg-as-png";

import {
  getEndDate,
  checkMillionBillion,
  formatXLSXCell,
} from "../../../store/reducers/config/helper";

import logo from "./../../../icons/flashcard_logo/PNG SANS FOND/Flashcard-Logo-1-Couleurs-Sans-Fond.png";

const color_negative_bar = "FFAFB1";

const isElement = (obj) =>
obj instanceof HTMLElement || obj instanceof SVGElement;

const requireDomNode = (el) => {
if (!isElement(el))
  throw new Error(`an HTMLElement or SVGElement is required; got ${el}`);
return;
};

const requireDomNodePromise = (el) =>
  new Promise((resolve, reject) => {
    if (isElement(el)) resolve(el);
    else
      reject(new Error(`an HTMLElement or SVGElement is required; got ${el}`));
  });

const saveSvgAsPng = (el, name, options) => {
  requireDomNode(el);
  out$.svgAsPngUri(el, options || {}, (uri) => out$.download(name, uri));
  requireDomNodePromise(el).then(out$.svgAsPngUri(el, options || {}));
};

const saveSvg = (el, name, options) => {
  requireDomNode(el);
  out$.svgAsDataUri(el, options || {}, (uri) => out$.download(name, uri));
  requireDomNodePromise(el).then(out$.svgAsDataUri(el, options || {}));
};

// download chart
export const downloadSvgPng = (chart, str) => {
  // id chart
  const id = chart.items[chart.activeIndex].id;

  // type chart
  const type = chart.items[chart.activeIndex].type;

  // find svg by type
  const element = document.getElementsByClassName(type + "-svg")[0];

  if (str === "svg") {
    // download svg
    saveSvg(element, id + ".svg", {});
  } else {
    // download svg as png
    saveSvgAsPng(element, id + ".png", {});
  }
};

// Download .xml
export const downloadXLS = ({ data, id, format }) => {
  const updateWb = (table, range, arrNames) => {
    for (var j = range.s.c + 1; j <= range.e.c; ++j) {
      // find name column(A, B, C, ...) by second row j column
      const ref_col_name = XLSX.utils.encode_cell({ r: 0, c: j });
      // find original_name column
      const curr_col_name = table[ref_col_name].v;
      // define format for this column
      const fmt = formatXLSXCell(curr_col_name, arrNames);
      for (var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 2 skips the header rows of the worksheet) */
        var ref = XLSX.utils.encode_cell({ r: i, c: j });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!table[ref]) continue;
        /* `.t == "n"` for number cells */
        if (table[ref].t != "n") continue;
        // /* assign the `.z` number format */

        if (!table[ref].v) {
          table[ref].z = '"-"';
        } else {
          if (fmt.name === "pts") {
            table[ref].v = table[ref].v;
            table[ref].z = fmt.fmt;
          } else if (fmt.name === "number") {
            table[ref].z = checkMillionBillion(table[ref].v);
          } else if (fmt.name === "percent") {
            table[ref].v = table[ref].v / 100;
            table[ref].z = fmt.fmt;
          } else {
            table[ref].z = fmt.fmt;
          }
        }
      }
    }
  };

  // atu
  if (["AoT_AT", "AoT_Prev_AT"].includes(id)) {
    const arrNames = data[0].legend.map((d) => ({
      name: d,
      fm: format,
      original_name: d,
    }));

    let arrObjForSheet = [];
    data.forEach((f, i) => {
      if (i === 0) {
        f.data.forEach((ff) => {
          let obj = {
            name: ff.name,
            [f.legend[0]]: ff.value,
            [f.legend[1]]: ff.innerValue,
          };
          arrObjForSheet.push(obj);
        });
      } else {
        f.data.forEach((ff, ii) => {
          if (arrObjForSheet.length <= ii) {
            arrObjForSheet.push({
              name: ff.name,
              [f.legend[0]]: ff.value,
              [f.legend[1]]: ff.innerValue,
            });
          } else {
            arrObjForSheet[ii][f.legend[0]] = ff.value;
            arrObjForSheet[ii][f.legend[1]] = ff.innerValue;
          }
        });
      }
    });

    // make the worksheet _
    var ws = XLSX.utils.json_to_sheet(arrObjForSheet);

    // /_ add to workbook _/
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, id, {
      bookType: "xlsx",
      type: "binary",
    });

    const table = wb.Sheets[wb.SheetNames[0]];
    /* get worksheet range */
    var range = XLSX.utils.decode_range(table["!ref"]);

    updateWb(table, range, arrNames);

    var fileName = id + ".xlsx";

    //  generate an XLSX file and export
    XLSX.writeFile(wb, fileName);
    return;
  }

  // OTHERS
  const arrNames = data.map((d) => ({
    name: d.name,
    fm: format,
    original_name: d.name,
  }));

  let arrObjForSheet = [];
  data.forEach((f, i) => {
    if (i === 0) {
      f.data.forEach((ff) => {
        let obj = {
          // legends: "",
          date: ff.date,
          [f.name]: ff.value,
        };
        arrObjForSheet.push(obj);
      });
    } else {
      f.data.forEach((ff, ii) => {
        if (arrObjForSheet.length <= ii) {
          arrObjForSheet.push({
            date: ff.date,
            [f.name]: ff.value,
          });
        } else {
          arrObjForSheet[ii][f.name] = ff.value;
        }
      });
    }

    // arrObjForSheet[i].legends = f.name;
  });

  // make the worksheet _
  var ws = XLSX.utils.json_to_sheet(arrObjForSheet, {
    dateNF: "mmm-yy",
    cellDates: true,
    raw: true,
  });

  // /_ add to workbook _/
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, id, {
    bookType: "xlsx",
    type: "binary",
    cellDates: true,
  });

  const table = wb.Sheets[wb.SheetNames[0]];
  /* get worksheet range */
  var range = XLSX.utils.decode_range(table["!ref"]);

  updateWb(table, range, arrNames);

  var fileName = id + ".xlsx";

  //  generate an XLSX file and export
  XLSX.writeFile(wb, fileName);
};

// download ppt
export const downloadPPT = (_data, chart, props) => {
  const { controlsData, actives, data: state, color_codes } = props;
  const names = props.selectedId.split(":").reverse().slice(0);
  const isMarketAvailable = props.dateEnd["market"];
  const isFinanceAvailable =
  props.datasource.subsetMap["finance"] &&
  props.datasource.subsetMap["finance"].dataLength > 0 &&
  props.dateEnd["finance"];

  const headerMarket =
    (actives.headerCountries
      ? actives.headerCountries.length > 7
        ? `${actives.headerCountries.length} Countries - `
        : actives.headerCountries.join(", ") + " - "
      : "") +
    (actives.headerProducts
      ? actives.headerProducts.length > 5
        ? `${actives.headerProducts.length} Products in `
        : actives.headerProducts.join(", ") + " in "
      : "") +
    (actives.headerCategories
      ? actives.headerCategories.length > 7
        ? `${actives.headerCategories.length} Market`
        : actives.headerCategories.join(", ") +
          (actives.headerCategories.indexOf("Libtayo Scorecard") > -1
            ? ""
            : " Market")
      : "") +
    "Market";
  const headerSales =
    (isMarketAvailable
      ? "Demand Sales in " +
        controlsData[3].data[1].controls.find((d) => d.active).title +
        (controlsData[3].data[6].controls[0].active ||
        controlsData[3].data[6].customDate.active
          ? " - " +
            getEndDate(
              props,
              "market",
              controlsData[3].data[0].controls[1].active
            )
          : "") +
        " - " +
        controlsData[3].data[0].controls.find((d) => d.active).title
      : "") +
    (isFinanceAvailable
      ? (isMarketAvailable ? " / " : "") +
        "Finance Sales in " +
        controlsData[3].data[8].controls.find((d) => d.active).title +
        " - " +
        getEndDate(props, "finance")
      : "");

  const pptx = new PptxGenJS();
  pptx.layout = "LAYOUT_WIDE";

  const slide = pptx.addSlide();
  slide.back = "FFFFFF";

  // add header
  slide.addImage({
    data: logo,
    x: 0.0,
    y: -0.075,
    w: 2.1177,
    h: 0.9,
    sizing: { type: "contain" },
  });

  slide.addText(headerMarket, {
    x: 2.2,
    y: 0.15,
    color: "6c81ae",
    fontSize: 18,
    bold: true,
  });
  slide.addText(headerSales, {
    x: 2.2,
    y: 0.4,
    color: "6c81ae",
    fontSize: 12,
  });

  // add rectangle
  slide.addShape(pptx.shapes.RECTANGLE, {
    x: 0.0,
    y: 0.725,
    w: "100%",
    h: 0.15,
    fill: "D9E5FF",
  });

  // add footer
  slide.addShape(pptx.shapes.RECTANGLE, {
    x: 0.0,
    y: 7.2,
    w: "100%",
    h: 0.3,
    fill: "D9E5FF",
  });
  slide.addText("Source: SINERGI - Global Business Intelligence", {
    x: 0.0,
    y: 7.21,
    color: "000000",
    fontSize: 10,
  });

  // add chart
  const chartName = [
    "MG",
    "GtPY",
    "GtMkt",
    "EI",
    "AG",
    "MSE",
    "AS",
    "DMS",
    "MSE_L",
    "AS_L",
    "MSE_WK",
    "AS_WK",
    "SoV_PR",
    "ASoV_PR",
    "FMS_F",
    "AMS_FMS_F",
    "AS_FS_F",
    "ABF_F",
    "YTD_YTG_F",

    "NR_FA",

    "SoD_AT", // atu
    "SoD_Prev_AT", // atu
    "AoT_AT", // atu
    "AoT_Prev_AT", // atu

    "MS_T_PT", // patient
    "MS_T_N_PT",
    "MS_T_NS_PT",
    "MS_T1_PT",
    "MS_T1_N_PT",
    "MS_T1_NS_PT",
    "MS_T2_PT",
    "MS_T2_N_PT",
    "MS_T2_NS_PT",
    "MS_T_NSA_PT",
    "MS_T_ADC_PT",

    "MS_T_PT_WK", // patient_weekly
    "MS_T_N_PT_WK",
    "MS_T_NS_PT_WK",
    "MS_T1_PT_WK",
    "MS_T1_N_PT_WK",
    "MS_T1_NS_PT_WK",
    "MS_T2_PT_WK",
    "MS_T2_N_PT_WK",
    "MS_T2_NS_PT_WK",
    "MS_T_NSA_PT_WK",

    // siso
    "NG_MG_SISO",
    "NG_MG_10_SISO",

    // sob
    "MN_SOB",
    "PS_SOB",
    "PC_SOB",
    "PPC_SOB",
    "NS_SOB",

    // SFE
    "CPA_ES_SFE",
    "PC_ES_SFE",
    "C_ES_SFE",
    "CT_ES_SFE",
    "CV_ES_SFE",
    "Cvg_ES_SFE",
    "F_ES_SFE",

    // CRM
    "CPA_ES_CRM",
    "PC_ES_CRM",
    "C_ES_CRM",
    "CT_ES_CRM",
    "CV_ES_CRM",
    "Cvg_ES_CRM",
    "F_ES_CRM",

    // PEQ
    "PL3SC_PEQ",
    "PL4SC_PEQ",
    "L3SC_PEQ",
    "L4SC_PEQ",
  ].includes(chart.id)
    ? `${
        chart.name +
        (chart.selectedDate
          ? d3.utcFormat("%b %Y")(props.dateParse(chart.selectedDate))
          : "")
      } (${chart.columns[0]}) - ${
        ["MSE_WK", "AS_WK"].includes(chart.id)
          ? "Weekly"
          : controlsData[3].data[4].controls.filter((d) => d.active)[0].name
      }`
    : `${chart.name} (${
        controlsData[3].data[8].controls.filter((d) => d.active)[0].title
      }), ${chart.aggr ? chart.aggr.name : ""} - ${names[0]}${
        names.length > 1 ? " in " + names.slice(1).join(", ") : ""
      }`;

  const opts = {
    x: 0.0,
    y: 1.2,
    w: "100%",
    h: 6.0,

    barDir: ["AoT_AT", "AoT_Prev_AT"].includes(chart.id) ? "bar" : "col",
    barGrouping: "clustered",
    invertedColors: [color_negative_bar],

    catAxisLabelColor: "999999",
    catAxisLabelFontFace: "Helvetica Neue",
    catAxisLabelFontSize: 8,
    catAxisOrientation: "minMax",
    catGridLine: "none",

    valAxisLabelColor: "999999",
    valAxisLabelFontFace: "Helvetica Neue",
    valAxisLabelFontSize: 8,
    valGridLine: "none",

    showTitle: true,
    title: chartName,
    titleFontBold: true,
    titleFontFace: "Helvetica Neue",
    titleFontSize: 16,

    showLegend: true,
    legendFontFace: "Helvetica Neue",
    legendFontSize: 10,
    legendPos: "b",
  };

  let chartTypes = [];

  const dateFormat = d3.utcFormat("%b %Y");
  const noDate = [
    "F_AT_M",
    "MSE_WK",
    "AS_WK",
    "AoT_AT",
    "AoT_Prev_AT",
    "SIT_STOCK_SISO_VOL",
    "SIT_STOCK_SISO_VAL",
    "SIT_STOCK_COVERAGE",
  ];

  let data = [];
  if (["AoT_AT", "AoT_Prev_AT"].includes(chart.id)) {
    _data[0].legend.reverse().forEach((l, i) =>
      data.push({
        name: l,
        type: "bar",
        color: _data[0].colors[1 - i],
        data: _data[0].data.map((p) => ({
          date: p.name,
          value: i === 1 ? p.value : p.innerValue,
        })),
      })
    );
  } else {
    data = _data;
  }

  switch (chart.id) {
    case "F_AT_M": // finance
    case "F_AS":
    case "F_AS_L": // launch finance
    case "MG": // market
    case "GtPY":
    case "GtMkt":
    case "EI":
    case "AG":
    case "MSE":
    case "AS":
    case "DMS":
    case "MSE_Comp":
    case "MSE_L": // launch market
    case "AS_L": // launch market
    case "PLP":
    case "0PLP":
    case "MSE_WK":
    case "AS_WK":
    case "SoV_PR":
    case "ASoV_PR":
    case "FMS_F": // forecast
    case "AMS_FMS_F": // forecast
    case "AS_FS_F":
    case "ABF_F":
    case "YTD_YTG_F":
    case "NR_FA": // finance accounts

    case "SoD_AT":
    case "SoD_Prev_AT":
    case "AoT_AT":
    case "AoT_Prev_AT":

    case "MS_T_PT":
    case "MS_T_N_PT":
    case "MS_T_NS_PT":
    case "MS_T1_PT":
    case "MS_T1_N_PT":
    case "MS_T1_NS_PT":
    case "MS_T2_PT":
    case "MS_T2_N_PT":
    case "MS_T2_NS_PT":
    case "MS_T_NSA_PT":
    case "MS_T_ADC_PT":

    case "MS_T_PT_WK":
    case "MS_T_N_PT_WK":
    case "MS_T_NS_PT_WK":
    case "MS_T1_PT_WK":
    case "MS_T1_N_PT_WK":
    case "MS_T1_NS_PT_WK":
    case "MS_T2_PT_WK":
    case "MS_T2_N_PT_WK":
    case "MS_T2_NS_PT_WK":
    case "MS_T_NSA_PT_WK":

    case "NG_MG_SISO":
    case "NG_MG_10_SISO":

    case "SIT_STOCK_SISO_VOL":
    case "SIT_STOCK_SISO_VAL":
    case "SIT_STOCK_COVERAGE":

    // SFE
    case "CPA_ES_SFE":
    case "PC_ES_SFE":
    case "C_ES_SFE":
    case "CT_ES_SFE":
    case "CV_ES_SFE":
    case "Cvg_ES_SFE":
    case "F_ES_SFE":

    // CRM
    case "CPA_ES_CRM":
    case "PC_ES_CRM":
    case "C_ES_CRM":
    case "CT_ES_CRM":
    case "CV_ES_CRM":
    case "Cvg_ES_CRM":
    case "F_ES_CRM":

    // sob
    case "MN_SOB":
    case "PS_SOB":
    case "PC_SOB":
    case "PPC_SOB":
    case "NS_SOB":

    // PEQ
    case "PL3SC_PEQ":
    case "PL4SC_PEQ":
    case "L3SC_PEQ":
    case "L4SC_PEQ": {
      chartTypes = [
        {
          type: pptx.charts.BAR,
          data: data
            .filter((d) => d.type === "bar")
            .map((d) => ({
              name: d.name,
              labels: d.data.map((p) =>
                noDate.includes(chart.id) ? p.date : dateFormat(p.date)
              ),
              values: d.data.map((p) => p.value),
            })),
          options: {
            barGrouping: [
              "AG",
              "NR_FA",

              // atu
              "SoD_AT",
              "SoD_Prev_AT",

              // patient
              "MS_T_PT",
              "MS_T_N_PT",
              "MS_T_NS_PT",
              "MS_T1_PT",
              "MS_T1_N_PT",
              "MS_T1_NS_PT",
              "MS_T2_PT",
              "MS_T2_N_PT",
              "MS_T2_NS_PT",
              "MS_T_NSA_PT",
              "MS_T_ADC_PT",

              // patient weekly
              "MS_T_PT_WK",
              "MS_T_N_PT_WK",
              "MS_T_NS_PT_WK",
              "MS_T1_PT_WK",
              "MS_T1_N_PT_WK",
              "MS_T1_NS_PT_WK",
              "MS_T2_PT_WK",
              "MS_T2_N_PT_WK",
              "MS_T2_NS_PT_WK",
              "MS_T_NSA_PT_WK",

              // sob
              "MN_SOB",
              "PS_SOB",
              "PC_SOB",
              "PPC_SOB",
              "NS_SOB",

              // PEQ
              "PL3SC_PEQ",
              "PL4SC_PEQ",
              "L3SC_PEQ",
              "L4SC_PEQ",
            ].includes(chart.id)
              ? "stacked"
              : "clustered",
            barGapWidthPct: 100,
            chartColors: data
              .filter((d) => d.type === "bar")
              .map((d, i) =>
                (
                  color_codes[d.name.split(" - ")[0]] ||
                  d.color ||
                  colors[i]
                ).replace("#", "")
              ),
            invertedColors: [color_negative_bar],
            shadow: "none",
          },
        },
        {
          type: pptx.charts.LINE,
          data: data
            .filter((d) => d.type === "line")
            .map((d) => ({
              name: d.name,
              labels: d.data.map((p) =>
                noDate.includes(chart.id) || chart.chartType === "launch"
                  ? p.date
                  : dateFormat(p.date)
              ),
              values: d.data.map((p) => p.value),
            })),
          options: {
            barGrouping: "clustered",
            // secondaryValAxis: !!opts.valAxes,
            lineSize: 1,
            // lineDataSymbolLineColor: "FFFFFF",
            lineDataSymbolSize: 6,
            // lineDataSymbolLineSize: 4,
            // lineDataSymbolLineColor: data.filter(d => d.type === "line").map((d, i) => (d.styles && d.styles.color.replace('#', '')) || colors[i].replace('#', '')),
            chartColors: data
              .filter((d) => d.type === "line")
              .map((d, i) =>
                (
                  color_codes[d.name.split(" - ")[0]] ||
                  (d.styles && d.styles.color) ||
                  colors[i]
                ).replace("#", "")
              ),
            shadow: "none",
          },
        },
      ];

      slide.addChart(chartTypes, opts);

      break;
    }
    case "F_AT_T": {
      // finance
      chartTypes = [
        {
          type: pptx.charts.BAR,
          data: [
            {
              labels: data[0].data.map((d) => d.date),
              values: data[0].data.map((d) => d.value),
            },
          ],
          options: {
            barGrouping: "clustered",
            barGapWidthPct: 50,
            chartColors: data[0].data.map((d) => d.color.replace("#", "")),
            shadow: "none",
          },
        },
      ];

      slide.addChart(chartTypes, opts);

      break;
    }
    case "F_GtP_1": {
      // finance
      chartTypes = [
        {
          type: pptx.charts.LINE,
          data: data
            .filter((d) => d.type === "line")
            .map((d) => ({
              name: d.name,
              labels: d.domain,
              values: d.data.map((p) => p.value),
            })),
          options: {
            showLabel: true,
            lineSize: 1,
            // lineDataSymbolLineColor: "FFFFFF",
            lineDataSymbolSize: 6,
            // lineDataSymbolLineSize: 6,
            // lineDataSymbolLineColor: data.filter(d => d.type === "line").map(d => d.styles.color.replace('#', '')),
            chartColors: data
              .filter((d) => d.type === "line")
              .map((d) => d.styles.color.replace("#", "")),
            shadow: "none",
          },
        },
      ];

      slide.addChart(chartTypes, opts);

      break;
    }
    default:
  }
  // console.log('PPT export charet types: ', chartTypes)
  pptx.writeFile("Chart");
};
