import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Box from '@material-ui/core/Box';
import { Loader } from './Loader';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import SimpleTooltip from './SimpleTooltip';

const useStyles = makeStyles((theme) => {
  return {
    tableContaner: {
      height: 200,
      position: 'relative',
      minWidth: 200,
      overflowX: 'unset',
      overflowY: 'auto',
    },
    th: {
      position: 'sticky !important',
      top: '0px',
      backgroundColor: theme.palette.common.white,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      zIndex: 1
    },
    td: {
      padding: '0.5rem 1rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tr: {
      position: 'relative',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    loaderStyle: {
      top: '41%',
      left: '45%',
      marginLeft: 0,
    },
    removeBlock: {
      padding: '8px 0px 0px 0px !important',
      position: 'absolute',
      right: 2,
      border: 'none',
      //backgroundColor: theme.palette.common.white
    },
    removeButton: {
      width: 20,
      cursor: 'pointer',
      marginLeft: 10,
    },
    confirmation: {
      //padding: '0px 0px 0px 0px !important',
      paddingRight: '3px !important',
      paddingLeft: 3,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
});

function descendingComparator(a, b, sortField) {
  if (b[sortField] < a[sortField]) {
    return -1;
  }
  if (b[sortField] > a[sortField]) {
    return 1;
  }
  return 0;
}

function getComparator(sortType, sortField) {
  return sortType === 'desc'
    ? (a, b) => descendingComparator(a, b, sortField)
    : (a, b) => -descendingComparator(a, b, sortField);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const sortType = comparator(a[0], b[0]);
    if (sortType !== 0) return sortType;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function MyTableRow ({ ind, el, rowsProps, columns, removeRow }) {
  const classes = useStyles();

  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    setRemoving(false)
  }, [el])

  return (
    <TableRow
      id={`row-${ind}`}
      {...(rowsProps ? rowsProps(el, ind) : {})}
      className={classes.tr}
    >
      {removing ? (
        <TableCell className={classes.confirmation}>
          <Typography variant="subtitle2">
            Are you sure you want to delete?
          </Typography>
          <SimpleTooltip title="Delete" placement="top">
            <CheckIcon
              className={classes.removeButton}
              fontSize="small"
              onClick={() => {
                setRemoving(false)
                removeRow(el)
              }}
            />
          </SimpleTooltip>
          <SimpleTooltip title="Cancel" placement="top">
            <CloseIcon
              className={classes.removeButton}
              fontSize="small"
              onClick={() => setRemoving(false)}
            />
          </SimpleTooltip>
        </TableCell>
      ) : (
        <>
          {columns.map((col, i) => (
            <TableCell
              className={classes.td}
              key={`body-cell-${ind}-${i}`}
              id={`cell-${el.config_id}`}
              {...(col.props ? { ...col.props } : {})}
            >
              {el[col.field]}
            </TableCell>
          ))}
          {removeRow ? (
            <TableCell className={classes.removeBlock}>
              <SimpleTooltip title="Delete" placement="top">
                <CloseIcon
                  className={classes.removeButton}
                  fontSize="small"
                  onClick={() => setRemoving(true)}
                />
              </SimpleTooltip>
            </TableCell>
          ) : undefined}
        </>
      )}
    </TableRow>
  );
};

export default function SimpleMaterialTable({
  data,
  columns,
  className,
  rowsProps,
  removeRow,
  loader = false,
  isSearch = true,
  isSort = true,
  tableContainerProps,
}) {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');
  const [sortType, setSortType] = useState('asc');
  const [sortField, setSortField] = useState('');

  const createSortHandler = (property) => (event) => {
    const isAsc = sortField === property && sortType === 'asc';
    setSortType(isAsc ? 'desc' : 'asc');
    setSortField(property);
  };

  return (
    <Box className={className}>
      {isSearch ? (
        <Box mb={1}>
          <TextField
            id="table search"
            label="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            size="small"
          />
        </Box>
      ) : (
        ''
      )}
      <TableContainer
        className={classes.tableContaner}
        {...tableContainerProps}
      >
        {loader ? (
          <Loader color="#000" className={{ root: classes.loaderStyle }} />
        ) : (
          <Table size="small" aria-label="simple material table">
            <TableHead>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell
                    className={classes.th}
                    key={`header-cell-${i}`}
                    id={`header-cell-${col.field}`}
                    {...(col.props ? { ...col.props } : {})}
                  >
                    {isSort ? (
                      <TableSortLabel
                        active={sortField === col.field}
                        direction={sortField === col.field ? sortType : 'asc'}
                        onClick={createSortHandler(col.field)}
                      >
                        {col.title}
                      </TableSortLabel>
                    ) : (
                      col.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(
                searchValue
                  ? data.filter((d) =>
                      Object.keys(d).find(
                        (k) =>
                          String(d[k])
                            .toLowerCase()
                            .indexOf(searchValue.toLowerCase()) > -1
                      )
                    )
                  : data,
                getComparator(sortType, sortField)
              ).map((el, ind) => (
                <MyTableRow
                  key={`row-${ind}`}
                  ind={ind}
                  el={el}
                  rowsProps={rowsProps}
                  columns={columns}
                  removeRow={removeRow}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
}
