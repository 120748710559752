import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Modal } from '../../shared';
import { actionHideModal } from './actions';
import { ModalTypes } from './constants';
import UpdateModal from './templates/UpdateModal';
import AddConfigModal from './templates/AddConfigModal';
import RsuiteTableModal from './templates/RsuiteTableModal';
import ShareModal from './templates/ShareModal';

const MODALS = {
  [ModalTypes.UpdateModal]: <UpdateModal />,
  [ModalTypes.AddConfigModal]: <AddConfigModal />,
  [ModalTypes.RsuiteTableModal]: <RsuiteTableModal />,
  [ModalTypes.ShareModal]: <ShareModal />,
};

export const ModalContainer = ({ open, type, config, actionHideModal }) => {
  const classes = {};

  if (type) {
    return (
      <Modal
        content={MODALS[type]}
        open={open}
        hideModal={() => actionHideModal()}
        headerText={config ? config.headerText : undefined}
        noPadding={config ? config.noPadding : false}
        headerClassName={classnames(classes.header, {
          [classes.headerNoPadding]: config && config.noPadding,
        })}
        contentClassName={classes.content}
        closeAfterTransition={config ? config.closeAfterTransition : true}
        disableBackdropClick={config ? config.disableBackdropClick : false}
        disableEscapeKeyDown={config ? config.disableEscapeKeyDown : false}
      />
    );
  }

  return null;
};

const mapStateToProps = ({ modal: state }, props) => {
  return {
    ...state,
    open: state.open,
    type: state.type,
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionHideModal: () => dispatch(actionHideModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
