import * as d3 from 'd3';

const getStackedData = (data) => {
  if (!data.length) return [];

  const isSingle = data.length === 1;
  let arrValues = [];
  let causes = [];
  data.forEach((f, i) => {
    causes.push(f.name);
    if (isSingle) causes.push('_zeros'); // fix bug for a single item in d3.stackOffsetDiverging

    f.data.forEach((ff, ii) => {
      if (isNaN(ff.value) || ff.value === null) {
        ff.value = 0;
      }
      const obj = {
        [f.name]: ff.value,
        date: ff.date,
      };

      if (isSingle) obj['_zeros'] = 0; // fix bug for a single item in d3.stackOffsetDiverging

      if (i === 0) {
        arrValues.push(obj);
      } else {
        if (!arrValues[ii]) arrValues[ii] = { date: ff.date };
        arrValues[ii][f.name] = ff.value;
      }
    });
  });
  arrValues.forEach((d) =>
    causes.forEach((c) => (d[c] = typeof d[c] === 'undefined' ? 0 : d[c]))
  );

  const layers = d3.stack().keys(causes).offset(d3.stackOffsetDiverging)(
    arrValues
  );

  if (isSingle) {
    layers[0].color = data[0].color;
  } else {
    layers.forEach((l, i) => (l.color = data[i].color));
  }

  return layers;
};

export { getStackedData };
