import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Router } from 'react-router';
import { connect } from 'react-redux';
import { ThemeProvider } from '../../shared';
import ModalContainer from '../Modals/ModalContainer';
import ResetPassword from '../Pages/ResetPassword';
import Notifications from '../Other/Notifications';

import Login from '../Pages/Login';
import Workspace from '../Pages/Workspace';
import Profile from '../Pages/Profile';

import Layout from '../PagesComponents/Layout';

import { isAuth } from './../../services/auth';
import history from './../../services/history';
import { actionLogin } from './../../store/actions';

import { colors } from '../../shared/theme';

import './App.scss';

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuth() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const LoginPage = connect()(({ dispatch }) => {
  const { from } = history.location.state || { from: '/' };

  return <Login login={(e, data) => dispatch(actionLogin({ data, from }))} />;
});

const App = () => {
  return (
    <ThemeProvider>
      <Router history={history}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/reset-password" component={ResetPassword} />

          <PrivateRoute path="/workspace">
            <Layout>
              <Workspace />
            </Layout>
          </PrivateRoute>
          
          <PrivateRoute path="/" exact>
            <Redirect to="/workspace" />
          </PrivateRoute>

          <PrivateRoute path="/profile">
            <Layout backgroundColor={colors.tabsBackground}>
              <Profile />
            </Layout>
          </PrivateRoute>
        </Switch>
      </Router>
      <ModalContainer />
      <Notifications />
    </ThemeProvider>
  );
};

export default App;
