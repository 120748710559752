import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  makeStyles,
  AppBar,
  Box,
  Typography,
  Tabs,
  Tab,
  withStyles,
  Link,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { colors } from '../../shared/theme';

import { actionLogout } from './../../store/actions';
import TabPanel from '../Other/TabPanel';
import GeneralTab from '../Other/GeneralTab';

const MyTabs = withStyles((theme) => ({
  root: {
    backgroundColor: colors.tabsBackground,
    minHeight: '36px',
  },
  indicator: {
    display: 'none',
  },
}))(Tabs);

const MyTab = withStyles((theme) => ({
  root: {
    backgroundColor: colors.tabsBackgroundDark,
    minHeight: '36px',
    textTransform: 'capitalize',
    marginRight: 4,
  },
  selected: {
    backgroundColor: colors.white,
    position: 'relative',
  },
}))(Tab);

const useStyles = makeStyles((theme) => {
  return {
    tabs: {
      backgroundColor: colors.tabsBackground,
      padding: '0px 4px',
      flexGrow: 1,
    },
    tabsContent: {
      maxWidth: 800,
      height: 'calc(100vh - 42px)',
      minHeight: '500px',
    },
    appBar: {
      top: '3.5rem',
      left: 'auto',
      right: 'auto',
      zIndex: 9,
      boxShadow: 'none',
      backgroundColor: colors.tabsBackground,
    },
    selected: {
      position: 'relative',
      // borderBottom: 0,
    },
    tab: {
      minHeight: '36px',
      textTransform: 'capitalize',
    },
    content: {
      paddingTop: 140,
      height: '100%',
      backgroundColor: colors.white,
      padding: 8,
    },
    link: {
      display: "flex",
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    linkWorkspace: {
      position: 'absolute',
      top: 75,
      left: 20,
    },
  };
});

function a11yProps(index) {
  return {
    id: `profile-header-tab-${index}`,
    'aria-controls': `profile-header-tabpanel-${index}`,
  };
}

const getDefaultTab = (history) => {
  switch (history.location.hash) {
    case 'access-rights':
      return 1;
    case 'billing-info':
      return 2;

    default:
      return 0;
  }
};

function Profile({}) {
  const classes = useStyles();

  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(getDefaultTab(history));

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        history.push('/profile');
        break;

      case 1:
        history.push('/profile#access-rights');
        break;

      case 2:
        history.push('/profile#billing-info');
        break;
    }
  }, [selectedTab]);

  const renderTabs = useCallback(() => {
    return (
      <Box className={classes.tabsContent} mx="auto">
        <Box className={classes.linkWorkspace}>
          <Link className={classes.link} href="/workspace" variant="body1">
            
          <ArrowBackIcon /> <Box ml={0.5}>Workspace</Box>
          </Link>
        </Box>
        <AppBar className={classes.appBar} position="fixed" color="default">
          <Box pt={1.5} pb={1} px={1.5}>
            <Typography variant="subtitle1" component="span">
              Your Profile
            </Typography>
          </Box>

          <MyTabs
            value={selectedTab}
            onChange={(e, n) => setSelectedTab(n)}
            indicatorColor="primary"
            textColor="primary"
            aria-label="profile tabs"
          >
            <MyTab label="General" {...a11yProps(0)} />
            <MyTab label="Access Rights" {...a11yProps(1)} />
            <MyTab label="Billing Info" {...a11yProps(2)} />
          </MyTabs>
        </AppBar>
        <Box className={classes.content}>
          <TabPanel value={selectedTab} index={0}>
            <GeneralTab />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}></TabPanel>
          <TabPanel value={selectedTab} index={2}></TabPanel>
        </Box>
      </Box>
    );
  }, [selectedTab]);

  return <Box className={classes.tabs}>{renderTabs()}</Box>;
}

const mapStateToProps = ({ data: state }, props) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
