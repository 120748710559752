import * as d3 from 'd3';
import colors from '../../../store/reducers/config/colors';

const updateLegend = ({
  data,
  svg,
  type,
  margin,
  innerHeight,
  innerWidth,
  axisFontSize,
  color_codes,
  full_screen,
}) => {
  svg.select('.legend').selectAll('.legend-item').remove();

  const _legend = svg.select('.legend');

  const legend = _legend
    .selectAll('.legend-item')
    .data(
      data.length === 1 && data[0].singleLegendMode
        ? data[0].data.map((d) => ({
            name: d.date,
            type: data[0].type,
            color: d.color,
          }))
        : data
    )
    .enter()
    .append('g')
    .attr('class', 'legend-item');

  let xOffset = 0,
    yOffset = 0,
    xPadding = 25,
    yPadding = 12;

  legend.each((f, i) => {
    const node = legend.nodes()[i];
    const legendItem = d3.select(node);

    legendItem.attr('transform', `translate(${xOffset},${yOffset})`);

    if (type === 'bar-line') {
      if (f.type === 'bar') {
        legendItem
          .append('rect')
          .attr('x1', 0)
          .attr('y1', 0)
          .attr('width', 30)
          .attr('height', 7)
          .attr(
            'fill',
            color_codes[f.name.split(' - ')[0]] || f.color || colors[i]
          );
      } else if (f.type === 'line') {
        legendItem
          .append('line')
          .attr('x1', 0)
          .attr('y1', 4)
          .attr('x2', 30)
          .attr('y2', 4)
          .attr(
            'stroke',
            color_codes[f.name.split(' - ')[0]] ||
              (f.styles && f.styles.color) ||
              colors[i]
          )
          .attr('stroke-width', (f.styles && f.styles.strokeWidth) || 3)
          .attr(
            'stroke-dasharray',
            (f.styles && f.styles.strokeDasharray) || 0
          );

        if (f.dots) {
          legendItem
            .append('circle')
            .attr('fill', '#fff')
            .attr(
              'stroke',
              color_codes[f.name.split(' - ')[0]] ||
                (f.styles && f.styles.color) ||
                colors[i]
            )
            .attr('class', 'dot')
            .attr('cx', 15)
            .attr('cy', 4)
            .attr('r', 3);
        }
      }

      legendItem
        .append('text')
        .attr('x', 35)
        .attr('y', 6.5)
        .text(f.name)
        .style('font-size', axisFontSize);
    } else {
      if (f.type === 'line') {
        legendItem
          .append('line')
          .attr('x1', 0)
          .attr('y1', 0)
          .attr('x2', 30)
          .attr('y2', 0)
          .attr('fill', 'none')
          .attr(
            'stroke',
            color_codes[f.name.split(' - ')[0]] ||
              (f.styles && f.styles.color) ||
              colors[i]
          )
          .attr('stroke-width', 3);

        if (f.dots) {
          legendItem
            .append('circle')
            .attr('fill', '#fff')
            .attr(
              'stroke',
              color_codes[f.name.split(' - ')[0]] ||
                (f.styles && f.styles.color) ||
                colors[i]
            )
            .attr('class', 'dot')
            .attr('cx', 15)
            .attr('cy', 0)
            .attr('r', 3);
        }
      } else if (f.type === 'bar') {
        legendItem
          .append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', 7)
          .attr('height', 7)
          .style(
            'fill',
            color_codes[f.name.split(' - ')[0]] || f.color || colors[i]
          );
      }
      legendItem
        .append('text')
        .attr('x', f.type === 'line' ? 35 : 15)
        .attr('y', f.type === 'line' ? 3 : 6.5)
        .text(f.name)
        .style('font-size', axisFontSize);
    }

    const rect = node.getBBox();
    if (xOffset + rect.width + xPadding > innerWidth - 5) {
      xOffset = 0;
      yOffset += yPadding;
    } else {
      xOffset += rect.width + xPadding;
    }
  });

  const size = _legend.node() ? _legend.node().getBBox() : { width: 0 };
  _legend.attr(
    'transform',
    `translate(${margin.left + (innerWidth - size.width) / 2},${
      margin.top + innerHeight + (full_screen ? 55 : 45)
    })`
  );
};

export { updateLegend };
