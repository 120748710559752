import React, { useCallback, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

import { useStyles, Copyright } from './Login';

export default function SignInSide() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const check = useCallback(
    (e) => {
      if (email) {
        console.log('Reset Password');
      } else {
        setError('email');
      }
    },
    [email]
  );

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Your Password
          </Typography>
          <Box my={2}>
            <Typography component="h3" variant="body1" align="center">
              Enter your email below and we'll send you a link to reset your
              password.
            </Typography>
          </Box>
          <form className={classes.form} noValidate>
            <TextField
              error={error === 'email'}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onInput={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <Button
              // type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => check()}
            >
              Reset Password
            </Button>
            <Box mt={1} display="flex" justifyContent="center">
              <Link className={classes.link} href="/login" variant="subtitle1">
                Return to Sign In
              </Link>
            </Box>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
