import * as d3 from 'd3';

const prepareDomains = ({
  data,
  type,
  chartType,
  x,
  y,
  y1,
  yDomain,
  secondYAxis,
  all_data = []
}) => {
  if (!data.length) return;

  const getYDomain = (data) => {
    if (!data.length) return [0, 0];

    const arrMaxValues = data.map((series) => {
      return d3.max(series.data, function (d) {
        return d.value || -Infinity;
      });
    });
    const maxValue = Math.max.apply(null, arrMaxValues);

    const arrMinValues = data.map((series) => {
      return d3.min(series.data, function (d) {
        return d.value || Infinity;
      });
    });
    const minValue = Math.min.apply(null, arrMinValues);

    const _yDomain =
      type === 'multiline' && chartType !== 'launch'
        ? [minValue, maxValue]
        : [Math.min(0, minValue), maxValue];
    const delta = 0.1 * Math.abs(_yDomain[1] - _yDomain[0]);

    return [
      _yDomain[0] === 0 ? _yDomain[0] : _yDomain[0] - delta,
      _yDomain[1] + delta,
    ];
  };

  if (type === 'stacked' || type === 'stacked-line') {
    let arrValues = [];
    data.forEach((f) => {
      f.forEach((ff) => {
        arrValues.push(ff[0]);
        arrValues.push(ff[1]);
      });
    });

    x.domain(
      data[0].map((d) => {
        return d.data.date;
      })
    );

    const _yDomain = [
      Math.min(0, Math.min.apply(null, arrValues)),
      Math.max.apply(null, arrValues),
    ];
    const delta = 0.1 * Math.abs(_yDomain[1] - _yDomain[0]);
    y.domain([
      _yDomain[0] < 0 ? _yDomain[0] - delta : _yDomain[0],
      _yDomain[1] + delta,
    ]);
    if (yDomain) y.domain(yDomain);

    if (all_data && secondYAxis) {
      y1.domain(getYDomain(all_data.filter((d) => d.secondYAxis)));
    }
  }

  // horizontal
  else if (type === 'horizontal-bar') {
    // x domain
    const maxValue = d3.max(data[0].data, (d) => d.value || 0);
    x.domain([0, 1.1 * maxValue]);

    // y domain
    let _yDomain = [];
    data.forEach((series) => {
      if (series.domain) {
        _yDomain = _yDomain.concat(series.domain);
      } else {
        series.data.forEach((d) => d.name !== null && _yDomain.push(d.name));
      }
    });
    y.domain(_yDomain);
  } else {
    // y domain
    if (secondYAxis)
      y1.domain(getYDomain(data.filter((d) => d.secondYAxis)));
    y.domain(getYDomain(data.filter((d) => !d.secondYAxis)));

    // x domain
    let xDomain = [];
    data.forEach((d) => {
      if (d.domain) {
        xDomain = xDomain.concat(d.domain);
      } else {
        d.data.forEach((dd) => dd.date !== null && xDomain.push(dd.date));
      }
    });
    x.domain(xDomain);
  }
};

const updateYDomain = (transform_arr, keys, y) => {
  const max_bar_values = d3.max(transform_arr, (d) =>
    d3.max(keys, (key) => d[key.name])
  );
  const min_bar_values = d3.min(transform_arr, (d) =>
    d3.min(keys, (key) => d[key.name])
  );

  const _min_value = min_bar_values < 0 ? min_bar_values : 0;
  y.domain([_min_value, max_bar_values]);
};

export { prepareDomains, updateYDomain };
