import React from "react";
import VirtualList from "react-tiny-virtual-list";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Typography, Box, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import Calendar from "./Calendar";

import SimpleTooltip from "./../../Other/SimpleTooltip"

const maxHeight = 211;

const TOOLTIP_LABELS = {
  EU: "Euro in Manufacturer Price",
  TD: "Treatment Days",
  LC: "Local Currency",
  SU: "Standard Units (Tabs, Vials…)",
  Peq: "Patient Equivalent",
  PB: "Euro in Public Price",
  RX: "Prescription",
  PACK: "Packs",

  Sales: "Absolute Sales",
  MS: "Market Share",
  "MS vs PY": "Market Share Delta vs. Past Year",
  "Gt vs PY": "Growth vs. Past Year",
  "Gt vs Mkt": "Growth vs. Total Market",
  EI: "Evolution Index",

  MTH: "Month",
  RQTR: "Rolling Quarter",
  R6M: "Rolling 6 Months",
  YTD: "Year to Date",
  MAT: "Moving Annual Total",
};

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 0,
    padding: 0,
    "&$expanded": {
      minHeight: 0,
      padding: 0,
    },
  },
  content: {
    padding: "0.05rem 0rem",
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
  expandIcon: {
    padding: 0,
    marginRight: 0,
    "&$expanded": {
      padding: 0,
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  rpButtons: {
    display: "flex",
    flexWrap: "wrap",
    //overflowY: "auto",
    maxHeight: maxHeight + "px",
  },
  rpButtonsItems: {
    display: "inline-block",
    textAlign: "center",
  },
  controlsButton: {
    backgroundColor: "#e2e6e9",
    margin: "2px",
    padding: "0px 5px",
    fontSize: "11px",
    borderRadius: "2px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  disabledButton: {
    pointerEvents: "none",
    backgroundColor: "#f7f7f7",
    color: "darkgray",
  },
  resetFiltersButton: {
    position: "absolute",
    height: "18px",
    width: "18px",
    fontSize: "0.8rem",
    top: "2px",
    right: "25px",
    color: " rgba(255, 0, 0, 0.6)",
    cursor: "pointer",
    zIndex: 10,

    "&:hover": {
      color: "red",
    },
  },
  brackets: {
    color: theme.palette.grey[500],
    fontWeight: "100",
    marginLeft: "5px",
    marginTop: "2px",
  },
}));

const getHighlightedText = (text, higlight) => {
  // Split on higlight term and include term into parts, ignore case
  if (!higlight) return text;

  let parts = text.split(new RegExp(`(${higlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === higlight.toLowerCase()
              ? { fontWeight: "bold" }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
};

export default function Control(props) {
  const { data, index, tabId, filter, expanded, setExpanded } = props;

  const classes = useStyles();

  const actives = data.controls.filter((f) => f.active).length;
  let dataControls = data.controls.sort((a, b) => {
    if (tabId === 3) return true;

    // sort to display the filtered items first, non-filtered items will be hide
    if (filter) {
      if (
        a.name.toLowerCase().indexOf(filter) > -1 &&
        b.name.toLowerCase().indexOf(filter) === -1
      )
        return -1;
      if (
        a.name.toLowerCase().indexOf(filter) === -1 &&
        b.name.toLowerCase().indexOf(filter) > -1
      )
        return 1;
    }

    if (!a.disabled && b.disabled) return -1;
    if (a.disabled && !b.disabled) return 1;

    if (!a.disabled && !b.disabled) return a.name > b.name ? 1 : -1;
    if (a.disabled && b.disabled) return a.name > b.name ? 1 : -1;
  });
  if (filter && tabId < 3)
    dataControls = dataControls.filter(
      (d) => d.name.toLowerCase().indexOf(filter) > -1
    );
  if (tabId === 3) dataControls = dataControls.filter((d) => !d.hidden);

  const numOfColumns = data.layout || 2; // number of columns

  const handleChange = (panel) => (event, newExpanded) => {
    if (newExpanded) setExpanded(panel);
  };

  return (
    <Box position="relative">
      {actives !== 0 && tabId !== 3 ? (
        <ClearIcon
          fontSize="small"
          className={classes.resetFiltersButton}
          onClick={() => props.actionControlReset(tabId, index)}
        />
      ) : null}

      <Accordion
        square
        expanded={expanded === index}
        onChange={handleChange(index)}
      >
        <AccordionSummary
          aria-controls={`accordion-${tabId}-${index}`}
          expandIcon={
            expanded === index ? (
              <RemoveIcon fontSize="small" />
            ) : (
              <AddIcon fontSize="small" />
            )
          }
          id={`accordion-${tabId}-${index}-header`}
        >
          <Box mr="2px">
            <Typography variant="subtitle2" component="span">
              {data.title || data.name}
            </Typography>
          </Box>
          {actives !== 0 && tabId !== 3 ? (
            <Typography variant="caption" color="error" component="span">
              {actives}
            </Typography>
          ) : null}
          {tabId !== 3 ? (
            <Typography
              className={classes.brackets}
              variant="caption"
              component="span"
            >
              [
              {filter && dataControls.length ? (
                <span>
                  <Typography
                    color="primary"
                    variant="caption"
                    component="span"
                  >
                    <b>{dataControls.length}</b>
                  </Typography>
                  {" | "}
                </span>
              ) : (
                ""
              )}
              {data.controls.length -
                data.controls.filter((c) => c.disabled).length}
              ]
            </Typography>
          ) : null}
        </AccordionSummary>

        <AccordionDetails className={classes.rpButtons}>
          <VirtualList
            width="100%"
            height={Math.min(
              maxHeight,
              4 + 19 * Math.round(dataControls.length / numOfColumns)
            )}
            itemCount={Math.round(dataControls.length / numOfColumns)}
            itemSize={19}
            renderItem={({ index: row_i, style }) => {
              const indices = Array.from(
                { length: numOfColumns },
                (_, j) => j + row_i * numOfColumns
              );
              return (
                <div key={row_i} style={style}>
                  {indices.map((i) => {
                    const d = dataControls[i];
                    return d ? (
                      <div
                        key={i}
                        className={
                          `${classes.rpButtonsItems} ` +
                          (data.className ? data.className : "")
                        }
                        style={{ width: `${Math.floor(100 / numOfColumns)}%` }}
                      >
                        <SimpleTooltip
                          title={TOOLTIP_LABELS[d.name] || d.title || d.name}
                          placement="top"
                        >
                          <Box
                            className={
                              `${classes.controlsButton} ` +
                              (d.active ? ` ${classes.activeButton} ` : "") +
                              (d.disabled ? ` ${classes.disabledButton} ` : "")
                            }
                            onClick={() =>
                              !d.disabled &&
                              props.actionControl(
                                d.active,
                                tabId,
                                index,
                                tabId === 3 && index === 7 ? d.index : i
                              )
                            }
                          >
                            {getHighlightedText(
                              tabId === 3 && [0, 1, 7, 8, 10].includes(index)
                                ? d.title || d.name
                                : d.title || d.name,
                              filter
                            )}
                          </Box>
                        </SimpleTooltip>
                      </div>
                    ) : null;
                  })}
                </div>
              );
            }}
          />
          {tabId === 3 && index === 6 ? (
            <Calendar
              className={
                `${classes.controlsButton} ` +
                (data.customDate.active ? ` ${classes.activeButton} ` : "") +
                (data.customDate.disabled ? ` ${classes.disabledButton} ` : "")
              }
              style={{ width: "100%", outline: "none" }}
              date={data.customDate.date}
              startDate={props.dateStartInit}
              endDate={props.dateEndInit}
              selectDate={(date) =>
                props.actionControl(
                  data.customDate.active,
                  tabId,
                  index,
                  -1,
                  date
                )
              }
              onClick={() =>
                props.actionControl(data.customDate.active, tabId, index, -1)
              }
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}