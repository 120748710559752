import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import './Chart.scss';
import _ from 'lodash';
import {
  prepareDomains,
  renderLineChart,
  redrawLineChart,
  updateAxes,
  renderBarChart,
  redrawBarChart,
  getStackedData,
  renderStackChart,
  renderHorizontalBarChart,
  updateLegend,
  renderNoData,
} from './chartComponents';
import { useMounted } from '../../services/hooks';

const defaultProps = {
  margin: { top: 15, right: 30, bottom: 90, left: 50 },
  width: 540,
  height: 280,
};

const Chart =
  // React.memo(
  (props) => {
    const {
      data: dataProp,
      id,
      type,
      format: formatProp,
      chartType: chartTypeProp,
      margin: marginProp,
      secondYAxis,
      secondYAxisFormat: secondYAxisFormatProp,
      yDomain: yDomainProp,
      showLabelsProp,
      selectedDate: selectedDateProp,
      full_screen: full_screenProp,
      color_codes,
      changeSelectedDate,
      isShowRP,
    } = props;

    const isMounted = useMounted();

    const rootRef = useRef();
    const svgRef = useRef();
    const svg = useRef();
    const g = useRef();
    const data = useRef();
    const width = useRef();
    const height = useRef();
    const full_screen = useRef();
    const selectedDate = useRef();
    const format = useRef();
    const secondYAxisFormat = useRef();
    const showLabels = useRef();
    const chartType = useRef();
    const yDomain = useRef();
    const axisFontSize = useRef();
    const labelFontSize = useRef();
    const margin = useRef();
    const innerWidth = useRef();
    const innerHeight = useRef();
    const x = useRef();
    const y = useRef();
    const y1 = useRef();
    const center = useRef();
    const defined = useRef();
    const tooltip = useRef();

    data.current = useMemo(() => dataProp, [dataProp]);
    selectedDate.current = useMemo(() => selectedDateProp, [selectedDateProp]);
    format.current = useMemo(() => formatProp, [formatProp]);
    yDomain.current = useMemo(() => yDomainProp, [yDomainProp, formatProp]);

    useEffect(() => {
      _updateDimensions();
      initGraph();
      renderGraph();
      window.addEventListener('resize', () =>
        updateResize({ fs: full_screen.current })
      );

      return () => window.removeEventListener('resize', updateResize);
    }, []);

    useEffect(() => {
      if (isMounted) {
        // setTimeout(() => {
        refresh();
        // }, 200);
      }
    }, [dataProp]);

    useEffect(() => {
      if (isMounted) {
        // setTimeout(() => {
        _updateDimensions();
        refresh();
        // }, 10);
      }
    }, [isShowRP]);

    useEffect(() => {
      if (isMounted) {
        d3.select(svgRef.current).style('opacity', 0.0001);
        // setTimeout(() => {
        _updateDimensions();
        refresh();
        d3.select(svgRef.current).style('opacity', 1);
        // }, 10);
      }
    }, [full_screenProp]);

    const renderGraph = useCallback(() => {
      updateSvg();

      // check if data is empty
      if (_renderNoData()) return;

      if (type === 'bar-line') {
        _prepareDomains();
        _renderBarChart();
        _renderLineChart();
      } else if (type === 'multiline') {
        _prepareDomains();
        _renderLineChart();
      } else if (type === 'stacked') {
        const layers = getStackedData(data.current);
        _prepareDomains(layers);
        _renderStackChart(layers);
      } else if (type === 'stacked-line') {
        const layers = getStackedData(
          data.current.filter((d) => d.type === 'bar')
        );
        _prepareDomains(layers, data.current);
        _renderStackChart(layers);
        _renderLineChart();
      } else if (type === 'horizontal-bar') {
        _prepareDomains();
        _renderHorizontalBarChart();
      }

      // // update axes
      _updateAxes();

      // update legend
      if (type === 'horizontal-bar') {
        _updateLegend(
          data.current[0].legend.map((l, i) => ({
            type: 'bar',
            name: l,
            color: data.current[0].colors[i],
          }))
        );
      } else {
        _updateLegend();
      }
    });

    const refresh = useCallback(() => {
      updateSvg();

      // check if data is empty
      if (_renderNoData()) return;

      if (type === 'bar-line') {
        _prepareDomains();
        _redrawBarChart();
        _redrawLineChart();
      } else if (type === 'multiline') {
        _prepareDomains();
        _redrawLineChart();
      } else if (type === 'stacked') {
        const layers = getStackedData(data.current);
        _prepareDomains(layers);
        _renderStackChart(layers);
      } else if (type === 'stacked-line') {
        const layers = getStackedData(
          data.current.filter((d) => d.type === 'bar')
        );
        _prepareDomains(layers, data.current);
        _renderStackChart(layers);
        _redrawLineChart();
      } else if (type === 'horizontal-bar') {
        _prepareDomains();
        _renderHorizontalBarChart();
      }

      // // update axes
      _updateAxes();

      // update legend
      if (type === 'horizontal-bar') {
        _updateLegend(
          data.current[0].legend.map((l, i) => ({
            type: 'bar',
            name: l,
            color: data.current[0].colors[i],
          }))
        );
      } else {
        _updateLegend();
      }
    });

    const updateResize = useCallback(({ fs }) => {
      _updateDimensions({ fs });
      refresh();
    });

    const _prepareDomains = useCallback((_data, all_data) =>
      prepareDomains({
        data: _data || data.current,
        type,
        chartType: chartType.current,
        x: x.current,
        y: y.current,
        y1: y1.current,
        yDomain: yDomain.current,
        secondYAxis,
        all_data: all_data || [],
      })
    );
    const _renderBarChart = useCallback(() =>
      renderBarChart({
        data: data.current.filter((d) => d.type === 'bar'),
        g: g.current,
        innerWidth: innerWidth.current,
        height: height.current,
        margin: margin.current,
        labelFontSize: labelFontSize.current,
        color_codes,
        tooltip: tooltip.current,
        format: format.current,
        innerWidth: innerWidth.current,
        x: x.current,
        y: y.current,
        showLabels: showLabels.current,
      })
    );
    const _redrawBarChart = useCallback(() =>
      redrawBarChart({
        data: data.current.filter((d) => d.type === 'bar'),
        g: g.current,
        innerWidth: innerWidth.current,
        height: height.current,
        margin: margin.current,
        labelFontSize: labelFontSize.current,
        color_codes,
        tooltip: tooltip.current,
        format: format.current,
        innerWidth: innerWidth.current,
        x: x.current,
        y: y.current,
        showLabels: showLabels.current,
      })
    );
    const _renderLineChart = useCallback(() =>
      renderLineChart({
        data: data.current.filter((d) => d.type === 'line'),
        g: g.current,
        defined: defined.current,
        color_codes,
        x: x.current,
        y: y.current,
        y1: y1.current,
        secondYAxisFormat: secondYAxisFormat.current,
        format: format.current,
        tooltip: tooltip.current,
        chartType: chartType.current,
        labelFontSize: labelFontSize.current,
        showLabels: showLabels.current,
      })
    );
    const _redrawLineChart = useCallback(() =>
      redrawLineChart({
        data: data.current.filter((d) => d.type === 'line'),
        g: g.current,
        defined: defined.current,
        color_codes,
        x: x.current,
        y: y.current,
        y1: y1.current,
        secondYAxisFormat: secondYAxisFormat.current,
        format: format.current,
        tooltip: tooltip.current,
        chartType: chartType.current,
        labelFontSize: labelFontSize.current,
        showLabels: showLabels.current,
      })
    );
    const _renderStackChart = useCallback((_data) =>
      renderStackChart({
        data: _data,
        id,
        g: g.current,
        color_codes,
        x: x.current,
        y: y.current,
        format: format.current,
        tooltip: tooltip.current,
        showLabels: showLabels.current,
      })
    );
    const _renderHorizontalBarChart = useCallback(() =>
      renderHorizontalBarChart({
        data: data.current,
        g: g.current,
        x: x.current,
        y: y.current,
        format: format.current,
        tooltip: tooltip.current,
        labelFontSize: labelFontSize.current,
      })
    );
    const _updateAxes = useCallback(() =>
      updateAxes({
        svg: svg.current,
        g: g.current,
        x: x.current,
        y: y.current,
        y1: y1.current,
        innerHeight: innerHeight.current,
        type,
        chartType: chartType.current,
        selectedDate: selectedDate.current,
        id,
        axisFontSize: axisFontSize.current,
        format: format.current,
        secondYAxis,
        secondYAxisFormat: secondYAxisFormat.current,
        center: center.current,
      })
    );
    const _updateLegend = useCallback((_data) =>
      updateLegend({
        data: _data || data.current,
        svg: svg.current,
        innerHeight: innerHeight.current,
        innerWidth: innerWidth.current,
        type,
        axisFontSize: axisFontSize.current,
        margin: margin.current,
        color_codes,
        full_screen: full_screen.current,
      })
    );
    const _renderNoData = useCallback(() =>
      renderNoData({
        data: data.current,
        svg: svg.current,
        height: height.current,
        width: width.current,
      })
    );
    const _updateDimensions = useCallback(() => updateDimensions());

    const updateDimensions = (props) => {
      full_screen.current = props?.fs || full_screenProp;
      secondYAxisFormat.current = secondYAxisFormatProp || format.current;
      showLabels.current = showLabelsProp;
      if (['bar-line', 'stacked', 'stacked-line', 'multiline'].includes(type)) {
        showLabels.current =
          !window.matchMedia('(max-width : 1024px)').matches &&
          full_screen.current;
      }
      chartType.current = chartTypeProp || 'sales';

      axisFontSize.current = full_screen.current ? '11px' : '8px';
      labelFontSize.current = full_screen.current ? '12px' : '10px';

      const _width = rootRef.current
        ? rootRef.current.parentNode.clientWidth
        : 0;
      const _height = rootRef.current
        ? rootRef.current.parentNode.clientHeight - 22
        : 0;

      width.current = _width || defaultProps.width;
      height.current = _height || defaultProps.height;
      margin.current = marginProp || defaultProps.margin;

      innerWidth.current =
        width.current - margin.current.left - margin.current.right;
      innerHeight.current =
        height.current -
        margin.current.top -
        margin.current.bottom +
        (full_screen.current ? -10 : 0);
      if (type === 'horizontal-bar') {
        x.current = d3.scaleLinear().rangeRound([0, innerWidth.current]);
        y.current = d3
          .scaleBand()
          .padding(0.2)
          .rangeRound([0, innerHeight.current]);
      } else {
        y.current = d3.scaleLinear().rangeRound([innerHeight.current, 0]);
        x.current = d3
          .scaleBand()
          .padding(0.2)
          .rangeRound([0, innerWidth.current]);
      }

      // secondYAxis
      if (secondYAxis)
        y1.current = d3.scaleLinear().rangeRound([innerHeight.current, 0]);
      center.current = d3.scaleLinear().range([0, innerWidth.current]);

      defined.current = (d) =>
        ['sales', 'wk_sales', 'finance'].includes(chartType.current) &&
        !['DMS'].includes(id)
          ? !isNaN(d.value) && d.value !== null && d.value !== 0
          : !isNaN(d.value) && d.value !== null;
    };

    const updateSvg = () => {
      svg.current = d3
        .select(svgRef.current)
        .attr(
          'width',
          innerWidth.current + margin.current.left + margin.current.right
        )
        .attr(
          'height',
          innerHeight.current +
            margin.current.top +
            margin.current.bottom +
            (full_screen.current ? 10 : 0)
        );
    };

    const initGraph = () => {
      // clear old svg
      // d3.select(svgRef.current)
      //   .selectAll('.' + type + '-svg')
      //   .remove();

      // // clear old tooltip
      // d3.select('body')
      //   .select('#tooltip_' + id)
      //   .remove();

      // create new tooltip
      tooltip.current = d3.select('.tooltip');

      if (!tooltip.current.node()) {
        tooltip.current = d3
          .select('body')
          .append('div')
          .attr('id', 'tooltip_' + id)
          .attr('class', 'tooltip')
          .style('opacity', 0);
      }

      // add svg
      svg.current = d3
        .select(svgRef.current)
        .attr('class', type + '-svg')
        .attr(
          'width',
          innerWidth.current + margin.current.left + margin.current.right
        )
        .attr(
          'height',
          innerHeight.current +
            margin.current.top +
            margin.current.bottom +
            (full_screen.current ? 10 : 0)
        );

      if (selectedDate.current) {
        svg.current.style('cursor', 'pointer');
        svg.current.on('click', () => {
          const eachBand = x.current.step();
          const domain = x.current.domain();
          let index = Math.floor(
            (d3.mouse(d3.event.target)[0] -
              (d3.event.target.tagName === 'svg' ? margin.current.left : 0) -
              x.current(domain[0])) /
              eachBand
          );
          index =
            index < 0
              ? 0
              : index > domain.length - 1
              ? domain.length - 1
              : index;
          const date = domain[index];
          changeSelectedDate(id, date);
        });
      }

      // add g
      g.current = svg.current
        .append('g')
        .attr(
          'transform',
          'translate(' + margin.current.left + ',' + margin.current.top + ')'
        );

      // add legend
      svg.current.append('g').attr('class', 'legend');

      // add axes
      g.current.append('g').attr('class', 'x-axis');
      g.current.append('g').attr('class', 'y-axis');
      if (secondYAxis)
        g.current
          .append('g')
          .attr('class', 'y-axis y1-axis')
          .attr('transform', 'translate( ' + innerWidth.current + ', 0 )');
      g.current.append('g').attr('class', 'zero-line');
      // this.updateAxes(data);

      // add bar chart
      g.current.append('g').attr('class', 'barChart');

      // add stacked bar chart
      g.current.append('g').attr('class', 'stackChart');

      // add multiple line charts
      g.current.append('g').attr('class', 'lines').attr('fill', 'none');
    };

    return (
      <div ref={rootRef} className='multi-chart' id={id}>
        <svg ref={svgRef} />
      </div>
    );
  };
//   (prev, next) => _.isEqual(prev, next)
// );

export default Chart;
