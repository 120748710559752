import _ from './../constants';

export const isAuth = () => _.STORAGE.getItem(_.SESSION_TOKEN);
export const email = () => _.STORAGE.getItem(_.USER_EMAIL);
export const roles = () => _.STORAGE.getItem(_.USER_ROLES);
export const full_name = () => _.STORAGE.getItem(_.USER_FULL_NAME);

export const login = (data) => {
  if (data) {
    _.STORAGE.setItem(_.SESSION_TOKEN, data.token);
    _.STORAGE.setItem(_.USER_EMAIL, data.email);
    _.STORAGE.setItem(_.USER_ROLES, data.role);
    _.STORAGE.setItem(_.USER_FULL_NAME, data.full_name);
  }
};

export const logout = () => {
  _.STORAGE.removeItem(_.SESSION_TOKEN);
  _.STORAGE.removeItem(_.USER_EMAIL);
  _.STORAGE.removeItem(_.USER_ROLES);
  _.STORAGE.removeItem(_.USER_FULL_NAME);
};

export const update_user_info = (data) => {
  _.STORAGE.setItem(_.USER_EMAIL, data.email);
  _.STORAGE.setItem(_.USER_FULL_NAME, data.full_name);
};
